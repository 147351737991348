import * as React from 'react';
import PropTypes from 'prop-types';
import {
  RootContext,
  SvgFullscreenIcon,
  SvgNormalscreenIcon,
  SvgMoreIcon,
  SvgZoomIcon,
  SvgCameraIcon,
  SvgArrowPrevIcon,
  SvgArrowNextIcon,
} from './index.js';

export default class AgoraMembers extends React.Component {
  static contextType = RootContext;

  constructor (props) {
    super(props);
    this.state = {
      activeArea: 0,
    };

    this.getAudioLevel = this.getAudioLevel.bind(this);
    this.onMemberPrev = this.onMemberPrev.bind(this);
    this.onMemberNext = this.onMemberNext.bind(this);
  }

  componentDidMount () {
    this.getAudioLevel();
  }

  getAudioLevel () {
    const members = this.props.members.map(member => {
      if (member.stream) {
        const vol = member.stream.getAudioLevel();
        member.style = `solid 1px #33${('000' + Math.min(Math.floor(vol * 512), 255).toString(16)).slice(-2)}33`;
      }
      return member;
    });
    this.setState({ members: members });
    setTimeout(() => { this.getAudioLevel(); }, 100);
  }

  onMemberPrev () {
    if (this.state.activeArea === 0) {
      this.setState({ activeArea: Math.floor(this.props.members.length / 4) });
    } else {
      this.setState(state => ({ activeArea: state.activeArea - 1 }));
    }
  }

  onMemberNext () {
    if (this.state.activeArea === Math.floor(this.props.members.length / 4)) {
      this.setState({ activeArea: 0 });
    } else {
      this.setState(state => ({ activeArea: state.activeArea + 1 }));
    }
  }

  render () {
    const members = this.props.isPatient ? this.props.members.filter(member => member.stream && !member.mained) : this.props.members.filter(member => !member.mained);
    const areas = [].concat.apply([], (members || []).map((member, i) => {
      return i % this.props.size ? [] : [members.slice(i, i + this.props.size)];
    }));
    const index = areas.length <= this.state.activeArea ? 0 : this.state.activeArea;
    return (
      <div className={ this.props.isSP ? 'p-member-area-sp' : 'p-member-area'}>
        { this.props.isDoctor && members.length > this.props.size ? (<div className="p-member-area--prev">
          <button onClick={this.onMemberPrev}>{ SvgArrowPrevIcon }</button>
        </div>) : null }
        <div className="p-member-area--list">
          {areas.map((area, i) => {
            return (
              <div key={`area-${i}`} className={index === i ? 'p-member-area--card-area' : 'd-n' }>
                {area.map(member => {
                  return (
                    <div key={member.uid} className={member.mained ? 'd-n' : ''}>
                      { member.joined ? (
                        <div id={member.uid} style={{ border: member.style }} className="p-member-area--card">
                          { !member.stream || member.muted ? (
                            <div className="p-member-area--card--videomute">{ SvgCameraIcon }</div>
                          ) : null }
                          <div className="p-member-area--card--btn">
                            <button onClick={ () => { this.props.onMemberZoom(member.uid); } }>{ SvgZoomIcon }</button>
                            { member.uid === 'local_stream' || this.props.isPatient ? null : (
                              <button onClick={ () => { this.props.onMemberMore(member.uid); } }>{ SvgMoreIcon }</button>
                            )}
                          </div>
                          { member.uid === 'local_stream' || this.props.isPatient ? null : (
                            <div className={ member.more ? 'p-member-area--card__kick' : 'd-n'}>
                              <p><a onClick={ () => { this.props.onMemberKick(member.uid); } }>強制退室させる</a></p>
                            </div>
                          )}
                        </div>
                      ) : this.props.isDoctor ? (
                        <div id={member.uid} className="p-member-area--card">
                          <p>{member.name}が入室しようとしています。</p>
                          <div className="p-member-area--card--invate">
                            <a className="c-mn-btn c-mn-btn--basic-s c-mn-btn--third c-mn-btn--compact js-modal-dialog02-close" onClick={() => { this.props.onMemberReject(member.uid); }}>
                              <span>拒否</span>
                            </a>
                            <a className="c-mn-btn c-mn-btn--basic-s c-mn-btn--green c-mn-btn--compact js-modal-dialog02-close" onClick={() => { this.props.onMemberAccept(member.uid); }}>
                              <span>許可</span>
                            </a>
                          </div>
                        </div>
                      ) : null }
                    </div>
                  );
                })}
              </div>
            );
          })}
          {
            this.props.isDoctor || this.props.isSP ? null : (
              <div className="p-member-area--language">
                <div>
                  { this.context.lang === 'ja' ? 'JP' : (<a onClick={ () => {
                    this.context.language('ja');
                  }}>JP</a>) }
                  &nbsp;/&nbsp;
                  { this.context.lang !== 'ja' ? 'EN' : (<a onClick={ () => {
                    this.context.language('en');
                  }}>EN</a>) }
                </div>
              </div>
            )
          }
        </div>
        { this.props.isPatient && members.length > this.props.size ? (<div className="p-member-area--prev">
          <button onClick={this.onMemberPrev}>{ SvgArrowPrevIcon }</button>
        </div>) : null }
        { members.length > this.props.size ? (<div className="p-member-area--next">
          <button onClick={this.onMemberNext}>{ SvgArrowNextIcon }</button>
        </div>) : null }
        <div className={this.props.onFullscreen ? 'p-menu-option__zoom' : 'd-n'}>
          <a onClick={this.props.onFullscreen}>
            { this.props.isFullscreen ? SvgNormalscreenIcon : SvgFullscreenIcon }
          </a>
        </div>
      </div>
    );
  }
}

AgoraMembers.propTypes = {
  size: PropTypes.number, // 表示サイズ
  isDoctor: PropTypes.bool,
  isPatient: PropTypes.bool,
  isFullscreen: PropTypes.bool,
  isSP: PropTypes.bool,
  members: PropTypes.array, // メンバー情報
  onMemberReject: PropTypes.func, // メンバー拒否
  onMemberAccept: PropTypes.func, // メンバー許可
  onMemberZoom: PropTypes.func, // 拡大表示
  onMemberKick: PropTypes.func, // 強制退室
  onMemberMore: PropTypes.func, // コンテキスト表示
  onFullscreen: PropTypes.func, // フルスクリーン切り替え
};
