import * as React from 'react';
import {
  RootContext,
} from '../../../../index';
import IconInfoCircle from '../../../../common/components/atoms/IconInfoCircle';
import {
  InvoiceForm,
  InvoiceLayout,
} from './index';

export default class Refunded extends React.Component {
  static contextType = RootContext;

  render () {
    const { invoice } = this.context;
    return (
      <InvoiceLayout>
        <dl className="c-mn-message--notice">
          <dt className="c-mn-message__title"><IconInfoCircle />請求を取り消しました。</dt>
          <dd className="c-mn-message__txt">患者に直接請求書の送付などを行ってください。</dd>
        </dl>
        <InvoiceForm invoice={invoice} disabled/>
      </InvoiceLayout>
    );
  }
}
