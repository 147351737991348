import * as React from 'react';
import PropTypes from 'prop-types';
import {
  AbstractComponent,
  MnButton,
  MnToast,
} from '../../../index';

export default class CanceledForm extends AbstractComponent {
  constructor (props) {
    super(props);
    this.state = {
      annotation: {},
      toast: null,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  validation = () => {
    return true;
  }

  handleClose = (arg) => {
    const event = arg.event;
    event.preventDefault();
    return new Promise((resolve, reject) => {
      resolve();
      setTimeout(() => {
        this.props.handleClose();
      });
    });
  }

  handleSubmit = () => {
    return new Promise((resolve, reject) => {
      if (!this.validation()) {
        return resolve();
      }
      this
        .fetch(
          this.props.apiLocation,
          {
            methods: 'PATCH',
            body: {
              state: 'canceled',
            },
          },
        )
        .then(() => {
          this.setState({ toast: 'キャンセルしました。' });
        })
        .then(() => {
          setTimeout(() => {
            this.setState({ toast: null });
          }, 3000);
        })
        .then(() => {
          setTimeout(() => {
            location.reload();
          }, 2000);
        }).catch(_error => {
          setTimeout(() => {
            this.setState({ toast: '保存できませんでした。' });
          }, 10);
        });
    });
  }

  render () {
    return (
      <form>
        <p className="c-mn-modal-dialog__title">{this.props.title ? this.props.title : 'この患者の予約をキャンセルしますか？'}</p>
        <p className="c-mn-modal-dialog__txt">{this.props.description ? this.props.description : '予約をキャンセルすると患者に通知されます。'}</p>
        <div className="is-flex jc-fe">
          <MnButton
            class="c-mn-btn c-mn-btn--basic-s c-mn-btn--third c-mn-btn--compact p-treatment__btn"
            onClick={this.handleClose}
          >
            <span>いいえ</span>
          </MnButton>
          <MnButton
            class="c-mn-btn c-mn-btn--basic-s c-mn-btn--first c-mn-btn--compact p-treatment__btn ml-md"
            onClick={this.handleSubmit}
          >
            <span>キャンセル</span>
          </MnButton>
        </div>
        <MnToast shown={ this.state.toast !== null }><span>{ this.state.toast }</span></MnToast>
      </form>
    );
  }
}

CanceledForm.propTypes = {
  handleClose: PropTypes.func,
  apiLocation: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};
