import React from 'react';
import * as PropTypes from 'prop-types';
import {
  MnInputText,
  MnInputTextarea,
  PageSectionValidators,
} from '../../../index';

export default class PageSectionsFormItem extends React.Component {
  maxTitleLength = 30;
  maxDescriptionLength = 1000;

  constructor (props) {
    super(props);
    this.state = {
      ...props,
      title: '',
      titleCount: 0,
      description: '',
      descriptionCount: 0,
      isInputTitle: false,
      isInputDescription: false,
      isTitleLengthError: false,
      isDescriptionLengthError: false,
      isTitleBlankError: false,
      isDescriptionBlankError: false,
    };
    this.handleChangeTitle = this.handleChangeTitle.bind(this);
    this.handleChangeDescription = this.handleChangeDescription.bind(this);
  }

  componentDidMount () {
    this.setState({
      title: this.props.title,
      titleCount: this.props.title.length,
      description: this.props.description,
      descriptionCount: this.props.description.length,
      isTitleLengthError: this.props.title.length > this.maxTitleLength,
      isDescriptionLengthError: this.props.description.length > this.maxDescriptionLength,
      isTitleBlankError: this.props.title.length < 1,
      isDescriptionBlankError: this.props.description.length < 1,
    });
  }

  handleChangeTitle (e) {
    this.setState({
      title: e.value,
      titleCount: e.value.length,
      isTitleLengthError: e.value.length > this.maxTitleLength,
      isTitleBlankError: e.value.length < 1,
      isInputTitle: true,
    });
    this.props.handleUpdatePageSection(this.props.index, { title: e.value, description: this.state.description });
  }

  handleChangeDescription (e) {
    this.setState({
      description: e.value,
      descriptionCount: e.value.length,
      isDescriptionLengthError: e.value.length > this.maxDescriptionLength,
      isDescriptionBlankError: e.value.length < 1,
      isInputDescription: true,
    });
    this.props.handleUpdatePageSection(this.props.index, { title: this.state.title, description: e.value });
  }

  render () {
    return (
      <div className="p-page-sections--register-form--item p-lg" >
        <div className="columns is-mobile mb0">
          <div className="column ta-l pb0">
            <label className="c-mn-form-label">タイトル</label>
          </div>
          <div className="column ta-r pb0">
            <div className="p-page-sections--register-form--item--length-count c-mn-txt c-mn-txt--caption">
              { this.state.titleCount }/{this.maxTitleLength}文字
            </div>
          </div>
        </div>
        <div className="columns is-mobile mb0">
          <div className="column">
            <MnInputText
              name={`title_${this.props.name}`}
              value={ this.props.title }
              placeholder={ 'タイトル' }
              disabled={ this.props.disabled }
              validates={ PageSectionValidators.title }
              onValidate={ this.props.handleValidate }
              onChange={ this.handleChangeTitle }
            />
          </div>
        </div>

        <div className="columns is-mobile mt-sm mb0">
          <div className="column ta-l pb0">
            <label className="c-mn-form-label">説明</label>
          </div>
          <div className="column ta-r pb0">
            <div className="p-page-sections--register-form--item--length-count c-mn-txt c-mn-txt--caption">
              { this.state.descriptionCount }/{this.maxDescriptionLength}文字
            </div>
          </div>
        </div>
        <div className="columns is-mobile mb0">
          <div className="column">
            <MnInputTextarea
              name={`description_${this.props.name}`}
              rows={ 6 }
              value={ this.props.description }
              placeholder={ '説明' }
              disabled={ this.props.disabled }
              validates={ PageSectionValidators.description }
              onValidate={ this.props.handleValidate }
              onChange={ this.handleChangeDescription }
            />
          </div>
        </div>

        { this.props.index === 0 || this.props.disabled ? null : (
          <div
            className="c-mn-txt--caption cur-p ta-r mt-sm"
            onClick={ () => this.props.handleRemovePageSection(this.props.index) }
          >
            削除
          </div>
        ) }
      </div>
    );
  }
}

PageSectionsFormItem.propTypes = {
  name: PropTypes.string,
  index: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  handleUpdatePageSection: PropTypes.func,
  handleRemovePageSection: PropTypes.func,
  handleValidate: PropTypes.func,
};
