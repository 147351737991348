import React from 'react';
import * as PropTypes from 'prop-types';
import { AccountsRolesI18n } from '../../index';

export default class AccountListTableCell extends React.Component {
  constructor (props) {
    super(props);
    this.state = { ...props };
  }

  render () {
    return (
      <tr className="p-account-list-cell">
        <td className="c-mn-table-td c-mn-table-td-first bdr-n va-m has-background-white">
          <div className={ 'c-mn-select-wrap w-a' }>
            <select
              className={ 'c-mn-select js-select is-selected' }
              defaultValue={ this.state.accountRole }
              onChange={ (e) => {
                this.state.handleUpdateAccountRole(e.target);
                this.setState({ accountRole: e.target.value });
              } }
            >
              { AccountsRolesI18n.map((option, index) =>
                <option key={ index } value={ option.key } disabled={ option.key === this.state.accountRole } >
                  { option.value }
                </option>,
              ) }
            </select>
          </div>
        </td>
        <td className="c-mn-table-td bdr-n va-m has-background-white">{ this.state.accountName }</td>
        <td className="c-mn-table-td bdr-n va-m has-background-white">{ this.state.accountNameKana }</td>
        <td className="c-mn-table-td bdr-n va-m has-background-white">{ this.state.accountEmail }</td>
        <td className="c-mn-table-td c-mn-table-td-end va-m ta-r has-background-white">
          <button
            className={ 'c-mn-btn c-mn-btn--basic-s c-mn-btn--third c-mn-btn--compact fc-black' }
            onClick={ () => this.state.handleDestroyAccount() }
          >
            <span>削除</span>
          </button>
        </td>
      </tr>
    );
  }
}

AccountListTableCell.propTypes = {
  accountId: PropTypes.number,
  accountName: PropTypes.string,
  accountNameKana: PropTypes.string,
  accountEmail: PropTypes.string,
  accountRole: PropTypes.string,
  accountRoleSelectOptions: PropTypes.array,
  handleUpdateAccountRole: PropTypes.func,
  handleDestroyAccount: PropTypes.func,
};
