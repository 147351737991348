import * as React from 'react';
import AgoraVideo from '../AgoraVideo';

export default class TreatmentsExamination extends React.Component {
  render () {
    return (
      <AgoraVideo />
    );
  }
}
