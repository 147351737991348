import * as React from 'react';
import * as PropTypes from 'prop-types';
import {
  AbstractForm,
  MnInputText,
  MnInputTextarea,
  MnInputRadioGroup,
  InterviewSettingValidators,
  RootContext,
  InterviewSettingsInputTypesI18n,
  MenusUseInterviewsI18n,
} from '../../../index';

export default class SettingInterview extends AbstractForm {
  static contextType = RootContext;
  maxInterviewCount = 20;
  maxInputtextCount = 50;
  maxTextareaCount = 300;
  constructor (props) {
    super(props);
    this.state = {
      titleCount: [],
      placeholderCount: [],
      isAddInterview: false,
    };
    this.renderInterview = this.renderInterview.bind(this);
    this.onAddInterview = this.onAddInterview.bind(this);
    this.onDeleteInterview = this.onDeleteInterview.bind(this);
    this.checkInterviewCount = this.checkInterviewCount.bind(this);
    this.onChangeUseInterview = this.onChangeUseInterview.bind(this);
    this.onChangeInterview = this.onChangeInterview.bind(this);
    this.updateInterview = this.updateInterview.bind(this);
  }

  componentDidMount () {
    if (this.context.menu.interview_settings.length === 0) {
      this.onAddInterview();
    }
    this.checkInterviewCount();
    this.context.onStateUpdate({ onValidateInterviewSetting: this.validation });
    console.log(this.context);
  }

  onAddInterview () {
    this.context.menu.interview_settings.push({
      menu_id: this.context.menu.id,
      input_type: InterviewSettingsInputTypesI18n[0].key,
      title: '',
      annotation: '',
      display_order: this.context.menu.interview_settings.length + 1,
    });
    this.context.onStateUpdate({ menu: this.context.menu });
    this.checkInterviewCount();
  }

  onDeleteInterview (index) {
    this.validators = this.validators.filter((validator, i) => {
      if (validator.name !== `title_${index}` && validator.name !== `annotation_${index}`) {
        return validator;
      }
    });
    const menu = this.context.menu;
    menu.interview_settings = menu.interview_settings.filter((_, i) => {
      return i !== index;
    });
    this.context.onStateUpdate({ menu: menu });
    this.checkInterviewCount();
  }

  checkInterviewCount () {
    const ok = this.context.menu.interview_settings.length < this.maxInterviewCount;
    const titleCount = this.context.menu.interview_settings.map(interview => (interview.title?.length));
    const placeholderCount = this.context.menu.interview_settings.map(interview => (interview.annotation?.length));
    this.setState({
      isAddInterview: ok,
      titleCount: titleCount,
      placeholderCount: placeholderCount,
    });
  }

  onChangeUseInterview (e) {
    const menu = this.context.menu;
    menu.use_interview = e.target.value;
    this.context.onStateUpdate({ menu: menu, isEdited: true });
  }

  onChangeInterview (e) {
    const index = e.callbackParams.index;
    const key = e.callbackParams.key;
    const value = e.value;
    this.updateInterview(index, key, value);
  }

  updateInterview (index, key, value) {
    const menu = this.context.menu;
    menu.interview_settings = menu.interview_settings.map((interview, i) => {
      if (index === i) {
        interview[key] = value;
      }
      return interview;
    });
    this.context.onStateUpdate({ menu: menu, isEdited: true });
    this.checkInterviewCount();
  }

  renderInterview (interviews) {
    return (
      <div>
        {interviews.map((interview, index) => {
          const disabled = !this.context.isEdit;
          return (
            <div key={index} className="p-interview_setting-panel">
              <div className="p-interview_setting-title">
                <p>問診{index + 1}</p>
                <span>{this.state.titleCount[index]}/{this.maxInputtextCount}文字</span>
              </div>
              <div className="p-interview_setting-input">
                <MnInputText
                  class="scrollbar"
                  name={`title_${index}`}
                  value={interview.title || ''}
                  callbackParams={ { index: index, key: 'title' } }
                  onChange={ this.onChangeInterview }
                  disabled={disabled}
                  validates={ InterviewSettingValidators.title }
                  onValidate={ (e) => { this.validators = [...this.validators, e]; } }

                />
              </div>
              <div className="p-interview_setting-title">
                <p>説明</p>
                <span>{this.state.placeholderCount.length > index ? this.state.placeholderCount[index] : 0}/{this.maxTextareaCount}文字</span>
              </div>
              <div className="p-interview_setting-input">
                <MnInputTextarea
                  class="scrollbar"
                  name={`annotation_${index}`}
                  value={interview.annotation || ''}
                  callbackParams={ { index: index, key: 'annotation' } }
                  onChange={ this.onChangeInterview }
                  disabled={disabled}
                  validates={ InterviewSettingValidators.annotation }
                  onValidate={ (e) => { this.validators = [...this.validators, e]; } }
                />
              </div>
              <div className="p-interview_setting-title">
                <p>回答形式</p>
              </div>
              <div className="c-mn-radio-btn--col2">
                <MnInputRadioGroup
                  className="c-mn-radio-btn__radio"
                  groups={InterviewSettingsInputTypesI18n}
                  name={`input_type_${index}`}
                  value={interview.input_type || ''}
                  callbackParams={ { index: index, key: 'input_type' } }
                  onChange={ this.onChangeInterview }
                  disabled={disabled}
                />
              </div>
              <div className="p-interview_setting-button">
                { this.context.isEdit && index > 0 ? (
                  <a onClick={ () => { this.onDeleteInterview(index); } }><span>削除</span></a>
                ) : null }
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  render () {
    const menu = this.context.menu;
    const interviews = this.context.menu.interview_settings;
    return (
      <div className="p-interview_setting">
        <div className="p-interview_setting-title">
          <p>事前問診の表示</p>
        </div>
        <div className="c-mn-radio-btn--col2">
          { MenusUseInterviewsI18n.map(data => {
            return (
              <div key={data.key} className="c-mn-radio-btn p-fractal__radio-btn">
                <input
                  type="radio"
                  className="c-mn-radio-btn__radio"
                  name="use_interview"
                  id={`use_interview_${data.key}`}
                  value={data.key}
                  checked={menu.use_interview === data.key}
                  onChange={this.onChangeUseInterview}
                  disabled={!this.context.isEdit}
                />
                <label className="c-mn-radio-btn__label" htmlFor={`use_interview_${data.key}`}>{data.value}</label>
              </div>
            );
          })}
        </div>
        { this.renderInterview(interviews) }
        { this.context.isEdit ? (
          <div className="p-interview_setting-button">
            <button className="c-mn-btn--third" onClick={ this.onAddInterview } disabled={!this.context.isEdit || !this.state.isAddInterview}>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7 0C6.44772 0 6 0.447716 6 1V6L1 6C0.447715 6 0 6.44772 0 7C0 7.55228 0.447716 8 1 8H6V13C6 13.5523 6.44772 14 7 14C7.55228 14 8 13.5523 8 13V8L13 8C13.5523 8 14 7.55228 14 7C14 6.44771 13.5523 6 13 6H8V1C8 0.447715 7.55228 0 7 0Z"
                  fill="#27B1DB"
                />
              </svg>
              <span>項目を追加</span>
            </button>
          </div>
        ) : null }
      </div>
    );
  }
}

SettingInterview.propTypes = {
  menu: PropTypes.object,
  const: PropTypes.object,
  onSave: PropTypes.func,
};
