import * as React from 'react';
import * as PropTypes from 'prop-types';
import {
  AbstractForm,
  MnInlineMessage,
  MnInputTextarea,
  InvoiceFeeValidators,
  RootContext,
  SvgCloseIcon,
  SvgPlusIcon,
} from '../../../../index';
import { v4 as uuidv4 } from 'uuid';
import validator from 'validator';

export default class InvoiceForm extends AbstractForm {
  static contextType = RootContext;
  maxTextLength = 30;
  maxMedicalFeeCount = 5;
  maxMedicalFeeSum = 9_999_999;

  constructor (props) {
    super(props);
    this.state = {
      invoice: this.props.invoice,
      error: undefined,
    };
    this.onChangeMedicalFee = this.onChangeMedicalFee.bind(this);
    this.onAddMedicalFee = this.onAddMedicalFee.bind(this);
    this.onDeleteMedicalFee = this.onDeleteMedicalFee.bind(this);
  }

  componentDidMount () {
    this.validators.push({
      name: 'amount',
      run: false,
      validate: () => {
        return new Promise((resolve, reject) => {
          if (validator.isInt(String(this.sumMedicalFees()), { gt: 0, lt: 50 })) {
            this.setState({ error: '不正な値です' });
            reject(new Error('不正な値です'));
          } else {
            this.setState({ error: undefined });
            resolve();
          }
        });
      },
    });
  }

  onSuccess (resolve) {
    this.props.onFormSuccess(resolve);
  }

  sumMedicalFees () {
    if (this.context.invoice.medical_fees.length < 1) { return 0; }

    const sum = this.context.invoice.medical_fees.map(fee => Number(fee.amount) >= 0 ? Number(fee.amount) : 0).reduce((a, b) => (a + b));
    return sum <= this.maxMedicalFeeSum ? sum.toLocaleString() : '- ';
  }

  onChangeMedicalFee (e) {
    const invoice = this.state.invoice;
    invoice.medical_fees = invoice.medical_fees.map((medicalFees, i) => {
      if (e.callbackParams.index === medicalFees.uuid || e.callbackParams.index === i) {
        medicalFees[e.callbackParams.key] = e.value;
      }
      return medicalFees;
    });
    this.setState({ invoice: invoice });
  }

  lessMaxMedicalFeeCount () {
    return this.state.invoice.medical_fees.length < this.maxMedicalFeeCount;
  }

  onAddMedicalFee () {
    if (!this.lessMaxMedicalFeeCount()) { return; }

    const invoice = this.state.invoice;
    invoice.medical_fees.push({ name: '', amount: 0, uuid: uuidv4() });
    this.setState({ invoice: invoice });
  }

  onDeleteMedicalFee (index) {
    this.validators = this.validators.filter((validator) => {
      if (validator.name !== `name_${index}` && validator.name !== `amount_${index}`) {
        return validator;
      }
    });
    const invoice = this.state.invoice;
    invoice.medical_fees = invoice.medical_fees.filter((medicalFee, i) => {
      return medicalFee.uuid !== index && i !== index;
    });
    this.setState({ invoice: invoice });
  }

  renderErrors () {
    return this.state.error ? (<MnInlineMessage mode="validation" messages={ this.state.error} />) : null;
  }

  render () {
    const { disabled } = this.props;
    return (
      <>
        {/*  TODO: menuのページで使っているp-menuのCSSを使っている。ここは共通のパーツを定義したい。 */}
        <div className="p-menu_content-fees">
          {this.state.invoice.medical_fees.map((medicalFee, index) => {
            return (
              <div
                key={medicalFee.uuid || index.toString()}
                className="p-menu_content-fee"
              >
                <div className="p-menu_content-title">
                  <p>診療代（税込）</p>
                  <span>
                    {medicalFee.name.length}/{this.maxTextLength}文字
                  </span>
                </div>
                <div></div>
                <div></div>
                <MnInputTextarea
                  name={`name_${medicalFee.uuid || index}`}
                  placeholder="診療代項目"
                  rows="2"
                  value={medicalFee.name}
                  validates={InvoiceFeeValidators.name}
                  onValidate={ this.onValidate }
                  callbackParams={{ index: (medicalFee.uuid || index), key: 'name' }}
                  onChange={this.onChangeMedicalFee}
                  disabled={disabled}
                />
                <div className="p-menu_content-fee-amount">
                  <MnInputTextarea
                    class={'c-mn-textarea ta-r'}
                    name={`amount_${medicalFee.uuid || index}`}
                    placeholder="0"
                    rows="2"
                    value={medicalFee.amount}
                    validates={InvoiceFeeValidators.amount}
                    onValidate={ this.onValidate }
                    callbackParams={{ index: (medicalFee.uuid || index), key: 'amount' }}
                    onChange={this.onChangeMedicalFee}
                    disabled={disabled}
                  />
                </div>
                <div className="p-menu_content-fee-others-invoice">
                  <div>円</div>
                  {!disabled && (
                    <div className={index > 0 ? 'delete-link' : 'delete-link hidden'}>
                      <a onClick={(e) => { this.onDeleteMedicalFee((medicalFee.uuid || index)); }}>
                        {SvgCloseIcon}
                      </a>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>

        <div className="p-invoice-button p-invoice-add-item-btn">
          { !disabled
            ? (<button className="c-mn-btn--third" onClick={this.onAddMedicalFee} disabled={!this.lessMaxMedicalFeeCount()}>
              {SvgPlusIcon}
              <span>項目を追加</span>
            </button>) : null
          }
        </div>

        <hr></hr>
        <div className="p-invoice-total">
          <div className="d-fx jc-sb">
            <span>合計金額（税込）</span>
            <span>
              {this.sumMedicalFees()}
              円
            </span>
          </div>
          <div className="d-fx jc-fe">
            { this.renderErrors() }
          </div>
        </div>

        {!disabled && (
          <>
            <p className="ta-c fz16 mb-lg fc-c_error">一度請求すると、再請求できません。</p>
            <p className="ta-c fz16 fc-c_error">合計金額をお確かめの上、請求してください。</p>

            <button className="p-invoice-submit-btn c-mn-btn--first mx-a" onClick={ this.onSave }>
              <span>請求する</span>
            </button>
          </>
        )}
      </>
    );
  }
}

InvoiceForm.propTypes = {
  invoice: PropTypes.object,
  onFormSuccess: PropTypes.func,
  disabled: PropTypes.bool,
};
