
import React from 'react';
import * as PropTypes from 'prop-types';
import { AbstractRoot, RootContext, i18n, Languages, MnErrorBoundary } from './index';
import * as Sentry from '@sentry/react';

export default class AbstractLayout extends AbstractRoot {
  constructor (props) {
    super(props);
    this.state = {
      ...this.methods,
      ...props.state,
      t: props.t,
      lang: i18n.language,
      language: this.language,
      i18n: i18n,
      exception: this.exception,
      errorBoundary: undefined,
    };

    Sentry.init({
      environment: props.env,
      dsn: props.dsn,
    });

    this.language = this.language.bind(this);
    this.exception = this.exception.bind(this);
    this.renderChildren = this.renderChildren.bind(this);
  }

  componentDidMount () {
    if (this.state.lang === 'ja') {
      document.body.classList.remove('wob-nm');
    } else {
      document.body.classList.add('wob-nm');
    }
    if (Languages.indexOf(this.state.lang) === -1) {
      this.language('en');
    }
  }

  language (lang) {
    i18n.changeLanguage(lang, () => {
      this.onStateUpdate({ lang: lang });
      if (lang === 'ja') {
        document.body.classList.remove('wob-nm');
      } else {
        document.body.classList.add('wob-nm');
      }
    });
  }

  exception (error) {
    this.state.errorBoundary && this.state.errorBoundary.onCatch(error);
  }

  renderChildren () {
    return this.props.children;
  }

  render () {
    return (<RootContext.Provider value={this.state}>
      <MnErrorBoundary onRef={(self) => {
        this.setState({ errorBoundary: self, exception: this.exception });
      }}>
        { this.renderChildren() }
      </MnErrorBoundary>
    </RootContext.Provider>);
  }
}

AbstractLayout.propTypes = {
  children: PropTypes.any, // inline表示するDOM要素
  state: PropTypes.object, // contextで共有したい値
  class: PropTypes.string, // クラス名
  showHeader: PropTypes.bool, // Headerの表示
  showFooter: PropTypes.bool, // Footerの表示
};

AbstractLayout.defaultProps = {
  showHeader: true,
  showFooter: true,
};
