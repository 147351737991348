import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  MnButton,
  RootContext,
} from '../../../index';

export default class Item extends React.Component {
  static contextType = RootContext;

  shouldComponentUpdate () {
    return false;
  }

  handleClick = (treatmentCode) => {
    location.href = `/treatments/${treatmentCode}`;
  }

  renderButtons = (treatment) => {
    const reservedAt = treatment.reserved_at ? moment(treatment.reserved_at, 'YYYY/MM/DD HH:mm') : null;

    switch (treatment.state) {
    case 'requested': // 診療申込
      return (
        <div className="p-buttons">
          <MnButton
            class="c-mn-btn--third fz14"
            onClick={ (ev) => {
              return new Promise((resolve, reject) => {
                resolve();
                this.props.handleOpenModalCanceled(ev, {
                  api_location: treatment.api_location.update,
                });
              });
            }}
          >
            <span>キャンセル</span>
          </MnButton>
          <MnButton
            class="c-mn-btn--first fz14"
            onClick={ (ev) => {
              return new Promise((resolve, reject) => {
                resolve();
                this.props.handleOpenModalWaitingExaminationConfirm(ev, {
                  api_location: treatment.api_location.update,
                });
              });
            }}
          >
            <span>確定</span>
          </MnButton>
        </div>
      );
    case 'waiting_examination': // 診療待ち
      return (
        <div className="p-buttons">
          <MnButton
            class="c-mn-btn--third fz14"
            onClick={ (ev) => {
              return new Promise((resolve, reject) => {
                resolve();
                this.props.handleOpenModalCanceled(ev, {
                  api_location: treatment.api_location.update,
                });
              });
            }}
          >
            <span>キャンセル</span>
          </MnButton>
          <MnButton
            class="c-mn-btn--first fz14"
            onClick={ (ev) => {
              return new Promise((resolve, reject) => {
                resolve();
                this.props.handleOpenModalWaitingExaminationUpdate(ev, {
                  api_location: treatment.api_location.update,
                  reserved_date: reservedAt.format('YYYY/MM/DD'),
                  reserved_time: reservedAt.format('HH:mm:ss'),
                });
              });
            }}
          >
            <span>変更</span>
          </MnButton>
        </div>
      );
    case 'examined': // 診療済
      return (
        <div className="p-buttons">
          <div className="r0 pos-a">
            <MnButton
              class="c-mn-btn--first fz14 p-treatment-state__button--invoiced"
            >
              <span>請求</span>
            </MnButton>
          </div>
        </div>
      );
    default:
      return null;
    }
  }

  render () {
    const treatment = this.props.treatment;
    const reservedAt = treatment.reserved_at ? moment(treatment.reserved_at, 'YYYY/MM/DD HH:mm').format('YY/MM/DD HH:mm') : null;

    return (
      <tr onClick={() => this.handleClick(treatment.code)}>
        <td className="c-mn-table-td">
          <span className={`c-mn-tag p-treatment-state__tag--${treatment.state}`}>{treatment.state_i18n}</span>
        </td>
        <td className="c-mn-table-td">{treatment.request_id}</td>
        { this.context.user.institution.regional_medical_cooperation ? (
          <td className="c-mn-table-td">{treatment.referring_institution?.name}</td>
        ) : null }
        <td className="c-mn-table-td p-ellipsis">{treatment.menu.title}</td>
        <td className="c-mn-table-td p-ellipsis">{treatment.patient.kana}</td>
        <td className="c-mn-table-td">{treatment.created_at}</td>
        <td className="c-mn-table-td">
          { treatment.requested_dates && treatment.requested_dates.length > 0 ? (
            <ul>
              { treatment.requested_dates.map((requestedDate, i) => {
                return (
                  <li key={i}>
                    <span>{requestedDate.date}</span>
                  </li>
                );
              })}
            </ul>
          ) : null }
        </td>
        <td className="c-mn-table-td">{reservedAt}</td>
        <td className="c-mn-table-td">{treatment.examinations_created_at}</td>
        <td className="c-mn-table-td">
          {treatment.worker ? `${treatment.worker.name} 先生` : ''}
          {this.renderButtons(treatment)}
        </td>
      </tr>
    );
  }
}

Item.propTypes = {
  treatment: PropTypes.object,
  handleOpenModalWaitingExaminationConfirm: PropTypes.func,
  handleOpenModalWaitingExaminationUpdate: PropTypes.func,
  handleOpenModalCanceled: PropTypes.func,
};
