export { default as PageSectionsForm } from './PageSectionsForm.js';
export { default as Footer } from './Footer.js';
export { default as Header } from './Header.js';
export { default as InstitutionForm } from './InstitutionForm.js';
export { default as TreatmentsExamination } from './TreatmentsExamination.js';
export { default as TreatmentsReferringInstitution } from './TreatmentsReferringInstitution.js';
export { default as RegionalMedicalCooperationTreatmentsPatient } from './RegionalMedicalCooperationTreatmentsPatient.js';
export { default as TreatmentsPatient } from './TreatmentsPatient.js';
export { default as MenuIndex } from './MenuIndex.js';
export { default as MenuForm } from './MenuForm.js';
export { default as Disclaimer } from './Disclaimer.js';
export { default as SettingInterview } from './Interview.js';
export { default as MenuContent } from './MenuContent.js';
export { default as MenuUseRequestForm } from './MenuUseRequestForm.js';
export { default as ModalDatetimeRequest } from './ModalDatetimeRequest.js';
export { default as TreatmentFlow } from './TreatmentFlow.js';
export { default as OpeningInquiryForm } from './OpeningInquiryForm.js';
export { default as VideoTesting } from './VideoTesting.js';
