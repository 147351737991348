import React from 'react';
import Modal from 'react-modal';
import AbstractComponent from '../../common/components/AbstractComponent';
import Layout from './Layout';
import MnButton from '../../common/components/MnButton';
import MnInlineMessage from '../../common/components/MnInlineMessage';

export default class InitialPassword extends AbstractComponent {
  constructor (props) {
    super(props);
    this.state = {
      ...props,
      errorMessage: '',
      isSuccess: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // アカウントの作成
  handleSubmit () {
    return new Promise(resolve => {
      this.fetch(this.state.api_location.register, {
        methods: 'PATCH',
        body: {
          password: this.state.password,
          password_confirmation: this.state.password_confirmation,
          initial_password_token: this.state.initial_password_token,
        },
      }).then(_ => {
        this.setState({ isSuccess: true });
      }).catch(err => {
        err.json().then(body => {
          this.setState({ errorMessage: body.message });
          body.errors.password ? this.setState({ passwordErrorMessage: body.errors.password }) : this.setState({ passwordErrorMessage: null });
          body.errors.password_confirmation ? this.setState({ passwordConfirmationErrorMessage: body.errors.password_confirmation }) : this.setState({ passwordConfirmationErrorMessage: null });
        });
      }).finally(() => {
        resolve();
      });
    });
  }

  render () {
    const errorMessage = (message) => {
      return (
        <div className="columns">
          <div className="column">
            <p className="c-mn-form-caption c-mn-form-caption--error" >
              <svg width="15" viewBox="0 0 19 20" fill="none" className="c-mn-icon-error t0" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M9.77995 20C14.8426 20 18.9466 15.8959 18.9466 10.8333C18.9466 5.77068 14.8426 1.66663 9.77995 1.66663C4.71734 1.66663 0.613281 5.77068 0.613281 10.8333C0.613281 15.8959 4.71734 20 9.77995 20ZM10.7745 12.8867H9.33445L9.01945 7.35168L8.94445 5.26668H11.1645L11.0895 7.35168L10.7745 12.8867ZM11.4345 15.4367C11.4345 16.2467 10.8345 16.8767 10.0545 16.8767C9.27445 16.8767 8.68945 16.2467 8.68945 15.4367C8.68945 14.6117 9.27445 13.9967 10.0545 13.9967C10.8345 13.9967 11.4345 14.6117 11.4345 15.4367Z" fill="#000" className="c-mn-icon-error-path"/></svg>
              { message }
            </p>
          </div>
        </div>
      );
    };

    const successModal = (
      <Modal isOpen={ this.state.isSuccess } className={ 'c-mn-modal-dialog is-active' } overlayClassName={ 'c-mn-modal is-active--dialog' } >
        <p className="c-mn-modal-dialog__title">パスワードを変更しました。</p>
        <div className={ 'c-mn-modal-dialog__btn-wrap' }>
          <button className={ 'c-mn-btn c-mn-btn--basic-s c-mn-btn--first c-mn-btn--compact' } onClick={ () => { location.href = this.state.url_location.login; }} >OK</button>
        </div>
      </Modal>
    );

    return (
      <Layout state={this.state} class="has-background-white">
        { successModal }

        <div className="hero p-initial-password">
          <div className="hero-body">
            <div className="mt-xl">
              <div className="m-a">
                <h1 className="fz32 fw-br ta-c pb-xl">
                  初回パスワード変更
                </h1>
                <p className="fz16 ta-c mt-md">初回ログイン時は必ずパスワードを変更していただきます。</p>
                <p className="fz16 ta-c mt-md">パスワードは、数字、英字大小文字、記号( !@#$&*_- ) を</p>
                <p className="fz16 ta-c mt-md pb-md">それぞれ１文字以上含む、１０文字以上で設定してください。</p>

                <div className="maw420px m-a">
                  { this.state.errorMessage !== '' && <MnInlineMessage messages={ [this.state.errorMessage] } class="mt-lg mb-xl" /> }
                </div>
              </div>

              <div className="maw420px m-a">
                <div className="field mt-xl m-a">
                  <label className="c-mn-form-label c-mn-form-label--navy">
                    新しいパスワード
                  </label>
                  <input
                    type="password"
                    className={ 'c-mn-input-text w100p maw-n' + (this.state.passwordErrorMessage ? ' is-error' : '') }
                    placeholder="新しいパスワードを入力"
                    onChange={ (e) => this.setState({ password: e.target.value }) }
                  />
                  { this.state.passwordErrorMessage && <span>{ errorMessage(this.state.passwordErrorMessage) }</span> }
                </div>

                <div className="field mt-xl m-a">
                  <label className="c-mn-form-label c-mn-form-label--navy">
                    新しいパスワード（確認用）
                  </label>
                  <input
                    type="password"
                    className={ 'c-mn-input-text w100p maw-n' + (this.state.passwordConfirmationErrorMessage ? ' is-error' : '') }
                    placeholder="新しいパスワードを入力"
                    onChange={ (e) => this.setState({ password_confirmation: e.target.value }) }
                  />
                  { this.state.passwordConfirmationErrorMessage && <span>{ errorMessage(this.state.passwordConfirmationErrorMessage) }</span> }
                </div>

                <div className="field mt-xl m-a ta-c">
                  <MnButton
                    class="c-mn-btn--first c-mn-btn--compact"
                    name="initial-password-button"
                    onClick={ this.handleSubmit }
                  >
                    <span>パスワードを変更する</span>
                  </MnButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
