import * as React from 'react';
import { RootContext, TreatmentsStatesI18n } from '../../../../index';
import {
  InvoiceForm,
  InvoiceModal,
  InvoiceLayout,
} from './index';

export default class Examined extends React.Component {
  static contextType = RootContext;

  constructor (props) {
    super(props);
    this.state = {
      isOpenInvoiceModal: false,
    };
    this.handleCreateInvoice = this.handleCreateInvoice.bind(this);
    this.handleUpdateTreatmentState = this.handleUpdateTreatmentState.bind(this);
  }

  handleUpdateTreatmentState = () => {
    return new Promise(resolve => {
      this.context.fetch(this.context.treatment.api_location.update, {
        methods: 'PATCH',
        body: { state: 'invoiced' },
      }).then(body => {
        const stateI18n = TreatmentsStatesI18n.find(state => state.key === body.state).value;
        this.setState({ treatmentState: body.state });
        this.context.onStateUpdate({
          state: body.state,
          state_i18n: stateI18n,
          treatment: { ...this.context, state: body.state, state_i18n: stateI18n },
        });
        this.context.onHeaderToast(`${stateI18n}に更新しました。`);
      }).finally(() => {
        resolve();
      });
    });
  }

  handleCreateInvoice = () => {
    const medicalFees = this.context.invoice.medical_fees.map(medicalFee => ({ name: medicalFee.name, amount: medicalFee.amount }));
    return new Promise(resolve => {
      this.context.showLoading();
      this.context.fetch(this.context.treatment.api_location.create_invoice, {
        methods: 'POST',
        body: { invoice: { medical_fees: medicalFees } },
      }).then(body => {
        const stateI18n = TreatmentsStatesI18n.find(state => state.key === body.message).value;
        this.context.onStateUpdate({
          state: body.message,
          state_i18n: stateI18n,
          treatment: { ...this.context, state: body.message, state_i18n: stateI18n },
        });
        this.setState({ treatmentState: body.message });
        this.context.onHeaderToast('請求完了しました');
      }).catch(err => {
        console.log(err);
        const stateI18n = TreatmentsStatesI18n.find(state => state.key === 'invoiced').value;
        this.context.onStateUpdate({
          state: 'invoiced',
          state_i18n: stateI18n,
          is_failed_invoice: true,
          treatment: { ...this.context, state: 'invoiced', state_i18n: stateI18n },
        });
        this.context.hideLoading();

        if (err.status === 402) {
          this.context.onHeaderToast('決済が失敗しました');
        } else {
          this.context.onStateUpdate({ alert: { shown: true } });
        }
        resolve();
      }).finally(() => {
        this.context.hideLoading();
        resolve();
      });
    });
  }

  sumMedicalFees () {
    return this.context.invoice.medical_fees.map(fee => Number(fee.amount) >= 0 ? Number(fee.amount) : 0).reduce((a, b) => (a + b));
  }

  render () {
    return (
      <InvoiceLayout>
        <>
          {this.context.treatment.patient.stripe_customer_id ? (
            <>
              <InvoiceForm
                invoice={this.context.invoice}
                onFormSuccess={() => this.setState({ isOpenInvoiceModal: true })}
              />
              <InvoiceModal
                isOpen={this.state.isOpenInvoiceModal}
                onSubmit={this.handleCreateInvoice}
                onClose={() => this.setState({ isOpenInvoiceModal: false })}
                title={this.context.treatment.patient.name + 'さんに請求しますか？'}
                alertText={'一度請求すると、再請求できません。\n合計金額をお確かめの上、請求してください。'}
                messageText={'請求金額：' + this.sumMedicalFees() + '円'}
                submitText="請求する"
              />
            </>)
            : (
              <>
                <div className="p-invoice-annotation-text">
                  <p>診療申込時点で、{this.context.treatment.menu.title}は</p>
                  <p>クレジットカード決済を受け付けていないため、</p>
                  <p>患者のクレジットカード情報が未登録です。</p>
                  <br></br>
                  <p>すでに請求が完了している場合は、</p>
                  <p>以下よりステータスを更新ください。</p>
                  <button className="p-invoice-submit-btn c-mn-btn--first mx-a" onClick={() => this.setState({ isOpenInvoiceModal: true })}>
                    <span>請求済みにする</span>
                  </button>
                </div>
                <InvoiceModal
                  isOpen={this.state.isOpenInvoiceModal}
                  onSubmit={this.handleUpdateTreatmentState}
                  onClose={() => this.setState({ isOpenInvoiceModal: false })}
                  title={this.context.treatment.patient.name + 'さんのステータスを請求済みに変更しますか？'}
                  submitText="変更する"
                />
              </>
            )}

        </>
      </InvoiceLayout>
    );
  }
}
