import * as React from 'react';
import {
  RootContext,
  MnModal,
  MnPagenation,
} from '../../../index';
import {
  Item,
  CanceledForm,
  WaitingExaminationConfirmForm,
  WaitingExaminationUpdateForm,
} from './index';

export default class List extends React.Component {
  static contextType = RootContext;
  constructor (props) {
    super(props);
    this.state = {
      apiLocation: '',
      title: '',
      description: '',
      reservedDate: '',
      reservedTime: '',
      isOpenModalWaitingExaminationConfirm: false,
      isOpenModalWaitingExaminationUpdate: false,
      isOpenModalCanceled: false,
    };
    this.handleOpenModalWaitingExaminationConfirm = this.handleOpenModalWaitingExaminationConfirm.bind(this);
    this.handleOpenModalWaitingExaminationUpdate = this.handleOpenModalWaitingExaminationUpdate.bind(this);
    this.handleOpenModalCanceled = this.handleOpenModalCanceled.bind(this);
    this.handleCloseModalWaitingExaminationConfirm = this.handleCloseModalWaitingExaminationConfirm.bind(this);
    this.handleCloseModalWaitingExaminationUpdate = this.handleCloseModalWaitingExaminationUpdate.bind(this);
    this.handleCloseModalCanceled = this.handleCloseModalCanceled.bind(this);
  }

  handleOpenModalWaitingExaminationConfirm = (ev, treatment) => {
    ev.event.preventDefault();
    ev.event.stopPropagation();
    this.setState({
      isOpenModalWaitingExaminationConfirm: true,
      apiLocation: treatment.api_location,
      title: this.context.user.institution.regional_medical_cooperation ? 'この紹介元の予約を確定しますか？' : 'この患者の予約を確定しますか？',
      description: this.context.user.institution.regional_medical_cooperation ? '予約を確定すると紹介元に確定通知されます。' : '予約を確定すると患者に確定通知されます。',
      reservedDate: '',
      reservedTime: '',
    });
  }

  handleOpenModalWaitingExaminationUpdate = (ev, treatment) => {
    ev.event.preventDefault();
    ev.event.stopPropagation();
    this.setState({
      isOpenModalWaitingExaminationUpdate: true,
      apiLocation: treatment.api_location,
      title: this.context.user.institution.regional_medical_cooperation ? 'この紹介元の予約を変更しますか？' : 'この患者の予約を変更しますか？',
      description: this.context.user.institution.regional_medical_cooperation ? '予約を変更すると紹介元に確定通知されます。' : '予約を変更すると患者に確定通知されます。',
      reservedDate: treatment.reserved_date,
      reservedTime: treatment.reserved_time,
    });
  }

  handleOpenModalCanceled = (ev, treatment) => {
    ev.event.preventDefault();
    ev.event.stopPropagation();
    this.setState({
      isOpenModalCanceled: true,
      apiLocation: treatment.api_location,
      title: this.context.user.institution.regional_medical_cooperation ? 'この紹介元の予約をキャンセルしますか？' : 'この患者の予約をキャンセルしますか？',
      description: this.context.user.institution.regional_medical_cooperation ? '予約をキャンセルすると紹介元に通知されます。' : '予約をキャンセルすると患者に通知されます。',
      reservedDate: '',
      reservedTime: '',
    });
  }

  handleCloseModalWaitingExaminationConfirm = () => {
    return new Promise((resolve, reject) => {
      this.setState({
        isOpenModalWaitingExaminationConfirm: false,
        apiLocation: '',
        reservedDate: '',
        reservedTime: '',
      });
      resolve();
    });
  }

  handleCloseModalWaitingExaminationUpdate = () => {
    this.setState({
      isOpenModalWaitingExaminationUpdate: false,
      apiLocation: '',
      reservedDate: '',
      reservedTime: '',
    });
  }

  handleCloseModalCanceled = () => {
    this.setState({
      isOpenModalCanceled: false,
      apiLocation: '',
      reservedDate: '',
      reservedTime: '',
    });
  }

  render () {
    return (
      <div className="p-treatment-table mb-xl">
        <table className="c-mn-table c-mn-table--plan">
          <thead>
            <tr>
              <th className="c-mn-table-th" style={{ width: 118 }}>ステータス</th>
              <th className="c-mn-table-th" style={{ width: 133 }}>申込ID</th>
              { this.context.user.institution.regional_medical_cooperation ? (
                <th className="c-mn-table-th" style={{ width: 160 }}>紹介元医療機関</th>
              ) : null }
              <th className="c-mn-table-th" style={{ width: 225 }}>メニュー</th>
              <th className="c-mn-table-th" style={{ width: 158 }}>氏名（カナ）</th>
              <th className="c-mn-table-th" style={{ width: 116 }}>申込日</th>
              <th className="c-mn-table-th" style={{ width: 116 }}>希望日</th>
              <th className="c-mn-table-th" style={{ width: 116 }}>確定日</th>
              <th className="c-mn-table-th" style={{ width: 80 }}>診療日</th>
              <th className="c-mn-table-th" style={{ width: 160 }}>担当</th>
            </tr>
          </thead>
          <tbody>
            {(this.context.treatments || []).map((treatment) => {
              return (
                <Item
                  key={treatment.id}
                  treatment={treatment}
                  handleOpenModalWaitingExaminationConfirm={this.handleOpenModalWaitingExaminationConfirm}
                  handleOpenModalWaitingExaminationUpdate={this.handleOpenModalWaitingExaminationUpdate}
                  handleOpenModalCanceled={this.handleOpenModalCanceled}
                />
              );
            })}
          </tbody>
        </table>

        { this.context.treatments !== null && this.context.treatments.length === 0 ? (
          <div>
            <p className="c-mn-message--error mt-lg">
              <svg width="19" height="20" viewBox="0 0 19 20" fill="none" className="c-mn-icon-error" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M9.77995 20C14.8426 20 18.9466 15.8959 18.9466 10.8333C18.9466 5.77068 14.8426 1.66663 9.77995 1.66663C4.71734 1.66663 0.613281 5.77068 0.613281 10.8333C0.613281 15.8959 4.71734 20 9.77995 20ZM10.7745 12.8867H9.33445L9.01945 7.35168L8.94445 5.26668H11.1645L11.0895 7.35168L10.7745 12.8867ZM11.4345 15.4367C11.4345 16.2467 10.8345 16.8767 10.0545 16.8767C9.27445 16.8767 8.68945 16.2467 8.68945 15.4367C8.68945 14.6117 9.27445 13.9967 10.0545 13.9967C10.8345 13.9967 11.4345 14.6117 11.4345 15.4367Z" fill="#000" className="c-mn-icon-error-path" /></svg>
              <span>
                該当する申し込みがありません。<br />
                条件を変更して再検索してください。
              </span>
            </p>
          </div>
        ) : null }

        <MnPagenation
          class="mt-xl"
          current={this.context.pagination.page}
          total={this.context.pagination.total_page}
          onChange={ page => {
            this.context.events.emit('search', {}, page);
          } }
        />

        <MnModal isOpen={this.state.isOpenModalWaitingExaminationConfirm}>
          <WaitingExaminationConfirmForm
            handleClose={this.handleCloseModalWaitingExaminationConfirm}
            apiLocation={this.state.apiLocation}
            title={this.state.title}
            description={this.state.description}
          />
        </MnModal>
        <MnModal isOpen={this.state.isOpenModalWaitingExaminationUpdate}>
          <WaitingExaminationUpdateForm
            handleClose={this.handleCloseModalWaitingExaminationUpdate}
            apiLocation={this.state.apiLocation}
            title={this.state.title}
            description={this.state.description}
            reservedDate={this.state.reservedDate}
            reservedTime={this.state.reservedTime}
          />
        </MnModal>
        <MnModal isOpen={this.state.isOpenModalCanceled}>
          <CanceledForm
            handleClose={this.handleCloseModalCanceled}
            apiLocation={this.state.apiLocation}
            title={this.state.title}
            description={this.state.description}
          />
        </MnModal>
      </div>
    );
  }
}
