import React from 'react';
import Modal from 'react-modal';
import AbstractComponent from '../../common/components/AbstractComponent';
import Layout from './Layout';
import MnButton from '../../common/components/MnButton';

export default class PasswordReset extends AbstractComponent {
  constructor (props) {
    super(props);
    this.state = {
      ...props,
      isSuccess: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // パスワードリセット申請
  handleSubmit () {
    return new Promise(resolve => {
      this.fetch(this.state.api_location.password_reset, {
        methods: 'POST',
        body: { logincode: this.state.logincode },
      }).then(_ => {
        this.setState({ isSuccess: true });
      }).catch(_ => {
        this.setState({ isSuccess: true });
      }).finally(() => {
        resolve();
      });
    });
  }

  render () {
    const successModal = (
      <Modal isOpen={ this.state.isSuccess } className={ 'c-mn-modal-dialog is-active' } overlayClassName={ 'c-mn-modal is-active--dialog' } >
        <p className="c-mn-modal-dialog__title">
          メールアドレス宛にIDとパスワードを送信しました
        </p>
        <div className={ 'c-mn-modal-dialog__btn-wrap' }>
          <button className={ 'c-mn-btn c-mn-btn--basic-s c-mn-btn--first c-mn-btn--compact' } onClick={ () => { location.reload(); }} >OK</button>
        </div>
      </Modal>
    );

    return (
      <Layout state={this.state} class="has-background-white">
        { successModal }

        <div className="p-password-reset">
          <div className="hero-body">
            <div className="mt-xl">
              <div className="maw420px m-a">
                <h1 className="fz32 fw-br ta-c pb-xl">
                  ID・パスワード再送
                </h1>

                <p className="fz16 ta-c mt-md">パスワードを再送します。</p>
                <p className="fz16 ta-c mt-md pb-md">IDを入力してください。</p>

                <div className="field mt-xl">
                  <label className="c-mn-form-label c-mn-form-label--navy">ID</label>
                  <input type="text" className="c-mn-input-text" placeholder="IDを入力" onChange={ (e) => this.setState({ logincode: e.target.value })} />
                </div>

                <div className="field mt-xl">
                  <MnButton class="c-mn-btn--first w-a m-a" name="login-button" onClick={ this.handleSubmit }>
                    <span>パスワードを再発行する</span>
                  </MnButton>
                </div>

                <div className="mt-xl has-text-centered">
                  <a className="c-mn-link fw-lr fz18" href="" onClick={ (e) => { e.preventDefault(); location.href = this.state.url_location.login; } }>
                    ログイン画面へ戻る
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
