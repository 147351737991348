import * as React from 'react';
import PropTypes from 'prop-types';
import {
  AbstractForm,
  MnButton,
  MnToast,
  RootContext,
} from '../../../../index';

export default class RefundForm extends AbstractForm {
  static contextType = RootContext;

  constructor (props) {
    super(props);
    this.state = {
      annotation: {},
      toast: null,
    };
    this.handleClose = this.handleClose.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  handleClose = (arg) => {
    const event = arg.event;
    event.preventDefault();
    return new Promise((resolve, reject) => {
      resolve();
      this.props.handleClose();
    });
  }

  validation () {
    return new Promise((resolve, reject) => {
      resolve();
    });
  }

  onSuccess = (resolve) => {
    this.context
      .fetch(
        this.props.treatment.api_location.refund_api_v1_treatment_invoice,
        { methods: 'PATCH' },
      ).then(() => {
        this.setState({ toast: '取り消ししました。' });
      })
      .then(() => {
        setTimeout(() => {
          this.setState({ toast: null });
        }, 3000);
      })
      .then(() => {
        resolve();
        setTimeout(() => {
          location.reload();
        }, 2000);
      }).catch(_error => {
        this.setState({ toast: '処理に失敗しました。お手数ですがメディカルノート 担当者にお問い合わせください。' });
        setTimeout(() => {
          this.setState({ toast: null });
          this.props.handleClose();
        }, 3000);
      });
  }

  render () {
    const patient = this.props.treatment.patient;

    return (
      <form>
        <p className="c-mn-modal-dialog__title">{patient.name}さんへの請求を取り消しますか？</p>
        <p className="c-mn-modal-dialog__txt has-text-danger">一度取り消しすると、元に戻せません。請求内容をお確かめの上、取り消ししてください。</p>
        <div className="is-flex jc-fe">
          <MnButton
            class="c-mn-btn c-mn-btn--basic-s c-mn-btn--third c-mn-btn--compact p-treatment__btn"
            onClick={this.handleClose}
          >
            <span>キャンセル</span>
          </MnButton>
          <MnButton
            class="c-mn-btn c-mn-btn--basic-s c-mn-btn--first c-mn-btn--compact p-treatment__btn ml-md"
            onClick={this.onSave}
          >
            <span>取り消す</span>
          </MnButton>
        </div>
        <MnToast shown={ this.state.toast !== null }><span>{ this.state.toast }</span></MnToast>
      </form>
    );
  }
}

RefundForm.propTypes = {
  handleClose: PropTypes.func,
  treatment: PropTypes.object,
};
