import * as React from 'react';
import * as PropTypes from 'prop-types';
import {
  MnButton,
} from '../../../../index';

export default class RefundButton extends React.Component {
  render () {
    return (
      <MnButton
        class="c-mn-btn--third c-mn-btn--compact is-size-5"
        onClick={this.props.handleClick}
        disabled={this.props.disabled}
      >
        <span>請求を取り消す</span>
      </MnButton>
    );
  }
}

RefundButton.propTypes = {
  handleClick: PropTypes.function,
  disabled: PropTypes.bool,
};
