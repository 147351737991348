import * as React from 'react';
import * as PropTypes from 'prop-types';
import {
  AbstractForm,
  MenuValidators,
  MnInputRadioGroup,
  RootContext,
  MenusAcceptDatetimeRequestsI18n,
  MenusAcceptStripePaymentsI18n,
  MenusUseRequestFlagsI18n,
  MenuUseRequestFlagValidator,
  MnInputTextarea,
  SvgPlusIcon,
  ChecklistValidators,
} from '../../../index';
import { v4 as uuidv4 } from 'uuid';

import ModalDatetimeRequest from './ModalDatetimeRequest';

export default class MenuUseRequestForm extends AbstractForm {
  static contextType = RootContext;
  maxLength = 50;
  maxCount = 5;
  dates = [];

  constructor (props) {
    super(props);
    this.dates = this.buildInitialDates(props.context.menu.slot_minute);
    this.state = {
      isOpen: false,
      timeTable: this.dates,
    };
    this.onChangeMenuContent = this.onChangeMenuContent.bind(this);
    this.onChangeAcceptDatetimeRequest = this.onChangeAcceptDatetimeRequest.bind(this);
    this.onChangeAcceptStripePayment = this.onChangeAcceptStripePayment.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.convertDatetimeRequestToTimeTable = this.convertDatetimeRequestToTimeTable.bind(this);
    this.convertTimeTableToDatetimeRequest = this.convertTimeTableToDatetimeRequest.bind(this);
  }

  buildInitialDates (slotMinute) {
    return new Array(7).fill().map((x, day) => {
      const slotNum = ((24 * 60) / slotMinute);
      return new Array(slotNum).fill().map((y, slotIndex) => {
        const hour = Math.floor((slotMinute / 60) * slotIndex);
        const minute = ((slotMinute % 60) * slotIndex) % 60;
        return { time: (day + 1) * 10000 + hour * 100 + minute, checked: false };
      });
    });
  }

  componentDidMount () {
    this.context.onStateUpdate({ onValidateMenuUseRequest: this.validation });
  }

  // date_time_reqests(context)をモーダルで使うテーブル形式(7×24の配列)に変換(モーダルを開く時に発火)
  convertDatetimeRequestToTimeTable () {
    this.context.menu.menu_datetime_requests.map((request) => {
      const oneDay = this.dates[request.day_of_week - 1];
      const startAt = new Date();
      startAt.setHours(request.start_at.slice(0, 2), request.start_at.slice(-2), 0, 0);
      const endAt = new Date();
      endAt.setHours(request.end_at.slice(0, 2), request.end_at.slice(-2), 0, 0);
      // date_time_reqests は 10〜14時の様に1時間枠の場合と17〜18時の様に2時間以上の連続の枠の2パターンがある
      // 後者は繰り返し処理が必要なため分岐
      if (endAt - startAt === this.context.menu.slot_minute * 60000) {
        oneDay[Math.floor((startAt.getHours() * 60 + startAt.getMinutes()) / this.context.menu.slot_minute)].checked = true;
      } else {
        // NOTE: setDateで値を更新しているが、これをESLintが認識できない。そのため、disableする。
        //       ref: https://github.com/eslint/eslint/issues/6984
        // eslint-disable-next-line no-unmodified-loop-condition
        for (const startTime = startAt; startTime < endAt; startTime.setDate(startTime.getDate() + this.context.menu.slot_minute)) {
          oneDay[Math.floor((startTime.getHours() * 60 + startTime.getMinutes()) / this.context.menu.slot_minute)].checked = true;
        }
      }
    });
    this.setState({
      timeTable: this.dates,
    });
  }

  // モーダルで使うテーブル形式(7×24の配列)からdate_time_reqests(context)に変換(モーダルを閉じる時に発火)
  convertTimeTableToDatetimeRequest () {
    let menuDatetimeRequests = [];
    let lastIndex = 0;
    this.dates.map(times => {
      menuDatetimeRequests = menuDatetimeRequests.concat(times.filter(time => (time.checked)).map(time => {
        const st = time.time % 10000;
        const day = Math.floor(time.time / 10000);
        const stHour = Math.floor(st / 100);
        const stMinute = st % 100;
        const edHour = stHour + Math.floor((stMinute + this.context.menu.slot_minute) / 60);
        const edMinute = (stMinute + this.context.menu.slot_minute) % 60;
        return {
          day_of_week: day,
          start_at: `${stHour.toString().padStart(2, '0')}${stMinute.toString().padStart(2, '0')}`,
          end_at: `${edHour.toString().padStart(2, '0')}${edMinute.toString().padStart(2, '0')}`,
        };
      }).filter((time, index, data) => {
        // 2時間以上連続している場合は結合する
        if (index === 0) {
          lastIndex = 0;
        } else if (time.start_at === data[lastIndex].end_at) {
          data[lastIndex].end_at = time.end_at;
          return false;
        } else {
          lastIndex++;
        }
        return true;
      }));
    });

    const menu = this.context.menu;
    menu.menu_datetime_requests = menuDatetimeRequests;
    this.setState({
      menu: menu,
    });
    this.context.onStateUpdate({
      isEdited: true,
    });
  }

  onChangeMenuContent (key, value) {
    const menu = this.context.menu;
    menu[key] = value;
    this.context.onStateUpdate({ menu: menu, isEdited: true });
  }

  onChangeAcceptDatetimeRequest (e) {
    this.onChangeMenuContent('accept_datetime_request', e.value);
  }

  onChangeAcceptStripePayment (e) {
    this.onChangeMenuContent('accept_stripe_payment', e.value);
  }

  openModal () {
    this.convertDatetimeRequestToTimeTable();
    this.setState({ isOpen: true });
  }

  closeModal (timeTable) {
    this.setState({ isOpen: false });
    this.dates = timeTable;
    this.convertTimeTableToDatetimeRequest();
  }

  render () {
    const disabled = !this.context.isEdit;
    const acceptDatetimeRequest = this.context.menu.accept_datetime_request;
    const acceptStripePayment = this.context.menu.accept_stripe_payment;
    return (
      <div className="p-menu_content">
        <div className="p-menu_content-title">
          <p>住所<span className="required required--display"/></p>
        </div>
        <MnInputRadioGroup
          groups={MenusUseRequestFlagsI18n}
          name="use_address"
          value={this.context.menu.use_address}
          onChange={(ev) => { this.context.onStateUpdate({ menu: { ...this.context.menu, use_address: ev.value }, isEdited: true }); }}
          disabled={disabled}
          isBoolean={true}
          validates={MenuUseRequestFlagValidator}
          onValidate={ this.onValidate }
        />
        <div className="p-menu_content-title">
          <p>紹介状の有無<span className="required required--display"/></p>
        </div>
        <MnInputRadioGroup
          groups={MenusUseRequestFlagsI18n}
          name="use_has_referral"
          value={this.context.menu.use_has_referral}
          onChange={(ev) => { this.context.onStateUpdate({ menu: { ...this.context.menu, use_has_referral: ev.value }, isEdited: true }); }}
          disabled={disabled}
          isBoolean={true}
          validates={MenuUseRequestFlagValidator}
          onValidate={ this.onValidate }
        />
        <div className="p-menu_content-title">
          <p>保険証のアップロード<span className="required required--display"/></p>
        </div>
        <MnInputRadioGroup
          groups={MenusUseRequestFlagsI18n}
          name="use_insurance_card"
          value={this.context.menu.use_insurance_card}
          onChange={(ev) => { this.context.onStateUpdate({ menu: { ...this.context.menu, use_insurance_card: ev.value }, isEdited: true }); }}
          disabled={disabled}
          isBoolean={true}
          validates={MenuUseRequestFlagValidator}
          onValidate={ this.onValidate }
        />
        <div className="p-menu_content-title">
          <p>医療証のアップロード</p>
        </div>
        <MnInputRadioGroup
          groups={MenusUseRequestFlagsI18n}
          name="use_etc_card"
          value={this.context.menu.use_etc_card}
          onChange={(ev) => { this.context.onStateUpdate({ menu: { ...this.context.menu, use_etc_card: ev.value }, isEdited: true }); }}
          disabled={disabled}
          isBoolean={true}
          validates={MenuUseRequestFlagValidator}
          onValidate={ this.onValidate }
        />
        <div className="p-menu_content-title">
          <p>診察券のアップロード</p>
        </div>
        <MnInputRadioGroup
          groups={MenusUseRequestFlagsI18n}
          name="use_patient_registration_card"
          value={this.context.menu.use_patient_registration_card}
          onChange={(ev) => { this.context.onStateUpdate({ menu: { ...this.context.menu, use_patient_registration_card: ev.value }, isEdited: true }); }}
          disabled={disabled}
          isBoolean={true}
          validates={MenuUseRequestFlagValidator}
          onValidate={ this.onValidate }
        />
        <div className="p-menu_content-title">
          <p>関連資料のアップロード</p>
        </div>
        <MnInputRadioGroup
          groups={MenusUseRequestFlagsI18n}
          name="use_document_related_to_referral_letter"
          value={this.context.menu.use_document_related_to_referral_letter}
          onChange={(ev) => { this.context.onStateUpdate({ menu: { ...this.context.menu, use_document_related_to_referral_letter: ev.value }, isEdited: true }); }}
          disabled={disabled}
          isBoolean={true}
          validates={MenuUseRequestFlagValidator}
          onValidate={ this.onValidate }
        />
        <div className="p-menu_content-title">
          <p>担当医の希望</p>
        </div>
        <MnInputRadioGroup
          groups={MenusUseRequestFlagsI18n}
          name="use_requested_worker"
          value={this.context.menu.use_requested_worker}
          onChange={(ev) => { this.context.onStateUpdate({ menu: { ...this.context.menu, use_requested_worker: ev.value }, isEdited: true }); }}
          disabled={disabled}
          isBoolean={true}
          validates={MenuUseRequestFlagValidator}
          onValidate={ this.onValidate }
        />
        <div className="p-menu_content-title">
          <p>リクエスト受付<span className="required required--reception"/></p>
        </div>
        <MnInputRadioGroup
          groups={MenusAcceptDatetimeRequestsI18n}
          name="accept_datetime_request"
          value={acceptDatetimeRequest}
          onChange={this.onChangeAcceptDatetimeRequest}
          disabled={disabled}
          validates={ MenuValidators.accept_datetime_request }
          onValidate={ this.onValidate }
        />
        {this.context.isEdit ? (
          <button className="c-mn-btn--third p-menu_content-datetime_button" onClick={ this.openModal }>
            <span>曜日・時間を設定する</span>
          </button>
        ) : null }

        {this.context.user.institution.stripe_account.status === 'available' ? (
          <>
            <div className="p-menu_content-title">
              <p>オンラインクレジットカード決済<span className="required required--reception"/></p>
            </div>
            <MnInputRadioGroup
              groups={MenusAcceptStripePaymentsI18n}
              name="accept_stripe_payment"
              value={acceptStripePayment}
              onChange={this.onChangeAcceptStripePayment}
              disabled={disabled}
              validates={ MenuValidators.accept_stripe_payment }
              onValidate={ this.onValidate }
            />
          </>
        ) : null }
        <div className="p-menu_content-title">
          <p>申込時の確認事項<span className="required required--display"/></p>
        </div>
        <MnInputRadioGroup
          groups={MenusUseRequestFlagsI18n}
          name="use_checklists"
          value={this.context.menu.use_checklists}
          onChange={(ev) => { this.context.onStateUpdate({ menu: { ...this.context.menu, use_checklists: ev.value }, isEdited: true }); }}
          disabled={disabled}
          isBoolean={true}
          validates={MenuUseRequestFlagValidator}
          onValidate={ this.onValidate }
        />
        <div className="p-interview_setting-panel">
          { this.context.menu.checklists.map((check, index) => {
            return (<div key={check.code}>
              <div className="p-menu_content-title">
                <p>確認事項{index + 1}</p>
                <span>{check.subject?.length || 0}/{this.maxLength}文字</span>
              </div>
              <div className="p-menu_content-input">
                <MnInputTextarea
                  name={`subject_${index}`}
                  onChange={ev => {
                    const checklists = this.context.menu.checklists.map(list => {
                      if (list.code === check.code) {
                        list.subject = ev.value;
                      }
                      return list;
                    });
                    this.context.onStateUpdate({ menu: { ...this.context.menu, checklists: checklists }, isEdited: true });
                  }}
                  disabled={disabled}
                  value={check.subject}
                  validates={ ChecklistValidators.subject }
                  onValidate={ this.onValidate }
                />
              </div>
              {this.context.isEdit && index > 0 ? (<div className="p-menu_content-checklists">
                <div className="delete-link">
                  <a onClick={ () => {
                    const checklists = this.context.menu.checklists.filter(list => check.code !== list.code);
                    this.context.onStateUpdate({ menu: { ...this.context.menu, checklists: checklists }, isEdited: true });
                  } }>削除</a>
                </div>
              </div>) : null}
            </div>);
          }) }
          {this.context.isEdit ? (
            <div className="p-menu_content-button">
              <button className="c-mn-btn--third w180px"
                onClick={() => {
                  const checklists = this.context.menu.checklists;
                  checklists.push({
                    code: uuidv4(),
                    subject: '',
                  });
                  this.context.onStateUpdate({ menu: { ...this.context.menu, checklists: checklists }, isEdited: true });
                }}
                disabled={this.context.menu.checklists.length >= this.maxCount}
              >
                {SvgPlusIcon}
                <span>確認事項を追加</span>
              </button>
            </div>
          ) : null }
        </div>
        <ModalDatetimeRequest isOpen={this.state.isOpen} closeModal={this.closeModal} timeTable={this.state.timeTable} datesInitializer={this.buildInitialDates} />
      </div>
    );
  }
}

MenuUseRequestForm.propTypes = {
  menu: PropTypes.object,
  const: PropTypes.object,
  onSave: PropTypes.func,
  context: PropTypes.object,
};
