import * as React from 'react';
import * as PropTypes from 'prop-types';
import Modal from 'react-modal';
import {
  AbstractForm,
  RootContext,
  MnInputText,
  MnInputSelect,
  MnButton,
  AccountsRolesI18n,
  AccountValidators,
} from '../../../index';

export default class AccountModal extends AbstractForm {
  static contextType = RootContext;

  kanaValidator = { matches: { validate: [/^[ぁ-んァ-ン]+$/], message: '不正な値です' } };

  constructor (props) {
    super(props);
    this.state = {
      user: { ...props.user },
      cancel: false,
      edited: false,
      errors: {},
    };
  }

  onSuccess (resolve) {
    this.context.showLoading();

    var accountParams = this.state.user;
    if (accountParams.role === 'normal') { delete accountParams.role; }
    this.context.fetch(this.state.user.api_location.update, {
      methods: 'PATCH',
      body: { account: accountParams },
    }).then(data => {
      this.setState({ user: data.result });
      this.context.onStateUpdate({ user: data.result });
      setTimeout(() => {
        this.props.onClose();
        this.props.onSave();
      });
    }).catch(err => {
      if (err.status === 400) {
        err.json().then(body => {
          this.setState({ errors: body.errors });
        });
      } else {
        this.context.onStateUpdate({ alert: { shown: true } });
      }
    }).finally(() => {
      this.context.hideLoading();
      resolve();
    });
  }

  render () {
    return (
      <Modal isOpen={ this.props.showModal } className="p-account-create-modal c-mn-modal-popup is-active has-background-white ol-n" overlayClassName="c-mn-modal is-active--dialog" >
        <span onClick={ () => {
          if (this.state.edited) {
            this.setState({ cancel: true });
          } else {
            this.props.onClose();
          }
        } } className="c-mn-popup__close">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="c-mn-icon-close" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="12" fill="#fff" className="c-mn-icon-close-x"></circle><path d="M12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C24 5.373 18.627 0 12 0ZM16.151 17.943L12.008 13.841L7.891 18L6.058 16.167L10.162 12.01L6 7.891L7.833 6.058L11.988 10.16L16.094 6L17.943 7.849L13.843 11.99L18 16.094L16.151 17.943Z" fill="#000" className="c-mn-icon-close-circle"></path><circle cx="12" cy="12" r="11.5" stroke="#000" className="c-mn-icon-close-flame"></circle></svg>
        </span>

        <div className="c-mn-modal-popup__header">
          <div className="my-sm">
            マイアカウント編集
          </div>
        </div>

        <div className="c-mn-modal-popup__contents-wrap">
          <div className="c-mn-modal-popup__contents ov-h">
            {this.props.user.role === 'manager' ? <div>
              <label className="c-mn-form-label">権限</label>
              <div className="w100p">
                <MnInputSelect
                  name='role'
                  value={ this.state.user.role }
                  hideBlank={true}
                  groups={ AccountsRolesI18n }
                  errors={ this.state.errors.role }
                  onChange={ (e) => { this.setState({ edited: true, user: { ...this.state.user, role: e.value } }); } }
                />
              </div>
            </div> : null}

            <div className="mt-16px">
              <label className="c-mn-form-label">氏名</label>
              <div className="columns is-mobile">
                <div className="column">
                  <MnInputText placeholder="姓"
                    name='family_name'
                    value={ this.state.user.family_name }
                    validates={ AccountValidators.family_name }
                    onValidate={ this.onValidate }
                    onChange={ (e) => { this.setState({ edited: true, user: { ...this.state.user, family_name: e.value } }); } }
                  />
                </div>
                <div className="column">
                  <MnInputText placeholder="名"
                    name='given_name'
                    value={ this.state.user.given_name }
                    validates={ AccountValidators.given_name }
                    onValidate={ this.onValidate }
                    onChange={ (e) => { this.setState({ edited: true, user: { ...this.state.user, given_name: e.value } }); } }
                  />
                </div>
              </div>
            </div>

            <div className="mt-16px">
              <label className="c-mn-form-label">氏名(カナ)</label>
              <div className="columns is-mobile">
                <div className="column">
                  <MnInputText placeholder="セイ"
                    name='family_name_kana'
                    value={ this.state.user.family_name_kana }
                    convertKana={true}
                    validates={ { ...AccountValidators.family_name_kana, ...this.kanaValidator } }
                    onValidate={ this.onValidate }
                    onChange={ (e) => { this.setState({ edited: true, user: { ...this.state.user, family_name_kana: e.value } }); } }
                  />
                </div>
                <div className="column">
                  <MnInputText placeholder="メイ"
                    name='given_name_kana'
                    value={ this.state.user.given_name_kana }
                    convertKana={true}
                    validates={ { ...AccountValidators.given_name_kana, ...this.kanaValidator } }
                    onValidate={ this.onValidate }
                    onChange={ (e) => { this.setState({ edited: true, user: { ...this.state.user, given_name_kana: e.value } }); } }
                  />
                </div>
              </div>
            </div>

            <div className="mt-16px">
              <label className="c-mn-form-label">メールアドレス</label>
              <MnInputText placeholder="例) sample@medicalnote.co.jp"
                name='email'
                value={ this.state.user.email }
                validates={ AccountValidators.email }
                onValidate={ this.onValidate }
                onChange={ (e) => { this.setState({ edited: true, user: { ...this.state.user, email: e.value } }); } }
              />
            </div>

            <div className="my-lg">
              <MnButton class="c-mn-btn c-mn-btn--basic-s c-mn-btn--first m0-a maw200px" onClick={ this.onSave }>
                <span>保存する</span>
              </MnButton>
            </div>
            <Modal
              isOpen={this.state.cancel}
              className="c-mn-modal-dialog is-active"
              overlayClassName="c-mn-modal is-active--dialog"
            >
              <p className="c-mn-modal-dialog__title">アカウントの編集をキャンセルしますか？</p>
              <div className="c-mn-modal-dialog__txt">キャンセルすると編集した内容は破棄されます。</div>
              <div className="c-mn-modal-dialog__btn-wrap">
                <a className="c-mn-btn c-mn-btn--basic-s c-mn-btn--third c-mn-btn--compact js-modal-dialog02-close" onClick={ () => { this.setState({ cancel: false }); } }>
                  <span>いいえ</span>
                </a>
                <a className="c-mn-btn c-mn-btn--basic-s c-mn-btn--first c-mn-btn--compact js-modal-dialog02-close" onClick={ () => {
                  this.setState({ cancel: false, edited: false, user: { ...this.props.user } });
                  this.props.onClose();
                } }>
                  <span>キャンセルする</span>
                </a>
              </div>
            </Modal>
          </div>
        </div>
      </Modal>
    );
  }
}

AccountModal.propTypes = {
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  user: PropTypes.object,
};
