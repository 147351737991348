import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import * as Sentry from '@sentry/react';

// user-agentによるiOSの判定
export function IsIOS () {
  return new RegExp('(iPhone|iPad|iPod)').test(navigator.userAgent);
}

// user-agentによるiOS13の判定
export function IsIOS13 () {
  return new RegExp('(iPhone|iPad).*Version/13.*Safari/').test(navigator.userAgent);
}

// WebRTCに対応しているか判別
export function IsWebRTC () {
  let ok = true;
  try {
    ok = !!RTCPeerConnection;
  } catch {
    ok = false;
  }
  return ok;
}

export function I18nT (items, key) {
  return items.find(item => item.key === key)?.value;
}

/** 対応言語 */
export const Languages = ['ja', 'en'];

export const ContactUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSfo7H_8QkKbk4DVeOz_qQIDJUsJA3QkzCBk0mH1KCTM1u7Mqg/viewform';

// i18next 初期化
i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
  backend: {
    loadPath: '/locales/{{lng}}.json',
  },
  fallbackLng: 'en',
  debug: true,
  interpolation: { escapeValue: false },
});

export { i18n };

export const ViewPatientsSexes = [
  { key: 'male', value: 'patients.sexes.male' },
  { key: 'female', value: 'patients.sexes.female' },
];

export const catchException = (exception) => {
  Sentry.captureException(exception);
};
