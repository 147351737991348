import * as React from 'react';
import {
  AbstractForm,
  MenuValidators,
  MenuFeeValidators,
  MnInputText,
  MnInputTextarea,
  MnInputRadioGroup,
  RootContext,
  MenusTreatmentCategoriesI18n,
} from '../../../index';

export default class MenuContent extends AbstractForm {
  static contextType = RootContext;
  maxTitleLength = 50;
  maxDescriptionLength = 300;
  maxMenuFeeLength = 30;
  maxMenuFeeCount = 5;

  constructor (props) {
    super(props);
    this.state = {
      titleLength: 0,
      descriptionLength: 0,
      menuFeeLength: [],
      isAddMenuFee: false,
    };
    this.renderMenuFee = this.renderMenuFee.bind(this);
    this.onAddMenuFee = this.onAddMenuFee.bind(this);
    this.onDeleteMenuFee = this.onDeleteMenuFee.bind(this);
    this.onChangeMenuFee = this.onChangeMenuFee.bind(this);
    this.onChangeMenuContent = this.onChangeMenuContent.bind(this);
    this.onChangeTreatmentCategory = this.onChangeTreatmentCategory.bind(this);
    this.onChangeTitleLength = this.onChangeTitleLength.bind(this);
    this.onChangeDescriptionLength = this.onChangeDescriptionLength.bind(this);
    this.checkCount = this.checkCount.bind(this);
    this.checkMenuFeeCount = this.checkMenuFeeCount.bind(this);
    this.renderTreatmentCategoryInputRadioGroup = this.renderTreatmentCategoryInputRadioGroup.bind(this);
  }

  componentDidMount () {
    this.checkCount();
    this.checkMenuFeeCount();
    this.context.onStateUpdate({ onValidateMenuContent: this.validation });
  }

  onChangeMenuContent (key, value) {
    const menu = this.context.menu;
    menu[key] = value;
    this.context.onStateUpdate({ menu: menu, isEdited: true });
  }

  onChangeTreatmentCategory (e) {
    this.onChangeMenuContent('treatment_category', e.value);
  }

  onChangeTitleLength (e) {
    this.setState({
      titleLength: e.value?.length || 0,
    });
    this.onChangeMenuContent('title', e.value);
  }

  onChangeDescriptionLength (e) {
    this.setState({
      descriptionLength: e.value?.length || 0,
    });
    this.onChangeMenuContent('description', e.value);
  }

  onAddMenuFee () {
    const menu = this.context.menu;
    this.context.menu.menu_fees.push({
      amount: 0,
      name: '',
    });
    this.context.onStateUpdate({ menu: menu });
    this.checkMenuFeeCount();
  }

  onDeleteMenuFee (index) {
    this.validators = this.validators.filter((validator, i) => {
      if (validator.name !== `name_${index}` && validator.name !== `amount_${index}`) {
        return validator;
      }
    });
    const menu = this.context.menu;
    menu.menu_fees = menu.menu_fees.filter((_, i) => {
      return i !== index;
    });
    this.context.onStateUpdate({ menu: menu });
    this.checkMenuFeeCount();
  }

  onChangeMenuFee (e) {
    const index = e.callbackParams.index;
    const key = e.callbackParams.key;
    const menu = this.context.menu;
    menu.menu_fees = menu.menu_fees.map((menuFee, i) => {
      if (index === i) {
        menuFee[key] = e.value;
      }
      return menuFee;
    });
    if (key === 'name') {
      this.checkMenuFeeCount();
    }
    this.context.onStateUpdate({ menu: menu, isEdited: true });
  }

  checkCount () {
    const menu = this.context.menu;
    this.setState({
      titleLength: menu.title.length,
      descriptionLength: menu.description.length,
    });
  }

  checkMenuFeeCount () {
    const ok = this.context.menu.menu_fees.length < this.maxMenuFeeCount;
    const menuFeeCount = this.context.menu.menu_fees.map(menuFee => (menuFee.name?.length));
    this.setState({
      isAddMenuFee: ok,
      menuFeeLength: menuFeeCount,
    });
  }

  renderMenuFee (menuFees) {
    if (this.context.user.institution.regional_medical_cooperation) return;

    return (
      <div className="p-menu_content-fees">
        {menuFees.map((menuFee, index) => {
          return (
            <div
              key={index}
              className="p-menu_content-fee"
            >
              <div className="p-menu_content-title">
                <p>価格{index + 1}（税込）</p>
                <span>{this.state.menuFeeLength[index]}/{this.maxMenuFeeLength}文字</span>
              </div>
              <div></div>
              <div></div>
              <MnInputText
                name={`name_${index}`}
                value={menuFee.name || ''}
                callbackParams={ { index: index, key: 'name' } }
                onChange={ this.onChangeMenuFee }
                disabled={!this.context.isEdit}
                validates={ MenuFeeValidators.name }
                onValidate={ (e) => { this.validators = [...this.validators, e]; } }
              />
              <div className="p-menu_content-fee-amount">
                <MnInputText
                  name={`amount_${index}`}
                  value={menuFee.amount === 0 ? '0' : menuFee.amount}
                  callbackParams={ { index: index, key: 'amount' } }
                  onChange={ this.onChangeMenuFee }
                  disabled={!this.context.isEdit}
                  validates={ MenuFeeValidators.amount }
                  onValidate={ (e) => { this.validators = [...this.validators, e]; } }
                />
              </div>
              <div className="p-menu_content-fee-others">
                {this.context.isEdit ? (
                  <div className="delete-link">
                    <a onClick={ () => { this.onDeleteMenuFee(index); } }>削除</a>
                  </div>
                ) : null}
              </div>
            </div>
          );
        })}
        {this.context.isEdit ? (
          <div className="p-menu_content-button">
            <button className="c-mn-btn--third" onClick={ this.onAddMenuFee } disabled={!this.state.isAddMenuFee}>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7 0C6.44772 0 6 0.447716 6 1V6L1 6C0.447715 6 0 6.44772 0 7C0 7.55228 0.447716 8 1 8H6V13C6 13.5523 6.44772 14 7 14C7.55228 14 8 13.5523 8 13V8L13 8C13.5523 8 14 7.55228 14 7C14 6.44771 13.5523 6 13 6H8V1C8 0.447715 7.55228 0 7 0Z"
                  fill="#27B1DB"
                />
              </svg>
              <span>値段を追加</span>
            </button>
          </div>
        ) : null }
      </div>
    );
  }

  renderTreatmentCategoryInputRadioGroup (disabled) {
    if (!this.context.user.institution.regional_medical_cooperation) {
      return <>
        <div className="p-menu_content-title">
          <p>診療区分</p>
        </div>
        <MnInputRadioGroup
          groups={MenusTreatmentCategoriesI18n}
          name="treatment_category"
          value={this.context.menu.treatment_category}
          onChange={this.onChangeTreatmentCategory}
          disabled={disabled} />
      </>;
    }
  }

  render () {
    const disabled = !this.context.isEdit;
    const titleValue = this.context.menu.title;
    const descriptionValue = this.context.menu.description;
    const menuFees = this.context.menu.menu_fees;

    return (
      <div className="p-menu_content">
        <div className="p-menu_content-title">
          <p>メニュータイトル</p>
          <span>{this.state.titleLength}/{this.maxTitleLength}文字</span>
        </div>
        <div className="p-menu_content-input">
          <MnInputTextarea
            name="title"
            class="scrollbar"
            onChange={this.onChangeTitleLength}
            disabled={disabled}
            max={this.maxTitleLength + 1}
            value={titleValue}
            validates={ MenuValidators.title }
            onValidate={ (e) => { this.validators = [...this.validators, e]; } }
          />
        </div>
        { this.renderTreatmentCategoryInputRadioGroup(disabled) }
        <div className="p-menu_content-title">
          <p>説明</p>
          <span>{this.state.descriptionLength}/{this.maxDescriptionLength}文字</span>
        </div>
        <div className="p-menu_content-input">
          <MnInputTextarea
            name="description"
            class="scrollbar"
            onChange={this.onChangeDescriptionLength}
            disabled={disabled}
            max={this.maxDescriptionLength + 1}
            value={descriptionValue}
            validates={ MenuValidators.description }
            onValidate={ (e) => { this.validators = [...this.validators, e]; } }
          />
        </div>
        { this.renderMenuFee(menuFees) }

      </div>
    );
  }
}
