import * as React from 'react';
import { RootContext } from '../../../index';

export default class ExaminationTimes extends React.Component {
  static contextType = RootContext;

  render () {
    return (
      <div className='p-examination-times'>
        <div>診療</div>
        <div>{this.context.examinations.map(examination => {
          return (<div key={examination.code}>{examination.time} ({examination.minutes}分)</div>);
        })}</div>
      </div>
    );
  }
}
