import * as React from 'react';
import * as PropTypes from 'prop-types';
import Modal from 'react-modal';

export default class RequestStop extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      ...props,
      isChange: false,
      is_service_i18n: props.is_service ? '受付停止' : '受付',
    };
    Modal.setAppElement('body');
  }

  onOK = (e) => {
    e.preventDefault();
    this.update();
  }

  update = () => {
    // csrfトークンを取得
    const token = document.querySelector('meta[name=csrf-token]').content;
    const url = (this.state.is_service) ? this.state.api_location.stop : this.state.api_location.start;
    fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'X-CSRF-TOKEN': token,
      },
    }).then(data => data.json()).then(data => {
      this.setState({
        ...data,
        isChange: false,
        is_service_i18n: data.is_service ? '受付停止' : '受付',
      });
    });
  }

  onCancel = (e) => {
    e.preventDefault();
    this.setState({ isChange: false });
  }

  onChange = (e) => {
    e.preventDefault();
    this.setState(state => ({ isChange: !state.isChange }));
  }

  render () {
    return (
      <div>
        <div className="p-input-panel">
          <span>オンライン診療</span>
          {this.state.states_i18n.map(data => {
            return (<div key={data.key}>
              <input type="radio" name="stop" id={data.key} value={data.key} checked={this.state.state === data.key} onChange={this.onChange} />
              <label htmlFor={data.key}>{data.value}中</label>
            </div>);
          })}
        </div>
        <Modal
          isOpen={this.state.isChange}
          className="c-mn-modal-dialog is-active"
          overlayClassName="c-mn-modal is-active--dialog"
        >
          <p className="c-mn-modal-dialog__title">{this.state.is_service_i18n}中に変更しますか？</p>
          <p className="c-mn-modal-dialog__txt">オンライン診療を{this.state.is_service_i18n}します。</p>
          <div className="c-mn-modal-dialog__btn-wrap">
            <button className="c-mn-btn c-mn-btn--basic-s c-mn-btn--third c-mn-btn--compact" onClick={this.onCancel}><span>キャンセル</span></button>
            <button className="c-mn-btn c-mn-btn--basic-s c-mn-btn--first c-mn-btn--compact" onClick={this.onOK}>{this.state.is_service_i18n}する</button>
          </div>
        </Modal>
      </div>
    );
  }
}

RequestStop.propTypes = {
  id: PropTypes.number,
  patient: PropTypes.object,
  state_i18n: PropTypes.string,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  is_service: PropTypes.bool,
};
