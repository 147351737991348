import React from 'react';
import {
  RootContext,
  AbstractForm,
  MnTab,
  MnButton,
  MnDialog,
  MnInputRadioGroup,
  MenusAcceptPublishesI18n,
} from '../../../index';
import {
  MenuContent,
  MenuUseRequestForm,
  Disclaimer,
  TreatmentFlow,
  SettingInterview,
} from './index';

export default class MenuForm extends AbstractForm {
  static contextType = RootContext;

  tabs = [
    { id: 1, title: 'STEP1\nメニュー内容' },
    { id: 2, title: 'STEP2\nフォーム表示項目' },
    { id: 3, title: 'STEP3\n免責事項' },
    { id: 4, title: 'STEP4\n診療フロー' },
    { id: 5, title: 'STEP5\n事前問診' },
  ];

  constructor (props) {
    super(props);
    this.state = {
      isSave: false, // 保存時のモーダル
    };

    this.onSaveOk = this.onSaveOk.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  validation () {
    return new Promise((resolve, reject) => {
      Promise.all([
        this.context.onValidateDisclaimer(),
        this.context.onValidateTreatmentFlow(),
        this.context.onValidateMenuContent(),
        this.context.onValidateMenuUseRequest(),
        this.context.onValidateInterviewSetting(),
      ]).then(() => {
        console.log('ok');
        resolve();
      }).catch(error => {
        console.log(error);
        reject(error);
      });
    });
  }

  onSuccess (resolve) {
    resolve();
    this.setState({ isSave: true });
  }

  onSaveOk () {
    return new Promise(resolve => {
      let url = this.context.menu.api_location.update;
      const params = {
        methods: 'PATCH',
        body: {
          menu: {
            ...this.context.menu,
            checklists: this.context.menu.checklists.map(check => {
              if (check.code?.indexOf('-') !== -1) {
                return { code: null, subject: check.subject || '' };
              }
              return check;
            }),
          },
        },
      };
      if (!this.context.menu.id) {
        url = this.context.api_location.create;
        params.methods = 'POST';
      }
      this.context.showLoading();
      this.context.fetch(url, params).then(data => {
        this.context.onStateUpdate({ menu: data.result, isEdited: false, isEdit: false });
        history.replaceState('', '', `${location.pathname.split('/').slice(0, -1).join('/')}/${data.result.id || 'new'}`);
        this.context.onUpdateMenus();
        this.context.onHeaderToast('保存しました。');
      }).catch(() => {
        this.context.onHeaderToast('保存できませんでした。');
      }).finally(() => {
        this.context.hideLoading();
        resolve();
        setTimeout(() => {
          this.setState({ isSave: false });
        }, 10);
      });
    });
  }

  onEdit () {
    this.context.onStateUpdate({ isEdit: true });
  }

  onCancel () {
    this.context.onEditedAlert(() => {
      if (this.context.menu.id) {
        this.context.onGetMenu(this.context.menu.api_location.show);
      } else {
        this.context.onCreateMenu();
      }
    });
  }

  render () {
    console.log(this.context);
    const isOpenNewMenu = !(this.context.menu && this.context.menu.code);
    return this.context.menu ? (<div className="p-menu_setting">
      <div className="p-menu_setting-head">
        <div>
          <span className="p-menu_setting-head-display_menu">メニュー表示</span>
          <MnInputRadioGroup
            name="accept_publish"
            class="c-mn-radio-btn c-mn-radio-btn--no-border"
            value={this.context.menu.accept_publish}
            groups={MenusAcceptPublishesI18n}
            disabled={!this.context.isEdit}
            onChange={(result) => {
              this.context.onStateUpdate({ menu: { ...this.context.menu, accept_publish: result.value } });
            }}
          />
        </div>
        {this.context.isEdit ? (
          <div>
            <button className="c-mn-btn--third bd-n" onClick={ this.onCancel }><span>キャンセル</span></button>
            <MnButton class="c-mn-btn--first" onClick={ this.onSave }><span>保存する</span></MnButton>
          </div>
        ) : (
          <div>
            <button
              className={ isOpenNewMenu ? 'c-mn-btn--disable' : 'c-mn-btn--second' }
              disabled={ isOpenNewMenu }
              onClick={ this.onEdit }
            ><span>編集する</span></button>
          </div>
        )}
      </div>
      <div className="p-menu_setting-tab">
        <MnTab tabs={this.tabs} active={this.context.tab} onChange={ (tab) => { this.context.onStateUpdate({ tab: tab.id }); } } />
      </div>
      <div className="p-menu_setting-body">
        <div className={this.context.tab === 1 ? 'p-menu_setting-body--content' : 'd-n'}><MenuContent/></div>
        <div className={this.context.tab === 2 ? 'p-menu_setting-body--content' : 'd-n'}><MenuUseRequestForm context={this.context}/></div>
        <div className={this.context.tab === 3 ? 'p-menu_setting-body--content' : 'd-n'}><Disclaimer/></div>
        <div className={this.context.tab === 4 ? 'p-menu_setting-body--content' : 'd-n'}><TreatmentFlow/></div>
        <div className={this.context.tab === 5 ? 'p-menu_setting-body--content' : 'd-n'}><SettingInterview/></div>
        <div className="p-menu_setting-body--button">
          {this.context.tab === 1 ? null : (
            <button className="c-mn-btn--third" onClick={() => { this.context.onStateUpdate({ tab: this.context.tab - 1 }); }} disabled={this.context.tab === 1}>
              <span>前のステップへ</span>
            </button>
          )}
          {this.context.tab === this.tabs.length ? null : (
            <button className="c-mn-btn--third" onClick={() => { this.context.onStateUpdate({ tab: this.context.tab + 1 }); }} disabled={this.context.tab === this.tabs.length}>
              <span>次のステップへ</span>
            </button>
          )}
        </div>
      </div>
      {this.context.isEdit ? (
        <div className="p-menu_setting-foot">
          <button className="c-mn-btn--first" onClick={ this.onSave }>保存する</button>
          <button className="c-mn-btn--third bd-n" onClick={ this.onCancel }><span>キャンセル</span></button>
        </div>
      ) : (
        <div className="p-menu_setting-foot">
          <button className="c-mn-btn--second" onClick={ this.onEdit }><span>編集する</span></button>
        </div>
      ) }
      <MnDialog
        isOpen={this.state.isSave}
        title="編集した内容を保存しますか？"
        btnSubmitLabel="保存する"
        handleSubmit={ this.onSaveOk }
        handleClose={ () => {
          this.setState({ isSave: false });
        } }
      />
    </div>) : null;
  }
}
