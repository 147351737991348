import * as React from 'react';
import PropTypes from 'prop-types';
import AbstractMnInput from './AbstractMnInput';
import MnInputText from './MnInputText';
import Modal from 'react-modal';

export default class MnInputReservation extends AbstractMnInput {
  weekOfDays = {
    0: '(日)',
    1: '(月)',
    2: '(火)',
    3: '(水)',
    4: '(木)',
    5: '(金)',
    6: '(土)',
    7: '(日)',
  };

  weekOfDayClass = {
    0: 'sunday',
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: 'saturday',
  };

  constructor (props) {
    super(props);
    const value = props.value instanceof Date ? props.value : props.value ? new Date(props.value) : props.value;
    this.state = {
      value: value,
      displayValue: this.formatDate(value, 'yyyy/mm/dd HH:MM'),
      annotation: props.annotation,
      isShown: false,
      viewDate: props.value || props.dateRange?.start || new Date(),
      x: 0,
      y: 40,
    };
    this.modalRef = React.createRef();

    this.onChange = this.onChange.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
    this.showReservation = this.showReservation.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.onFinish = this.onFinish.bind(this);
    this.onPrevWeek = this.onPrevWeek.bind(this);
    this.onNextWeek = this.onNextWeek.bind(this);
    this.onError = this.onError.bind(this);
  }

  shouldComponentUpdate (nextProps, nextState) {
    super.shouldComponentUpdate(nextProps, nextState);
    if (nextState.isShown && this.modalRef.current) {
      let x = nextState.x;
      let y = nextState.y;
      const rect = this.modalRef.current.getBoundingClientRect();
      if (rect.x < 0) {
        x = 0 + this.modalRef.current.getBoundingClientRect().x;
      }
      if (this.modalRef.current.getBoundingClientRect().x + this.modalRef.current.getBoundingClientRect().width > window.innerWidth) {
        x = 0 - this.modalRef.current.getBoundingClientRect().x;
      }
      if (this.modalRef.current.getBoundingClientRect().y + window.pageYOffset < 0) {
        y = y + this.modalRef.current.getBoundingClientRect().y;
      }
      nextState.x = x;
      nextState.y = y;
    }
    return true;
  }

  isValid () {
    return new Promise((resolve, reject) => {
      const value = this.sanitizers(this._onChange({ value: this.state.value }));
      this.validate(value).then(() => {
        this.setState({ errors: undefined });
        resolve();
      }).catch(err => {
        this.setState({ errors: err });
        reject(err);
      });
    });
  }

  onChange (e) {
    const value = this.sanitizers(this._onChange(e));
    const display = this.formatDate(e.value, 'yyyy/mm/dd HH:MM');
    this.setState({ value: e.value, displayValue: display });
    this.props.onChange({ name: this.props.name, value: display });
    this.validate(value).then(() => {
      this.setState({ errors: undefined });
    }).catch(err => {
      this.setState({ errors: err });
      this.props.onError(err);
    });
  }

  _onChange (e) {
    const value = e.value?.getTime() || 0;
    return (value === 0) ? '' : value;
  }

  onChangeDate (date) {
    this.setState({ value: date });
    return date;
  }

  onChangeValue (e) {
    const date = this.onChangeDate(new Date(e.value));
    this.onChange({ value: date });
  }

  onError (e) {
    this.setState({ errors: e.errors });
  }

  onPrevWeek () {
    this.setState(state => ({ viewDate: new Date(state.viewDate.getTime() - 604800000) }));
  }

  onNextWeek () {
    this.setState(state => ({ viewDate: new Date(state.viewDate.getTime() + 604800000) }));
  }

  resetScroll () {
    setTimeout(() => {
      const el = document.querySelector(`#${this.props.name}-body .now`);
      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'center' });
      } else {
        document.querySelector('.c-mn-radio-btn.c-mn-radio-btn--no-border:not(.disabled)')?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 100);
  }

  showReservation (e) {
    this.resetScroll();
    this.setState({ isShown: true });
    if (e && e.target) {
      e.target.blur();
    }
  }

  renderIcon () {
    return (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M15.8332 2.49998H16.6665C17.5832 2.49998 18.3332 3.24998 18.3332 4.16665V17.5C18.3332 18.4166 17.5832 19.1666 16.6665 19.1666H3.33317C2.4165 19.1666 1.6665 18.4166 1.6665 17.5V4.16665C1.6665 3.24998 2.4165 2.49998 3.33317 2.49998H4.1665V1.66665C4.1665 1.20831 4.5415 0.833313 4.99984 0.833313C5.45817 0.833313 5.83317 1.20831 5.83317 1.66665V2.49998H14.1665V1.66665C14.1665 1.20831 14.5415 0.833313 14.9998 0.833313C15.4582 0.833313 15.8332 1.20831 15.8332 1.66665V2.49998ZM4.1665 17.5H15.8332C16.2915 17.5 16.6665 17.125 16.6665 16.6666V6.66665H3.33317V16.6666C3.33317 17.125 3.70817 17.5 4.1665 17.5Z" fill="black" fillOpacity="0.54"/></svg>);
  }

  renderCloseIcon () {
    return (<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 0C3.13425 0 0 3.13425 0 7C0 10.8657 3.13425 14 7 14C10.8657 14 14 10.8657 14 7C14 3.13425 10.8657 0 7 0ZM9.42142 10.4668L7.00467 8.07392L4.60308 10.5L3.53383 9.43075L5.92783 7.00583L3.5 4.60308L4.56925 3.53383L6.993 5.92667L9.38817 3.5L10.4668 4.57858L8.07508 6.99417L10.5 9.38817L9.42142 10.4668Z" fill="#999999"/></svg>);
  }

  renderPrevIcon () {
    return (<svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M0.324771 4.9997C0.306666 5.21017 0.37815 5.42691 0.539222 5.58798L4.73405 9.78281C5.02364 10.0724 5.49316 10.0724 5.78275 9.78281C6.07235 9.49321 6.07235 9.02369 5.78275 8.7341L2.04868 5.00003L5.78281 1.2659C6.0724 0.976308 6.0724 0.506786 5.7828 0.217194C5.49321 -0.0723981 5.02369 -0.0723981 4.7341 0.217195L0.539274 4.41202C0.378357 4.57294 0.306856 4.78941 0.324771 4.9997Z" fill="#27B1DB"/></svg>);
  }

  renderNextIcon () {
    return (<svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M5.67523 5.0003C5.69333 4.78983 5.62185 4.57309 5.46078 4.41202L1.26595 0.217194C0.97636 -0.0723981 0.506838 -0.072398 0.217246 0.217194C-0.0723466 0.506786 -0.0723461 0.976309 0.217246 1.2659L3.95132 4.99997L0.217194 8.7341C-0.0723981 9.02369 -0.072398 9.49321 0.217194 9.78281C0.506786 10.0724 0.976309 10.0724 1.2659 9.78281L5.46073 5.58798C5.62164 5.42706 5.69314 5.21059 5.67523 5.0003Z" fill="#27B1DB"/></svg>);
  }

  formatDate (date, format) {
    if (!date) {
      return date;
    }
    format = format.replace(/yyyy/g, date.getFullYear());
    format = format.replace(/mm/g, ('0' + (date.getMonth() + 1)).slice(-2));
    format = format.replace(/dd/g, ('0' + date.getDate()).slice(-2));
    format = format.replace(/HH/g, ('0' + date.getHours()).slice(-2));
    format = format.replace(/MM/g, ('0' + date.getMinutes()).slice(-2));
    format = format.replace(/SS/g, ('0' + date.getSeconds()).slice(-2));
    format = format.replace(/sss/g, ('00' + date.getMilliseconds()).slice(-3));
    return format;
  }

  formatTime (date) {
    return parseInt(this.formatDate(date, 'HHMM'));
  }

  onFinish () {
    this.setState({ isShown: false });
    this.onChange({ value: this.state.value });
  }

  isToday (date) {
    return this.formatDate(date, 'yyyymmdd') === this.formatDate(this.state.value, 'yyyymmdd');
  }

  isNow (hour) {
    return hour === this.formatDate(this.state.value, 'HH:MM');
  }

  isActive (date) {
    return this.formatDate(this.state.value, 'yyyymmddHHMM') === this.formatDate(date, 'yyyymmddHHMM');
  }

  isDisabled (date) {
    if (!this.props.accepts || this.props.accepts.length === 0) {
      return false;
    }
    const isDateRange = (!this.props.dateRange.start || this.props.dateRange.start <= date) && (!this.props.dateRange.end || date < this.props.dateRange.end);
    return this.props.accepts.findIndex(accept => {
      const time = this.formatTime(date);
      return isDateRange &&
        this.weekOfDays[accept.day_of_week] === this.weekOfDays[date.getDay()] &&
        accept.start_at <= time && time < accept.end_at;
    }) === -1;
  }

  render () {
    const dates = new Array(7).fill().map((_, index) => {
      const date = new Date(this.state.viewDate.getTime() + index * 86400000);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      return date;
    });
    // 1日の分数を枠の時間で割ったものが1日の予約枠の数
    const slotNum = Math.floor((24 * 60) / this.props.slotMinute);
    const rows = new Array(slotNum).fill().map((_, index) => {
      return dates.map(date => {
        const datetime = new Date(date.getTime() + index * (this.props.slotMinute * 60000));
        return {
          date: datetime,
          hour: `${datetime.getHours().toString().padStart(2, '0')}:${datetime.getMinutes().toString().padStart(2, '0')}`,
          class: this.isDisabled(datetime) ? 'disabled' : this.isActive(datetime) ? 'active' : '',
        };
      });
    });
    const { t } = this.props;
    return (
      <div className="p-input-reservation">
        <MnInputText
          class={ this.props.class }
          value={ this.state.displayValue }
          name={ this.props.name }
          placeholder={ this.props.placeholder }
          annotation={ this.state.annotation }
          errors={ this.state.errors }
          validates={ this.props.inputValidates }
          sanitizers={ this.props.sanitizers }
          disabled={ this.props.disabled }
          onChange={ this.onChangeValue }
          onFocus={ this.showReservation }
          onBlur={ this.props.onBlur }
          onError={ this.onError }
          onValidate={ this.props.onValidate }
          required={ this.props.required }
          readonly={ true }
          t={ this.props.t }
        >
          <div className="p-input-reservation--icon">
            <button className="p-input-reservation--btn-none" onClick={ this.showReservation } disabled={ this.props.disabled }>{this.icon ? this.icon() : this.renderIcon()}</button>
          </div>
        </MnInputText>
        <Modal
          isOpen={this.state.isShown}
          className="p-input-reservation--dialog"
          overlayClassName="c-mn-modal is-active--dialog"
        >
          <div ref={ this.modalRef } className="p-input-reservation--modal">
            <div className="p-input-reservation--modal--head">
              <button className="c-mn-btn--blank" onClick={ this.onPrevWeek }>{this.renderPrevIcon()}<span>{t ? t('MnInputReservation.prev_week') : '前の週'}</span></button>
              <span>{ this.formatDate(this.state.viewDate, t ? t('MnInputReservation.yyyy-mm') : 'yyyy年mm月') }<span className="sub">({this.props.title})</span></span>
              <button className="c-mn-btn--blank" onClick={ this.onNextWeek }><span>{t ? t('MnInputReservation.next_week') : '次の週'}</span>{this.renderNextIcon()}</button>
            </div>
            <div className="p-input-reservation--modal--column">
              <div></div>
              {dates.map(date => {
                return (
                  <div key={date.getDate()} className={ this.isToday(date) ? 'today' : this.weekOfDayClass[date.getDay()] }>
                    <div>{date.getDate()}</div>
                    <div>{t ? t(`MnInputReservation.day${date.getDay()}`) : this.weekOfDays[date.getDay()]}</div>
                  </div>
                );
              })}
            </div>
            <div id={ `${this.props.name}-body` } className="p-input-reservation--modal--body">
              {rows.map((cols, row) => {
                return (
                  <div key={`r${row}`}>
                    <div id={`${this.props.name}_${this.formatDate(cols[0].date, 'HHMM')}`} className={ this.isNow(cols[0].hour) ? 'now' : '' }>{cols[0].hour}</div>
                    {cols.map((cell, col) => {
                      return (<div key={`${row}-${col}`} className={`c-mn-radio-btn c-mn-radio-btn--no-border ${cell.class}`}>
                        <input type="radio"
                          onChange={ () => { this.onChangeDate(cell.date); } }
                          id={`${this.props.name}${row}-${col}`}
                          name={ this.props.name }
                          checked={ cell.class === 'active' }
                          disabled={ cell.class === 'disabled' }
                          className="c-mn-radio-btn__radio"
                        />
                        <label className="c-mn-radio-btn__label" htmlFor={`${this.props.name}${row}-${col}`}></label>
                      </div>);
                    })}
                  </div>
                );
              })}
            </div>
            <div className="p-input-reservation--modal--foot">
              <button className="c-mn-btn--blank" onClick={ this.onFinish }>{this.renderCloseIcon()}<span>{t ? t('MnInputReservation.done') : '完了'}</span></button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

MnInputReservation.propTypes = {
  ...AbstractMnInput.propTypes, // 基底クラスのpropTypesを継承
  accepts: PropTypes.array, // 受付可能曜日+時間
  dateRange: PropTypes.object, // 設定可能日時範囲
  inputValidates: PropTypes.object, // MnInputText用validates
  title: PropTypes.string, // タイトル
  slotMinute: PropTypes.number, // 予約枠の時間
};

MnInputReservation.defaultProps = {
  ...AbstractMnInput.defaultProps,
  dateRange: { start: new Date(), end: null },
  inputValidates: {
    matches: { validate: [/^\d{4}\/\d{2}\/\d{2} \d{2}:\d{2}$/], message: '不正な値です' },
  },
  validates: {
    isInt: { validate: [{ min: Date.now() }], message: '過去の日付は指定できません' },
  },
  sanitizers: {
    whitelist: ['\\d\\/: '],
  },
  title: '日時選択',
  slotMinute: 60,
};
