import * as React from 'react';
import Select from 'react-select';
import {
  AbstractMnInput,
  MnPopup,
} from '../../../index';

export default class TimePicker extends AbstractMnInput {
  onChange (time) {
    const value = this.sanitizers(this._onChange(time));
    this.setState({ value: time });
    this.props.onChange(value);
    this.validate(value).then(() => {
      this.setState({ errors: undefined });
    }).catch(err => {
      this.setState({ errors: err });
      this.props.onError(err);
    });
  }

  _onChange (timeObject) {
    return timeObject.value;
  }

  render () {
    const value = this.props.value ? {
      value: this.props.value,
      label: this.props.value.replace(/:00$/, ''),
    } : undefined;
    const options = [];
    for (let i = 8; i < 24; i++) {
      options.push({ value: `${('00' + i).slice(-2)}:00:00`, label: `${('00' + i).slice(-2)}:00` });
      options.push({ value: `${('00' + i).slice(-2)}:30:00`, label: `${('00' + i).slice(-2)}:30` });
    }
    const styles = {
      placeholder: () => ({
        color: '#ccc',
        fontSize: '1.6rem',
      }),
      singleValue: (provided, state) => {
        const fontSize = '1.6rem';

        return { ...provided, fontSize };
      },
    };

    return (
      <MnPopup annotation={this.state.annotation} closeIcon={this.renderIconClose} hideAnnotation={this.hideAnnotation}>
        <div className="pos-r">
          <Select
            name="reservedTime"
            value={value}
            options={options}
            placeholder="13:00"
            styles={styles}
            onChange={time => this.onChange(time)}
          />
          { this.renderErrors() }
        </div>
      </MnPopup>
    );
  }
}

TimePicker.propTypes = {
  ...AbstractMnInput.propTypes, // 基底クラスのpropTypesを継承
};
