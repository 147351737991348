import React from 'react';
import Modal from 'react-modal';
import Agora from './AgoraUtil';
import EventEmitter from 'events';
import {
  RootContext,
  MnButton,
  MnToast,
  SvgChatMinimumIcon,
  SvgChatOpenIcon,
  SvgChatCloseIcon,
  SvgShareCloseIcon,
  SvgChatSendIcon,
  SvgChatSendDisableIcon,
  IsIOS,
} from './index';
import { v4 as uuidv4 } from 'uuid';
import SimpleFormat from '@16g/react-simple-format';

export default class AbstractAgoraIO extends React.Component {
  static contextType = RootContext;

  SystemCall = {
    Stop: 1, // オンライン診療の中断
    Finish: 2, // オンライン診療の終了
    Join: 3, // メンバー検知
    Alive: 4, // 生存応答
    Kick: 5, // 強制退室
    Accept: 6, // 入室許可
    Reject: 7, // 入室拒否
  };

  AlertMode = {
    Reload: 0, // リロード
    List: 1, // 一覧に戻る
    None: 2, // 何もしない
  };

  FinishCode = {
    Reload: 0, // リロード
    List: 1, // 一覧に戻る
    Complete: 2, // 完了に進む
    Back: 3, // 事前問診に戻る
    Duplicate: 4, // 重複ログインエラー
    Kick: 5, // 強制退出
    None: 6, // 何もしない
    Exit: 7, // 退室
  };

  constructor (props) {
    super(props);
    // 各種関数をバインドしておかないとエラーになる
    this.initAgoraIO = this.initAgoraIO.bind(this);
    this.onNext = this.onNext.bind(this);
    this.onStey = this.onStey.bind(this);
    this.onFullscreen = this.onFullscreen.bind(this);
    this.onMuteAudio = this.onMuteAudio.bind(this);
    this.onMuteVideo = this.onMuteVideo.bind(this);
    this.onMuteScreen = this.onMuteScreen.bind(this);
    this.onChangeSpeekerDevice = this.onChangeSpeekerDevice.bind(this);
    this.onChangeVideoDevice = this.onChangeVideoDevice.bind(this);
    this.onChangeAudioDevice = this.onChangeAudioDevice.bind(this);
    this.setVolume = this.setVolume.bind(this);
    this.sendSystemCall = this.sendSystemCall.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.finish = this.finish.bind(this);
    this.start = this.start.bind(this);
    this.onKeyupSendMessage = this.onKeyupSendMessage.bind(this);
    this.onShowCameraArea = this.onShowCameraArea.bind(this);
    this.onShowAudioArea = this.onShowAudioArea.bind(this);
    this.onShowVolumeArea = this.onShowVolumeArea.bind(this);
    this.onShowShareArea = this.onShowShareArea.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onFinish = this.onFinish.bind(this);
    this.onMemberLeft = this.onMemberLeft.bind(this);
    this.onPeerLeave = this.onPeerLeave.bind(this);
    this.onStreamPublished = this.onStreamPublished.bind(this);
    this.onStreamAdded = this.onStreamAdded.bind(this);
    this.onStreamSubscribed = this.onStreamSubscribed.bind(this);
    this.onStreamRemoved = this.onStreamRemoved.bind(this);
    this.onStreamUpdated = this.onStreamUpdated.bind(this);
    this.onPlayerStatusChange = this.onPlayerStatusChange.bind(this);
    this.onTokenPrivilegeWillExpire = this.onTokenPrivilegeWillExpire.bind(this);
    this.onTokenPrivilegeDidExpire = this.onTokenPrivilegeDidExpire.bind(this);
    this.onMuteVideoEvent = this.onMuteVideoEvent.bind(this);
    this.onUnmuteVideoEvent = this.onUnmuteVideoEvent.bind(this);
    this.onConnectionStateChanged = this.onConnectionStateChanged.bind(this);
    this.onMessageFromPeer = this.onMessageFromPeer.bind(this);
    this.onSystemCall = this.onSystemCall.bind(this);
    this.onChannelMessage = this.onChannelMessage.bind(this);
    this.onMemberJoined = this.onMemberJoined.bind(this);
    this.onResize = this.onResize.bind(this);
    this.onViewResize = this.onViewResize.bind(this);
    this.onAlert = this.onAlert.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.updateState = this.updateState.bind(this);
    this.onMemberAccept = this.onMemberAccept.bind(this);
    this.onMemberReject = this.onMemberReject.bind(this);
    this.onMemberKick = this.onMemberKick.bind(this);
    this.setLocalStream = this.setLocalStream.bind(this);
    this.onChatToggle = this.onChatToggle.bind(this);
    this.onChatClose = this.onChatClose.bind(this);
    this.onChatOpen = this.onChatOpen.bind(this);
    this.onMemberZoom = this.onMemberZoom.bind(this);
    this.onMemberMore = this.onMemberMore.bind(this);
    this.copyUrl = this.copyUrl.bind(this);
    this.onCopyUrl = this.onCopyUrl.bind(this);
    this.renderSharingUrl = this.renderSharingUrl.bind(this);
    this.t = this.t.bind(this);

    // UUID作成or読み込み
    const uuid = window.localStorage.getItem('uuid') || uuidv4();
    if (!window.localStorage.getItem('uuid')) {
      window.localStorage.setItem('uuid', uuid);
    }
    // state初期化
    this.state = {
      uuid: uuid,
      isStart: false, // 診療開始フラグ
      isStop: false, // 配信停止フラグ
      isDuplicate: false, // 重複ログイン
      isLoading: false, // 右ペインのローディング表示
      microphoneId: null, // 選択済みオーディオデバイス
      cameraId: null, // 選択済みビデオデバイス
      speekerId: null, // 選択済みスピーカーデバイス
      audio: [], // マイクデバイス一覧
      video: [], // ビデオデバイス一覧
      speeker: [], // スピーカーデバイス一覧
      chats: [], // チャットログ
      isUnreadChat: false, // チャット未読フラグ
      readChat: 0, // チャット既読数
      text: '', // 現在のテキスト
      isMuteAudio: false, // 音声ミュート
      isMuteVideo: false, // 映像ミュート
      isMuteScreen: true, // 画面共有ミュート
      volume: 100, // ボリューム
      isFullscreen: false, // フルスクリーン
      isCameraArea: false, // カメラエリア
      isAudioArea: false, // オーディオエリア
      isVolumeArea: false, // ボリュームエリア
      isShareArea: false, // 共有URLエリア
      btnCameraX: 0, // カメラボタン座標
      btnAudioX: 0, // オーディオボタン座標
      btnVolumeX: 0, // ボリュームボタン座標
      btnShareX: 0, // 共有URLボタン座標
      member: null, // 相手の存在を確認
      members: [{ uid: 'local_stream', name: 'ローカル', joined: true, mained: false, more: false, stream: null, muted: false, style: 'solid 1px #333' }], // メンバー一覧
      memberCount: 1, // メンバー数
      mainStream: null, // メインストリーム
      activeArea: 0, // メンバー一覧のアクティブ表示
      isStay: false, // 中断確認フラグ
      isFinish: false, // 完了確認フラグ
      isAlert: false, // アラートフラグ
      isExit: false, // 退室確認フラグ
      alertTitle: undefined, // アラートモーダルのタイトル
      alertBody: undefined, // アラートモーダルのボディ
      alertMode: this.AlertMode.Reload, // アラート発生時の動作モード
      isAbleFinish: false, // 診療終了が可能か確認するフラグ
      isMinChat: true, // チャットのミニマイズ
      isHideChat: false, // チャットの非表示
      finishCode: this.FinishCode.Reload, // 終了時の動作指定
      isPatient: false, // 患者モード
      isDoctor: false, // 医師モード
      containerSize: { w: 1280, h: 720 }, // コンテナサイズ
      toastMessage: null, // トースト表示
    };
    // イベント管理
    this.events = new EventEmitter();
    // リサイズイベント取得
    this.resizeEvent = window.addEventListener('resize', this.onResize);
    Modal.setAppElement('body');
    // コンソールログ無効化
    // console.log = () => {};
  }

  componentWillUnmount () {
    // コンポーネント破棄時にwindowイベントを削除
    window.removeEventListener('resize', this.onResize);
  }

  componentDidMount () {
    // イベントの監視
    this.events.on('systemcall', ev => {
      // アクションがあったメンバーを管理情報に追加
      switch (ev.code) {
      case this.SystemCall.Join:
        this.sendSystemCall(this.SystemCall.Alive);
      // eslint-disable-next-line no-fallthrough
      case this.SystemCall.Alive:
        if (this.context.agora.uid !== ev.event[1] && this.state.members.findIndex(member => (member.uid === ev.event[1])) === -1) {
          this.state.members.push({
            uid: ev.event[1],
            name: (ev.event[1].indexOf('doctor') !== -1) ? this.t('Agora.doctor', '医師') : `${this.t('Agora.patient', '患者')}${this.state.memberCount}`,
            joined: this.state.isPatient,
            mained: false,
            more: false,
            stream: null,
            muted: false,
            style: 'solid 1px #333',
          });
          this.setState(state => ({ members: this.state.members, memberCount: state.memberCount + 1 }));
        }
        break;
      case this.SystemCall.Kick:
        if (this.context.agora.uid === ev.event[0].text.split(' ')[2]) {
          this.setState({ finishCode: this.FinishCode.Kick });
          this.finish();
        }
        break;
      case this.SystemCall.Accept:
        console.log(this.context.agora.uid, ev.event[0].text.split(' '));
        if (this.context.agora.uid === ev.event[0].text.split(' ')[2]) {
          this.publish();
          this.setState({ isWaiting: false });
        }
        break;
      case this.SystemCall.Reject:
        console.log(this.context.agora.uid, ev.event[0].text.split(' '));
        if (this.context.agora.uid === ev.event[0].text.split(' ')[2]) {
          this.setState({ finishCode: this.FinishCode.Reject });
          this.finish();
        }
        break;
      }
    });
    this.onResize();
  }

  initAgoraIO () {
    // this.context.showLoading();
    this.setState({ isLoading: true });
    this.context.fetch(`${this.context.agora.api_location.token}?uuid=${this.state.uuid}`).then(body => {
      this.context.onStateUpdate({ agora: body.result });
      // ステータス監視
      if (body.result.state !== this.context.state) {
        location.reload();
        return;
      }
      // agora初期化
      this.agora = new Agora(this.context.agora.uid, this.context.agora.video_id, this.context.agora.app_id, this.context.agora.chat_token);
      this.handleEvents();
      this.agora.getDevice().then(item => {
        this.setState({
          microphoneId: item.audio[0],
          cameraId: item.video[0],
          speekerId: item.speeker[0],
          audio: item.audio,
          video: item.video,
          speeker: item.speeker,
        });
        this.agora.createStream().then(stream => {
          if (stream) {
            this.setLocalStream(stream);
            this.agora.mute(this.state.isMuteAudio).catch((e) => { console.log(e); });
            this.agora.mute(this.state.isMuteVideo, false).catch((e) => { console.log(e); });
            this.events.emit('constructor', true);
          } else {
            this.events.emit('constructor', false);
          }
        }).catch(e => {
          this.events.emit('constructor', false);
          this.events.emit('errors', e);
        });
      }).catch(err => {
        this.events.emit('constructor', false);
        this.events.emit('errors', err);
      });
    });
  }

  /**
   * APIリクエスト
   * @param {*} url
   * @param {*} params
   */
  updateState (url, params = {}) {
    return this.context.fetch(url, {
      methods: params.methods ? params.methods : 'POST',
      body: params.body,
    });
  }

  /**
   * window.resizeの監視
   */
  onResize () {
    if (this.state.isCameraArea || this.state.isAudioArea || this.state.isVolumeArea || this.state.isShareArea) {
      this.setState(() => ({
        isCameraArea: false,
        isAudioArea: false,
        isVolumeArea: false,
        isShareArea: false,
      }));
    }
    this.onViewResize();
  }

  /**
   * 映像サイズの監視
   */
  onViewResize () {
    const size = this.state.containerSize;
    const client = this.state.isDoctor ? { w: window.innerWidth - 360, h: window.innerHeight - 80 } : { w: window.innerWidth, h: window.innerHeight - 102 };
    const minSize = this.state.isDoctor ? { w: 680, h: 612.5 } : { w: 0, h: 0 };
    const margin = this.state.isDoctor ? { x: 0, y: 242, z: 64 } : { x: 0, y: 0 };
    // const main = this.state.members.find(member => member.mained);
    // const rate = main.stream.videoConstraint.height / main.stream.videoConstraint.width;
    // const aspect = (rate === 0.75) ? { w: 4, h: 3 } : (rate === 0.625) ? { w: 16, h: 10 } : { w: 16, h: 9 };
    const aspect = { w: 16, h: 9 }; // TODO:一旦16:9固定とする
    if ((client.w - margin.x) / aspect.w > (client.h - margin.y) / aspect.h) {
      size.w = (client.h - margin.y) / aspect.h * aspect.w + margin.x;
      size.h = client.h;
    } else {
      size.w = client.w;
      size.h = (client.w - margin.x) / aspect.w * aspect.h + margin.y;
    }
    this.setState({ containerSize: { w: Math.max(size.w, minSize.w), h: Math.max(size.h, minSize.h) } });
    document.documentElement.style.setProperty('--videoHeight', Math.max(size.h, minSize.h) - margin.z + 'px');
  }

  onPeerLeave (event) {
    // 相手が退出した際の処理が必要
    this.events.emit('onPeerLeave', event);
  }

  onStreamPublished (event) {
    // 自分のstreamが配信された際のイベント、ハンドリング不要？
    this.events.emit('onStreamPublished', event);
  }

  onStreamAdded (event) {
    console.log(event);
    this.agora.subscribe(event.stream).then(e => {
      this.events.emit('onStreamAdded', e);
    });
  }

  onStreamSubscribed (event) {
    console.log(event);
    // event.stream.setAudioVolume(this.state.volume); // ボリューム機能はなくなったのでコメントアウト
    const members = this.state.members.map(member => {
      if (member.uid === event.stream.params.streamID) {
        member.stream = event.stream;
        if (member.mained) {
          setTimeout(() => {
            this.setMainStream(member.uid);
          }, 10);
        }
      }
      return member;
    });
    this.setState({ members: members });
    this.events.emit('onStreamSubscribed', { uid: event.stream.params.streamID, stream: event.stream });
  }

  onStreamUpdated (ev) {
    console.log(ev);
    const members = this.state.members.map(member => {
      if (member.uid === ev.stream.params.streamID) {
        member.stream = ev.stream;
        if (member.mained) {
          setTimeout(() => {
            this.setMainStream(member.uid);
          }, 10);
        }
      }
      return member;
    });
    this.setState({ members: members });
    this.events.emit('onStreamUpdated', ev);
  }

  onStreamRemoved (event) {
    console.log(event);
    this.agora.unsubscribe(event.stream);
    const members = this.state.members.map(member => {
      if (member.uid === event.stream.params.streamID) {
        member.stream = null;
      }
      return member;
    });
    this.setState({ members: members });
    this.events.emit('onStreamRemoved', event);
  }

  onTokenPrivilegeWillExpire (event) {
    this.context.fetch(`${this.context.agora.api_location.token}?uuid=${this.state.uuid}`).then(body => {
      console.log(body);
      this.agora.renewVideoToken(body.result.video_id, body.result.chat_token);
    });
  }

  onTokenPrivilegeDidExpire (event) {
    // TODO: トークンの有効期限切れ
    this.events.emit('onTokenPrivilegeDidExpire', event);
  }

  onConnectionStateChanged (event) {
    this.events.emit('onConnectionStateChanged', event);
  }

  onPlayerStatusChange (ev) {
    if (ev.isErrorState && ev.status === 'play') {
      this.showAlert(
        this.t('Agora.video_error_annotation', 'ビデオまたは音声の再生でエラーが発生しました。\nご利用の端末では本サービスを使用できない可能性があります。'),
        this.AlertMode.None,
      );
    }
  }

  onMessageFromPeer (event) {
    console.log('onMessageFromPeer', event);
    this.events.emit('onMessageFromPeer', event);
  }

  onSystemCall (event) {
    const code = parseInt(event[0].text.split(' ')[1]);
    this.events.emit('systemcall', {
      code: code,
      event: event,
    });
  }

  onChannelMessage (event) {
    if (event[0].text.indexOf('/systemcall') === 0) {
      return this.onSystemCall(event);
    }
    this.state.chats.push({
      id: this.state.chats.length + 1,
      isOwn: event[1] === this.context.agora.uid,
      name: this.state.members.find(member => (member.uid === event[1]))?.name || this.t('Agora.your', 'あなた'),
      message: event[0].text,
      ts: event[2].serverReceivedTs,
    });
    this.setState({
      chats: this.state.chats,
      isUnreadChat: this.state.isHideChat || this.state.isMinChat,
      readChat: this.state.chats.length,
    });
    if (!this.state.isHideChat && !this.state.isMinChat) {
      this.setState({ readChat: this.state.chats.length });
    }
  }

  onMemberJoined (event) {
    this.sendSystemCall(this.SystemCall.Alive);
    this.events.emit('onMemberJoined', event);
  }

  onMemberLeft (event) {
    this.setState({ members: this.state.members.filter(mem => (event.indexOf(mem.uid) === -1)) });
    this.events.emit('onMemberLeft', event);
  }

  onMuteVideoEvent (ev) {
    const members = this.state.members.map(member => {
      if (member.uid === ev.uid) {
        member.muted = true;
      }
      return member;
    });
    this.setState({ members: members });
  }

  onUnmuteVideoEvent (ev) {
    const members = this.state.members.map(member => {
      if (member.uid === ev.uid) {
        member.muted = false;
      }
      return member;
    });
    this.setState({ members: members });
  }

  // イベント監視
  handleEvents () {
    // ビデオイベント
    this.agora.listenEvents()
      .on('peer-leave', ev => this.onPeerLeave(ev))
      .on('stream-published', ev => this.onStreamPublished(ev))
      .on('stream-added', ev => this.onStreamAdded(ev))
      .on('stream-subscribed', ev => this.onStreamSubscribed(ev))
      .on('stream-removed', ev => this.onStreamRemoved(ev))
      .on('stream-unpublished', ev => this.onStreamRemoved(ev))
      .on('onTokenPrivilegeWillExpire', () => this.onTokenPrivilegeWillExpire())
      .on('onTokenPrivilegeDidExpire', () => this.onTokenPrivilegeWillExpire())
      .on('mute-audio', ev => { console.log(ev); })
      .on('unmute-audio', ev => { console.log(ev); })
      .on('mute-video', ev => this.onMuteVideoEvent(ev))
      .on('unmute-video', ev => this.onUnmuteVideoEvent(ev))
      .on('crypt-error', ev => { console.log(ev); })
      .on('error', ev => { console.log(ev); })
      .on('exception', ev => { console.log(ev); })
      .on('stream-fallback', ev => { console.log(ev); })
      .on('stream-updated', ev => this.onStreamUpdated(ev))
      .on('player-status-change', ev => this.onPlayerStatusChange(ev));
    // チャットイベント
    this.agora.listenEvents()
      .on('ConnectionStateChanged', ev => this.onConnectionStateChanged(ev))
      .on('MessageFromPeer', ev => this.onMessageFromPeer(ev))
      .on('ChannelMessage', ev => this.onChannelMessage(ev))
      .on('MemberJoined', ev => this.onMemberJoined(ev))
      .on('MemberLeft', ev => this.onMemberLeft(ev));
  }

  // 診療開始
  start () {
    if (!this.agora) { // agoraの初期化が終わってなければリトライ
      setTimeout(() => { this.start(); }, 100);
      return;
    }
    console.log('start', this.context);
    this.setState({
      isStart: true,
    });
    this.agora.joinChat(this.context.agora.channel_name).then(channel => {
      const account = new RegExp(`^${this.context.agora.uid.split('-')[0]}`);
      this.sendSystemCall(this.SystemCall.Join);
      if (account.test('doctor')) {
        this.publish();
      }
      this.events.emit('join', true);
    }).catch(() => {
      this.events.emit('join', false);
    }).finally(() => {
      if (this.state.isPatient) {
        // this.context.hideLoading();
        this.setState({ isLoading: false });
      }
    });
  }

  // ビデオ配信開始
  publish () {
    console.log('joinVideo');
    this.agora.joinVideo(this.context.agora.channel_name).then(() => {
      this.events.emit('start', true);
    }).catch(() => {
      this.events.emit('start', false);
    });
  }

  // ローカルストリームの保管
  setLocalStream (stream) {
    const member = this.state.members.find(member => (member.uid === 'local_stream'));
    member.stream = stream;
    if (member.mained) {
      this.setMainStream(member.uid);
    } else {
      this.agora.forcePlay('local_stream', member.stream);
    }
  }

  // メインストリームの設定
  setMainStream (uid) {
    // メインストリームにするメンバー
    const member = this.state.members.find(member => (member.uid === uid));
    // 現在メインストリームで再生しているメンバー
    const main = this.state.members.find(member => member.mained);
    // 現在のメインストリームはワイプに戻す
    if (main && member.uid !== main.uid) {
      main.mained = false;
      this.agora.forcePlay(main.uid, main.stream);
    }
    // 新しいメインストリームを再生
    if (member) {
      member.mained = true;
      this.setState({ mainStream: undefined });
      setTimeout(() => {
        this.setState({ mainStream: member });
        this.agora.forcePlay('main_stream', member.stream);
      });
    }
    // stateを更新
    this.setState({ members: this.state.members });
    // 表示サイズを更新
    this.onViewResize();
  }

  // 診療終了
  finish () {
    // 退出時の状態を通知
    if (this.state.isDoctor) {
      this.sendSystemCall((this.state.isFinish) ? this.SystemCall.Finish : this.SystemCall.Stop);
    }
    if (this.agora) {
      this.agora.leaveChat().then(() => {
        this.agora.leaveVideo().then(() => {
          this.events.emit('finish', true);
        }).catch(() => {
          this.events.emit('finish', false);
        });
      }).catch(() => {
        this.events.emit('finish', false);
      });
    }
  }

  /**
   * キーボード操作でメッセージ送信
   * @param {*} e KeyboardEvent
   */
  onKeyupSendMessage (e) {
    if (e.key === 'Enter' && e.ctrlKey) { // Ctrl+Enter
      this.sendMessage();
    }
  }

  // メッセージ送信
  sendMessage () {
    return new Promise(resolve => {
      if (this.state.isStart && this.state.text) {
        this.agora.sendChatMessage(this.state.text);
        this.setState(() => ({
          text: '',
        }));
      }
      setTimeout(() => {
        resolve();
      }, 100);
    });
  }

  // メッセージ送信
  sendSystemCall (text) {
    this.agora.sendChatMessage(`/systemcall ${text}`);
  }

  /**
   * 音量調整
   */
  setVolume (add) {
    this.setState(() => ({
      volume: Math.max(Math.min(add, 100), 0),
    }));
    this.agora.setAudioVolume(this.state.volume);
  }

  /**
   * 次オーディオデバイス
   */
  onChangeAudioDevice (e) {
    e.preventDefault();
    const device = this.state.audio.find(v => v.name === e.target.value);
    this.setState(() => ({
      microphoneId: device,
      audio: [...this.state.audio],
    }));
    this.agora.setVideoDevice({ audio: device });
  }

  /**
   * 次ビデオデバイス
   */
  onChangeVideoDevice (e) {
    e.preventDefault();
    const device = this.state.video.find(v => v.name === e.target.value);
    this.setState(() => ({
      cameraId: device,
      video: [...this.state.video],
    }));
    this.agora.setVideoDevice({ video: device });
  }

  /**
   * 次スピーカーデバイス
   */
  onChangeSpeekerDevice (e) {
    e.preventDefault();
    const device = this.state.speeker.find(v => v.name === e.target.value);
    this.setState(() => ({
      speekerId: device,
      speeker: [...this.state.speeker],
    }));
    this.agora.setVideoDevice({ speeker: device });
  }

  /**
   * 音声配信の停止・再開
   */
  onMuteAudio (e) {
    e.preventDefault();
    if (!IsIOS()) {
      this.agora.mute(!this.state.isMuteAudio).then(ok => {
        this.setState(() => ({
          isMuteAudio: ok,
        }));
      }).catch(err => {
        console.log(err);
      });
    } else {
      this.setState({ toastMessage: null });
      setTimeout(() => {
        this.setState({ toastMessage: this.t('Agora.not_support', 'このOSバージョンではご利用できません') });
      }, 10);
    }
  }

  /**
   * 映像配信の停止・再開
   */
  onMuteVideo (e) {
    e.preventDefault();
    this.agora.mute(!this.state.isMuteVideo, false).then(ok => {
      const members = this.state.members.map(member => {
        if (member.uid === 'local_stream') {
          member.muted = ok;
        }
        return member;
      });
      this.setState({ members: members, isMuteVideo: ok });
    }).catch(err => {
      console.log(err);
    });
  }

  /**
   * 画面共有の停止・再開
   */
  onMuteScreen (e, isMute = this.state.isMuteScreen) {
    e.preventDefault();
    this.agora.createStream(isMute).then(stream => {
      if (stream) {
        this.agora.mute(this.state.isMuteAudio).catch((e) => { console.log(e); });
        this.agora.mute(this.state.isMuteVideo, false).catch((e) => { console.log(e); });
        this.setLocalStream(stream);
        this.agora.publishVideo();
      }
      this.setState(state => ({
        isMuteScreen: !isMute,
      }));
    }).catch(() => {
      this.onMuteScreen(e, false);
    });
  }

  /**
   * カメラエリアを表示・非表示
   * @param {*}} e
   */
  onShowCameraArea (e) {
    e.preventDefault();
    this.agora.getDevice().then(item => {
      this.setState({
        audio: item.audio,
        video: item.video,
        speeker: item.speeker,
      });
      if (item.video.findIndex(video => video.name === this.state.cameraId.name) === -1) {
        // 選択済みのデバイスが存在しない場合は、最初のデバイスを選択する
        this.setState({
          microphoneId: item.audio[0],
          cameraId: item.video[0],
          speekerId: item.speeker[0],
        });
      }
    });
    const x = document.querySelector('#cameraPanel').offsetLeft;
    this.setState(state => ({
      btnCameraX: x,
      isCameraArea: !state.isCameraArea,
      isAudioArea: (!state.isCameraArea) ? state.isCameraArea : state.isAudioArea,
      isVolumeArea: (!state.isCameraArea) ? state.isCameraArea : state.isVolumeArea,
      isShareArea: (!state.isCameraArea) ? state.isCameraArea : state.isShareArea,
    }));
  }

  /**
   * オーディオエリアを表示・非表示
   * @param {*}} e
   */
  onShowAudioArea (e) {
    e.preventDefault();
    this.agora.getDevice().then(item => {
      this.setState({
        audio: item.audio,
        video: item.video,
        speeker: item.speeker,
      });
      if (item.audio.findIndex(audio => audio.name === this.state.microphoneId.name) === -1) {
        // 選択済みのデバイスが存在しない場合は、最初のデバイスを選択する
        this.setState({
          microphoneId: item.audio[0],
          cameraId: item.video[0],
          speekerId: item.speeker[0],
        });
      }
    });
    const x = document.querySelector('#audioPanel').offsetLeft;
    this.setState(state => ({
      btnAudioX: x,
      isCameraArea: (!state.isAudioArea) ? state.isAudioArea : state.isCameraArea,
      isAudioArea: !state.isAudioArea,
      isVolumeArea: (!state.isAudioArea) ? state.isAudioArea : state.isVolumeArea,
      isShareArea: (!state.isAudioArea) ? state.isAudioArea : state.isShareArea,
    }));
  }

  /**
   * ボリュームエリアを表示・非表示
   * @param {*}} e
   */
  onShowVolumeArea (e) {
    e.preventDefault();
    const x = e.target.offsetLeft;
    this.setState(state => ({
      btnVolumeX: x,
      isCameraArea: (!state.isVolumeArea) ? state.isVolumeArea : state.isCameraArea,
      isAudioArea: (!state.isVolumeArea) ? state.isVolumeArea : state.isAudioArea,
      isVolumeArea: !state.isVolumeArea,
      isShareArea: (!state.isVolumeArea) ? state.isVolumeArea : state.isShareArea,
    }));
  }

  /**
   * 共有URLエリアを表示・非表示
   * @param {*}} e
   */
  onShowShareArea (e) {
    e.preventDefault();
    const x = document.querySelector('#sharePanel').offsetLeft - 90;
    this.setState(state => ({
      btnShareX: x,
      isCameraArea: (!state.isShareArea) ? state.isShareArea : state.isCameraArea,
      isAudioArea: (!state.isShareArea) ? state.isShareArea : state.isAudioArea,
      isVolumeArea: (!state.isShareArea) ? state.isShareArea : state.isVolumeArea,
      isShareArea: !state.isShareArea,
      isCopy: false,
    }));
  }

  /**
   * フルスクリーン
   */
  onFullscreen (e) {
    e.preventDefault();
    this.context.onStateUpdate({ isFullscreen: !this.state.isFullscreen });
    this.setState(state => ({
      isFullscreen: !state.isFullscreen,
    }));
    document.body.className = this.state.isFullscreen ? '' : 'full';
  }

  /**
   * 診療の中断
   */
  onStey (e) {
    e.preventDefault();
    this.setState(() => ({
      isStay: true,
    }));
  }

  /**
   * 診療の開始・終了
   */
  onNext () {
    if (!this.state.isStart) {
      this.initAgoraIO();
    } else {
      this.setState(() => ({
        isFinish: true,
      }));
    }
  }

  // キャンセル
  onCancel (e) {
    return new Promise(resolve => {
      this.setState({
        isStay: false,
        isFinish: false,
        isExit: false,
      });
      resolve();
    });
  }

  // 診療終了
  onFinish () {
    return new Promise(resolve => {
      this.finish();
      resolve();
    });
  }

  // アラートOK
  onAlert (e) {
    return new Promise(resolve => {
      switch (this.state.alertMode) {
      case this.AlertMode.Reload: location.reload(); break;
      case this.AlertMode.List: location.href = this.context.url_location.index; break;
      case this.AlertMode.None: this.setState({ isAlert: false }); break;
      }
      resolve();
    });
  }

  showAlert (body = '', mode = this.AlertMode.Reload, ok = undefined) {
    this.setState({
      isAlert: true,
      alertBody: body,
      alertMode: mode,
      alertOk: () => {
        this.context.exception(new Error(body || this.state.alertTitle));
        ok && ok();
      },
    });
  }

  // メンバー許可
  onMemberAccept (uid) {
    this.sendSystemCall(`${this.SystemCall.Accept} ${uid}`);
    const main = this.state.members.find(member => (member.mained));
    const members = this.state.members.map(member => {
      if (member.uid === uid) {
        member.joined = true;
        // member.stream = this.agora.getRemoteStream(uid); // TODO: 別のエラーが発生するのでコメントアウト
        if (member.stream) {
          if (member.mained) {
            this.setMainStream(uid);
          } else {
            this.agora.forcePlay(member.uid, member.stream);
          }
        }
        if (!main) {
          this.setMainStream(uid);
        }
      }
      return member;
    });
    this.setState({ members: members });
  }

  // メンバー拒否
  onMemberReject (uid) {
    this.sendSystemCall(`${this.SystemCall.Reject} ${uid}`);
    const members = this.state.members.filter(member => (member.uid !== uid));
    this.setState({ members: members });
  }

  // メンバーコンテキスト表示
  onMemberMore (uid) {
    const members = this.state.members.map(member => {
      if (member.uid === uid) {
        member.more = !member.more;
      }
      return member;
    });
    this.setState({ members: members });
  }

  // メンバー強制退出
  onMemberKick (uid) {
    this.sendSystemCall(`${this.SystemCall.Kick} ${uid}`);
    const members = this.state.members.filter(member => (member.uid !== uid));
    this.setState({ members: members });
  }

  onMemberZoom (uid) {
    this.setMainStream(uid);
  }

  onChatToggle () {
    this.setState(state => ({
      isMinChat: !state.isMinChat,
    }));
  }

  onChatClose () {
    this.setState({
      isHideChat: true,
    });
  }

  onChatOpen () {
    this.setState({
      isUnreadChat: false,
      readChat: this.state.chats.length,
      isMinChat: false,
      isHideChat: false,
    });
  }

  // チャット表示
  getChat (notMin = false) {
    return (
      <div className={ this.state.isMinChat && this.state.isFullscreen ? 'd-n' : !this.state.isHideChat ? 'p-chat' : 'd-n' }>
        <div className={ this.state.isUnreadChat ? 'p-chat--head unread' : 'p-chat--head' }>
          <span>{this.t('Agora.chat', 'チャット')}</span>
          {notMin ? null : (<button onClick={this.onChatToggle}>{this.state.isMinChat ? SvgChatOpenIcon : SvgChatMinimumIcon }</button>)}
          <button onClick={this.onChatClose}>{ SvgChatCloseIcon }</button>
        </div>
        <div className={ this.state.isMinChat ? 'd-n' : 'p-chat--body' }>
          {this.state.chats.map(data => {
            return (
              <div key={data.id} className="p-chat--body__message">
                <div className="p-chat--body__author">{data.name}</div>
                <div className="p-chat--body__message">{data.message}</div>
              </div>
            );
          })}
        </div>
        <div className={ this.state.isMinChat ? 'd-n' : 'p-chat--foot' }>
          <div className="p-chat-input">
            <div className="p-chat-input__form">
              <textarea
                name="message"
                placeholder={this.t('Agora.input_message', 'メッセージを入力')}
                disabled={!this.state.isStart}
                value={this.state.text}
                onChange={(e) => this.setState({ text: e.target.value })}
                onKeyUp={this.onKeyupSendMessage}
                className="c-mn-input-text pr-xl"
              />
              <MnButton
                class={!this.state.isStart || !this.state.text ? 'p-chat-input__form--disabled' : 'p-chat-input__form--btn'}
                disabled={!this.state.isStart || !this.state.text}
                onClick={this.sendMessage}
              >
                <span>{!this.state.isStart || !this.state.text ? SvgChatSendDisableIcon : SvgChatSendIcon }</span>
              </MnButton>
            </div>
          </div>
        </div>
      </div>
    );
  }

  formatSimple (text) {
    return (<SimpleFormat text={ text } />);
  }

  // モーダル表示
  getModal (key, modal = {
    title: '',
    body: '',
    resolve: () => {},
    reject: () => {},
  }, option = {}) {
    option = { ...{ showOK: true, showCancel: true, ok: 'OK', cancel: this.t('Agora.cancel', 'キャンセル'), classOK: '', classCancel: '' }, ...option };
    return (<Modal
      isOpen={this.state[key]}
      className="c-mn-modal-dialog is-active"
      overlayClassName="c-mn-modal is-active--dialog"
    >
      <p className="c-mn-modal-dialog__title">{modal.title}</p>
      <div className="c-mn-modal-dialog__txt">{ this.context.formatSimple(modal.body) }</div>
      <div className="c-mn-modal-dialog__btn-wrap d-fx jc-fe">
        { option.showCancel ? (<MnButton class={ option.classCancel ? option.classCancel : 'c-mn-btn c-mn-btn--basic-s c-mn-btn--third c-mn-btn--compact js-modal-dialog02-close mr-md'} onClick={modal.reject}>
          <span>{option.cancel}</span>
        </MnButton>) : '' }
        { option.showOK ? (<MnButton class={ option.classOK ? option.classOK : 'c-mn-btn c-mn-btn--basic-s c-mn-btn--first c-mn-btn--compact js-modal-dialog02-close'} onClick={modal.resolve}>
          <span>{option.ok}</span>
        </MnButton>) : '' }
      </div>
    </Modal>);
  }

  copyUrl (ev) {
    ev.clipboardData.setData('text/plain', this.context.url_location.share);
    ev.preventDefault();
    this.setState({ isCopy: false });
    setTimeout(() => {
      this.setState({ isCopy: true });
    }, 10);
    document.removeEventListener('copy', this.copyUrl);
  }

  onCopyUrl () {
    const isiOS = navigator.userAgent.match(/ipad|iphone/i);
    // iOS は copy イベントを利用できないため、copy イベントを使わない方法で実装
    if (isiOS) {
      var textArea = document.createElement('textArea');
      textArea.value = this.context.url_location.share;
      document.body.appendChild(textArea);

      var range = document.createRange();
      range.selectNodeContents(textArea);

      var selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);

      textArea.setSelectionRange(0, 999999);
      document.execCommand('copy');
      document.body.removeChild(textArea);

      this.setState({ isCopy: false });
      setTimeout(() => {
        this.setState({ isCopy: true });
      }, 10);
    } else {
      document.addEventListener('copy', this.copyUrl);
      document.execCommand('copy');
    }
  }

  renderSharingUrl () {
    return (
      <div className="p-sharing-url">
        <div className="p-sharing-url--btn">
          <div>{this.t('Agora.share_link', '共有URL')}</div>
          <button onClick={ this.onCopyUrl }><span>{this.t('Agora.copy', 'URLをコピー')}</span></button>
        </div>
        <div className="p-sharing-url--close"><a onClick={ this.onShowShareArea }>{ SvgShareCloseIcon }</a></div>
        {this.context.formatSimple(this.context?.url_location?.share)}
        <MnToast shown={ this.state.isCopy }><span>{this.t('Agora.copy_toast', '共有URLをコピーしました。')}</span></MnToast>
      </div>
    );
  }

  renderToast () {
    return (<MnToast shown={ this.state.toastMessage }><span>{ this.state.toastMessage }</span></MnToast>);
  }

  t (src, def = '') {
    return this.context.t ? this.context.t(src) : def;
  }

  render () {
    return <div></div>;
  }
}
