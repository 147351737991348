import * as React from 'react';
import PropTypes from 'prop-types';
import AbstractMnInput from './AbstractMnInput';
import MnPopup from './MnPopup';

export default class MnInputRadioGroup extends AbstractMnInput {
  render () {
    const groups = this.props.groups ? this.props.groups : [];
    return (
      <MnPopup annotation={this.props.annotation} closeIcon={this.renderIconClose} hideAnnotation={this.hideAnnotation}>
        <div id={this.props.name} className={this.props.class ? this.props.class : 'c-mn-radio-btn--col2'}>
          { groups.map(data => {
            return (
              <div key={data.key} className="c-mn-radio-btn p-fractal__radio-btn">
                <input type="radio" className="c-mn-radio-btn__radio" name={this.props.name} id={`${this.props.name}_${data.key}`} value={data.key} checked={this.state.value === data.key} onChange={ this.onChange } disabled={this.props.disabled}/>
                <label className="c-mn-radio-btn__label" htmlFor={`${this.props.name}_${data.key}`}>{ this.t(data.value) }</label>
              </div>
            );
          })}
        </div>
        { this.renderErrors() }
      </MnPopup>
    );
  }
}

MnInputRadioGroup.propTypes = {
  ...AbstractMnInput.propTypes, // 基底クラスのpropTypesを継承
  groups: PropTypes.array, // チェックボックスの選択肢 `[{key: xxx, value: yyy}, ...]`
};
