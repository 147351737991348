import React from 'react';
import { Layout, OpeningInquiryForm } from '../index';

export default class OpeningInquiryNew extends React.Component {
  render () {
    return (
      <Layout state={ this.props } class="bg-w">
        <OpeningInquiryForm/>
      </Layout>
    );
  }
}
