import React from 'react';
import { AbstractComponent } from '../../index';
import MnButton from '../../common/components/MnButton';

export default class PasswordChangeForm extends AbstractComponent {
  constructor (props) {
    super(props);
    this.state = {
      ...props,
      errorMessage: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit () {
    return new Promise((resolve, reject) => {
      this.fetch(this.state.api_location.password_change, {
        methods: 'PATCH',
        body: {
          current_password: this.state.current_password || '',
          password: this.state.password || '',
          password_confirmation: this.state.password_confirmation || '',
        },
      }).then(data => {
        resolve(location.href = this.state.url_location.root);
      }).catch(err => {
        reject(err.json().then(body => this.setState({ errorMessage: body.message })));
      });
    });
  }

  render () {
    return (
      <section className="hero p-password-change-form">
        <div className="hero-body">
          <div className="container mt-xl">
            <div className="column is-4 is-offset-4">
              <h1 className="fz32 fw-br ta-c">
                パスワード変更
              </h1>

              { this.state.errorMessage !== '' && (
                <div className="c-mn-message--error mt-lg">
                  <svg width="19" height="20" viewBox="0 0 19 20" fill="none" className="c-mn-icon-error" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M9.77995 20C14.8426 20 18.9466 15.8959 18.9466 10.8333C18.9466 5.77068 14.8426 1.66663 9.77995 1.66663C4.71734 1.66663 0.613281 5.77068 0.613281 10.8333C0.613281 15.8959 4.71734 20 9.77995 20ZM10.7745 12.8867H9.33445L9.01945 7.35168L8.94445 5.26668H11.1645L11.0895 7.35168L10.7745 12.8867ZM11.4345 15.4367C11.4345 16.2467 10.8345 16.8767 10.0545 16.8767C9.27445 16.8767 8.68945 16.2467 8.68945 15.4367C8.68945 14.6117 9.27445 13.9967 10.0545 13.9967C10.8345 13.9967 11.4345 14.6117 11.4345 15.4367Z" fill="#000" className="c-mn-icon-error-path"/></svg>
                  <div className="whs-pw">
                    { this.state.errorMessage }
                  </div>
                </div>
              )}

              <p className="fz16 ta-c mt-xl">
                パスワードの変更をおこないます。<br /><br />
                半角英小文字大文字数字をそれぞれ1種類以上含む、<br /><br />
                8文字以上30文字以内で入力してください。
              </p>

              <div className="field mt-lg">
                <label className="c-mn-form-label c-mn-form-label--navy">現在のパスワード</label>
                <input type="password" className="c-mn-input-text" placeholder="パスワードを入力" onChange={ (e) => this.setState({ current_password: e.target.value })} />
              </div>

              <div className="field mt-lg">
                <label className="c-mn-form-label c-mn-form-label--navy">新しいパスワード</label>
                <input type="password" className="c-mn-input-text" placeholder="パスワードを入力" onChange={ (e) => this.setState({ password: e.target.value })} />
              </div>

              <div className="field mt-lg">
                <label className="c-mn-form-label c-mn-form-label--navy">新しいパスワード(確認)</label>
                <input type="password" className="c-mn-input-text" placeholder="パスワードを入力" onChange={ (e) => this.setState({ password_confirmation: e.target.value })} />
              </div>

              <div className="field mt-xl">
                <MnButton class="c-mn-btn--first m-a" name="password-change-button" onClick={ this.handleSubmit }>
                  <>パスワードを変更する</>
                </MnButton>
              </div>

              <div className="mt-xl has-text-centered">
                <a className="c-mn-link fw-br" href="" onClick={ (e) => { e.preventDefault(); location.href = this.state.url_location.root; } }>
                  トップへ戻る
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
