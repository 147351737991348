import * as React from 'react';
import * as PropTypes from 'prop-types';
import {
  AbstractForm,
  RootContext,
  MnInputTextarea,
  MenuValidators,
} from '../../../index';

export default class Disclaimer extends AbstractForm {
  static contextType = RootContext;
  maxLength = 2000;

  constructor (props) {
    super(props);
    this.state = {
      length: 0,
    };
    this.onChange = this.onChange.bind(this);
    this.checkCount = this.checkCount.bind(this);
  }

  componentDidMount () {
    this.checkCount();
    this.context.onStateUpdate({ onValidateDisclaimer: this.validation });
  }

  checkCount () {
    this.setState({
      length: this.context.menu.disclaimer.length,
    });
  }

  onChange (e) {
    this.setState({
      length: e.value?.length || 0,
    });
    const menu = this.context.menu;
    menu.disclaimer = e.value;
    this.context.onStateUpdate({ menu: menu, isEdited: true });
  }

  render () {
    const disabled = !this.context.isEdit;
    const value = this.context.menu.disclaimer;
    return (
      <div className="p-disclaimer">
        <div className="p-disclaimer-maximum">
          {this.state.length}/{this.maxLength}文字
        </div>

        <MnInputTextarea
          class="scrollbar"
          onChange={this.onChange}
          disabled={disabled}
          max={this.maxLength + 1}
          value={value}
          validates={ MenuValidators.disclaimer }
          onValidate={ this.onValidate }
        />
      </div>
    );
  }
}

Disclaimer.propTypes = {
  menu: PropTypes.object,
  const: PropTypes.object,
  onSave: PropTypes.func,
};
