import React from 'react';
import PropTypes from 'prop-types';
import {
  AbstractAgoraIO,
  AgoraMembers,
  SvgArrowIcon,
  SvgMicIcon,
  SvgMicMuteIcon,
  SvgCameraIcon,
  SvgCameraMuteIcon,
  SvgScreenIcon,
  SvgChatIcon,
  SvgShareIcon,
} from '../../index';
import ExaminationTimes from './treatments/ExaminationTimes';

export default class AgoraVideo extends AbstractAgoraIO {
  constructor (props) {
    super(props);

    this.renderWaiting = this.renderWaiting.bind(this);
    this.renderExaminationTimes = this.renderExaminationTimes.bind(this);
  }

  componentDidMount () {
    this.setState({ isDoctor: true });
    setTimeout(() => { this.onViewResize(); }, 10);
    // 診療済の場合は早期リターン
    if (['requested', 'examined', 'canceled'].indexOf(this.context.state) !== -1) { return null; }

    super.componentDidMount();
    this.events.on('constructor', ok => {
      console.log(this.state);
      // for (const key of ['hoge1', 'hoge2', 'hoge3', 'hoge4', 'hoge5']) {
      //   this.state.members.push({
      //     uid: key,
      //     name: `患者${this.state.memberCount++}`,
      //     joined: false,
      //     stream: null,
      //     style: 'solid 1px #333',
      //   });
      //   this.setState({ members: this.state.members, memberCount: this.state.memberCount, isStart: true });
      // }
      if (!ok) {
        this.showAlert('ビデオまたはオーディオデバイスの初期化に失敗しました。  \n設定を確認の上、OKボタンを押してください。', this.AlertMode.None);
      } else {
        this.start();
      }
    }).once('start', ok => {
      this.updateState(this.context.api_location.examinations, {
        body: {
          treatment: {
            id: this.context.code,
          },
        },
      }).then(data => {
        this.context.onStateUpdate({
          examination_id: data.result.code,
          state: data.result.treatment.state,
          state_i18n: data.result.treatment.state_i18n,
          worker: data.result.treatment.worker,
        });
        this.setState({ isAbleFinish: true });
      }).catch(() => {
        this.showAlert();
      }).finally(() => {
        // this.context.hideLoading();
        this.setState({ isLoading: false });
      });
    }).once('finish', () => {
      // 通信完了時の処理
      if (this.state.isFinish) {
        this.updateState(this.context.api_location.diagnoses, {
          body: {
            examination: {
              id: this.context.examination_id,
            },
          },
        }).then(() => {
          location.href = this.context.url_location.index + '?history=1';
        }).catch(() => {
          this.showAlert();
        });
      } else if (this.state.isStay) {
        this.updateState(`${this.context.api_location.examinations}/${this.context.examination_id}`, {
          methods: 'PATCH',
        }).then(() => {
          location.reload();
        }).catch(() => {
          this.showAlert();
        });
      } else if (this.state.isDuplicate) {
        // TODO: 文言どうする？
        window.alert('すでに医師が診療を開始している可能性があるため、再読み込みを行います。');
        location.reload();
      } else {
        location.href = this.context.url_location.index + '?history=1';
      }
    }).on('onStreamSubscribed', ev => {
      const member = this.state.members.find(member => (member.uid === ev.uid));
      if (member && member.joined) {
        if (member.mained) {
          this.setMainStream(member.uid);
        } else {
          this.agora.forcePlay(ev.uid, ev.stream);
        }
      }
    });
  }

  renderWaiting () {
    const isRequested = ['requested'].indexOf(this.context.state) !== -1;
    const isCompleted = ['examined', 'canceled'].indexOf(this.context.state) !== -1;
    return (
      <div className={this.state.isStart ? 'd-n' : 'p-center-area'}>
        { isRequested ? (
          <div>
            <p className="fz24">診療日を確定してください</p>
            <a className="c-mn-btn--first" onClick={ () => { this.context.onStateUpdate({ isOpenModalWaitingExaminationConfirm: true }); } }><span>確定する</span></a>
            <a className="p-xl" href="/treatments?history=1"><span>患者一覧に戻る</span></a>
          </div>
        ) : isCompleted ? (
          <div>
            <p className="fz24">診療は完了しました。</p>
            <a className="p-xl" href="/treatments?history=1"><span>患者一覧に戻る</span></a>
          </div>
        ) : (
          <div>
            <p className="fz24">患者が診療待ちです。</p>
            <a className="c-mn-btn--green" onClick={this.onNext}><span>オンライン診療を開始する</span></a>
            <div className="mt-lg">
              {this.renderSharingUrl()}
            </div>
            <a className="p-xl" href="/treatments?history=1"><span>患者一覧に戻る</span></a>
          </div>
        )}
      </div>
    );
  }

  renderExaminationTimes () {
    if (!this.state.isFullscreen) {
      return (<ExaminationTimes />);
    }
  }

  render () {
    return (
      <div
        className={this.state.isFullscreen ? 'p-video hide' : this.state.isLoading ? 'pos-r p-video p-loading p-loading--2x' : 'pos-r p-video'}
        style={ { width: this.state.containerSize.w, height: this.state.containerSize.h } }
      >
        {this.renderWaiting()}
        <div className={ !this.state.isStart ? 'd-n' : this.state.isFullscreen ? 'p-video--full' : 'p-video--normal' }>
          <AgoraMembers
            size= { 4 }
            members={ this.state.members }
            isDoctor={ this.state.isDoctor }
            isPatient={ this.state.isPatient }
            isFullscreen={ this.state.isFullscreen }
            onMemberReject={ this.onMemberReject }
            onMemberAccept={ this.onMemberAccept }
            onMemberZoom={ this.onMemberZoom }
            onMemberKick={ this.onMemberKick }
            onMemberMore={ this.onMemberMore }
            onFullscreen={ this.onFullscreen }
          />
          <div className="p-video-container">
            <div>
              <div className="p-screen">
                { this.state.mainStream ? (<div id="main_stream" className="p-main-video">{ this.state.mainStream?.muted ? (<div className="p-video--videomute">{SvgCameraIcon}</div>) : null }</div>) : null }
                <div className={this.state.isStart ? 'p-menu-option' : 'd-n'}>
                  <div>
                    <div style={{ left: this.state.btnCameraX }} className={(this.state.isCameraArea) ? 'p-menu-option__item p-menu-option__item__bg' : 'p-menu-option__item is-hide'}>
                      <p className="p-menu-option__item-label">カメラ</p>
                      {this.state.video.map(item => {
                        return (
                          <div key={`${this.state.cameraId.name}_${item.name}`} className="p-menu-option__item-option">
                            <input type="radio" name="camera" id={item.name} value={item.name} checked={item.name === this.state.cameraId.name} onChange={this.onChangeVideoDevice} />
                            <label htmlFor={item.name}>{item.name}</label>
                          </div>
                        );
                      })}
                    </div>
                    <div style={{ left: this.state.btnAudioX }} className={(this.state.isAudioArea) ? 'p-menu-option__item p-menu-option__item__bg' : 'p-menu-option__item is-hide'}>
                      <p className="p-menu-option__item-label">マイク</p>
                      {this.state.audio.map(item => {
                        return (
                          <div key={`${this.state.microphoneId.name}_${item.name}`} className="p-menu-option__item-option">
                            <input type="radio" name="mic" id={`a${item.name}`} value={item.name} checked={item.name === this.state.microphoneId.name} onChange={this.onChangeAudioDevice} />
                            <label htmlFor={`a${item.name}`}>{item.name}</label>
                          </div>
                        );
                      })}
                      <hr />
                      <p className="p-menu-option__item-label">スピーカー</p>
                      {this.state.speeker.map(item => {
                        return (
                          <div key={`${this.state.speekerId.name}_${item.name}`} className="p-menu-option__item-option">
                            <input type="radio" name="speeker" id={`s${item.name}`} value={item.name} checked={item.name === this.state.speekerId.name} onChange={this.onChangeSpeekerDevice} />
                            <label htmlFor={`s${item.name}`}>{item.name}</label>
                          </div>
                        );
                      })}
                    </div>
                    <div style={{ left: this.state.btnShareX }} className={(this.state.isShareArea) ? 'p-menu-option__item' : 'p-menu-option__item is-hide'}>
                      {this.renderSharingUrl()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="p-menu-area">
            <div className="level-left">
              <div id="audioPanel" className="level-item has-text-centered p-menu-area__panel">
                <a onClick={this.onMuteAudio} className="fz12 p-menu-area__panel-button p-menu-area__panel-button--w80">
                  { this.state.isMuteAudio ? SvgMicIcon : SvgMicMuteIcon }
                  <p className="fz12">{this.state.isMuteAudio ? 'ミュート解除' : 'ミュート'}</p>
                </a>
                <a onClick={this.onShowAudioArea} className="fz12 p-menu-area__panel-button p-menu-area__panel-arrow">{ SvgArrowIcon }</a>
              </div>
              <div id="cameraPanel" className="level-item has-text-centered p-menu-area__panel">
                <a onClick={this.onMuteVideo} className="fz12 p-menu-area__panel-button">
                  { this.state.isMuteVideo ? SvgCameraIcon : SvgCameraMuteIcon }
                  <p className="fz12">{this.state.isMuteVideo ? 'カメラ開始' : 'カメラ停止'}</p>
                </a>
                <a onClick={this.onShowCameraArea} className="fz12 p-menu-area__panel-button p-menu-area__panel-arrow">{ SvgArrowIcon }</a>
              </div>
            </div>
            <div className="level mb0">
              <div id="sharePanel" className="level-item has-text-centered p-menu-area__panel">
                <a onClick={this.onShowShareArea} className="fz12 p-menu-area__panel-button">
                  { SvgShareIcon }
                  <p className="fz10">共有URL</p>
                </a>
              </div>
              <div className="level-item has-text-centered p-menu-area__panel">
                <button onClick={this.onMuteScreen} className="fz12 p-menu-area__panel-button" disabled={!this.state.isAbleFinish}>
                  { SvgScreenIcon }
                  <p className="fz12">{this.state.isMuteScreen ? '画面共有' : '共有解除'}</p>
                </button>
              </div>
              <div className="level-item has-text-centered p-menu-area__panel">
                <a onClick={this.onChatOpen} className={ this.state.isUnreadChat ? 'fz12 p-menu-area__panel-button unread' : 'fz12 p-menu-area__panel-button' }>
                  { SvgChatIcon }
                  <p className="fz12">チャット</p>
                </a>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item has-text-centered p-menu-area__panel">
                <button onClick={this.onStey} className="p-menu-area__panel-button p-menu-area__panel--stey" disabled={!this.state.isAbleFinish}>
                  <p className="fz14 fw-br">中断する</p>
                </button>
              </div>
              <div className="level-item has-text-centered p-menu-area__panel">
                <button onClick={this.onNext} className="p-menu-area__panel-button p-menu-area__panel--finish" disabled={!this.state.isAbleFinish}>
                  <p className="fz14 fw-br">終了する</p>
                </button>
              </div>
            </div>
          </div>
          { this.state.isLoading ? null : this.getChat() }
          <form name="finish" action="/diagnoses" method="POST">
            <input type="hidden" name="authenticity_token" value={this.state.csrfToken} />
            <input type="hidden" name="examination_id" value={this.context.code} />
          </form>
        </div>
        {this.renderExaminationTimes()}
        {this.getModal('isStay', {
          title: 'この診療を中断しますか？',
          body: '診療を中断すると、診療待ちの画面に戻ります。',
          resolve: this.onFinish,
          reject: this.onCancel,
        }, {
          ok: '中断する',
        })}
        {this.getModal('isFinish', {
          title: 'この診療を終了しますか？',
          body: '診療を終了すると、患者一覧画面に遷移します。',
          resolve: this.onFinish,
          reject: this.onCancel,
        }, {
          ok: '終了する',
        })}
        {this.getModal('isAlert', {
          title: this.state.alertTitle, // '通信でエラーが発生しました',
          body: this.state.alertBody, // 'OKを押すと一覧画面に戻ります。',
          resolve: () => {
            this.state.alertOk();
            this.setState({ isAlert: false });
            this.onAlert();
          },
        }, {
          showCancel: false,
          ok: this.state.alertButton || '閉じる', // '一覧に戻る',
          classOK: 'c-mn-btn c-mn-btn--basic-s c-mn-btn--conversion c-mn-btn--compact js-modal-dialog02-close',
        })}
        { this.renderToast() }
      </div>
    );
  }
}

AgoraVideo.propTypes = {
  code: PropTypes.string,
  video_id: PropTypes.string,
  app_id: PropTypes.string,
  chat_token: PropTypes.string,
  channel_name: PropTypes.string,
  waiting_count: PropTypes.number,
  uid: PropTypes.string,
  mode: PropTypes.string,
  codec: PropTypes.string,
  treatment: PropTypes.object,
};
