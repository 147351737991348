import React from 'react';
import * as PropTypes from 'prop-types';
import Layout from '../Layout';
import {
  TreatmentsExamination,
  TreatmentsPatient,
  TreatmentsReferringInstitution,
  RegionalMedicalCooperationTreatmentsPatient,
} from '../organisms/index';
import {
  Examined,
  Invoiced,
  Deposited,
  Refunded,
} from '../treatments/invoices/index';
import { RootContext } from '../../../index';

export default class TreatmentsShow extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      ...props.treatment,
      treatment: props.treatment,
      agora: props.agora,
      user: props.user,
    };
  }

  render () {
    const RightColumn = () => {
      const context = React.useContext(RootContext);
      switch (context.treatment.state) {
      case 'requested':
      case 'waiting_examination':
      case 'examining':
      case 'canceled':
        return (<TreatmentsExamination />);
      case 'examined':
        return (<Examined/>);
      case 'invoiced':
        return (<Invoiced />);
      case 'deposited':
        return (<Deposited />);
      case 'refunded':
        return (<Refunded />);
      }
    };

    return (
      <Layout state={this.state} showFooter={false} dsn={this.props.dsn} env={this.props.env}>
        <div className="p-treatment">
          <div className="columns p-columns">
            { this.state.treatment.is_regional_medical_cooperation ? (
              <>
                <div className="column p-column px-lg pt-lg mr-md p-treatment__patient">
                  <TreatmentsReferringInstitution />
                </div>
                <div className="column p-column px-lg py-lg p-treatment__right_column">
                  <RegionalMedicalCooperationTreatmentsPatient />
                </div>
              </>
            ) : (
              <>
                <div className="column p-column px-lg pt-lg p-treatment__patient">
                  <TreatmentsPatient />
                </div>
                <div className="column px-lg py-lg p-treatment__right_column">
                  <RightColumn />
                </div>
              </>
            )
            }
          </div>
        </div>
      </Layout>
    );
  }
}

TreatmentsShow.propTypes = {
  treatment: PropTypes.object, // treatments
  agora: PropTypes.object, // agora.io
  user: PropTypes.object, // current_user
  dsn: PropTypes.string, // sentry
  env: PropTypes.string, // sentry
};
