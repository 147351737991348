import React from 'react';
import Layout from '../Layout';
import { PageSectionsForm } from '../organisms/index';

export default class PageSections extends React.Component {
  render () {
    return (
      <Layout state={this.props} class="has-background-white">
        <PageSectionsForm/>
      </Layout>
    );
  }
}
