import React from 'react';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import moment from 'moment';
import ja from 'date-fns/locale/ja';
import {
  RootContext,
  AbstractComponent,
  MnInputCheckboxGroup,
  MnInputText,
  MnInputSelect,
} from '../../../index';
import EventEmitter from 'events';
import 'react-datepicker/dist/react-datepicker.css';
registerLocale('ja', ja);

export default class SearchForm extends AbstractComponent {
  static contextType = RootContext;
  constructor (props) {
    super(props);

    this.state = this.buildState();

    this.handleSearch = this.handleSearch.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleToggleAllStates = this.handleToggleAllStates.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeStates = this.handleChangeStates.bind(this);
  }

  componentDidMount () {
    const events = new EventEmitter().on('search', (params, page) => {
      this.search(params, page);
    });
    this.context.onStateUpdate({
      events: events,
    });
    this.search({}, this.context.pagination.page);
  }

  buildState () {
    const params = new URLSearchParams(window.location.search);
    const storage = new URLSearchParams(window.localStorage.getItem('treatments_query'));

    if (params.get('history') === '1') {
      return {
        patientKana: storage.get('patient_kana') || '',
        examinedDate: storage.get('examined_date') ? new Date(storage.get('examined_date')) : '',
        treatmentCreatedDate: storage.get('treatment_created_date') ? new Date(storage.get('treatment_created_date')) : '',
        treatmentStates: storage.get('treatment_states') ? storage.get('treatment_states').split(',') : this.props.states.map(state => { return state.key; }),
        menuId: storage.get('menu_id') || '',
        accountId: storage.get('account_id') || '',
        requestId: storage.get('request_id') || '',
        referringInstitutionName: storage.get('referring_institution_name') || '',
        allStates: storage.has('all_states') ? [storage.get('all_states')] : ['1'],
        page: storage.get('page') ? parseInt(storage.get('page')) : 0,
      };
    }

    return {
      patientKana: params.get('patient_kana') || '',
      examinedDate: params.get('examined_date') ? new Date(params.get('examined_date')) : '',
      treatmentCreatedDate: params.get('treatment_created_date') ? new Date(params.get('treatment_created_date')) : '',
      treatmentStates: params.get('treatment_states') ? params.get('treatment_states').split(',') : this.props.states.map(state => { return state.key; }),
      menuId: params.get('menu_id') || '',
      accountId: params.get('account_id') || '',
      requestId: params.get('request_id') || '',
      referringInstitutionName: params.get('referring_institution_name') || '',
      allStates: params.has('all_states') ? [params.get('all_states')] : ['1'],
      page: params.get('page') ? parseInt(params.get('page')) : 0,
    };
  }

  handleSearch () {
    // 再検索時は1ページスタート
    this.search({}, 1);
  }

  search (params = {}, page = 0) {
    const query = this.generateSearchQuery(params, page);
    this
      .fetch(`${this.context.api_location.search}?${query}`)
      .then(data => {
        // URLの書き換え
        if (query) {
          history.replaceState('', '', `${location.pathname}?${query}`);
        } else {
          history.replaceState('', '', location.pathname);
        }
        // スクロール位置の修正
        document.body.scrollIntoView(true);
        if (data.meta.status === 200) {
          this.setState({ page: data.meta.page });
          this.context.onStateUpdate({
            treatments: data.results,
            pagination: data.meta,
          });
        }
        window.localStorage.setItem('treatments_query', query);
      })
      .catch(response => {
        if (response.status === 401) {
          location.href = '/login';
        }
      });
  }

  handleChange = (arg) => {
    this.setState({ [arg.name]: arg.value });
  }

  handleChangeStates = (target) => {
    this.setState({ treatmentStates: target.value });
  }

  handleToggleAllStates = (target) => {
    this.setState({ allStates: target.value });
    if (target.value.length) {
      this.setState({ treatmentStates: this.context.states.map(state => { return state.key; }) });
    } else {
      this.setState({ treatmentStates: [] });
    }
  }

  handleClear = () => {
    this.setState({
      patientKana: '',
      treatmentCreatedDate: '',
      examinedDate: '',
      treatmentStates: [],
      menuId: '',
      accountId: '',
      requestId: '',
      referringInstitutionName: '',
      allStates: [],
      page: 0,
    });
  }

  generateSearchQuery = (params = {}, page = 0, limit = 20, isCsv = false) => {
    const object = {};
    if (params !== null && Object.keys(params).length) {
      const searchParams = new URLSearchParams(params);
      const state = {};
      [
        { snake: 'patient_kana', camel: 'patientKana' },
        { snake: 'examined_date', camel: 'examinedDate' },
        { snake: 'treatment_created_date', camel: 'treatmentCreatedDate' },
        { snake: 'treatment_states', camel: 'treatmentStates' },
        { snake: 'menu_id', camel: 'menuId' },
        { snake: 'account_id', camel: 'accountId' },
        { snake: 'request_id', camel: 'requestId' },
        { snake: 'referring_institution_name', camel: 'referringInstitutionName' },
        { snake: 'all_states', camel: 'allStates' },
      ].forEach(key => {
        if (searchParams.has(key.snake)) {
          object[key.snake] = searchParams.get(key.snake);
          if (['treatmentStates', 'allStates'].includes(key.camel)) {
            state[key.camel] = searchParams.get(key.snake).split(',');
          } else {
            state[key.camel] = searchParams.get(key.snake);
          }
        }
      });
      this.setState(state);
    } else {
      if (this.state.patientKana) {
        object.patient_kana = this.state.patientKana;
      }
      if (this.state.examinedDate) {
        object.examined_date = moment(this.state.examinedDate).format('YYYY-MM-DD');
      }
      if (this.state.treatmentCreatedDate) {
        object.treatment_created_date = moment(this.state.treatmentCreatedDate).format('YYYY-MM-DD');
      }
      if (this.state.treatmentStates.length > 0) {
        object.treatment_states = this.state.treatmentStates;
      }
      if (this.state.menuId) {
        object.menu_id = this.state.menuId;
      }
      if (this.state.accountId) {
        object.account_id = this.state.accountId;
      }
      if (this.state.requestId) {
        object.request_id = this.state.requestId;
      }
      if (this.state.referringInstitutionName) {
        object.referring_institution_name = this.state.referringInstitutionName;
      }
      if (this.state.allStates) {
        object.all_states = this.state.allStates;
      }
    }
    if (isCsv) {
      object.page = 0;
    } else if (page) {
      object.page = page;
    } else if (this.state.page) {
      object.page = this.state.page;
    }
    if (limit) {
      object.limit = Math.min(limit, 1000);
    }

    return new URLSearchParams(object).toString();
  }

  generateDownloadUrl = () => {
    return `${this.context.api_location.download}?${this.generateSearchQuery({}, 0, 1000, true)}`;
  }

  menuOptions = () => {
    const {
      menus,
    } = this.context;

    const options = [];
    menus.map(menu => {
      options.push({ key: menu.id, value: menu.title });
    });
    return options;
  }

  workerOptions = () => {
    const {
      workers,
    } = this.context;

    const options = [];
    workers.map(worker => {
      options.push({ key: worker.id, value: worker.name });
    });
    return options;
  }

  render () {
    return (
      <div className="p-treatment-search">
        <div className="columns">
          <div className="column is-1">
            <label className="c-mn-form-label fw-br fz14">ステータス</label>
          </div>
          <div className="column is-flex">
            <MnInputCheckboxGroup
              name="allStates"
              value={this.state.allStates}
              groups={[{ key: '1', value: 'すべて' }]}
              class="dummy"
              btnClass="c-mn-checkbox-btn c-mn-checkbox-btn--no-border"
              popupOuterClass="dummy"
              onChange={this.handleToggleAllStates}
            />
            <MnInputCheckboxGroup
              name="treatmentMenuTypes"
              value={this.state.treatmentStates}
              groups={this.context.states}
              class="dummy"
              btnClass="c-mn-checkbox-btn c-mn-checkbox-btn--no-border"
              popupOuterClass="dummy"
              onChange={this.handleChangeStates}
            />
          </div>
        </div>
        <div className="columns p-columns--border-top">
          <div className="column">
            <label className="c-mn-form-label fw-br fz14">診療メニュー</label>
            <div className="mt-sm">
              <MnInputSelect
                name="menuId"
                value={this.state.menuId}
                groups={this.menuOptions()}
                placeholder='指定なし'
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="column">
            <label className="c-mn-form-label fw-br fz14">診療日</label>
            <div className="mt-sm">
              <DatePicker
                dateFormat="yyyy/MM/dd"
                locale="ja"
                onChange={date => this.setState({ examinedDate: date })}
                selected={this.state.examinedDate}
                className="c-mn-input-text"
                placeholderText="2020/04/01"
              />
            </div>
          </div>
          <div className="column">
            <label className="c-mn-form-label fw-br fz14">担当</label>
            <div className="mt-sm">
              <MnInputSelect
                name="accountId"
                value={this.state.accountId}
                groups={this.workerOptions()}
                placeholder='指定なし'
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="column">
            <label className="c-mn-form-label fw-br fz14">患者氏名（カナ）</label>
            <div className="mt-sm">
              <MnInputText
                name="patientKana"
                value={this.state.patientKana}
                placeholder="指定なし"
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="column">
            <label className="c-mn-form-label fw-br fz14">申込ID</label>
            <div className="mt-sm">
              <MnInputText
                name="requestId"
                value={this.state.requestId}
                placeholder="指定なし"
                onChange={this.handleChange}
              />
            </div>
          </div>
          { this.context.user.institution.regional_medical_cooperation ? (
            <div className="column">
              <label className="c-mn-form-label fw-br fz14">紹介元医療機関名</label>
              <div className="mt-sm">
                <MnInputText
                  name="referringInstitutionName"
                  value={this.state.referringInstitutionName}
                  placeholder="指定なし"
                  onChange={this.handleChange}
                />
              </div>
            </div>
          ) : null }
          <div className="column is-flex fxd-c jc-fe ai-fe">
            <a href={this.generateDownloadUrl()} className="c-mn-link has-text-weight-bold p-btn-csv" download>CSV出力</a>
          </div>
          <div className="column is-flex fxd-c jc-fe">
            <a className="c-mn-btn--third mr-md fz14" onClick={this.handleClear}>
              <span>
                クリア
              </span>
            </a>
          </div>
          <div className="column is-flex fxd-c jc-fe">
            <button className="c-mn-btn--first fz14" onClick={this.handleSearch}>
              <span>
                絞り込む
              </span>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

SearchForm.propTypes = {
  states: PropTypes.array,
};
