import React from 'react';
import * as PropTypes from 'prop-types';
import Layout from '../Layout';
import { RootContext } from '../../../index';
import SearchForm from '../treatments/SearchForm';
import List from '../treatments/List';

export default class TreatmentsIndex extends React.Component {
  static contextType = RootContext;
  constructor (props) {
    super(props);
    this.state = { ...props };
  }

  render () {
    return (
      <Layout state={this.state}>
        <div className="p-treatments">
          <div className="columns p-columns">
            <div className="column p-column px-20 py-lg">
              <SearchForm states={this.props.states} />
            </div>
          </div>
          <div className="columns p-columns mt-xs">
            <div className="column p-column p0">
              <List />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

TreatmentsIndex.propTypes = {
  user: PropTypes.object,
  workers: PropTypes.array,
  menus: PropTypes.array,
  states: PropTypes.array,
  treatments: PropTypes.object,
  pagination: PropTypes.object,
  api_location: PropTypes.object,
};
