import React from 'react';
import AbstractComponent from '../../common/components/AbstractComponent';
import MnButton from '../../common/components/MnButton';
import Layout from './Layout';

export default class Login extends AbstractComponent {
  constructor (props) {
    super(props);
    this.state = {
      ...props,
      errorMessage: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // ログイン
  handleSubmit () {
    return new Promise(resolve => {
      this.fetch(this.state.api_location.login, {
        methods: 'POST',
        body: { logincode: this.state.logincode, password: this.state.password },
      }).then(_ => {
        location.href = this.state.url_location.root;
      }).catch(err => {
        err.json().then(body => {
          this.setState({ errorMessage: body.message });
        });
      }).finally(() => {
        resolve();
      });
    });
  }

  render () {
    return (
      <Layout state={this.state} class="has-background-white">
        <div className="p-login">
          <div className="hero-body">
            <div className="mt-xl">
              <div className="maw420px m-a">
                <h1 className="fz32 fw-br ta-c">
                  ログイン
                </h1>

                { this.state.errorMessage !== '' && (
                  <p className="c-mn-message--error mt-lg">
                    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" className="c-mn-icon-error" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M9.77995 20C14.8426 20 18.9466 15.8959 18.9466 10.8333C18.9466 5.77068 14.8426 1.66663 9.77995 1.66663C4.71734 1.66663 0.613281 5.77068 0.613281 10.8333C0.613281 15.8959 4.71734 20 9.77995 20ZM10.7745 12.8867H9.33445L9.01945 7.35168L8.94445 5.26668H11.1645L11.0895 7.35168L10.7745 12.8867ZM11.4345 15.4367C11.4345 16.2467 10.8345 16.8767 10.0545 16.8767C9.27445 16.8767 8.68945 16.2467 8.68945 15.4367C8.68945 14.6117 9.27445 13.9967 10.0545 13.9967C10.8345 13.9967 11.4345 14.6117 11.4345 15.4367Z" fill="#000" className="c-mn-icon-error-path"/></svg>
                    <div className="whs-pw">
                      { this.state.errorMessage }
                    </div>
                  </p>
                )}

                <div className="field mt-xl">
                  <label className="c-mn-form-label c-mn-form-label--navy">ID</label>
                  <input type="text" className="c-mn-input-text" placeholder="IDを入力" onChange={ (e) => this.setState({ logincode: e.target.value })} />
                </div>

                <div className="field mt-lg">
                  <label className="c-mn-form-label c-mn-form-label--navy">パスワード</label>
                  <input type="password" className="c-mn-input-text" placeholder="パスワードを入力" onChange={ (e) => this.setState({ password: e.target.value })} />
                </div>

                <div className="field mt-xl">
                  <MnButton class="c-mn-btn--first maw200px m-a" name="login-button" onClick={ this.handleSubmit }>
                    <span>ログインする</span>
                  </MnButton>
                </div>

                <div className="mt-xl has-text-centered">
                  <a className="c-mn-link fw-lr fz18" href="" onClick={ (e) => { e.preventDefault(); location.href = this.state.url_location.password_reset; } }>
                    ID・パスワードを忘れた場合はこちら
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
