import * as React from 'react';
import PropTypes from 'prop-types';
import AbstractComponent from './AbstractComponent';
import MnInlineMessage from './MnInlineMessage';
import validator from 'validator';

/** 入力フォームの既定クラス */
export default class AbstractMnInput extends AbstractComponent {
  constructor (props) {
    super(props);
    this.state = {
      name: props.name,
      value: props.isBoolean ? props.value : props.value || '',
      annotation: props.annotation,
      errors: props.errors,
    };

    this.hideAnnotation = this.hideAnnotation.bind(this);
    this.showAnnotation = this.showAnnotation.bind(this);
    this.onChange = this.onChange.bind(this);
    this._onChange = this._onChange.bind(this);
    this.renderErrors = this.renderErrors.bind(this);
    this.validate = this.validate.bind(this);
    this.isValid = this.isValid.bind(this);
    this.t = this.t.bind(this);
  }

  shouldComponentUpdate (nextProps, nextState) {
    if (this.props.value !== nextProps.value) {
      nextState.value = this.props.isBoolean ? nextProps.value : nextProps.value || '';
    }
    if (this.props.annotation !== nextProps.annotation) {
      nextState.annotation = nextProps.annotation;
    }
    if (this.props.errors !== nextProps.errors) {
      nextState.errors = nextProps.errors;
    }
    return true;
  }

  componentDidMount () {
    this.props.onValidate({ name: this.props.name, validate: this.isValid, run: false });
  }

  componentWillUnmount () {
    this.props.onDestroy({ name: this.props.name });
  }

  t (key) {
    return this.props.t ? this.props.t(key) : key;
  }

  onChange (e) {
    const value = this.sanitizers(this._onChange(e));
    this.setState({ value: value });
    this.props.onChange({ name: this.props.name, value: value, callbackParams: this.props.callbackParams });
    this.validate(value).then(() => {
      this.setState({ errors: undefined });
    }).catch(err => {
      console.warn(err);
      this.setState({ errors: err });
      this.props.onError({ name: this.props.name, errors: err });
    });
  }

  _onChange (e) {
    return this.props.isBoolean ? e.target.value.toLowerCase() === 'true' : e.target.value;
  }

  isValid () {
    return new Promise((resolve, reject) => {
      const value = this.sanitizers(this.state.value);
      this.validate(value).then(() => {
        this.setState({ errors: undefined });
        resolve();
      }).catch(err => {
        this.setState({ errors: err });
        this.props.onError({ name: this.props.name, errors: err });
        const el = document.querySelector(`#${this.props.name}`);
        if (el) {
          el.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
        reject(err);
      });
    });
  }

  renderIconClose () {
    return (<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9" cy="9" r="9" fill="white"/><path fillRule="evenodd" clipRule="evenodd" d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM6.22032 13.1974C5.83499 13.5827 5.21024 13.5827 4.82491 13.1974C4.43958 12.812 4.43958 12.1873 4.82491 11.802L7.61575 9.01113L4.87449 6.26988C4.48916 5.88455 4.48916 5.2598 4.87449 4.87447C5.25983 4.48914 5.88457 4.48914 6.2699 4.87447L9.01116 7.61572L11.802 4.82491C12.1873 4.43958 12.812 4.43958 13.1974 4.82491C13.5827 5.21025 13.5827 5.83499 13.1974 6.22032L10.4066 9.01113L13.247 11.8515C13.6323 12.2369 13.6323 12.8616 13.247 13.2469C12.8616 13.6323 12.2369 13.6323 11.8515 13.2469L9.01116 10.4065L6.22032 13.1974Z" fill="#AAAAAA"/></svg>);
  }

  hideAnnotation () {
    this.setState({
      annotation: undefined,
    });
  }

  showAnnotation (annotation = '') {
    this.setState(state => {
      if (!state.annotation) {
        state.annotation = [];
      }
      state.annotation.push(annotation);
      return { annotation: state.annotation };
    });
  }

  renderErrors () {
    return this.state.errors ? (<MnInlineMessage mode="validation" messages={this.t(this.state.errors)} />) : null;
  }

  validate (value, validates = this.props.validates) {
    return new Promise((resolve, reject) => {
      if (this.props.required) {
        const errors = [this.props.required];
        if (value instanceof Array) {
          if (value.length === 0) {
            reject(errors);
          } else if (typeof value[0] === 'string' && !validator.isLength(value[0], { min: 1 })) {
            reject(errors);
          } else if (typeof value[0] !== 'string' && !validator.isByteLength(value[0], { min: 1 })) {
            reject(errors);
          }
        } else {
          if (!validator.isByteLength(value, { min: 1 })) {
            reject(errors);
          }
        }
      }
      const errors = Object.entries(validates).map(validate => {
        if (value instanceof Array) {
          return value.map(val => {
            if (!validator[validate[0]].call(this, val.toString(), ...validate[1].validate)) {
              return validate[1].message;
            }
          }).filter(error => error !== undefined)[0];
        } else {
          if (!validator[validate[0]].call(this, value.toString(), ...validate[1].validate)) {
            return validate[1].message;
          }
        }
      }).filter(error => error !== undefined);
      if (errors.length > 0) {
        reject(errors.filter((err, i, self) => self.indexOf(err) === i));
      } else {
        resolve();
      }
    });
  }

  sanitizers (value) {
    Object.entries(this.props.sanitizers).map(sanitizer => {
      if (value instanceof Array) {
        return value.map((val, index) => {
          value[index] = validator[sanitizer[0]].call(this, val.toString(), ...sanitizer[1]);
        });
      } else {
        value = validator[sanitizer[0]].call(this, value.toString(), ...sanitizer[1]);
      }
    });
    return value;
  }

  translate (args) {
    if (this.props?.t) {
      if (args instanceof Array) {
        return this.props.t(...args);
      }
      return this.props.t(args);
    }
    return args;
  }

  render () {
    return (<></>);
  }
}

AbstractMnInput.propTypes = {
  /** クラス名 */
  class: PropTypes.string,
  /** 初期値 */
  value: PropTypes.any,
  /** name属性 */
  name: PropTypes.string,
  /** placeholder属性 */
  placeholder: PropTypes.string,
  /** ポップアップ表示 */
  annotation: PropTypes.array,
  /** インラインメッセージ表示 */
  errors: PropTypes.array,
  /** バリデータ */
  validates: PropTypes.object,
  /** サニタイズ */
  sanitizers: PropTypes.object,
  /** disabled属性 */
  disabled: PropTypes.bool,
  /** 必須入力チェックとそのメッセージ */
  required: PropTypes.string,
  /** コールバック 初期化後にバリデーションメソッドの返却 */
  onValidate: PropTypes.func,
  /** コールバック `name` , `value` を返却 */
  onChange: PropTypes.func,
  /** コールバック `errors` を返却 */
  onError: PropTypes.func,
  /** コールバック */
  onFocus: PropTypes.func,
  /** コールバック */
  onBlur: PropTypes.func,
  /** コールバック コンポーネント破棄時に実行 */
  onDestroy: PropTypes.func,
  /** 必須ではないその他の属性 */
  callbackParams: PropTypes.object,
  /** i18n変換 */
  t: PropTypes.func,
  /** bool値の許容 */
  isBoolean: PropTypes.bool,
};

AbstractMnInput.defaultProps = {
  value: null,
  validates: {},
  sanitizers: {},
  disabled: false,
  required: null,
  onValidate: () => {},
  onChange: () => {},
  onError: () => {},
  onFocus: () => {},
  onBlur: () => {},
  onDestroy: () => {},
  callbackParams: {},
  isBoolean: false,
};
