import React from 'react';
import Layout from '../Layout';
import { MenuIndex, MenuForm } from '../organisms/index';

export default class Menus extends React.Component {
  render () {
    return (
      <Layout state={this.props}>
        <div className="p-menu-container">
          <MenuIndex/>
          <MenuForm/>
        </div>
      </Layout>
    );
  }
}
