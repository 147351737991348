import React from 'react';
import * as PropTypes from 'prop-types';
import { AbstractLayout } from '../../index';
import {
  Footer,
  Header,
} from './organisms/index';

export default class Layout extends AbstractLayout {
  componentDidMount () {
    document.body.classList.remove('wob-nm');
  }

  renderChildren () {
    return (<React.Fragment>
      <div className="l-conatiner">
        { this.props.showHeader ? <Header /> : null }
        <div className={ 'p-container p0' + (this.props.class ? ` ${this.props.class}` : '')} >
          {this.props.children}
        </div>
        { this.props.showFooter ? <Footer /> : null }
      </div>
    </React.Fragment>);
  }
}

Layout.propTypes = {
  children: PropTypes.object, // inline表示するDOM要素
  state: PropTypes.object, // contextで共有したい値
  class: PropTypes.string, // クラス名
  showHeader: PropTypes.bool, // Headerの表示
  showFooter: PropTypes.bool, // Footerの表示
};

Layout.defaultProps = {
  showHeader: true,
  showFooter: true,
};
