import * as React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

export default class InvoiceModal extends React.Component {
  constructor (props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleClose = () => this.props.onClose();
  handleSubmit = () => {
    this.props.onSubmit();
    this.props.onClose();
  }

  render () {
    const { isOpen, title, alertText, messageText, submitText } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        className="c-mn-modal-dialog is-active has-background-white" overlayClassName="c-mn-modal is-active--dialog"
      >
        <p className="c-mn-modal-dialog__title">{title}</p>
        {alertText ? <p className="c-mn-modal-dialog__txt c-mn-modal-dialog__txt-alert">{alertText.split('\n').map((str, index) => (
          <React.Fragment key={index}>{str}<br /></React.Fragment>
        ))}</p> : null}
        {messageText ? <p className="c-mn-modal-dialog__txt">{messageText}</p> : null}
        <div className="c-mn-modal-dialog__btn-wrap">
          <button className="c-mn-btn c-mn-btn--basic-s c-mn-btn--third c-mn-btn--compact" onClick={this.handleClose}>
            <span>キャンセル</span>
          </button>
          <button className="c-mn-btn c-mn-btn--basic-s c-mn-btn--first c-mn-btn--compact" onClick={this.handleSubmit}>
            <span>{submitText}</span>
          </button>
        </div>
      </Modal>
    );
  }
}

InvoiceModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  alertText: PropTypes.string,
  messageText: PropTypes.string,
  submitText: PropTypes.string,
};
