import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown/with-html';

import { MnDialog, catchException } from '.';

export class MnErrorBoundary extends React.Component {
  constructor (props) {
    super(props);
    this.state = { hasError: false, isSend: false, error: undefined, errorInfo: undefined };
  }

  static getDerivedStateFromError (error) {
    return { hasError: true, error: error };
  }

  componentDidMount () {
    this.props.onRef(this);
  }

  onCatch (error) {
    this.setState({ isSend: true, error: error });
  }

  render () {
    return (<React.Fragment>
      {this.state.hasError ? null : this.props.children}
      <MnDialog
        isOpen={this.state.hasError || this.state.isSend}
        title={this.props.title || 'クラッシュレポート'}
        btnSubmitLabel={this.props.btnSubmitLabel || '送信する'}
        btnCloseLabel={this.props.btnCloseLabel || 'キャンセル'}
        handleClose={() => {
          this.setState({ hasError: false, isSend: false });
        }}
        handleSubmit={() => {
          return new Promise((resolve) => {
            console.warn(this.state);
            catchException(this.state.error);
            resolve();
            setTimeout(() => {
              this.setState({ hasError: false, isSend: false });
            }, 10);
          });
        }}
      >
        <ReactMarkdown source={this.props.description || 'ご不便をおかけして申し訳ありません。  \nこの問題の迅速な解決のために送信にご協力お願いします。'} escapeHtml={true}/>
      </MnDialog>
    </React.Fragment>);
  }
}

MnErrorBoundary.propTypes = {
  /** inline表示するDOM要素 */
  children: PropTypes.any,
  title: PropTypes.string,
  description: PropTypes.string,
  btnSubmitLabel: PropTypes.string,
  btnCloseLabel: PropTypes.string,
  onRef: PropTypes.func,
};
