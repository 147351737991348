import * as React from 'react';
import PropTypes from 'prop-types';

export default class MnSwitch extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      name: props.name,
      checked: props.checked,
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange (e) {
    this.setState({ checked: e.target.checked });
    if (this.props.onChange) {
      this.props.onChange(this.state);
    }
  }

  renderIcon () {
    return (<svg width="29" height="20" viewBox="0 0 29 20" fill="none" className="icon-check" xmlns="http://www.w3.org/2000/svg"><path d="M1.51592 10.4222C0.75511 9.66136 0.755111 8.42785 1.51592 7.66704C2.27673 6.90623 3.51024 6.90623 4.27105 7.66704L11.7129 15.1089C12.4737 15.8697 12.4737 17.1032 11.7129 17.864C10.9521 18.6248 9.71855 18.6248 8.95774 17.864L1.51592 10.4222Z" fill="#000" className="c-mn-icon-check-path"></path><path d="M11.9062 18.0573C11.1454 18.8181 9.91185 18.8181 9.15104 18.0573C8.39023 17.2965 8.39023 16.063 9.15104 15.3022L23.4527 1.00054C24.2135 0.23973 25.447 0.239729 26.2078 1.00054C26.9686 1.76135 26.9686 2.99486 26.2078 3.75567L11.9062 18.0573Z" fill="#000" className="c-mn-icon-check-path"></path></svg>);
  }

  render () {
    return (<div className={ this.props.class ? this.props.class : 'c-mn-switch mr-md'}>
      <input type="checkbox" name={this.props.name} checked={this.state.checked} onChange={this.onChange} />
      <label>{this.props.icon ? this.props.icon : this.renderIcon() }</label>
    </div>);
  }
}

MnSwitch.propTypes = {
  class: PropTypes.string, // クラス名
  checked: PropTypes.bool, // 初期値
  name: PropTypes.string, // name属性
  disabled: PropTypes.bool, // disabled属性
  icon: PropTypes.func, // チェック時のアイコン
  onChange: PropTypes.func, // コールバック `this.state` を返却
};
