import React from 'react';

/**
 * AbstractComponent: MnInput系を使ったフォーム用の基底コンポーネントクラス
 */
export default class AbstractForm extends React.Component {
  constructor (props) {
    super(props);
    this.validators = []; // フォームバリデーション格納変数

    this.onValidate = this.onValidate.bind(this);
    this.onRemoveValidate = this.onRemoveValidate.bind(this);
    this.validation = this.validation.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onFailure = this.onFailure.bind(this);
  }

  onValidate (e) {
    const validate = this.validators.find(validate => validate.name === e.name);
    if (validate) {
      validate.validate = e.validate;
      validate.run = false;
    } else {
      this.validators.unshift(e);
    }
  }

  onRemoveValidate (e) {
    this.validators = this.validators.filter(validate => validate.name !== e.name);
  }

  validation () {
    return new Promise((resolve, reject) => {
      let count = 0;
      this.validators.map(validator => {
        validator.validate().catch(() => {
          count++;
        }).finally(() => {
          validator.run = true;
          if (this.validators.findIndex(validator => !validator.run) === -1) {
            if (count === 0) {
              resolve();
            } else {
              reject(count);
            }
            this.validators.map(validator => {
              validator.run = false;
            });
          }
        });
      });
    });
  }

  onSave (e) {
    return new Promise((resolve) => {
      this.validation().then(() => {
        this.onSuccess(resolve);
      }).catch(err => {
        this.onFailure(resolve, err);
      });
    });
  }

  onSuccess (resolve) {
    resolve();
  }

  onFailure (resolve, errors) {
    resolve(errors);
  }
}
