import * as React from 'react';
import * as PropTypes from 'prop-types';
import {
  AbstractForm,
  RootContext,
  MnInputText,
  MnInputSelect,
  MnButton,
  MnDialog,
  MnInlineMessage,
  InstitutionValidators,
  KanaValidator,
  TelValidator,
  FaxValidator,
  PostalCodeValidator,
  ContactUrl,
} from '../../../index';
import Modal from 'react-modal';

export default class InstitutionForm extends AbstractForm {
  static contextType = RootContext;

  constructor (props) {
    super(props);

    this.state = {
      edited: false,
      isOpenModalCreateStripeAccount: false,
    };

    this.onSetAddress = this.onSetAddress.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.handleCreateStripeAccount = this.handleCreateStripeAccount.bind(this);
  }

  handleCreateStripeAccount = () => {
    return new Promise(() => {
      this.setState({ isOpenModalCreateStripeAccount: false });
      this.context.showLoading();
      this.context.fetch(this.context.url_location.stripe_accounts, {
        methods: 'POST',
      })
        .then(data => {
          this.context.onStateUpdate({ institution: data.result });
          this.context.onHeaderToast('Stripeのアカウントを作成しました。');
        })
        .catch(this.context.onStateUpdate({ alert: { shown: true } }))
        .finally(() => {
          this.context.hideLoading();
        });
    });
  }

  onSetAddress () {
    this.context.fetch(`${this.context.institution.api_location.postal_code}?code=${this.context.institution.postal_code}`).then(data => {
      if (data.result) {
        const prefectureCode = `00${data.result.prefecture_code}`.slice(-2);
        const address = `${data.result.city}${data.result.town}`;
        this.context.onStateUpdate({
          institution: {
            ...this.context.institution,
            prefecture_code: prefectureCode,
            address: address,
          },
        });
      }
    });
  }

  onCancel () {
    return new Promise(resolve => {
      this.context.showLoading();
      this.context.fetch(this.context.institution.api_location.show).then(data => {
        this.context.onStateUpdate({ institution: data.result });
        this.context.onHeaderToast('編集をキャンセルしました。');
      }).finally(() => {
        this.context.hideLoading();
        this.setState({ cancel: false });
        resolve();
      });
    });
  }

  onSuccess (resolve) {
    this.context.showLoading();
    this.context.fetch(this.context.institution.api_location.update, {
      methods: 'PATCH',
      body: { institution: this.context.institution },
    }).then(data => {
      this.context.onStateUpdate({ user: { ...this.context.user, institution: data.result } });
      this.context.onHeaderToast('保存しました。');
    }).finally(() => {
      this.context.hideLoading();
      resolve();
    });
  }

  renderStripeAccountNotification () {
    if (this.context.institution.regional_medical_cooperation) return null;

    return (
      <div className="py-lg">
        { this.context.institution.stripe_account.status === 'none'
          ? <MnInlineMessage mode="notice-middle" messages={ ['オンラインカード決済申し込みがされていません。', 'オンラインカード決済をご希望の場合、<a class="c-mn-link" href="https://stripe.com/jp/connect-account/legal">Stripeアカウントの利用規約</a>をご確認し、以下より申し込みください。'] }>
            <div className="ta-r">
              <button className="c-mn-btn--third-s c-mn-btn--compact mt-lg mx0" onClick={ () => this.setState({ isOpenModalCreateStripeAccount: true }) }>
                <span>合意して申し込む</span>
              </button>
            </div>
          </MnInlineMessage>
          : null }
        { this.context.institution.stripe_account.status === 'under_review'
          ? <MnInlineMessage mode="notice-middle" messages={ ['オンラインカード決済申し込みが完了しました。', '現在、販売者情報を審査中です。\n審査が完了するまで、しばらくお待ちください。'] }/>
          : null }
        { this.context.institution.stripe_account.status === 'available'
          ? <MnInlineMessage mode="notice-middle" messages={ ['販売者情報の審査が完了しました。', `<a class="c-mn-link" href=${this.context.user.institution.url_location.menus}>診療メニュー</a>よりクレジットカード決済を設定いただけます。\n口座情報を変更したい場合は、<a href=${ContactUrl} target="_blank">こちら</a>からお問い合わせください。`] }/>
          : null }
        { ['rejected', 'other'].includes(this.context.institution.stripe_account.status)
          ? <MnInlineMessage mode="notice-middle" messages={ ['オンラインカード決済がご利用いただけません。', `<a href=${ContactUrl} target="_blank">こちら</a>からお問い合わせください。`] }/>
          : null }
      </div>
    );
  }

  render () {
    return (
      <div className="hero-body">
        <MnDialog
          isOpen={this.state.isOpenModalCreateStripeAccount}
          title="オンラインカード決済に申し込みますか？"
          btnSubmitLabel="申し込む"
          handleSubmit={ this.handleCreateStripeAccount }
          handleClose={ () => { this.setState({ isOpenModalCreateStripeAccount: false }); } }
        />
        <div className="container">
          <div className="column is-6 m-a maw420">
            <h1 className="fz32 fw-br ta-c my-xl pb-md">
              医療機関情報
            </h1>
            {this.renderStripeAccountNotification()}

            <div className="my-12px">
              <label className="c-mn-form-label">医療機関名<span className="required"/></label>
              <MnInputText placeholder="医療機関名を入力してください"
                name='name'
                value={ this.context.institution.name }
                validates={ InstitutionValidators.name }
                onValidate={ (e) => { this.validators = [...this.validators, e]; } }
                onChange={ (e) => { this.context.onStateUpdate({ institution: { ...this.context.institution, name: e.value } }); } }
              />
            </div>
            <div className="my-12px">
              <label className="c-mn-form-label">医療機関名（カナ）</label>
              <MnInputText placeholder="医療機関名（カナ）を入力してください"
                name='name_kana'
                convertKana={true}
                value={ this.context.institution.name_kana }
                validates={ { ...InstitutionValidators.name_kana, ...KanaValidator } }
                onValidate={ (e) => { this.validators = [...this.validators, e]; } }
                onChange={ (e) => { this.context.onStateUpdate({ institution: { ...this.context.institution, name_kana: e.value } }); } }
              />
            </div>
            <div className="my-12px">
              <label className="c-mn-form-label">メールアドレス<span className="required"/></label>
              <MnInputText placeholder="例) sample@medicalnote.co.jp"
                name='email'
                value={ this.context.institution.email }
                validates={ InstitutionValidators.email }
                onValidate={ (e) => { this.validators = [...this.validators, e]; } }
                onChange={ (e) => { this.context.onStateUpdate({ institution: { ...this.context.institution, email: e.value } }); } }
              />
            </div>
            <div className="my-12px">
              <label className="c-mn-form-label">電話番号<span className="required"/></label>
              <MnInputText placeholder="例）0364190822"
                name='telephone'
                value={ this.context.institution.telephone }
                validates={ { ...InstitutionValidators.telephone, ...TelValidator } }
                onValidate={ (e) => { this.validators = [...this.validators, e]; } }
                onChange={ (e) => { this.context.onStateUpdate({ institution: { ...this.context.institution, telephone: e.value } }); } }
              />
            </div>
            <div className="my-12px">
              <label className="c-mn-form-label">FAX</label>
              <MnInputText placeholder="例）0364190822"
                name='fax'
                value={ this.context.institution.fax }
                validates={ { ...InstitutionValidators.fax, ...FaxValidator } }
                onValidate={ (e) => { this.validators = [...this.validators, e]; } }
                onChange={ (e) => { this.context.onStateUpdate({ institution: { ...this.context.institution, fax: e.value } }); } }
              />
            </div>
            <div className="my-12px">
              <label className="c-mn-form-label">住所</label>
            </div>
            <div className="my-12px d-fx">
              <div className="column is-6 fx0-1-a p0 p-input_file">
                <label className="c-mn-form-label">郵便番号</label>
                <MnInputText placeholder="例）1500002"
                  name='postal_code'
                  value={ this.context.institution.postal_code }
                  validates={ { ...InstitutionValidators.postal_code, ...PostalCodeValidator } }
                  onValidate={ (e) => { this.validators = [...this.validators, e]; } }
                  onChange={ (e) => { this.context.onStateUpdate({ institution: { ...this.context.institution, postal_code: e.value } }); } }
                />
              </div>
              {/* <div className="column is-6 p0 pl-lg">
                <label className="c-mn-form-label"></label>
                <button className="c-mn-btn--third h36 fz14" onClick={ this.onSetAddress }><span>住所の自動入力</span></button>
              </div> */}
              <div className="column is-6 fx0-1-a p0 p-input_file ml-md">
                <label className="c-mn-form-label">都道府県</label>
                <MnInputSelect
                  name="prefecture_code"
                  value={ this.context.institution.prefecture_code }
                  groups={ this.context.institution.prefectures_i18n }
                  placeholder="都道府県"
                  onChange={ (e) => { this.context.onStateUpdate({ institution: { ...this.context.institution, prefecture_code: e.value } }); } }
                />
              </div>
            </div>
            <div className="my-12px d-fx">
              <div className="column is-6 fx0-1-a p0 p-input_file">
                <label className="c-mn-form-label">市区町村以下</label>
                <MnInputText placeholder="例）渋谷3丁目11－11"
                  name='address'
                  value={ this.context.institution.address }
                  validates={ InstitutionValidators.address }
                  onValidate={ (e) => { this.validators = [...this.validators, e]; } }
                  onChange={ (e) => { this.context.onStateUpdate({ institution: { ...this.context.institution, address: e.value } }); } }
                />
              </div>
              <div className="column is-6 fx0-1-a p0 ml-md">
              </div>
            </div>
            <div className="my-24px">
              <label className="c-mn-form-label">WebサイトURL</label>
              <MnInputText placeholder="例) https://medicalnote.jp/"
                name='homepage_url'
                value={ this.context.institution.homepage_url }
                validates={ InstitutionValidators.homepage_url }
                onValidate={ (e) => { this.validators = [...this.validators, e]; } }
                onChange={ (e) => { this.context.onStateUpdate({ institution: { ...this.context.institution, homepage_url: e.value } }); } }
              />
            </div>

            <div className="mt-40px">
              <div className="column is-6 p0 m-a">
                <MnButton class="c-mn-btn c-mn-btn--first m0-a maw200px" onClick={ this.onSave }>
                  <span>保存する</span>
                </MnButton>
              </div>
            </div>
            <div className="mt-40px">
              <div className="column is-6 p0 m-a ta-c">
                <a className="fz18 m0-a maw200px" onClick={ () => { this.setState({ cancel: true }); } }>
                  <span>キャンセル</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.cancel}
          className="c-mn-modal-dialog is-active"
          overlayClassName="c-mn-modal is-active--dialog"
        >
          <p className="c-mn-modal-dialog__title">医療機関情報の変更をキャンセルしますか？</p>
          <div className="c-mn-modal-dialog__txt">キャンセルすると編集した内容は破棄されます。</div>
          <div className="c-mn-modal-dialog__btn-wrap">
            <a className="c-mn-btn c-mn-btn--basic-s c-mn-btn--third c-mn-btn--compact js-modal-dialog02-close" onClick={ () => { this.setState({ cancel: false }); } }>
              <span>いいえ</span>
            </a>
            <a className="c-mn-btn c-mn-btn--basic-s c-mn-btn--first c-mn-btn--compact js-modal-dialog02-close" onClick={ this.onCancel }>
              <span>キャンセルする</span>
            </a>
          </div>
        </Modal>
      </div>
    );
  }
}

InstitutionForm.propTypes = {
  institution: PropTypes.object,
};
