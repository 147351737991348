import * as React from 'react';
import {
  AbstractComponent,
} from '../../../index';

export default class UploadFile extends AbstractComponent {
  constructor (props) {
    super(props);
    this.state = {
      apiLocation: props.apiLocation,
      src: null,
    };
  }

  componentDidMount () {
    this
      .fetch(
        this.state.apiLocation,
      )
      .then(data => {
        this.setState({ src: data.result.data_url });
      }).catch(error => {
        console.log(error);
      });
  }

  render () {
    return (
      <div>
        <div className="is-flex jc-fe">
          <a onClick={this.props.handleClose} className="c-mn-btn c-mn-btn--basic-s c-mn-btn--third c-mn-btn--compact">閉じる</a>
        </div>
        <div className="my-md">
          <img src={this.state.src} />
        </div>
      </div>
    );
  }
}
