import React from 'react';
import * as PropTypes from 'prop-types';
import { RootContext } from '../../index';

export default class Pagination extends React.Component {
  static contextType = RootContext;
  search = (e) => {
    e.preventDefault();
    if (!e.target.dataset.page) { // svgがtargetに入っていたケースがある
      e.target = e.target.parentElement;
      if (!e.target.dataset.page) { // pathがtargetに入っていたケースがある
        e.target = e.target.parentElement;
      }
    }
    this.context.events.emit('search', {}, e.target.dataset.page);
  };

  render () {
    const currentPage = this.context.pagination.page;
    const totalPages = this.context.pagination.total_page;

    const listItem = (n) => {
      if (currentPage === n) {
        return <li className="c-mn-paging__current" key={ n }><a className="cur-d">{ n }</a></li>;
      }
      return <li key={ n }><a onClick={this.search} data-page={ n }>{ n }</a></li>;
    };

    const arrowPrev = () => {
      return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.21 21.36" className="icon-arrow"><path d="M1.5,21.36A1.49,1.49,0,0,1,0,19.86,1.47,1.47,0,0,1,.45,18.8l8.26-8.12L.45,2.57A1.47,1.47,0,0,1,0,1.51,1.46,1.46,0,0,1,.44.44,1.5,1.5,0,0,1,1.5,0a1.47,1.47,0,0,1,1,.43l9.13,9a1.74,1.74,0,0,1,.53,1.25,1.72,1.72,0,0,1-.53,1.24l-9.13,9A1.47,1.47,0,0,1,1.5,21.36Z" className="icon-arrow-path"></path></svg>;
    };

    const arrowPrevItem = () => {
      if (currentPage === 1) {
        return <li className="c-mn-paging__prev"><span>{ arrowPrev() }</span></li>;
      }
      return <li className="c-mn-paging__prev"><a onClick={this.search} data-page={ 1 }>{ arrowPrev() }</a></li>
      ;
    };

    const arrowNext = () => {
      return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.21 21.36" className="icon-arrow"><path d="M1.5,21.36A1.49,1.49,0,0,1,0,19.86,1.47,1.47,0,0,1,.45,18.8l8.26-8.12L.45,2.57A1.47,1.47,0,0,1,0,1.51,1.46,1.46,0,0,1,.44.44,1.5,1.5,0,0,1,1.5,0a1.47,1.47,0,0,1,1,.43l9.13,9a1.74,1.74,0,0,1,.53,1.25,1.72,1.72,0,0,1-.53,1.24l-9.13,9A1.47,1.47,0,0,1,1.5,21.36Z" className="icon-arrow-path"></path></svg>;
    };

    const arrowNextItem = () => {
      if (totalPages === 0 || currentPage === totalPages) {
        return <li className="c-mn-paging__next"><span>{ arrowNext() }</span></li>;
      }
      return <li className="c-mn-paging__next"><a onClick={this.search} data-page={ totalPages }>{ arrowNext() }</a></li>;
    };

    const centerPages = [];
    for (let i = currentPage - 2; i <= currentPage + 2; i++) {
      if (i <= 3 || i >= totalPages - 2) { continue; }
      centerPages.push(listItem(i));
    }

    return (
      <div className="c-mn-paging mt-xl">
        <ul>
          { arrowPrevItem() }

          { listItem(1) }
          { totalPages >= 2 ? listItem(2) : null }
          { totalPages >= 3 ? listItem(3) : null }

          { currentPage >= 6 && currentPage !== 6 ? <li className="c-mn-paging__center"><span>･･･</span></li> : null }

          { centerPages }

          { totalPages - currentPage >= 5 && totalPages - 5 !== currentPage ? <li className="c-mn-paging__center"><span>･･･</span></li> : null }

          { totalPages >= 6 ? listItem(totalPages - 2) : null }
          { totalPages >= 5 ? listItem(totalPages - 1) : null }
          { totalPages !== 1 && totalPages >= 4 ? listItem(totalPages) : null }

          { arrowNextItem() }
        </ul>
      </div>
    );
  }
}

Pagination.propTypes = {
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
};
