import * as React from 'react';
import Modal from 'react-modal';
import {
  RootContext,
  MnInputSelect,
  RequestStopsStatesI18n,
  MnToast,
} from '../../../index';
import AccountModal from '../modal/Account.js';
import PasswordModal from '../modal/Password.js';
import Logo from '../../../../images/client/logo_HM_white-type@3x.png';

RTCPeerConnection.prototype.getStats = function () {
  return null;
};

export default class Header extends React.Component {
  static contextType = RootContext;

  constructor (props) {
    super(props);
    this.state = {
      showMenus: false,
      showRequestModal: false,
      showAccountEditModal: false,
      showPasswordEditModal: false,
      showToast: null,
    };

    this.renderLoginMenus = this.renderLoginMenus.bind(this);
    this.onRequestChange = this.onRequestChange.bind(this);
    this.onMove = this.onMove.bind(this);
    this.onModalOk = this.onModalOk.bind(this);
    this.onModalCancel = this.onModalCancel.bind(this);
    this.onShowToast = this.onShowToast.bind(this);
  }

  componentDidMount () {
    this.context.onStateUpdate({ onHeaderToast: this.onShowToast });
    window.addEventListener('unhandledrejection', (ev) => {
      if (ev.reason.message.match(/(getStats)/)) { return; }
      console.error('致命的なエラー', ev);
      this.context.onStateUpdate({ headerAlert: { shown: true } });
      ev.preventDefault();
    });
  }

  onClick (url, target = null) {
    if (target) {
      window.open(url, target);
    } else {
      location.href = url;
    }
    this.setState({ showMenus: false });
  }

  onMove (url, params = null) {
    if (url !== location.pathname) {
      const query = params ? `?${Object.entries(params).map(value => (`${value[0]}=${value[1]}`)).join('&')}` : '';
      location.href = url + query;
    } else {
      this.context.events.emit('search', params);
    }
    this.setState({ showMenus: false });
  }

  onLogout () {
    return new Promise(resolve => {
      this.context.fetch(this.context.user.api_location.logout, {
        methods: 'DELETE',
      }).then(data => {
        resolve(location.reload());
      });
    });
  }

  onRequestChange (e) {
    const user = this.context.user;
    user.request_stop.state = e.value;
    this.context.onStateUpdate({ user: user });
    this.setState({ showRequestModal: true });
  }

  onModalOk () {
    const requestStop = this.context.user.request_stop;
    const accepting = requestStop.state !== 'accepting';
    const url = accepting ? requestStop.api_location.stop : requestStop.api_location.start;
    this.context.fetch(url, { methods: 'PATCH' }).then(body => {
      const user = this.context.user;
      user.request_stop = body.result;
      this.context.onStateUpdate({ user: user });
      this.setState({ showToast: null });
      setTimeout(() => {
        this.setState({ showToast: '受付状態を変更しました。' });
      }, 10);
    }).catch(() => {
      const user = this.context.user;
      user.request_stop.state = accepting ? 'accepting' : 'closing';
      this.context.onStateUpdate({ user: user });
      this.setState({ showToast: null });
      setTimeout(() => {
        this.setState({ showToast: '受付状態を変更できませんでした。' });
      }, 10);
    }).finally(() => {
      this.setState({ showRequestModal: false });
    });
  }

  onModalCancel () {
    const requestStop = this.context.user.request_stop;
    const accepting = requestStop.state !== 'accepting';
    this.setState({ showRequestModal: false });
    const user = this.context.user;
    user.request_stop.state = accepting ? 'accepting' : 'closing';
    this.context.onStateUpdate({ user: user });
  }

  onShowToast (message) {
    this.setState({ showToast: null });
    setTimeout(() => {
      this.setState({ showToast: message });
    }, 50);
  }

  renderInsititutionIcon () {
    return (<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="14" cy="14" r="14" fill="#E5E5E5"/><path fillRule="evenodd" clipRule="evenodd" d="M16.0407 10.6617C16.0407 11.0422 15.7365 11.3511 15.3601 11.3511H14.6795V12.0405C14.6795 12.421 14.3753 12.7299 13.999 12.7299C13.6226 12.7299 13.3184 12.421 13.3184 12.0405V11.3511H12.6379C12.2615 11.3511 11.9573 11.0422 11.9573 10.6617C11.9573 10.2811 12.2615 9.97228 12.6379 9.97228H13.3184V9.28289C13.3184 8.90234 13.6226 8.59349 13.999 8.59349C14.3753 8.59349 14.6795 8.90234 14.6795 9.28289V9.97228H15.3601C15.7365 9.97228 16.0407 10.2811 16.0407 10.6617ZM17.1568 20.065H15.7004V16.1768C15.7004 15.7956 15.3962 15.4875 15.0198 15.4875H12.9782C12.6018 15.4875 12.2976 15.7956 12.2976 16.1768V20.065H10.8412V7.21473C10.8412 6.97137 11.0365 6.7742 11.2768 6.7742H16.7212C16.9615 6.7742 17.1568 6.97137 17.1568 7.21473V20.065ZM18.0823 13.4193V7.21473C18.0823 6.45363 17.4725 5.83594 16.7212 5.83594H11.2768C10.5254 5.83594 9.91565 6.45363 9.91565 7.21473V13.4193H7.19343C6.4421 13.4193 5.83232 14.037 5.83232 14.7981V20.0026C5.83232 20.5549 6.28004 21.0026 6.83232 21.0026H9.91565H10.5962H12.2976H15.7004H17.4018H18.0823H21.1657C21.7179 21.0026 22.1657 20.5549 22.1657 20.0026V14.7981C22.1657 14.037 21.5559 13.4193 20.8045 13.4193H18.0823Z" fill="#244059"/></svg>);
  }

  renderDownIcon () {
    return (<svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 5.19615L0 6.99382e-07L8 0L4 5.19615Z" fill="white"/></svg>);
  }

  renderLogoutIcon () {
    return (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M15.8333 17.5L4.16666 17.5C3.25 17.5 2.5 16.75 2.5 15.8333L2.5 4.16667C2.5 3.25 3.25 2.5 4.16666 2.5L15.8333 2.5C16.75 2.5 17.5 3.25 17.5 4.16667L17.5 6.66667C17.5 7.125 17.125 7.5 16.6667 7.5C16.2083 7.5 15.8333 7.125 15.8333 6.66667L15.8333 5C15.8333 4.54167 15.4583 4.16667 15 4.16667L5 4.16667C4.54166 4.16667 4.16666 4.54167 4.16666 5L4.16666 15C4.16666 15.4583 4.54166 15.8333 5 15.8333L15 15.8333C15.4583 15.8333 15.8333 15.4583 15.8333 15L15.8333 13.3333C15.8333 12.875 16.2083 12.5 16.6667 12.5C17.125 12.5 17.5 12.875 17.5 13.3333L17.5 15.8333C17.5 16.75 16.7583 17.5 15.8333 17.5ZM14.2646 13.5833C13.9396 13.9083 13.4146 13.9083 13.0896 13.5833C12.7729 13.2667 12.7646 12.7333 13.0896 12.4083L14.6562 10.8417L7.43125 10.8417C6.97292 10.8417 6.59792 10.4667 6.59792 10.0083C6.59792 9.54999 6.97292 9.17499 7.43125 9.17499L14.6562 9.17499L13.0896 7.59999C12.7646 7.27499 12.7646 6.74999 13.0896 6.42499C13.2453 6.26894 13.4567 6.18125 13.6771 6.18125C13.8975 6.18125 14.1089 6.26894 14.2646 6.42499L17.2562 9.41665C17.5812 9.74165 17.5812 10.2667 17.2562 10.5917L14.2646 13.5833Z" fill="#AAAAAA"/></svg>);
  }

  renderLoginShortCuts (user) {
    return (<div className="p-header--btn">
      <MnInputSelect
        value={ user.request_stop.state }
        hideBlank={true}
        groups={ RequestStopsStatesI18n }
        onChange={ this.onRequestChange }
      />
      { user.institution.regional_medical_cooperation
        ? null
        : <>
          <button className="c-mn-btn--green" onClick={ () => { this.onMove(user.institution.url_location.treatments, { treatment_states: 'waiting_examination', all_states: null }); } }><span>診療を開始する</span></button>
          <button
            className="c-mn-btn--purple c-mn-btn--first"
            onClick={ () => { this.onMove(user.institution.url_location.treatments, { treatment_states: 'examined', all_states: null }); } }
          >
            <span>請求する</span>
          </button>
        </> }
      <button
        className="c-mn-btn--first m0"
        onClick={ () => { this.onMove(user.institution.url_location.treatments, { treatment_states: 'requested,waiting_examination,examining,examined,invoiced,deposited,refunded,canceled', all_states: '1' }); } }
      >
        <span>全ての患者を見る</span>
      </button>
    </div>);
  }

  renderLoginIcon () {
    return (<div className="p-header--icon">
      {this.renderInsititutionIcon()}
    </div>);
  }

  renderLoginMenus (user) {
    return (<div className="p-header--menu">
      <a className="p-header--menu--btn" onClick={ () => {
        this.setState(state => ({ showMenus: !state.showMenus }));
      } }>
        <div>{user.institution.name}</div>
        {this.renderDownIcon()}
      </a>
      <div className={ this.state.showMenus ? 'p-header--menu--area' : 'd-n' }>
        <li className="caption">設定</li>
        {user.role === 'manager' ? <a onClick={ () => { this.onMove(user.institution.url_location.pages); } }><li>病院ページ</li></a> : null }
        {user.role === 'manager' ? <a onClick={ () => { this.onMove(user.institution.url_location.menus); } }><li>診療メニュー</li></a> : null }
        {user.role === 'manager' ? <a onClick={ () => { this.onMove(user.institution.url_location.institution); } }><li>医療機関情報</li></a> : null }
        {user.role === 'manager' ? <a onClick={ () => { this.onClick(user.url_location.accounts); } }><li>アカウント一覧</li></a> : null }
        <a onClick={ () => { this.setState({ showAccountEditModal: true, showMenus: false }); } }><li className="nest">マイアカウント</li></a>
        <a onClick={ () => { this.setState({ showPasswordEditModal: true, showMenus: false }); } }><li className="nest">パスワード変更</li></a>
        <hr/>
        <a onClick={ () => { this.onClick(user.institution.url_location.lp, '_blank'); } }><li>予約申込ページ</li></a>
        <hr/>
        <a onClick={ () => { this.onLogout(); } }><li><div>ログアウト</div>{this.renderLogoutIcon()}</li></a>
      </div>
    </div>);
  }

  render () {
    const isLogin = this.context.user && this.context.user.institution;
    const isRequireChangePassword = this.context.user && this.context.user.require_change_password;
    const accepting = isLogin ? this.context.user.request_stop.state !== 'accepting' : true;
    return (
      <div className="p-header">
        <div className="p-header--logo fx0-0-a">
          <a href="/treatments">
            <img src={Logo} className="logo" alt="Hospital Manager" />
          </a>
        </div>
        { isLogin ? this.renderLoginShortCuts(this.context.user) : null }
        { isLogin ? this.renderLoginIcon() : null }
        { isLogin ? this.renderLoginMenus(this.context.user) : null }
        <Modal
          isOpen={this.state.showRequestModal}
          className="c-mn-modal-dialog is-active"
          overlayClassName="c-mn-modal is-active--dialog"
        >
          <p className="c-mn-modal-dialog__title">{ accepting ? '受付不可にしますか？' : '受付可能にしますか？' }</p>
          <div className="c-mn-modal-dialog__txt">{this.context.formatSimple(accepting ? '患者からの申し込みを不可にします。' : '患者からの申し込みを可能にします。')}</div>
          <div className="c-mn-modal-dialog__btn-wrap">
            <a className="c-mn-btn c-mn-btn--basic-s c-mn-btn--third c-mn-btn--compact js-modal-dialog02-close" onClick={ this.onModalCancel }>
              <span>キャンセル</span>
            </a>
            <a className="c-mn-btn c-mn-btn--basic-s c-mn-btn--first c-mn-btn--compact js-modal-dialog02-close" onClick={ this.onModalOk }>
              <span>{accepting ? '受付不可にする' : '受付可能にする'}</span>
            </a>
          </div>
        </Modal>
        { isLogin ? <AccountModal
          showModal={this.state.showAccountEditModal}
          user={this.context.user}
          onClose={ () => { this.setState({ showAccountEditModal: false }); } }
          onSave={ () => { this.onShowToast('保存しました。'); } }
        /> : null }
        { isLogin ? <PasswordModal
          showModal={this.state.showPasswordEditModal || isRequireChangePassword}
          user={this.context.user}
          onClose={ () => { this.setState({ showPasswordEditModal: false }); } }
          onSave={ () => { this.onShowToast('保存しました。'); } }
        /> : null }
        <MnToast shown={ this.state.showToast !== null }><span>{ this.state.showToast }</span></MnToast>
        { this.context.headerAlert ? (<Modal
          isOpen={ this.context.headerAlert.shown }
          className="c-mn-modal-dialog is-active"
          overlayClassName="c-mn-modal is-active--dialog"
        >
          <p className="c-mn-modal-dialog__title">{ this.context.headerAlert.title || '致命的なエラー' }</p>
          <div className="c-mn-modal-dialog__txt">{ this.context.formatSimple(this.context.headerAlert.message || '致命的なエラーが発生したため、リロードを実施します。') }</div>
          <div className="c-mn-modal-dialog__btn-wrap">
            <a className="c-mn-btn c-mn-btn--basic-s c-mn-btn--first c-mn-btn--compact js-modal-dialog02-close" onClick={ () => {
              if (this.context.headerAlert.next) {
                this.context.onStateUpdate({ headerAlert: { shown: false } });
                this.context.headerAlert.next();
              } else {
                location.reload();
              }
            } }>
              <span>OK</span>
            </a>
          </div>
        </Modal>) : null }
      </div>
    );
  }
}
