import * as React from 'react';
import Layout from '../Layout';
import { InstitutionForm } from '../organisms/index';

export default class Institution extends React.Component {
  render () {
    return (
      <Layout state={this.props} class="has-background-white">
        <InstitutionForm/>
      </Layout>
    );
  }
}
