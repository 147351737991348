// 自動変換できないバリデータの定義
export const hiraganaValidator = { matches: { validate: [/^([ぁ-ゖゝゞゟ]+)?$/], message: '不正な値です' } };
export const KatakanaValidator = { matches: { validate: [/^([ァ-ヶヷヸヹヺヺ・ーヽヾヿ]+)?$/], message: '不正な値です' } };
export const KanaValidator = { matches: { validate: [/^([ぁ-ゖゝゞゟァ-ヶヷヸヹヺヺ・ーヽヾヿ]+)?$/], message: '不正な値です' } };
export const TelValidator = {
  isLength: { validate: [{ max: 11 }], message: '11文字まで入力できます' },
  matches: { validate: [/^(\d{10,})?$/], message: '不正な値です' },
};
export const TelValidatorJa = {
  isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  isLength: { validate: [{ max: 11 }], message: 'validation.up_to_11_characters' },
  matches: { validate: [/^(\d{10,})?$/], message: 'validation.invalid' },
};
export const TelValidatorNotJa = {
  isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  isLength: { validate: [{ max: 16 }], message: 'validation.up_to_16_characters' },
  matches: { validate: [/^(\+\d+)?$/], message: 'validation.invalid' },
};
export const FaxValidator = {
  isLength: { validate: [{ max: 12 }], message: '12文字まで入力できます' },
  matches: { validate: [/^(\d{10,})?$/], message: '不正な値です' },
};
export const PostalCodeValidator = {
  isLength: { validate: [{ max: 7 }], message: '7文字まで入力できます' },
  matches: { validate: [/^(\d{7,})?$/], message: '不正な値です' },
};
export const PostalCodeValidatorJa = {
  isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  isLength: { validate: [{ max: 7 }], message: 'validation.up_to_7_characters' },
  matches: { validate: [/^(\d{7,})?$/], message: 'validation.invalid' },
};
export const PostalCodeValidatorNotJa = {
  isLength: { validate: [{ max: 16 }], message: 'validation.up_to_16_characters' },
};
export const InvoiceFeeValidators = {
  name: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
    isLength: { validate: [{ max: 30 }], message: '30文字まで入力できます' },
  },
  amount: {
    isInt: { validate: [{ gt: -1 }], message: '不正な値です' },
    isLength: { validate: [{ max: 6 }], message: '上限は999,999円です' },
  },
};

// かな→カナ変換
export const KanaConvert = (value) => {
  return value.replace(/[\u3041-\u3096]/g, (match) => {
    return String.fromCharCode(match.charCodeAt(0) + 0x60);
  });
};
// カナ→かな変換
export const HiraConvert = (value) => {
  return value.replace(/[\u30A1-\u30F6]/g, (match) => {
    return String.fromCharCode(match.charCodeAt(0) - 0x60);
  });
};

export const MenusUseRequestFlagsI18n = [
  {
    key: true,
    value: '表示する',
  },
  {
    key: false,
    value: '表示しない',
  },
];
export const MenuUseRequestFlagValidator = {
  isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
};
