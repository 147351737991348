import * as React from 'react';
import PropTypes from 'prop-types';
import AbstractMnInput from './AbstractMnInput';
import MnPopup from './MnPopup';

export default class MnInputCheckboxGroup extends AbstractMnInput {
  _onChange (e) {
    return this.props.groups.map(item => item.key).filter(val => {
      if (e.target.value === val.toString()) {
        return e.target.checked === true;
      } else {
        return this.state.value.indexOf(val) !== -1;
      }
    });
  }

  renderCheckIcon () {
    return (<svg width="29" height="20" viewBox="0 0 29 20" fill="none" className="c-mn-icon-check" xmlns="http://www.w3.org/2000/svg"><path d="M1.51592 10.4222C0.75511 9.66136 0.755111 8.42785 1.51592 7.66704C2.27673 6.90623 3.51024 6.90623 4.27105 7.66704L11.7129 15.1089C12.4737 15.8697 12.4737 17.1032 11.7129 17.864C10.9521 18.6248 9.71855 18.6248 8.95774 17.864L1.51592 10.4222Z" fill="#000" className="c-mn-icon-check-path"></path><path d="M11.9062 18.0573C11.1454 18.8181 9.91185 18.8181 9.15104 18.0573C8.39023 17.2965 8.39023 16.063 9.15104 15.3022L23.4527 1.00054C24.2135 0.23973 25.447 0.239729 26.2078 1.00054C26.9686 1.76135 26.9686 2.99486 26.2078 3.75567L11.9062 18.0573Z" fill="#000" className="c-mn-icon-check-path"></path></svg>);
  }

  render () {
    const groups = this.props.groups ? this.props.groups : [];
    return (
      <MnPopup annotation={this.props.annotation} closeIcon={this.renderIconClose} hideAnnotation={this.hideAnnotation} outerClass={this.props.popupOuterClass}>
        <div className={this.props.class ? this.props.class : 'c-mn-checkbox-btn--col2 p-fractal__checkbox-btn'}>
          { groups.map(data => {
            return (
              <div key={data.key} className={this.props.btnClass ? this.props.btnClass : 'c-mn-checkbox-btn'}>
                <input
                  type="checkbox" className="c-mn-checkbox-btn__checkbox"
                  name={this.props.name}
                  id={`${this.props.name}_${data.key}`}
                  value={data.key}
                  checked={this.state.value.indexOf(data.key) !== -1}
                  onChange={ this.onChange }
                  onFocus={ this.props.onFocus }
                  onBlur={ this.props.onBlur }
                  disabled={this.props.disabled}
                />
                <label className="c-mn-checkbox-btn__label" htmlFor={`${this.props.name}_${data.key}`}>
                  {this.props.icon ? this.props.icon() : this.renderCheckIcon()}{data.value}
                </label>
              </div>
            );
          })}
        </div>
        { this.renderErrors() }
      </MnPopup>
    );
  }
}

MnInputCheckboxGroup.propTypes = {
  ...AbstractMnInput.propTypes, // 基底クラスのpropTypesを継承
  value: PropTypes.array, // 選択の値
  groups: PropTypes.array, // チェックボックスの選択肢 `[{key: xxx, value: yyy}, ...]`
  icon: PropTypes.func, // チェック時のアイコン
};
