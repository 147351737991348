import * as React from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import {
  RootContext,
  AbstractComponent,
  MnButton,
  MnDialog,
  TreatmentsStatesI18n,
} from '../../../index';
import {
  WaitingExaminationConfirmModal,
  WaitingExaminationUpdateModal,
  CanceledModal,
  UploadFileModal,
} from '../treatments/index';

export default class TreatmentsPatient extends AbstractComponent {
  static contextType = RootContext;
  constructor () {
    super();
    this.state = {
      isOpenModalWaitingExaminationConfirm: false,
      isOpenModalWaitingExaminationUpdate: false,
      isOpenModalCanceled: false,
      isOpenModalUploadFile: false,
      isOpenModalExaminedUpdate: false,
      isOpenModalExaminedRollback: false,
      uploadFileModalApiLocation: null,
    };
    this.handleOpenModalWaitingExaminationConfirm = this.handleOpenModalWaitingExaminationConfirm.bind(this);
    this.handleOpenModalWaitingExaminationUpdate = this.handleOpenModalWaitingExaminationUpdate.bind(this);
    this.handleOpenModalCanceled = this.handleOpenModalCanceled.bind(this);
    this.handleOpenModalUploadFile = this.handleOpenModalUploadFile.bind(this);
    this.handleCloseModalWaitingExaminationConfirm = this.handleCloseModalWaitingExaminationConfirm.bind(this);
    this.handleCloseModalWaitingExaminationUpdate = this.handleCloseModalWaitingExaminationUpdate.bind(this);
    this.handleCloseModalCanceled = this.handleCloseModalCanceled.bind(this);
    this.handleCloseModalUploadFile = this.handleCloseModalUploadFile.bind(this);
    this.handleOpenModalExaminedUpdate = this.handleOpenModalExaminedUpdate.bind(this);
    this.handleOpenModalExaminedRollback = this.handleOpenModalExaminedRollback.bind(this);
  }

  handleOpenModalWaitingExaminationConfirm = () => {
    return new Promise((resolve, reject) => {
      this.setState({ isOpenModalWaitingExaminationConfirm: true });
      resolve();
    });
  }

  handleOpenModalWaitingExaminationUpdate = () => {
    return new Promise((resolve, reject) => {
      this.setState({ isOpenModalWaitingExaminationUpdate: true });
      resolve();
    });
  }

  handleOpenModalExaminedUpdate = () => {
    return new Promise((resolve, reject) => {
      this.setState({ isOpenModalExaminedUpdate: true });
      resolve();
    });
  }

  handleOpenModalExaminedRollback = () => {
    return new Promise((resolve, reject) => {
      this.setState({ isOpenModalExaminedRollback: true });
      resolve();
    });
  }

  handleOpenModalCanceled = () => {
    return new Promise((resolve, reject) => {
      this.setState({ isOpenModalCanceled: true });
      resolve();
    });
  }

  handleOpenModalUploadFile = (e) => {
    this.setState({ isOpenModalUploadFile: true, uploadFileModalApiLocation: e.target.dataset.url });
  }

  handleCloseModalWaitingExaminationConfirm = () => {
    this.setState({ isOpenModalWaitingExaminationConfirm: false });
    this.context.onStateUpdate({ isOpenModalWaitingExaminationConfirm: false });
  }

  handleCloseModalWaitingExaminationUpdate = () => {
    this.setState({ isOpenModalWaitingExaminationUpdate: false });
    this.context.onStateUpdate({ isOpenModalWaitingExaminationUpdate: false });
  }

  handleCloseModalCanceled = () => {
    this.setState({ isOpenModalCanceled: false });
  }

  handleCloseModalUploadFile = () => {
    this.setState({ isOpenModalUploadFile: false });
  }

  renderButtons = () => {
    switch (this.context.state) {
    case 'requested': // 診療申込
      return (
        <div className="p-buttons">
          <div className="columns">
            <div className="column px-20 is-flex jc-c">
              <div className="fxg1">
                <MnButton
                  class="c-mn-btn c-mn-btn--basic-s c-mn-btn--third c-mn-btn--compact p-treatment__btn"
                  onClick={this.handleOpenModalCanceled}
                >
                  <span>キャンセル</span>
                </MnButton>
              </div>
              <div className="fxg0" style={{ width: 12 }}></div>
              <div className="fxg1">
                <MnButton
                  class="c-mn-btn c-mn-btn--basic-s c-mn-btn--first c-mn-btn--compact p-treatment__btn"
                  onClick={this.handleOpenModalWaitingExaminationConfirm}
                >
                  <span>確定する</span>
                </MnButton>
              </div>
            </div>
          </div>
        </div>
      );
    case 'waiting_examination': // 診療待ち
      return (
        <div className="p-buttons">
          <div className="columns">
            <div className="column is-flex jc-c">
              <div className="fxg1">
                <MnButton
                  class="c-mn-btn c-mn-btn--basic-s c-mn-btn--third c-mn-btn--compact p-treatment__btn"
                  onClick={this.handleOpenModalCanceled}
                >
                  <span>キャンセル</span>
                </MnButton>
              </div>
              <div className="fxg0" style={{ width: 12 }}></div>
              <div className="fxg1">
                <MnButton
                  class="c-mn-btn c-mn-btn--basic-s c-mn-btn--first c-mn-btn--compact p-treatment__btn"
                  onClick={this.handleOpenModalExaminedUpdate}
                >
                  <span>診療済みにする</span>
                </MnButton>
              </div>
            </div>
          </div>
        </div>
      );
    case 'examined': // 診療済み
      return (
        <div className="p-buttons">
          <div className="columns">
            <div className="column px-20 is-flex jc-c">
              <div className="fxg1">
                <MnButton
                  class="c-mn-btn c-mn-btn--basic-s c-mn-btn--first c-mn-btn--compact p-treatment__btn"
                  onClick={this.handleOpenModalExaminedRollback}
                >
                  <span>診療待ちに戻す</span>
                </MnButton>
              </div>
            </div>
          </div>
        </div>
      );
    default:
      return null;
    }
  }

  tagParamsForFilePreviewLink = (uploadFile) => {
    if (uploadFile.filename.match('.pdf$')) {
      return {
        href: uploadFile.preview_url,
        target: '_blank',
      };
    }

    return {
      'data-url': uploadFile.url,
      onClick: this.handleOpenModalUploadFile,
    };
  }

  renderInterviewAnswer = (interviewSetting) => {
    switch (interviewSetting.input_type) {
    case 'textarea':
      return interviewSetting?.interview?.text ? this.formatSimple(interviewSetting.interview.text) : '-';
    case 'radio':
      return interviewSetting?.interview?.selectI18n || '-';
    case 'file':
      if (!interviewSetting.images || interviewSetting.images.length === 0) {
        return '-';
      }

      return (
        <ul>
          { interviewSetting.images.map((image, i) => {
            return (
              <li key={ i }>
                <a {...this.tagParamsForFilePreviewLink(image)} >
                  { image.filename }
                </a>
              </li>
            );
          })}
        </ul>
      );
    default:
      return null;
    }
  }

  render () {
    const {
      treatment,
    } = this.context;
    const isOpenModalWaitingExaminationConfirm = this.state.isOpenModalWaitingExaminationConfirm || this.context.isOpenModalWaitingExaminationConfirm;
    const isOpenModalWaitingExaminationUpdate = this.state.isOpenModalWaitingExaminationUpdate || this.context.isOpenModalWaitingExaminationUpdate;
    const reservedAt = treatment.reserved_at ? moment(treatment.reserved_at, 'YYYY/MM/DD HH:mm') : null;

    return (
      <div className={ this.context.isFullscreen ? 'd-n' : 'p-patient is-relative' }>
        <div className="p-patient__information">
          <div className="mb-lg is-flex jc-sb">
            <div>
              <span className={`c-mn-tag p-treatment-state__tag--${this.context.state}`}>{this.context.state_i18n}</span>
            </div>
            <div className="is-flex ai-c">
              <a href={treatment.api_location.download} className="c-mn-link has-text-weight-bold" download>CSV出力</a>
            </div>
          </div>
          <ul>
            <li className="columns is-vcentered">
              <div className="column c-mn-form-label">診療メニュー</div>
              <div className="column lh-md">{treatment.menu.title}</div>
            </li>
            <li className="columns is-vcentered">
              <div className="column c-mn-form-label">患者氏名</div>
              <div className="column lh-md">{treatment.patient.name}</div>
            </li>
            {treatment.patient.language_code === 'ja' ? (<li className="columns is-vcentered">
              <div className="column c-mn-form-label">患者氏名（カナ）</div>
              <div className="column lh-md">{treatment.patient.kana}</div>
            </li>) : null}
            <li className="columns is-vcentered">
              <div className="column c-mn-form-label">生年月日</div>
              <div className="column lh-md">{treatment.patient.birthday || '-'}</div>
            </li>
            <li className="columns is-vcentered">
              <div className="column c-mn-form-label">性別</div>
              <div className="column lh-md">{treatment.patient.sex_i18n || '-'}</div>
            </li>
            <li className="columns is-vcentered">
              <div className="column c-mn-form-label">電話番号</div>
              <div className="column lh-md">{treatment.patient.telephone}</div>
            </li>
            <li className="columns is-vcentered">
              <div className="column c-mn-form-label">メールアドレス</div>
              <div className="column lh-md">{treatment.patient.email_address}</div>
            </li>
            <li className="columns is-vcentered">
              <div className="column c-mn-form-label">住所</div>
              <div className="column lh-md">{treatment.patient.full_address ? this.context.formatSimple(treatment.patient.full_address) : '-'}</div>
            </li>
            <li className="columns is-vcentered">
              <div className="column c-mn-form-label">紹介状の有無</div>
              <div className="column lh-md">{treatment.has_referral_i18n}</div>
            </li>
            <li className="columns is-vcentered">
              <div className="column c-mn-form-label">保険証画像</div>
              <div className="column lh-md">
                { treatment.patient.insurance_cards && treatment.patient.insurance_cards.length > 0 ? (
                  <ul>
                    { treatment.patient.insurance_cards.map((insuranceCard, i) => {
                      return (
                        <li key={ i }>
                          <a {...this.tagParamsForFilePreviewLink(insuranceCard)}>
                            { insuranceCard.filename }
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                ) : '-' }
              </div>
            </li>
            <li className="columns is-vcentered">
              <div className="column c-mn-form-label">医療証画像</div>
              <div className="column lh-md">
                { treatment.patient.etc_cards && treatment.patient.etc_cards.length > 0 ? (
                  <ul>
                    { treatment.patient.etc_cards.map((etcCard, i) => {
                      return (
                        <li key={ i }>
                          <a {...this.tagParamsForFilePreviewLink(etcCard)} >
                            { etcCard.filename }
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                ) : '-' }
              </div>
            </li>
            <li className="columns is-vcentered">
              <div className="column c-mn-form-label">診察券画像</div>
              <div className="column lh-md">
                { treatment.patient.patient_registration_cards && treatment.patient.patient_registration_cards.length > 0 ? (
                  <ul>
                    { treatment.patient.patient_registration_cards.map((patientRegistrationCard, i) => {
                      return (
                        <li key={ i }>
                          <a {...this.tagParamsForFilePreviewLink(patientRegistrationCard)}>
                            { patientRegistrationCard.filename }
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                ) : '-' }
              </div>
            </li>
            <li className="columns is-vcentered">
              <div className="column c-mn-form-label">関連資料</div>
              <div className="column lh-md">
                { treatment.patient.documents_related_to_referral_letter && treatment.patient.documents_related_to_referral_letter.length > 0 ? (
                  <ul>
                    { treatment.patient.documents_related_to_referral_letter.map((documentRelatedToReferralLetter, i) => {
                      return (
                        <li key={ i }>
                          <a {...this.tagParamsForFilePreviewLink(documentRelatedToReferralLetter)}>
                            { documentRelatedToReferralLetter.filename }
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                ) : '-' }
              </div>
            </li>
            <li className="columns is-vcentered">
              <div className="column c-mn-form-label">リクエスト希望日</div>
              <div className="column lh-md">
                { treatment.requested_dates && treatment.requested_dates.length > 0 ? (
                  <ul>
                    { treatment.requested_dates.map((requestedDate, i) => {
                      return <li key={i}><span>{requestedDate.date}</span></li>;
                    })}
                  </ul>
                ) : '-' }
              </div>
            </li>
            <li className="columns is-vcentered">
              <div className="column c-mn-form-label">診療確定日</div>
              <div className="column lh-md">
                {reservedAt ? reservedAt.format('YY/MM/DD HH:mm') : '-' }
                {reservedAt && this.context.state === 'waiting_examination' ? <a className="ml-sm" onClick={ this.handleOpenModalWaitingExaminationUpdate }>変更</a> : null }
              </div>
            </li>
            <li className="columns is-vcentered">
              <div className="column c-mn-form-label p-patient__information__name">担当医の希望</div>
              <div className="column lh-md p-patient__information__value">{this.context.formatSimple(treatment.requested_worker || '-')}</div>
            </li>
            <li className="columns is-vcentered">
              <div className="column c-mn-form-label">担当医</div>
              <div className="column lh-md">{this.context.worker ? this.context.worker.name : '-'}</div>
            </li>
          </ul>
          { treatment.menu.use_interview === 'using' ? (
            <ul>
              {treatment.interview_settings.map((interviewSetting) => {
                return (
                  <li key={interviewSetting.id} className="columns is-vcentered">
                    <div className="column c-mn-form-label p-patient__information__name">{interviewSetting.title}</div>
                    <div className="column lh-md p-patient__information__value">{this.renderInterviewAnswer(interviewSetting)}</div>
                  </li>
                );
              })}
            </ul>
          ) : null }

          <div className="mt-md">
            <div className="columns fz12">
              <div className="column is-6">ID: {treatment.request_id}</div>
              <div className="column is-7">{treatment.patient.created_at}</div>
            </div>
          </div>
        </div>

        {this.renderButtons()}

        <WaitingExaminationConfirmModal
          isOpen={isOpenModalWaitingExaminationConfirm}
          handleClose={this.handleCloseModalWaitingExaminationConfirm}
          apiLocation={treatment.api_location.update}
          title={'この患者の予約を確定しますか？'}
          description={'予約を確定すると患者に確定通知されます。'}
        />
        <WaitingExaminationUpdateModal
          isOpen={isOpenModalWaitingExaminationUpdate}
          handleClose={this.handleCloseModalWaitingExaminationUpdate}
          apiLocation={treatment.api_location.update}
          title={'この患者の予約を変更しますか？'}
          description={'予約を変更すると患者に確定通知されます。'}
          reservedDate={reservedAt ? reservedAt.format('YYYY/MM/DD') : null}
          reservedTime={reservedAt ? reservedAt.format('HH:mm:ss') : null}
        />
        <CanceledModal
          isOpen={this.state.isOpenModalCanceled}
          handleClose={this.handleCloseModalCanceled}
          apiLocation={treatment.api_location.update}
          title={'この患者の予約をキャンセルしますか？'}
          description={'予約をキャンセルすると患者に通知されます。'}
        />
        <UploadFileModal
          isOpen={this.state.isOpenModalUploadFile}
          apiLocation={this.state.uploadFileModalApiLocation}
          handleClose={this.handleCloseModalUploadFile}
        />
        <MnDialog
          isOpen={this.state.isOpenModalExaminedRollback}
          title="この患者の予約を診療待ちにしますか？"
          btnSubmitLabel="診療待ちにする"
          handleSubmit={ () => {
            return new Promise(resolve => {
              this.fetch(treatment.api_location.update, {
                methods: 'PATCH',
                body: {
                  state: 'waiting_examination',
                },
              }).then(body => {
                const stateI18n = TreatmentsStatesI18n.find(state => state.key === body.state);
                this.context.onStateUpdate({
                  treatment: { ...this.context, state: body.state, state_i18n: stateI18n },
                  state: body.state,
                  state_i18n: stateI18n.value,
                });
                this.setState({ isOpenModalExaminedRollback: false });
                this.context.onHeaderToast('診療待ちに更新しました。');
              });
              resolve();
            });
          } }
          handleClose={ () => {
            this.setState({ isOpenModalExaminedRollback: false });
          } }
        >
          診療待ちステータスに変更になります。
        </MnDialog>
        <MnDialog
          isOpen={this.state.isOpenModalExaminedUpdate}
          title="この患者の予約を診療済みにしますか？"
          btnSubmitLabel="診療済みにする"
          handleSubmit={ () => {
            return new Promise(resolve => {
              this.fetch(treatment.api_location.update, {
                methods: 'PATCH',
                body: {
                  state: 'examined',
                },
              }).then(body => {
                const stateI18n = TreatmentsStatesI18n.find(state => state.key === body.state);
                this.context.onStateUpdate({
                  treatment: { ...this.context, state: body.state, state_i18n: stateI18n },
                  state: body.state,
                  state_i18n: stateI18n.value,
                });
                this.setState({ isOpenModalExaminedUpdate: false });
                this.context.onHeaderToast('診療済みに更新しました。');
              });
              resolve();
            });
          } }
          handleClose={ () => {
            this.setState({ isOpenModalExaminedUpdate: false });
          } }
        >
          診療済みステータスに変更になります。
        </MnDialog>
      </div>
    );
  }
}

TreatmentsPatient.propTypes = {
  id: PropTypes.number,
  patient: PropTypes.object,
  state_i18n: PropTypes.string,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
};
