import React from 'react';

export default class MnLoading extends React.Component {
  componentDidMount () {
    document.body.className = 'p-loading';
  }

  componentWillUnmount () {
    document.body.className = '';
  }

  render () {
    return (<></>);
  }
}
