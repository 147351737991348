import * as React from 'react';
import PropTypes from 'prop-types';
import AbstractMnInput from './AbstractMnInput';
import MnPopup from './MnPopup';

export default class MnInputDate extends AbstractMnInput {
  today = new Date();
  years = new Array(101).fill().map((_, index) => {
    const year = this.today.getFullYear() - 100 + index;
    return { key: year.toString(), value: year };
  });

  months = new Array(12).fill().map((_, index) => {
    const month = (index + 1).toString();
    return { key: month, value: `00${month}`.slice(-2) };
  });

  days = new Array(31).fill().map((_, index) => {
    const day = (index + 1).toString();
    return { key: day, value: `00${day}`.slice(-2) };
  });

  constructor (props) {
    super(props);
    this.state = {
      ...this.state,
      focus: { year: false, month: false, day: false },
    };
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  onFocus (name, e) {
    const focus = this.state.focus;
    focus[name] = true;
    this.setState({ focus: focus });
    this.props.onFocus(e);
  }

  onBlur (name, e) {
    const focus = this.state.focus;
    focus[name] = false;
    this.setState({ focus: focus });
    this.props.onBlur(e);
  }

  _onChange (e) {
    e.target.blur();
    return e.target.value;
  }

  onChange (name, e) {
    const value = this.state.value;
    value[name] = this.sanitizers(this._onChange(e));
    this.setState({ value: value });
    this.props.onChange({ name: this.props.name, value: value, callbackParams: this.props.callbackParams });
    this.validate(value).then(() => {
      this.setState({ errors: undefined });
    }).catch(err => {
      console.warn(err);
      this.setState({ errors: err });
      this.props.onError({ name: this.props.name, errors: err });
    });
  }

  validate (value) {
    return new Promise((resolve, reject) => {
      const errors = [];
      const values = Object.values(value);
      const date = new Date(...values.map((day, i) => {
        if (i === 1) {
          return Number(day) - 1;
        }
        return Number(day);
      }));
      if (values.indexOf('') === -1 && date.getMonth() + 1 !== Number(values[1])) {
        errors.push(this.props.t ? 'MnInputDate.invalid_date' : '存在しない日付です');
      }
      if (this.today < date) {
        errors.push(this.props.t ? 'MnInputDate.after_date' : '現在より先は指定できません');
      }
      Promise.all([
        super.validate(value.year, this.props.validates.year),
        super.validate(value.month, this.props.validates.month),
        super.validate(value.day, this.props.validates.day),
      ]).catch(err => {
        errors.push(...err);
      }).finally(() => {
        if (errors.length === 0) {
          resolve();
        } else {
          reject(errors.filter((err, i, self) => self.indexOf(err) === i));
        }
      });
    });
  }

  render () {
    return (
      <MnPopup annotation={this.props.annotation} closeIcon={this.renderIconClose} hideAnnotation={this.hideAnnotation}>
        <div id={this.props.name} className={this.props.class ? this.props.class : 'p-input-date'}>
          <div className="c-mn-select-wrap">
            <select
              name={`${this.props.name}_year`}
              value={this.state.value.year}
              className={this.state.value.year || this.state.focus.year ? 'c-mn-select c-mn-select--selected' : 'c-mn-select'}
              onChange={ e => { this.onChange('year', e); } }
              onFocus={ e => { this.onFocus('year', e); } }
              onBlur={ e => { this.onBlur('year', e); } }
              disabled={this.props.disabled}
            >
              { this.props.hideBlank ? null : <option value="">{this.props.t ? this.props.t(this.props.placeholder.year) : '年'}</option> }
              { this.years.map(data => {
                return (<option key={data.key} value={data.key}>{data.value}</option>);
              })}
            </select>
          </div>
          <div className="c-mn-select-wrap">
            <select
              name={`${this.props.name}_month`}
              value={this.state.value.month}
              className={this.state.value.month || this.state.focus.month ? 'c-mn-select c-mn-select--selected' : 'c-mn-select'}
              onChange={ e => { this.onChange('month', e); } }
              onFocus={ e => { this.onFocus('month', e); } }
              onBlur={ e => { this.onBlur('month', e); } }
              disabled={this.props.disabled}
            >
              { this.props.hideBlank ? null : <option value="">{this.props.t ? this.props.t(this.props.placeholder.month) : '月'}</option> }
              { this.months.map(data => {
                return (<option key={data.key} value={data.key}>{data.value}</option>);
              })}
            </select>
          </div>
          <div className="c-mn-select-wrap">
            <select
              name={`${this.props.name}_day`}
              value={this.state.value.day}
              className={this.state.value.day || this.state.focus.day ? 'c-mn-select c-mn-select--selected' : 'c-mn-select'}
              onChange={ e => { this.onChange('day', e); } }
              onFocus={ e => { this.onFocus('day', e); } }
              onBlur={ e => { this.onBlur('day', e); } }
              disabled={this.props.disabled}
            >
              { this.props.hideBlank ? null : <option value="">{this.props.t ? this.props.t(this.props.placeholder.day) : '日'}</option> }
              { this.days.map(data => {
                return (<option key={data.key} value={data.key}>{data.value}</option>);
              })}
            </select>
          </div>
        </div>
        { this.renderErrors() }
      </MnPopup>
    );
  }
}

MnInputDate.propTypes = {
  ...AbstractMnInput.propTypes, // 基底クラスのpropTypesを継承
  value: PropTypes.object, // {year: 年, month: 月, day: 日}
  hideBlank: PropTypes.bool, // ブランク行の非表示
  isFutureDate: PropTypes.bool, // 未来の日付の許可
  placeholder: PropTypes.object, // {year: 年, month: 月, day: 日}
};

MnInputDate.defaultProps = {
  ...AbstractMnInput.defaultProps,
  hideBlank: false,
  isFutureDate: true,
  placeholder: { year: 'MnInputDate.year', month: 'MnInputDate.month', day: 'MnInputDate.day' },
};
