import * as React from 'react';
import {
  RootContext,
} from '../../../../index';
import {
  RefundButton,
  RefundModal,
  InvoiceLayout,
  InvoiceForm,
} from './index';

export default class Deposited extends React.Component {
  static contextType = RootContext;
  constructor (props) {
    super(props);
    this.state = {
      isOpenModalRefund: false,
    };
    this.handleOpenModalRefund = this.handleOpenModalRefund.bind(this);
    this.handleCloseModalRefund = this.handleCloseModalRefund.bind(this);
  }

  handleOpenModalRefund = () => {
    return new Promise((resolve, reject) => {
      this.setState({
        isOpenModalRefund: true,
      });
      resolve();
    });
  }

  handleCloseModalRefund = () => {
    this.setState({ isOpenModalRefund: false });
  }

  render () {
    const { invoice } = this.context;
    const amount = invoice.medical_fees.map(fee => Number(fee.amount) >= 0 ? Number(fee.amount) : 0).reduce((a, b) => (a + b));

    return (
      <InvoiceLayout>
        <>
          <InvoiceForm invoice={invoice} disabled/>
          <div className="columns">
            <div className="column has-text-centered">
              <RefundButton handleClick={this.handleOpenModalRefund} disabled={ amount < 50 } />
            </div>
          </div>
          <RefundModal
            isOpen={this.state.isOpenModalRefund}
            handleClose={this.handleCloseModalRefund}
            treatment={this.context.treatment}
          />
        </>
      </InvoiceLayout>
    );
  }
}
