import * as React from 'react';
import PropTypes from 'prop-types';
import AbstractMnInput from './AbstractMnInput';
import MnPopup from './MnPopup';

export default class MnInputSelect extends AbstractMnInput {
  constructor (props) {
    super(props);
    this.state = {
      ...this.state,
      focus: false,
    };
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  onFocus (e) {
    this.setState({ focus: true });
    this.props.onFocus(e);
  }

  onBlur (e) {
    this.setState({ focus: false });
    this.props.onBlur(e);
  }

  _onChange (e) {
    e.target.blur();
    return e.target.value;
  }

  render () {
    const groups = this.props.groups ? this.props.groups : [];
    return (
      <MnPopup annotation={this.state.annotation} closeIcon={this.renderIconClose} hideAnnotation={this.hideAnnotation}>
        <div className={this.props.class ? this.props.class : 'c-mn-select-wrap'}>
          <select
            id={this.props.name}
            name={this.props.name}
            value={this.state.value}
            className={this.state.value || this.state.focus ? 'c-mn-select c-mn-select--selected' : 'c-mn-select'}
            onChange={this.onChange}
            onFocus={ this.onFocus }
            onBlur={ this.onBlur }
            disabled={this.props.disabled}
          >
            { this.props.hideBlank ? null : <option value="">{ this.props.placeholder ? this.props.placeholder : '選択してください'}</option> }
            { groups.map(data => {
              return (<option key={data.key} value={data.key}>{data.value}</option>);
            })}
          </select>
        </div>
        { this.renderErrors() }
      </MnPopup>
    );
  }
}

MnInputSelect.propTypes = {
  ...AbstractMnInput.propTypes, // 基底クラスのpropTypesを継承
  /** チェックボックスの選択肢 `[{key: xxx, value: yyy}, ...]` */
  groups: PropTypes.array,
  /** ブランク行の非表示 */
  hideBlank: PropTypes.bool,
};
