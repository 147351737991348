import * as React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import moment from 'moment';
import ja from 'date-fns/locale/ja';
import 'react-datepicker/dist/react-datepicker.css';
import {
  AbstractMnInput,
  MnPopup,
} from '../../../index';
registerLocale('ja', ja);

export default class MnDatePicker extends AbstractMnInput {
  onChange (date) {
    const value = this.sanitizers(this._onChange(date));
    this.setState({ value: date });
    this.props.onChange(value);
    this.validate(value).then(() => {
      this.setState({ errors: undefined });
    }).catch(err => {
      this.setState({ errors: err });
      this.props.onError(err);
    });
  }

  _onChange (date) {
    return moment(date, 'YYYY/MM/DD').format('YYYY/MM/DD');
  }

  render () {
    const selected = this.props.value ? new Date(this.props.value) : '';

    return (
      <MnPopup annotation={this.state.annotation} closeIcon={this.renderIconClose} hideAnnotation={this.hideAnnotation}>
        <div className="pos-r">
          <DatePicker
            dateFormat="yyyy/MM/dd"
            locale="ja"
            onChange={date => this.onChange(date)}
            selected={selected}
            className="c-mn-input-text"
            placeholderText="2020/04/01"
            minDate={moment().toDate()}
          />
          { this.renderErrors() }
        </div>
      </MnPopup>
    );
  }
}

MnDatePicker.propTypes = {
  ...AbstractMnInput.propTypes, // 基底クラスのpropTypesを継承
};
