import * as React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

export default class MnToast extends React.Component {
  render () {
    return ReactDOM.createPortal((<div className="c-mn-toast-wrap">
      <div className={ this.props.shown ? 'c-mn-toast fadein-out' : 'd-n' }>{this.props.children}</div>
    </div>), document.body);
  }
}

MnToast.propTypes = {
  children: PropTypes.object, // inline表示するDOM要素
  shown: PropTypes.bool, // トーストの表示
};
