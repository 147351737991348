import * as React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

export default class MnModal extends React.Component {
  render () {
    return (
      <Modal
        isOpen={this.props.isOpen}
        className={this.props.class}
        overlayClassName={this.props.overlayClass}
      >{this.props.children}</Modal>
    );
  }
}

MnModal.propTypes = {
  children: PropTypes.any,
  class: PropTypes.string,
  overlayClass: PropTypes.string,
  isOpen: PropTypes.bool,
};

MnModal.defaultProps = {
  class: 'c-mn-modal-dialog is-active has-background-white',
  overlayClass: 'c-mn-modal is-active--dialog',
  isOpen: false,
};
