import * as React from 'react';
import PropTypes from 'prop-types';
import { MnModal, MnButton } from './index';

export default class MnDialog extends React.Component {
  render () {
    return (
      <MnModal
        isOpen={this.props.isOpen}
      >
        <p className="c-mn-modal-dialog__title">{this.props.title}</p>
        <div className="c-mn-modal-dialog__txt">
          {this.props.children}
        </div>
        <div className="is-flex jc-fe">
          { this.props.btnCloseHidden ? null : <MnButton
            class="c-mn-btn c-mn-btn--basic-s c-mn-btn--third c-mn-btn--compact"
            onClick={ () => {
              return new Promise(resolve => {
                resolve();
                setTimeout(() => {
                  this.props.handleClose();
                });
              });
            }}
          >
            <span>{this.props.btnCloseLabel}</span>
          </MnButton> }
          <MnButton
            class="c-mn-btn c-mn-btn--basic-s c-mn-btn--first c-mn-btn--compact ml-md"
            onClick={this.props.handleSubmit}
          >
            <span>{this.props.btnSubmitLabel}</span>
          </MnButton>
        </div>
      </MnModal>
    );
  }
}

MnDialog.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  btnSubmitLabel: PropTypes.string,
  btnCloseLabel: PropTypes.string,
  btnCloseHidden: PropTypes.bool,
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
};

MnDialog.defaultProps = {
  btnSubmitLabel: '決定',
  btnCloseLabel: 'キャンセル',
  isOpen: false,
  btnCloseHidden: false,
};
