import React from 'react';
import { RootContext, MnDialog, SvgPlusIcon } from '../../../index';
import EventEmitter from 'events';

export default class Menus extends React.Component {
  static contextType = RootContext;
  maxMenuCount = 20;

  constructor (props) {
    super(props);
    this.state = {
      isEditedAlert: false, // 編集確認アラート
      event: new EventEmitter(), // モーダルイベント監視
    };

    this.onGetMenu = this.onGetMenu.bind(this);
    this.onUpdateMenus = this.onUpdateMenus.bind(this);
    this.onCreateMenu = this.onCreateMenu.bind(this);
    this.onEditedAlert = this.onEditedAlert.bind(this);
    this.onEditedAlertOk = this.onEditedAlertOk.bind(this);
    this.onEditedAlertCancel = this.onEditedAlertCancel.bind(this);
  }

  componentDidMount () {
    this.onGetMenu();
    this.onUpdateMenus();
    this.context.onStateUpdate({
      onEditedAlert: this.onEditedAlert,
      onGetMenu: this.onGetMenu,
      onUpdateMenus: this.onUpdateMenus,
      onCreateMenu: this.onCreateMenu,
    });
  }

  /** メニュー取得 */
  onGetMenu (url = this.context.api_location.show) {
    this.context.showLoading();
    this.context.onStateUpdate({ menu: undefined });
    this.context.fetch(url).then(data => {
      this.context.onStateUpdate({ menu: data.result, tab: 1, isEdit: false, isEdited: false });
      history.replaceState('', '', `${location.pathname.split('/').slice(0, -1).join('/')}/${data.result.id || 'new'}`);
    }).finally(() => {
      this.context.hideLoading();
    });
  }

  /** メニュー一覧の情報取得/表示更新 */
  onUpdateMenus () {
    this.context.fetch(this.context.api_location.index).then(data => {
      this.context.onStateUpdate({ menus: data.results });
    }).catch(() => {
      setTimeout(() => {
        this.updateMenus(); // 1秒後にリトライ
      }, 1000);
    });
  }

  /** メニューの新規作成 */
  onCreateMenu () {
    this.context.showLoading();
    this.context.fetch(this.context.api_location.new).then(data => {
      this.context.onStateUpdate({
        menu: data.result,
        tab: 1,
        isEdit: true,
        isEdited: false,
      });
      history.replaceState('', '', `${location.pathname.split('/').slice(0, -1).join('/')}/new`);
    }).finally(() => {
      this.context.hideLoading();
    });
  }

  /** 編集中アラートの表示 */
  onEditedAlert (resolve) {
    if (this.context.isEdited) {
      this.state.event.once('isEditedAlert', ok => {
        if (ok) {
          resolve();
        }
      });
      this.setState({ isEditedAlert: true });
    } else {
      resolve();
    }
  }

  onEditedAlertOk () {
    return new Promise(resolve => {
      resolve();
      setTimeout(() => {
        this.setState({ isEditedAlert: false });
        this.state.event.emit('isEditedAlert', true);
      }, 10);
    });
  }

  onEditedAlertCancel () {
    this.setState({ isEditedAlert: false });
    this.state.event.emit('isEditedAlert', false);
  }

  render () {
    const isMaxMenuCount = this.context.menus?.length >= this.maxMenuCount;
    return (<div className="p-menu_list">
      <div className="p-menu_list-head">
        <h1>診療メニュー</h1>
        <button
          className={ isMaxMenuCount ? 'c-mn-btn--disable' : 'c-mn-btn--third' }
          disabled={ isMaxMenuCount }
          onClick={ () => { this.onEditedAlert(this.onCreateMenu); } }
        >{SvgPlusIcon}<span>新規登録</span></button>
      </div>
      <div className="p-menu_list-body">
        { this.context.menus && this.context.menus.map(menu => {
          return (
            <div key={menu.id} className={this.context.menu?.id === menu.id ? 'active' : ''}>
              {this.context.menu?.id === menu.id ? (
                <div className="lh-nm">{menu.title}</div>
              ) : (
                <a onClick={ () => { this.onEditedAlert(() => { this.onGetMenu(menu.api_location.show); }); } }><div className="lh-nm">{menu.title}</div></a>
              ) }
            </div>
          );
        })}
      </div>
      <MnDialog
        isOpen={this.state.isEditedAlert}
        title="編集をキャンセルしますか？"
        btnSubmitLabel="キャンセル"
        btnCloseLabel="いいえ"
        handleSubmit={ this.onEditedAlertOk }
        handleClose={ this.onEditedAlertCancel }
      >キャンセルすると編集内容が保存されません</MnDialog>
    </div>);
  }
}
