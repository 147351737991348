export const AccountsPath = () => { return '/accounts'; };
export const AdminLoginPath = () => { return '/login'; };
export const AdminPasswordResetPath = () => { return '/password_reset'; };
export const AdminRootPath = () => { return '/'; };
export const AgoraApiV1TestsPath = () => { return '/api/v1/tests/agora'; };
export const AgoraApiV1TreatmentPath = (id) => { return `/api/v1/treatments/${id}/agora`; };
export const AgoraUserApiV1TreatmentPath = (id) => { return `/api/v1/treatments/${id}/agora`; };
export const AgreedApiV1InstitutionPath = (id) => { return `/api/v1/institutions/${id}/agreed`; };
export const ApiV1AccountPath = (id) => { return `/api/v1/accounts/${id}`; };
export const ApiV1AccountRolePath = (accountId) => { return `/api/v1/accounts/${accountId}/role`; };
export const ApiV1AccountsPath = () => { return '/api/v1/accounts'; };
export const ApiV1AdminLoginPath = () => { return '/api/v1/login'; };
export const ApiV1AdminLogoutPath = () => { return '/api/v1/logout'; };
export const ApiV1DiagnosesPath = () => { return '/api/v1/diagnoses'; };
export const ApiV1ExaminationPath = (id) => { return `/api/v1/examinations/${id}`; };
export const ApiV1ExaminationsPath = () => { return '/api/v1/examinations'; };
export const ApiV1InitialPasswordPath = () => { return '/api/v1/initial_password'; };
export const ApiV1InstitutionPath = (id) => { return `/api/v1/institutions/${id}`; };
export const ApiV1InstitutionsPath = () => { return '/api/v1/institutions'; };
export const ApiV1LoginPath = () => { return '/api/v1/login'; };
export const ApiV1LogoutPath = () => { return '/api/v1/logout'; };
export const ApiV1MenuPath = (id) => { return `/api/v1/menus/${id}`; };
export const ApiV1MenusPath = () => { return '/api/v1/menus'; };
export const ApiV1OpeningInquiriesPath = () => { return '/api/v1/opening_inquiries'; };
export const ApiV1PageSectionsPath = () => { return '/api/v1/page_sections'; };
export const ApiV1PasswordChangePath = () => { return '/api/v1/password_change'; };
export const ApiV1PasswordResetsPath = () => { return '/api/v1/password_resets'; };
export const ApiV1RequestStopPath = (id) => { return `/api/v1/request_stops/${id}`; };
export const ApiV1StaffSessionsPath = () => { return '/api/v1/staff_sessions'; };
export const ApiV1TreatmentInterviewPath = (treatmentId) => { return `/api/v1/treatments/${treatmentId}/interview`; };
export const ApiV1TreatmentInvoicePath = (treatmentId) => { return `/api/v1/treatments/${treatmentId}/invoice`; };
export const ApiV1TreatmentPath = (id) => { return `/api/v1/treatments/${id}`; };
export const ApiV1TreatmentsPath = () => { return '/api/v1/external/treatments'; };
export const ApiV1UploadFilePath = (code) => { return `/api/v1/upload_files/${code}`; };
export const ClientInstitutionPath = () => { return '/institution'; };
export const ClientInstitutionStripeAccountsPath = () => { return '/institution/stripe_accounts'; };
export const ClientIntroductionPath = () => { return '/introduction'; };
export const ClientLoginPath = () => { return '/login'; };
export const ClientRootPath = () => { return '/'; };
export const CompleteInstitutionMenuTreatmentExaminationsPath = (institutionId, menuId, treatmentId) => { return `/institutions/${institutionId}/menus/${menuId}/treatments/${treatmentId}/examinations/complete`; };
export const CompleteInstitutionMenuTreatmentPath = (institutionId, menuId, id) => { return `/institutions/${institutionId}/menus/${menuId}/treatments/${id}/complete`; };
export const CountApiV1TreatmentsPath = () => { return '/api/v1/treatments/count'; };
export const DevDevRootPath = () => { return '/dev'; };
export const EditApiV1TreatmentPath = (id) => { return `/api/v1/external/treatments/${id}/edit`; };
export const EditPageSectionsPath = () => { return '/page_sections/edit'; };
export const ExitInstitutionMenuTreatmentExaminationsPath = (institutionId, menuId, treatmentId) => { return `/institutions/${institutionId}/menus/${menuId}/treatments/${treatmentId}/examinations/exit`; };
export const ExitInstitutionMenuTreatmentPath = (institutionId, menuId, id) => { return `/institutions/${institutionId}/menus/${menuId}/treatments/${id}/exit`; };
export const FinishedApiV1InstitutionPath = (id) => { return `/api/v1/institutions/${id}/finished`; };
export const InstitutionMenuPath = (institutionId, id) => { return `/institutions/${institutionId}/menus/${id}`; };
export const InstitutionMenuTreatmentExaminationPath = (institutionId, menuId, treatmentId, id) => { return `/institutions/${institutionId}/menus/${menuId}/treatments/${treatmentId}/examinations/${id}`; };
export const InstitutionMenuTreatmentsPath = (institutionId, menuId) => { return `/institutions/${institutionId}/menus/${menuId}/treatments`; };
export const InstitutionMenusPath = (institutionId) => { return `/institutions/${institutionId}/menus`; };
export const InstitutionPath = (id) => { return `/institutions/${id}`; };
export const InstitutionsPath = () => { return '/institutions'; };
export const InterviewInstitutionMenuTreatmentPath = (institutionId, menuId, id) => { return `/institutions/${institutionId}/menus/${menuId}/treatments/${id}/interview`; };
export const InterviewUserApiV1TreatmentPath = (id) => { return `/api/v1/treatments/${id}/interview`; };
export const LetterOpenerWebPath = () => { return '/letter_opener'; };
export const NewAdminInitialPasswordPath = () => { return '/initial_password'; };
export const NewApiV1MenuPath = () => { return '/api/v1/menus/new'; };
export const NewApiV1TreatmentPath = () => { return '/api/v1/external/treatments/new'; };
export const NewInitialPasswordPath = () => { return '/initial_password/new'; };
export const NewInstitutionMenuTreatmentPath = (institutionId, menuId) => { return `/institutions/${institutionId}/menus/${menuId}/treatments/new`; };
export const NewPasswordResetPath = () => { return '/password_resets/new'; };
export const NewWorkerSessionPath = () => { return '/worker_sessions/new'; };
export const PasswordApiV1AccountPath = (id) => { return `/api/v1/accounts/${id}/password`; };
export const PasswordChangePath = () => { return '/password_change'; };
export const PostalCodeApiV1InstitutionsPath = () => { return '/api/v1/institutions/postal_code'; };
export const PostalCodeUserApiV1TreatmentsPath = () => { return '/api/v1/treatments/postal_code'; };
export const RefundApiV1TreatmentInvoicePath = (treatmentId) => { return `/api/v1/treatments/${treatmentId}/invoice/refund`; };
export const SearchApiV1TreatmentsPath = () => { return '/api/v1/treatments/search'; };
export const SettingsMenuPath = (id) => { return `/settings/menus/${id}`; };
export const SettingsMenusPath = () => { return '/settings/menus'; };
export const SettingsPath = () => { return '/settings'; };
export const SpecifiedCommercialTransactionsLawInstitutionsPath = () => { return '/institutions/specified_commercial_transactions_law'; };
export const StartApiV1RequestStopPath = (id) => { return `/api/v1/request_stops/${id}/start`; };
export const StopApiV1RequestStopPath = (id) => { return `/api/v1/request_stops/${id}/stop`; };
export const TermsInstitutionsPath = () => { return '/institutions/terms'; };
export const RegionalMedicalCooperationTermsPath = () => { return '/institutions/regional_medical_cooperation_terms'; };
export const TreatmentPath = (id) => { return `/treatments/${id}`; };
export const TreatmentUserApiV1InstitutionMenuPath = (institutionId, id) => { return `/api/v1/institutions/${institutionId}/menus/${id}/treatment`; };
export const TreatmentsPath = () => { return '/treatments'; };
export const TwilioCallbackLogApiV1TreatmentPath = (id) => { return `/api/v1/external/treatments/${id}/twilio_callback_log`; };
export const UserApiV1InstitutionMenuPath = (institutionId, id) => { return `/api/v1/institutions/${institutionId}/menus/${id}`; };
export const UserApiV1InstitutionMenusPath = (institutionId) => { return `/api/v1/institutions/${institutionId}/menus`; };
export const UserApiV1InstitutionPath = (id) => { return `/api/v1/institutions/${id}`; };
export const UserApiV1InstitutionUploadFilePath = (institutionId, id) => { return `/api/v1/institutions/${institutionId}/upload_files/${id}`; };
export const UserApiV1InstitutionUploadFilesPath = (institutionId) => { return `/api/v1/institutions/${institutionId}/upload_files`; };
export const UserApiV1StripeClientSecretResetPath = () => { return '/api/v1/stripe_client_secret_reset'; };
export const UserApiV1TreatmentPath = (id) => { return `/api/v1/treatments/${id}`; };
export const UserApiV1TreatmentsPath = () => { return '/api/v1/treatments'; };
export const UserRootPath = () => { return '/'; };
export const VideoInstitutionMenuTreatmentPath = (institutionId, menuId, id) => { return `/institutions/${institutionId}/menus/${menuId}/treatments/${id}/video`; };
export const VideoTestsPath = () => { return '/tests/video'; };
export const WorkerSessionPath = (id) => { return `/worker_sessions/${id}`; };
export const WorkerSessionsPath = () => { return '/worker_sessions'; };
