import * as React from 'react';
import PropTypes from 'prop-types';
import { RootContext, MenusAcceptablePeriodsI18n, SlotMinutesI18n } from '../../../index';
import Modal from 'react-modal';

export default class ModalDatetimeRequest extends React.Component {
  static contextType = RootContext;

  constructor (props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangeAcceptablePeriod = this.onChangeAcceptablePeriod.bind(this);
    this.onChangeSlotMinute = this.onChangeSlotMinute.bind(this);
    this.CheckCellInCol = this.CheckCellInCol.bind(this);
    this.ChangeBackgroundOfCell = this.ChangeBackgroundOfCell.bind(this);
    this.UndoBackgroundOfCell = this.UndoBackgroundOfCell.bind(this);
    this.buildStartTimes = this.buildStartTimes.bind(this);
    this.dates = this.props.timeTable;
    this.state = {
      datesList: this.dates,
      hoverdRow: null,
      hoverdCol: null,
    };
  }

  closeModal () {
    this.props.closeModal(this.state.datesList);
  }

  onChange (e) {
    const dayOfWeek = parseInt(e.target.value[0] - 1);
    const index = e.target.getAttribute('data-index');
    this.dates[dayOfWeek][index].checked = !this.dates[dayOfWeek][index].checked;
    this.setState({
      datesList: this.dates,
    });
  }

  onChangeAcceptablePeriod (e) {
    const menu = this.context.menu;
    menu.acceptable_period = e.target.value;
    this.context.onStateUpdate({ menu: menu, isEdited: true });
  }

  onChangeSlotMinute (e) {
    const menu = this.context.menu;
    menu.slot_minute = Number(e.target.value);
    this.context.onStateUpdate({ menu: menu, isEdited: true });
    this.dates = this.props.datesInitializer(menu.slot_minute);
    this.setState({
      datesList: this.dates,
    });
  }

  CheckCellInCol (num) {
    return [...Array(this.state.datesList[num].length).keys()].some(index => this.state.datesList[num][index].checked);
  }

  ChangeBackgroundOfCell (e) {
    const row = parseInt(e.target.getAttribute('data-row'));
    const col = parseInt(e.target.getAttribute('data-col'));
    this.setState({
      hoverdRow: row,
    });
    this.setState({
      hoverdCol: col,
    });
  }

  UndoBackgroundOfCell () {
    this.setState({
      hoverdRow: null,
      hoverdCol: null,
    });
  }

  buildStartTimes () {
    const slotMinute = this.context.menu.slot_minute;
    const slotNum = Math.floor((24 * 60) / slotMinute);
    const firstDate = new Date();
    firstDate.setHours(0, 0, 0, 0);
    return new Array(slotNum).fill().map((_, index) => {
      const datetime = new Date(firstDate.getTime() + index * (slotMinute * 60000));
      return `${datetime.getHours().toString()}:${datetime.getMinutes().toString().padStart(2, '0')}`;
    });
  }

  render () {
    return (
      <Modal isOpen={ this.props.isOpen } className={ 'datetime_request-modal ol-n' } overlayClassName={ 'c-mn-modal is-active--dialog' } >
        <p className="datetime_request-modal-title">リクエスト受付可能な曜日・時間設定</p>
        <button className="datetime_request-modal-close-button" onClick= { this.closeModal }>
          <svg width="20" height="20" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 0C3.13425 0 0 3.13425 0 7C0 10.8657 3.13425 14 7 14C10.8657 14 14 10.8657 14 7C14 3.13425 10.8657 0 7 0ZM9.42142 10.4668L7.00467 8.07392L4.60308 10.5L3.53383 9.43075L5.92783 7.00583L3.5 4.60308L4.56925 3.53383L6.993 5.92667L9.38817 3.5L10.4668 4.57858L8.07508 6.99417L10.5 9.38817L9.42142 10.4668Z" fill="#999999"/></svg>
        </button>
        <div className="datetime_request-modal-select">
          <div className="datetime_request-modal-select-item">
            <span className="datetime_request-modal-select-item-start">受付開始</span>
            <select name="acceptable_period" onChange={this.onChangeAcceptablePeriod} value={this.context.menu.acceptable_period}>
              <option value="">選択してください</option>
              { MenusAcceptablePeriodsI18n.map(data => {
                return (<option key={data.key} value={data.key}>{data.value}</option>);
              })}
            </select>
            <span className="datetime_request-modal-select-item-before">から受付</span>
          </div>
          <div className="datetime_request-modal-select-item">
            <span className="datetime_request-modal-select-item-start">予約枠</span>
            <select name="slot_minute" onChange={this.onChangeSlotMinute} value={this.context.menu.slot_minute}>
              { SlotMinutesI18n.map(data => { return (<option key={data.key} value={data.key}>{data.value}</option>); }) }
            </select>
            <span className="datetime_request-modal-select-item-before">単位で受付</span>
          </div>
        </div>
        <table className="datetime_request-modal-table-head">
          <thead>
            <tr>
              <th className="day-of-week-td"></th>
              <th className={ (this.CheckCellInCol(0) ? 'day-of-week-td_checked' : 'day-of-week-td') + (this.state.hoverdCol === 0 ? '_hover' : '') }>月</th>
              <th className={ (this.CheckCellInCol(1) ? 'day-of-week-td_checked' : 'day-of-week-td') + (this.state.hoverdCol === 1 ? '_hover' : '') }>火</th>
              <th className={ (this.CheckCellInCol(2) ? 'day-of-week-td_checked' : 'day-of-week-td') + (this.state.hoverdCol === 2 ? '_hover' : '') }>水</th>
              <th className={ (this.CheckCellInCol(3) ? 'day-of-week-td_checked' : 'day-of-week-td') + (this.state.hoverdCol === 3 ? '_hover' : '') }>木</th>
              <th className={ (this.CheckCellInCol(4) ? 'day-of-week-td_checked' : 'day-of-week-td') + (this.state.hoverdCol === 4 ? '_hover' : '') }>金</th>
              <th className={ (this.CheckCellInCol(5) ? 'day-of-week-td_checked' : 'day-of-week-td') + (this.state.hoverdCol === 5 ? '_hover' : '') }>土</th>
              <th className={ (this.CheckCellInCol(6) ? 'day-of-week-td_checked' : 'day-of-week-td') + (this.state.hoverdCol === 6 ? '_hover' : '') }>日</th>
            </tr>
          </thead>
        </table>
        <div className="datetime_request-modal-table-content-wrap">
          <table className="datetime_request-modal-table-content">
            <tbody>
              {
                this.buildStartTimes().map((startTime, startTimeIndex) => {
                  return (
                    <tr key={startTimeIndex}>
                      <th className={ this.state.hoverdRow === startTimeIndex ? 'content-th_hover' : 'content-th'}>{startTime}</th>
                      {
                        [0, 1, 2, 3, 4, 5, 6].map((num) => {
                          return (
                            <td
                              className={ this.state.datesList[num][startTimeIndex].checked ? 'content-td_checked' : 'content-td' }
                              key={num}
                              onMouseEnter={this.ChangeBackgroundOfCell}
                              onMouseLeave={this.UndoBackgroundOfCell}
                              data-row={startTimeIndex}
                              data-col={num}
                            >
                              <label>
                                <input
                                  type="checkbox"
                                  className="checkbox-input"
                                  value={this.state.datesList[num][startTimeIndex].time}
                                  checked={this.state.datesList[num][startTimeIndex].checked}
                                  data-index={startTimeIndex}
                                  onChange={ this.onChange }
                                ></input>
                                <span></span>
                              </label>
                            </td>
                          );
                        })
                      }
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </Modal>
    );
  }
}

ModalDatetimeRequest.propTypes = {
  isOpen: PropTypes.bool,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  timeTable: PropTypes.array,
  datesInitializer: PropTypes.func,
};
