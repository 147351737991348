import * as React from 'react';
import * as PropTypes from 'prop-types';
import Modal from 'react-modal';
import {
  AbstractForm,
  RootContext,
  MnInputText,
  MnButton,
  AccountValidators,
} from '../../../index';

export default class PasswordModal extends AbstractForm {
  static contextType = RootContext;

  passwordValidator = { matches: { validate: [/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$&*_-])[a-zA-Z\d!@#$&*_-]{10,32}$/], message: '不正な値です' } };

  constructor (props) {
    super(props);
    this.state = {
      user: {
        ...props.user,
        current_password: '',
        password: '',
        password_confirmation: '',
      },
      cancel: false,
      edited: false,
      errors: {},
    };
  }

  onSuccess (resolve) {
    this.context.showLoading();
    this.context.fetch(this.state.user.api_location.password, {
      methods: 'PATCH',
      body: {
        account: {
          current_password: this.state.user.current_password,
          password: this.state.user.password,
          password_confirmation: this.state.user.password_confirmation,
        },
      },
    }).then(data => {
      this.context.onStateUpdate({ user: data.result });
      setTimeout(() => {
        this.props.onClose();
        this.props.onSave();
      });
    }).catch(err => {
      if (err.status === 400) {
        err.json().then(body => {
          this.setState({ errors: body.errors });
        });
      } else {
        this.context.onStateUpdate({ alert: { shown: true } });
      }
    }).finally(() => {
      this.context.hideLoading();
      resolve();
    });
  }

  render () {
    const isRequireChangePassword = this.context.user && this.context.user.require_change_password;

    return (
      <Modal isOpen={ this.props.showModal } className="p-account-create-modal c-mn-modal-popup is-active has-background-white ol-n" overlayClassName="c-mn-modal is-active--dialog" >
        <span onClick={ () => {
          if (this.state.edited) {
            this.setState({ cancel: true });
          } else {
            this.props.onClose();
          }
        } } className={ 'c-mn-popup__close' + (isRequireChangePassword ? ' d-n' : '')}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="c-mn-icon-close" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="12" fill="#fff" className="c-mn-icon-close-x"></circle><path d="M12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C24 5.373 18.627 0 12 0ZM16.151 17.943L12.008 13.841L7.891 18L6.058 16.167L10.162 12.01L6 7.891L7.833 6.058L11.988 10.16L16.094 6L17.943 7.849L13.843 11.99L18 16.094L16.151 17.943Z" fill="#000" className="c-mn-icon-close-circle"></path><circle cx="12" cy="12" r="11.5" stroke="#000" className="c-mn-icon-close-flame"></circle></svg>
        </span>

        <div className="c-mn-modal-popup__header">
          <div className="my-sm">
            パスワード変更
          </div>
        </div>

        <div className="c-mn-modal-popup__contents-wrap">
          <div className="c-mn-modal-popup__contents ov-h">
            <div>
              { isRequireChangePassword && (
                <p className="maw420">パスワードの有効期限が切れました。パスワードを変更してください。</p>
              ) }
              <p className="maw420">パスワードは、数字、英字大小文字、記号( !@#$&*_- ) をそれぞれ１文字以上含む、１０文字以上で設定してください。</p>
            </div>
            <div className="mt-12px">
              <MnInputText placeholder="現在のパスワードを入力"
                name='current_password'
                password={true}
                value={ this.state.user.current_password }
                validates={ { ...AccountValidators.password, ...this.passwordValidator } }
                onValidate={ (e) => { this.validators = [...this.validators, e]; } }
                onChange={ (e) => { this.setState({ edited: true, user: { ...this.state.user, current_password: e.value } }); } }
                errors={ this.state.errors.current_password }
              />
            </div>
            <div className="mt-12px">
              <MnInputText placeholder="新しいパスワードを入力"
                name='password'
                password={true}
                value={ this.state.user.password }
                validates={ { ...AccountValidators.password, ...this.passwordValidator } }
                onValidate={ (e) => { this.validators = [...this.validators, e]; } }
                onChange={ (e) => { this.setState({ edited: true, user: { ...this.state.user, password: e.value } }); } }
                errors={ this.state.errors.password }
              />
            </div>
            <div className="mt-12px">
              <MnInputText placeholder="新しいパスワードを入力（確認用）"
                name='password_confirmation'
                password={true}
                value={ this.state.user.password_confirmation }
                validates={ { ...AccountValidators.password, ...this.passwordValidator } }
                onValidate={ (e) => { this.validators = [...this.validators, e]; } }
                onChange={ (e) => { this.setState({ edited: true, user: { ...this.state.user, password_confirmation: e.value } }); } }
                errors={ this.state.errors.password_confirmation }
              />
            </div>

            <div className="my-lg">
              <MnButton class="c-mn-btn c-mn-btn--basic-s c-mn-btn--first m0-a maw200px" onClick={ this.onSave }>
                <span>変更する</span>
              </MnButton>
            </div>
            <Modal
              isOpen={this.state.cancel}
              className="c-mn-modal-dialog is-active"
              overlayClassName="c-mn-modal is-active--dialog"
            >
              <p className="c-mn-modal-dialog__title">パスワードの変更をキャンセルしますか？</p>
              <div className="c-mn-modal-dialog__txt">キャンセルすると編集した内容は破棄されます。</div>
              <div className="c-mn-modal-dialog__btn-wrap">
                <a className="c-mn-btn c-mn-btn--basic-s c-mn-btn--third c-mn-btn--compact js-modal-dialog02-close" onClick={ () => { this.setState({ cancel: false }); } }>
                  <span>いいえ</span>
                </a>
                <a className="c-mn-btn c-mn-btn--basic-s c-mn-btn--first c-mn-btn--compact js-modal-dialog02-close" onClick={ () => {
                  this.setState({
                    cancel: false,
                    edited: false,
                    user: {
                      ...this.props.user,
                      current_password: '',
                      password: '',
                      password_confirmation: '',
                    },
                  });
                  this.props.onClose();
                } }>
                  <span>キャンセルする</span>
                </a>
              </div>
            </Modal>
          </div>
        </div>
      </Modal>
    );
  }
}

PasswordModal.propTypes = {
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  user: PropTypes.object,
};
