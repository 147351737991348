import * as React from 'react';
import PropTypes from 'prop-types';
import AbstractMnInput from './AbstractMnInput';
import MnPopup from './MnPopup';
import { KanaConvert } from '../../index';

/** 文字入力フォーム */
export default class MnInputText extends AbstractMnInput {
  constructor (props) {
    super(props);

    this.onBlur = this.onBlur.bind(this);
  }

  onBlur () {
    if (this.props.convertKana) {
      const value = this.sanitizers(KanaConvert(this.state.value));
      this.setState({ value: value });

      this.validate(this.state.value).then(() => {
        this.setState({ errors: undefined });
      }).catch(err => {
        this.setState({ errors: err });
        this.props.onError({ name: this.props.name, errors: err });
      });
    }
    this.props.onBlur();
  }

  render () {
    return (
      <MnPopup annotation={this.state.annotation} closeIcon={this.renderIconClose} hideAnnotation={this.hideAnnotation}>
        <div className="pos-r">
          <input type={ this.props.password ? 'password' : 'text'}
            className={this.props.class ? this.props.class : 'c-mn-input-text'}
            value={this.state.value || ''}
            id={this.props.name || ''}
            name={this.props.name || ''}
            placeholder={this.props.placeholder ? this.props.placeholder : ''}
            onChange={ this.onChange }
            onFocus={ this.props.onFocus }
            onBlur={ this.onBlur }
            disabled={this.props.disabled}
            readOnly={this.props.readonly}
          />
          { this.props.children }
          { this.renderErrors() }
        </div>
      </MnPopup>
    );
  }
}

MnInputText.propTypes = {
  ...AbstractMnInput.propTypes, // 基底クラスのpropTypesを継承
  /** input後に表示したいDOM要素 */
  children: PropTypes.object,
  /** パスワード表示 */
  password: PropTypes.bool,
  /** カナ変換 */
  convertKana: PropTypes.bool,
  /** リードオンリー */
  readonly: PropTypes.bool,
};

MnInputText.defaultProps = {
  ...AbstractMnInput.defaultProps,
  password: false,
  convertKana: false,
  readonly: false,
};
