import * as React from 'react';
import PropTypes from 'prop-types';
import SimpleFormat from '@16g/react-simple-format';

export default class MnInlineMessage extends React.Component {
  renderErrorIcon () {
    return (<svg width="19" height="20" viewBox="0 0 19 20" fill="none" className="c-mn-icon-error" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M9.77995 20C14.8426 20 18.9466 15.8959 18.9466 10.8333C18.9466 5.77068 14.8426 1.66663 9.77995 1.66663C4.71734 1.66663 0.613281 5.77068 0.613281 10.8333C0.613281 15.8959 4.71734 20 9.77995 20ZM10.7745 12.8867H9.33445L9.01945 7.35168L8.94445 5.26668H11.1645L11.0895 7.35168L10.7745 12.8867ZM11.4345 15.4367C11.4345 16.2467 10.8345 16.8767 10.0545 16.8767C9.27445 16.8767 8.68945 16.2467 8.68945 15.4367C8.68945 14.6117 9.27445 13.9967 10.0545 13.9967C10.8345 13.9967 11.4345 14.6117 11.4345 15.4367Z" fill="#000" className="c-mn-icon-error-path"></path></svg>);
  }

  renderValidationError (messages) {
    return (<div>
      { messages.map((message, index) => {
        return (<p key={index} className="c-mn-form-caption c-mn-form-caption--error">{this.renderErrorIcon()}{message}</p>);
      })}
    </div>);
  }

  renderError (messages) {
    return (<ul className="c-mn-message--error">
      {messages.map((message, index) => {
        return (<li key={index} className="c-mn-message__list">{this.renderErrorIcon()}{message || message.title}</li>);
      })}
    </ul>);
  }

  renderNoticeIcon () {
    return (<svg width="12" height="12" viewBox="0 0 12 12" fill="none" className="c-mn-icon-error02" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6ZM12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6ZM6.53395 9.74995H5.21095V4.70995H6.53395V9.74995ZM6.64195 3.17095C6.64195 3.61195 6.32695 3.89995 5.87695 3.89995C5.41795 3.89995 5.10295 3.61195 5.10295 3.17095C5.10295 2.73895 5.41795 2.45095 5.87695 2.45095C6.32695 2.45095 6.64195 2.73895 6.64195 3.17095Z" fill="#000" className="c-mn-icon-error2-path"></path></svg>);
  }

  renderArrowIcon () {
    return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.21 21.36" className="c-mn-icon-arrow"><path d="M1.5,21.36A1.49,1.49,0,0,1,0,19.86,1.47,1.47,0,0,1,.45,18.8l8.26-8.12L.45,2.57A1.47,1.47,0,0,1,0,1.51,1.46,1.46,0,0,1,.44.44,1.5,1.5,0,0,1,1.5,0a1.47,1.47,0,0,1,1,.43l9.13,9a1.74,1.74,0,0,1,.53,1.25,1.72,1.72,0,0,1-.53,1.24l-9.13,9A1.47,1.47,0,0,1,1.5,21.36Z" className="c-mn-icon-arrow-path"></path></svg>);
  }

  renderNotice (messages) {
    return this.props.mode.split('--')[1] === 'noicon' ? (
      <dl className={ `c-mn-message--${this.props.mode.split('--')[0]}` }>
        <dt className="c-mn-message__title m0">{['notice', 'notice-middle'].indexOf(this.props.mode) !== -1 ? this.renderNoticeIcon() : null }{messages}</dt>
      </dl>
    ) : messages.length > 1 ? (
      <dl className={ `c-mn-message--${this.props.mode.split('--')[0]}` }>
        <dt className="c-mn-message__title">{['notice', 'notice-middle'].indexOf(this.props.mode) !== -1 ? this.renderNoticeIcon() : null }{messages[0]}</dt>
        <dd className="c-mn-message__txt"><SimpleFormat text={ messages[1] }/></dd>
        {this.props.children}
      </dl>
    ) : (
      <p className={ `c-mn-message--${this.props.mode}` }><SimpleFormat text={ messages[0] }/></p>
    );
  }

  renderLink (messages) {
    const mode = this.props.mode.split('--')[0];
    return messages.length > 1 ? (
      <a className="c-mn-message__link" onClick={ this.props.onClick }>
        <dl className={ `c-mn-message--${mode}` }>
          <dt className="c-mn-message__title">{this.renderNoticeIcon()}{messages[0]}</dt>
          <dd className="c-mn-message__txt"><SimpleFormat text={ messages[1] }/></dd>
        </dl>
        {this.renderArrowIcon()}
      </a>
    ) : (
      <p className="c-mn-message--disclaimer"><SimpleFormat text={ messages[0] }/></p>
    );
  }

  render () {
    const messages = (this.props.messages instanceof Array) ? this.props.messages : [this.props.messages.toString()];
    const mode = this.props.mode || 'error';
    return (<div className={this.props.class ? this.props.class : '' }>
      { mode === 'validation' ? this.renderValidationError(messages) : null }
      { mode === 'error' ? this.renderError(messages) : null }
      { mode === 'disclaimer' ? this.renderNotice(messages) : null }
      { mode === 'caution' ? this.renderNotice(messages) : null }
      { mode === 'notice' ? this.renderNotice(messages) : null }
      { mode === 'notice-middle' ? this.renderNotice(messages) : null }
      { mode === 'notice-middle--noicon' ? this.renderNotice(messages) : null }
      { mode === 'notice--link' ? this.renderLink(messages) : null }
      { mode === 'notice-middle--link' ? this.renderLink(messages) : null }
    </div>);
  }
}

MnInlineMessage.propTypes = {
  children: PropTypes.any,
  class: PropTypes.string, // クラス名
  mode: PropTypes.string, // 表示モード
  messages: PropTypes.any, // メッセージリスト
  onClick: PropTypes.func, // コールバック
};
