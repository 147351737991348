import * as React from 'react';
import * as PropTypes from 'prop-types';
import Modal from 'react-modal';
import {
  AbstractForm,
  RootContext,
  MnInputText,
  MnInputSelect,
  MnButton,
  AccountsRolesI18n,
  AccountValidators,
} from '../../../index';

export default class NewAccountModal extends AbstractForm {
  static contextType = RootContext;

  kanaValidator = { matches: { validate: [/^[ぁ-んァ-ン]+$/], message: '不正な値です' } };

  constructor (props) {
    super(props);
    this.state = {
      account: { ...props.account },
      errors: {},
    };
  }

  onSuccess (resolve) {
    this.context.fetch(this.state.account.api_location.create, {
      methods: 'POST',
      body: { account: this.state.account },
    }).then(data => {
      this.setState({ account: this.props.account, errors: {} });
      setTimeout(() => {
        this.props.onSave(data.result);
      });
    }).catch(err => {
      if (err.status === 400) {
        err.json().then(body => {
          this.setState({ errors: body.errors });
        });
      } else {
        this.context.onStateUpdate({ alert: { shown: true } });
      }
    }).finally(() => {
      this.context.hideLoading();
      resolve();
    });
  }

  render () {
    return (
      <Modal isOpen={ this.props.showModal } className={ 'p-account-create-modal c-mn-modal-popup is-active has-background-white ol-n' } overlayClassName={ 'c-mn-modal is-active--dialog' } >
        <span onClick={ this.props.onClose } className="c-mn-popup__close">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="c-mn-icon-close" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="12" fill="#fff" className="c-mn-icon-close-x"></circle><path d="M12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C24 5.373 18.627 0 12 0ZM16.151 17.943L12.008 13.841L7.891 18L6.058 16.167L10.162 12.01L6 7.891L7.833 6.058L11.988 10.16L16.094 6L17.943 7.849L13.843 11.99L18 16.094L16.151 17.943Z" fill="#000" className="c-mn-icon-close-circle"></path><circle cx="12" cy="12" r="11.5" stroke="#000" className="c-mn-icon-close-flame"></circle></svg>
        </span>

        <div className={ 'c-mn-modal-popup__header' }>
          <div className={ 'my-sm' }>
            アカウント新規発行
          </div>
        </div>

        <div className="c-mn-modal-popup__contents-wrap">
          <div className="c-mn-modal-popup__contents ov-h">
            <div className={ 'mb-lg' }>
              <label className={ 'c-mn-form-label' }>権限</label>
              <div className="w100p">
                <MnInputSelect
                  name='role'
                  value={ this.state.account.role }
                  hideBlank={ true }
                  groups= { AccountsRolesI18n }
                  onChange={ (e) => { this.setState({ account: { ...this.state.account, role: e.value } }); } }
                />
              </div>
            </div>

            <div className={ 'my-lg' }>
              <label className={ 'c-mn-form-label' }>氏名</label>
              <div className={ 'columns is-mobile'}>
                <div className={ 'column'}>
                  <MnInputText placeholder="姓"
                    name='family_name'
                    value={ this.state.account.family_name }
                    validates={ AccountValidators.family_name }
                    onValidate={ this.onValidate }
                    onChange={ (e) => { this.setState({ account: { ...this.state.account, family_name: e.value } }); } }
                  />
                </div>
                <div className={ 'column'}>
                  <MnInputText placeholder="名"
                    name='given_name'
                    value={ this.state.account.given_name }
                    validates={ AccountValidators.given_name }
                    onValidate={ this.onValidate }
                    onChange={ (e) => { this.setState({ account: { ...this.state.account, given_name: e.value } }); } }
                  />
                </div>
              </div>
            </div>

            <div className={ 'my-lg' }>
              <label className={ 'c-mn-form-label' }>氏名(カナ)</label>
              <div className={ 'columns is-mobile'}>
                <div className={ 'column'}>
                  <MnInputText placeholder="セイ"
                    name='family_name_kana'
                    value={ this.state.account.family_name_kana }
                    convertKana={true}
                    validates={ { ...AccountValidators.family_name_kana, ...this.kanaValidator } }
                    onValidate={ this.onValidate }
                    onChange={ (e) => { this.setState({ account: { ...this.state.account, family_name_kana: e.value } }); } }
                  />
                </div>
                <div className={ 'column'}>
                  <MnInputText placeholder="メイ"
                    name='given_name_kana'
                    value={ this.state.account.given_name_kana }
                    convertKana={true}
                    validates={ { ...AccountValidators.given_name_kana, ...this.kanaValidator } }
                    onValidate={ this.onValidate }
                    onChange={ (e) => { this.setState({ account: { ...this.state.account, given_name_kana: e.value } }); } }
                  />
                </div>
              </div>
            </div>

            <div className={ 'my-lg' }>
              <label className={ 'c-mn-form-label' }>メールアドレス</label>
              <MnInputText
                class="miw100p"
                placeholder="例) sample@medicalnote.co.jp"
                name='email'
                value={ this.state.account.email }
                validates={ AccountValidators.email }
                onValidate={ this.onValidate }
                onChange={ (e) => { this.setState({ account: { ...this.state.account, email: e.value } }); } }
                errors={ this.state.errors.email }
              />
            </div>

            <div className={ 'mt-lg' }>

              <MnButton class="c-mn-btn c-mn-btn--basic-s c-mn-btn--first m0-a maw200px" onClick={ this.onSave }>
                <span>発行する</span>
              </MnButton>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

NewAccountModal.propTypes = {
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  account: PropTypes.object,
};
