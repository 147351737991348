import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import SimpleFormat from '@16g/react-simple-format';
import MnToast from './MnToast';
import ReactMarkdown from 'react-markdown/with-html';

/**
 * AbstractComponent: 各コンポーネントで使用できるメソッドを備えた汎用的な基底クラス
 */
export default class AbstractComponent extends React.Component {
  constructor (props) {
    super(props);
    // 各関数をバインド
    this.fetch = this.fetch.bind(this);
    this.renderModal = this.renderModal.bind(this);
    // モーダルのセットアップ
    Modal.setAppElement('body');
  }

  /**
   * APIコール
   */
  fetch (url, params = {}) {
    let csrf = this.state.csrf;
    if (!this.state.csrf) {
      csrf = document.querySelector('meta[name=csrf-token]')?.content;
      this.setState({ csrf: csrf });
    }
    const header = {
      'Content-Type': 'application/json; charset=utf-8',
      'X-CSRF-TOKEN': csrf,
    };
    let body = params.body ? JSON.stringify(this.trimQuery(params.body)) : params.body;
    // ファイルアップロード
    if (params.file) {
      body = new FormData();
      body.append('file', params.file);
      delete header['Content-Type'];
    }
    return fetch(url, {
      method: params.methods ? params.methods : 'GET',
      headers: header,
      body: body,
    }).then(data => {
      // 正常系はbodyを返却
      if ([200, 201].indexOf(data.status) !== -1) {
        return data.json();
      }
      // その他のレスポンス
      return new Promise((resolve, reject) => {
        // 正常かつcontentが存在しない系
        if ([204].indexOf(data.status) !== -1) {
          return resolve(data);
        }
        // 認証エラー
        if (data.status === 403) {
          const div = document.createElement('div');
          document.body.appendChild(div);
          ReactDOM.render(<Modal
            isOpen={ true }
            className="c-mn-modal-dialog is-active"
            overlayClassName="c-mn-modal is-active--dialog"
          >
            <p className="c-mn-modal-dialog__title">通信でエラーが発生しました</p>
            <div className="c-mn-modal-dialog__txt">画面のリロードを実施します。</div>
            <div className="c-mn-modal-dialog__btn-wrap">
              <a className="c-mn-btn c-mn-btn--basic-s c-mn-btn--first c-mn-btn--compact js-modal-dialog02-close" onClick={ () => {
                location.reload();
              } }>
                <span>再読み込み</span>
              </a>
            </div>
          </Modal>, div);
          this.hideLoading();
          return;
        }
        // メンテナンス中
        if (data.status === 503) {
          const div = document.createElement('div');
          document.body.appendChild(div);
          ReactDOM.render(<Modal
            isOpen={ true }
            className="c-mn-modal-dialog is-active"
            overlayClassName="c-mn-modal is-active--dialog"
          >
            <p className="c-mn-modal-dialog__title">ただいまメンテナンス中です</p>
            <div className="c-mn-modal-dialog__btn-wrap">
              <a className="c-mn-btn c-mn-btn--basic-s c-mn-btn--first c-mn-btn--compact js-modal-dialog02-close" onClick={ () => {
                location.reload();
              } }>
                <span>再読み込み</span>
              </a>
            </div>
          </Modal>, div);
          this.hideLoading();
          return;
        }
        // 異常系はレスポンスを返却
        reject(data);
      });
    });
  }

  // リクエストパラメータから不要なキーを削除する
  trimQuery (params) {
    // 配列は別に処理する
    if (params instanceof Array) {
      return params.map(data => {
        return this.trimQuery(data);
      });
    }
    const query = {};
    Object.keys(params).map(key => {
      if (key.indexOf('i18n') !== -1 || ['api_location', 'url_location'].indexOf(key) !== -1) {
        return;
      }
      if (params[key] instanceof Array) {
        query[key] = params[key];
      } else if (params[key] instanceof Object) {
        query[key] = this.trimQuery(params[key]);
      } else {
        query[key] = params[key];
      }
    });
    return query;
  }

  renderModal (key, modal = {
    title: '',
    body: '',
    resolve: () => {},
    reject: () => {},
  }, option = {}) {
    option = { ...{ showOK: true, showCancel: true, ok: 'OK', cancel: 'キャンセル', classOK: '', classCancel: '' }, ...option };
    return (<Modal
      isOpen={this.state[key]}
      className="c-mn-modal-dialog is-active"
      overlayClassName="c-mn-modal is-active--dialog"
    >
      <p className="c-mn-modal-dialog__title">{modal.title}</p>
      <div className="c-mn-modal-dialog__txt">{this.formatSimple(modal.body)}</div>
      <div className="c-mn-modal-dialog__btn-wrap">
        { option.showCancel ? (<a className={ option.classCancel ? option.classCancel : 'c-mn-btn c-mn-btn--basic-s c-mn-btn--third c-mn-btn--compact js-modal-dialog02-close'} onClick={modal.reject}>
          <span>{option.cancel}</span>
        </a>) : '' }
        { option.showOK ? (<a className={ option.classOK ? option.classOK : 'c-mn-btn c-mn-btn--basic-s c-mn-btn--first c-mn-btn--compact js-modal-dialog02-close'} onClick={modal.resolve}>
          <span>{option.ok}</span>
        </a>) : '' }
      </div>
    </Modal>);
  }

  formatSimple (text) {
    return (<SimpleFormat text={ text } />);
  }

  formatMarkdown (text, escape = true) {
    return (<ReactMarkdown source={text} escapeHtml={escape}/>);
  }

  showLoading () {
    document.body.classList.add('p-loading');
  }

  hideLoading () {
    document.body.classList.remove('p-loading');
  }

  showToast (text) {
    return (<MnToast shown={ true }><span>{ text }</span></MnToast>);
  }

  /**
   * 現在と異なるURLの場合にヒストリに追加する
   * @param {*} url
   */
  appendHistory (url) {
    if (location.href !== `${location.origin}${url}`) {
      history.pushState('', '', url);
    }
  }

  /**
   * 現在のURLを置き換える
   * @param {*} url
   */
  replaceHistory (url) {
    history.replaceState('', '', url);
  }

  /**
   * URLSearchParamsを更新
   * @param URLSearchParams params URLSearchParams
   * @param object query
   */
  updateSearch (params, query = {}) {
    Object.entries(query).map(val => {
      if (val[1]) {
        params.set(val[0], val[1]);
      } else {
        params.delete(val[0]);
      }
    });
    return params;
  }

  /**
   * 現在のURLのURLSearchParamsを取得して引数の値でパラメータを上書き
   * @param object query
   */
  parseSearch (query = {}) {
    const params = new URLSearchParams(location.search);
    return this.updateSearch(params, query);
  }

  /**
   * QueryStringを生成
   * @param URLSearchParams params URLSearchParams
   * @param object query
   */
  buildSearch (params, query = {}) {
    return this.updateSearch(params, query).toLocaleString();
  }

  /**
   * URLを生成
   * @param URLSearchParams params URLSearchParams
   * @param object query
   */
  buildPath (params, query = {}, path = location.pathname) {
    const url = this.buildSearch(params, query);
    if (url.length === 0) {
      return path;
    }
    return `${path}?${url}`;
  }
}
