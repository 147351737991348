import AbstractComponent from './AbstractComponent';
import { v4 as uuidv4 } from 'uuid';

/**
 * AbstractRoot: コンテキストを定義する親コンポーネントの基底クラス
 */
export default class AbstractRoot extends AbstractComponent {
  constructor (props) {
    super(props);
    // 各関数をバインド
    this.onStateUpdate = this.onStateUpdate.bind(this);
    // stateに定義する共通メソッド
    this.methods = {
      onStateUpdate: this.onStateUpdate,
      fetch: this.fetch,
      renderModal: this.renderModal,
      formatSimple: this.formatSimple,
      formatMarkdown: this.formatMarkdown,
      showLoading: this.showLoading,
      hideLoading: this.hideLoading,
      updateSearch: this.updateSearch,
      parseSearch: this.parseSearch,
      buildSearch: this.buildSearch,
      buildPath: this.buildPath,
      appendHistory: this.appendHistory,
      replaceHistory: this.replaceHistory,
    };
    // UUIDの作成
    const uuid = window.localStorage.getItem('uuid') || uuidv4();
    if (!window.localStorage.getItem('uuid')) {
      window.localStorage.setItem('uuid', uuid);
    }
    // ステート初期化
    this.state = {
      ...this.methods, // methodsをstateに反映
      ...props, // 与えられたpropsをstateに反映
    };
  }

  /**
   * ステート更新
   * @param {*} state
   */
  onStateUpdate (state) {
    this.setState(state);
  }
}
