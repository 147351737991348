import * as React from 'react';
import { RootContext } from '../../../../index';
import {
  InvoiceForm,
  InvoiceLayout,
} from '.';

export default class Invoiced extends React.Component {
  static contextType = RootContext;

  render () {
    const { invoice, patient } = this.context;
    const isStripePayment = Boolean(patient.stripe_customer_id);
    const isFailedInvoice = this.context.is_failed_invoice;
    return (
      <InvoiceLayout>
        <>
          {isStripePayment ? (
            <>
              { isFailedInvoice && (
                <dl className="c-mn-message--notice">
                  <dt className="c-mn-message__title">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" className="c-mn-icon-error02" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6ZM12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6ZM6.53395 9.74995H5.21095V4.70995H6.53395V9.74995ZM6.64195 3.17095C6.64195 3.61195 6.32695 3.89995 5.87695 3.89995C5.41795 3.89995 5.10295 3.61195 5.10295 3.17095C5.10295 2.73895 5.41795 2.45095 5.87695 2.45095C6.32695 2.45095 6.64195 2.73895 6.64195 3.17095Z" fill="#000" className="c-mn-icon-error2-path"></path></svg>
                    登録済みのカードから決済できませんでした。
                  </dt>
                  <dd className="c-mn-message__txt">患者に直接請求書の送付などを行ってください。</dd>
                </dl>
              ) }
              <InvoiceForm invoice={invoice} disabled/>
            </>
          ) : (
            <div className="p-invoice-annotation-text my-xl">
              <p>この患者はすでに請求済みです。</p>
            </div>
          )}
        </>
      </InvoiceLayout>
    );
  }
}
