import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  AbstractComponent,
  MnButton,
  MnToast,
} from '../../../index';
import MnDatePicker from './MnDatePicker';
import TimePicker from './TimePicker';

export default class WaitingExaminationUpdateForm extends AbstractComponent {
  constructor (props) {
    super(props);
    this.state = {
      reservedDate: props.reservedDate,
      reservedTime: props.reservedTime,
      annotation: {},
      toast: null,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  validation = () => {
    let isValid = true;
    const annotation = {};
    if (!this.state.reservedDate) {
      annotation.reservedDate = ['必須入力です。'];
      isValid = false;
    }
    if (!this.state.reservedTime) {
      annotation.reservedTime = ['必須入力です。'];
      isValid = false;
    }
    if (this.isBefore()) {
      annotation.reservedTime = ['過去の日時です。'];
      isValid = false;
    }

    this.setState({ annotation: annotation });
    return isValid;
  }

  isBefore = () => {
    return moment(`${this.state.reservedDate} ${this.state.reservedTime}`, 'YYYY/MM/DD HH:mm:ss').isBefore(moment());
  }

  handleClose = () => {
    return new Promise((resolve, reject) => {
      resolve();
      setTimeout(() => {
        this.props.handleClose();
      });
    });
  }

  handleSubmit = () => {
    return new Promise((resolve, reject) => {
      if (!this.validation()) {
        return resolve();
      }
      this
        .fetch(
          this.props.apiLocation,
          {
            methods: 'PATCH',
            body: {
              state: 'waiting_examination',
              reserved_at: `${this.state.reservedDate} ${this.state.reservedTime}`,
            },
          },
        )
        .then(() => {
          this.setState({ toast: '変更しました。' });
        })
        .then(() => {
          setTimeout(() => {
            this.setState({ toast: null });
          }, 3000);
        })
        .then(() => {
          setTimeout(() => {
            location.reload();
          }, 2000);
        }).catch(_error => {
          setTimeout(() => {
            this.setState({ toast: '保存できませんでした。' });
          }, 10);
        });
    });
  }

  render () {
    return (
      <div>
        <p className="c-mn-modal-dialog__title">{this.props.title ? this.props.title : 'この患者の予約を変更しますか？'}</p>
        <p className="c-mn-modal-dialog__txt">{this.props.description ? this.props.description : '予約を変更すると患者に確定通知されます。'}</p>
        <div className="columns">
          <div className="column">
            <label className="c-mn-form-label fw-br fz14">診療日</label>
            <MnDatePicker
              name="reservedDate"
              value={this.state.reservedDate}
              errors={this.state.annotation.reservedDate}
              onChange={date => this.setState({ reservedDate: date })}
            />
          </div>
          <div className="column">
            <label className="c-mn-form-label fw-br fz14">時間</label>
            <TimePicker
              name="reservedTime"
              value={this.state.reservedTime}
              errors={this.state.annotation.reservedTime}
              onChange={time => this.setState({ reservedTime: time })}
            />
          </div>
        </div>
        <div className="is-flex jc-fe">
          <MnButton
            class="c-mn-btn c-mn-btn--basic-s c-mn-btn--third c-mn-btn--compact p-treatment__btn"
            onClick={this.handleClose}
          >
            <span>キャンセル</span>
          </MnButton>
          <MnButton
            class="c-mn-btn c-mn-btn--basic-s c-mn-btn--first c-mn-btn--compact p-treatment__btn ml-md"
            onClick={this.handleSubmit}
          >
            <span>変更する</span>
          </MnButton>
        </div>
        <MnToast shown={ this.state.toast !== null }><span>{ this.state.toast }</span></MnToast>
      </div>
    );
  }
}

WaitingExaminationUpdateForm.propTypes = {
  handleClose: PropTypes.func,
  apiLocation: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};
