import * as React from 'react';
import PropTypes from 'prop-types';
import SimpleFormat from '@16g/react-simple-format';

export default class MnPopup extends React.Component {
  render () {
    return (
      <div className={this.props.outerClass ? this.props.outerClass : 'c-mn-frames-paragraph'}>
        <div className="c-mn-popup__wrap">
          {this.props.children}
          <div className={this.props.annotation && this.props.annotation.length > 0 ? 'c-mn-popup c-mn-popup--txt d-b' : 'd-n'}>
            <div className="c-mn-popup__inner">
              <div className="c-mn-popup__txt"><SimpleFormat text={ this.props.annotation?.join('\n') } /></div>
              {this.props.closeIcon ? (<a className="c-mn-popup__close" onClick={this.props.hideAnnotation}>{this.props.closeIcon()}</a>) : null }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MnPopup.propTypes = {
  children: PropTypes.any, // inline表示するDOM要素
  annotation: PropTypes.array, // ポップアップ文言
  closeIcon: PropTypes.func, // 閉じるボタンの表示する場合のアイコン
  hideAnnotation: PropTypes.func, // コールバック 閉じるボタン押下時に発火
  outerClass: PropTypes.string, // 大外要素のclass
};
