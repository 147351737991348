import React from 'react';
import {
  AbstractForm,
  MnDialog,
  RootContext,
} from '../../../index';
import {
  PageSectionsFormItem,
} from '../molecules/index';
import { v4 as uuidv4 } from 'uuid';

export default class PageSectionsForm extends AbstractForm {
  static contextType = RootContext;
  maxPageSectionCount = 10;

  constructor (props) {
    super(props);
    this.state = {
      isAbleEdit: false,
      isAbleAddSection: true,
      isOpenSaveConfirmModal: false,
      isOpenCancelConfirmModal: false,
    };
    this.handleAddPageSection = this.handleAddPageSection.bind(this);
    this.handleUpdatePageSection = this.handleUpdatePageSection.bind(this);
    this.handleRemovePageSection = this.handleRemovePageSection.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount () {
    if (this.context.page_sections.length < 1) { this.handleAddPageSection(); }
    if (this.context.page_sections.length >= this.maxPageSectionCount) { this.setState({ isAbleAddSection: false }); }
  }

  // セクションの追加
  handleAddPageSection () {
    const pageSection = { id: null, institution_id: this.state.institution_id, title: '', description: '', uuid: uuidv4() };
    const pageSections = this.context.page_sections.concat(pageSection);
    this.context.onStateUpdate({ page_sections: pageSections });
    if (pageSections.length >= this.maxPageSectionCount) {
      this.setState({ isAbleAddSection: false });
    }
  }

  // セクションの更新
  handleUpdatePageSection (targetIndex, params) {
    const pageSections = this.context.page_sections;
    pageSections[targetIndex] = { ...pageSections[targetIndex], ...params };
    this.context.onStateUpdate({ page_sections: pageSections });
  }

  // セクションの削除
  handleRemovePageSection (targetIndex) {
    const pageSections = this.context.page_sections.filter((section, index) => {
      if (targetIndex === index) {
        this.onRemoveValidate({ name: `title_${section.code || section.uuid}` });
        this.onRemoveValidate({ name: `description_${section.code || section.uuid}` });
      }
      return targetIndex !== index;
    });
    this.context.onStateUpdate({ page_sections: pageSections });
    if (pageSections.length < this.maxPageSectionCount) {
      this.setState({ isAbleAddSection: true });
    }
  }

  // 設定したセクションを送信
  handleSubmit (ev) {
    return new Promise(resolve => {
      this.context.showLoading();
      this.context.fetch(this.context.api_location.register, {
        methods: 'PATCH',
        body: { page_sections: this.context.page_sections },
      }).then(body => {
        this.context.onStateUpdate({ page_sections: body.results });
        this.context.onHeaderToast('反映が完了しました。');
        resolve();
        setTimeout(() => {
          this.setState({ isAbleEdit: false, isOpenSaveConfirmModal: false });
        });
      }).finally(() => {
        this.context.hideLoading();
      });
    });
  }

  onSuccess () {
    this.setState({ isOpenSaveConfirmModal: true });
  }

  onFailure () {
    this.context.onHeaderToast('入力項目に不備があります。');
  }

  render () {
    return (
      <div>
        <MnDialog
          isOpen={ this.state.isOpenSaveConfirmModal }
          title="入力された内容を申込ページに反映しますか？"
          btnSubmitLabel="反映する"
          handleClose={ () => { this.setState({ isOpenSaveConfirmModal: false }); } }
          handleSubmit={ this.handleSubmit }
        >
        </MnDialog>
        <MnDialog
          isOpen={ this.state.isOpenCancelConfirmModal }
          title="申込ページの編集をキャンセルしますか？"
          btnSubmitLabel="キャンセルする"
          btnCloseLabel="いいえ"
          handleClose={ () => { this.setState({ isOpenCancelConfirmModal: false }); } }
          handleSubmit={ () => {
            return new Promise(resolve => {
              resolve();
              setTimeout(() => {
                location.reload();
              });
            });
          } }
        >
          キャンセルすると編集した内容は破棄されます。
        </MnDialog>

        <div className={ 'container' }>
          <div className={ 'column is-6 is-offset-3' }>
            <h1 className={ 'fz32 fw-br ta-c my-xl pb-md' }>
              病院ページの内容
            </h1>

            <div className={ 'p-page-sections--register-form' }>
              <div className={ 'w100p ta-c' }>
                { this.state.isAbleEdit ? (
                  <>
                    <a className={ 'c-mn-link ta-r my-xl mx-md' + (this.state.isAbleEdit ? '' : ' d-n') } onClick={ () => this.setState({ isOpenCancelConfirmModal: true }) }>
                      キャンセル
                    </a>
                    <button className={ 'c-mn-btn--first-s c-mn-btn--compact mx-md' + (this.state.isAbleEdit ? '' : ' d-n') } onClick={ this.onSave } >
                      <span>保存する</span>
                    </button>
                  </>
                ) : (
                  <button className={ 'm-a c-mn-btn--second-s c-mn-btn--compact' + (this.state.isAbleEdit ? ' d-n' : '') } onClick={ () => this.setState({ isAbleEdit: true }) }>
                    <span>編集する</span>
                  </button>
                ) }
              </div>

              <div className="p-page-sections--register-form--items m-a">
                { this.context.page_sections.map((pageSection, index) =>
                  <div className={ 'mt-xl mb-lg' } key={ pageSection.code || pageSection.uuid }>
                    <PageSectionsFormItem
                      name={ pageSection.code || pageSection.uuid }
                      index={ index }
                      title={ pageSection.title }
                      description={ pageSection.description }
                      disabled={ !this.state.isAbleEdit }
                      handleUpdatePageSection={ this.handleUpdatePageSection }
                      handleRemovePageSection={ this.handleRemovePageSection }
                      handleValidate={ this.onValidate }
                    />
                  </div>,
                ) }
              </div>

              { this.state.isAbleEdit ? (
                <div className={ 'p-page-sections--register-form--add-item-btn w100p ta-c mt-xl' } >
                  <button
                    className={ 'm-a ' + (this.state.isAbleAddSection ? 'c-mn-btn--third' : 'c-mn-btn--disable') }
                    onClick={ () => this.handleAddPageSection() }
                    disabled={ !this.state.isAbleAddSection }
                  >
                    <span>
                      <svg height="26" viewBox="0 0 26 26" width="26" className="c-mn-icon-plus" xmlns="http://www.w3.org/2000/svg"><g><path d="m11 0h4v26h-4z" className="c-mn-icon-plus-path"></path><path d="m0 11h26v4h-26z" className="c-mn-icon-plus-path"></path></g></svg>
                      項目を追加する
                    </span>
                  </button>
                </div>
              ) : false }

              { this.state.isAbleEdit && !this.state.isAbleAddSection ? (
                <p className={ 'c-mn-txt--caption ta-c mt-lg pt-md' }>
                  項目の設置可能な上限（{this.maxPageSectionCount}個）に達しました。<br />
                  新たに追加したい場合は、<br />
                  既存の項目を削除してから追加してください。
                </p>
              ) : null }

              <div className={ 'mt-xl pt-md' }>
                <hr className={ 'p-page-sections--register-form--items--end-line m-a' } />
              </div>

              <div className={ 'w100p ta-c mt-xl' } >
                { this.state.isAbleEdit ? (
                  <>
                    <button className={ 'c-mn-btn--first m-a' } onClick={ this.onSave }>
                      <span>保存する</span>
                    </button>
                    <div className={ 'mt-xl pt-sm' }>
                      <a className={ 'c-mn-link fz18' } onClick={ () => this.setState({ isOpenCancelConfirmModal: true }) }>
                        キャンセル
                      </a>
                    </div>
                  </>
                ) : (
                  <button className={ 'c-mn-btn--second m-a' } onClick={ () => this.setState({ isAbleEdit: true }) }>
                    <span>編集する</span>
                  </button>
                ) }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
