import * as React from 'react';
import PropTypes from 'prop-types';
import AbstractMnInput from './AbstractMnInput';
import MnPopup from './MnPopup';

export default class MnInputTextarea extends AbstractMnInput {
  render () {
    return (
      <MnPopup annotation={this.state.annotation} closeIcon={this.renderIconClose} hideAnnotation={this.hideAnnotation}>
        <textarea
          className={this.props.class ? this.props.class : 'c-mn-textarea'}
          value={this.state.value || ''}
          id={this.props.name || ''}
          name={this.props.name || ''}
          rows={this.props.rows || ''}
          placeholder={this.props.placeholder ? this.props.placeholder : ''}
          onChange={ this.onChange }
          onFocus={ this.props.onFocus }
          onBlur={ this.props.onBlur }
          disabled={this.props.disabled}
        />
        { this.renderErrors() }
      </MnPopup>
    );
  }
}

MnInputTextarea.propTypes = {
  ...AbstractMnInput.propTypes, // 基底クラスのpropTypesを継承
  /** 行数 */
  rows: PropTypes.number,
};

MnInputTextarea.defaultProps = {
  ...AbstractMnInput.defaultProps,
  rows: 2,
};
