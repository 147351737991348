import * as React from 'react';
import PropTypes from 'prop-types';
import AbstractMnInput from './AbstractMnInput';
import MnPopup from './MnPopup';
import { v4 as uuidv4 } from 'uuid';

export default class MnInputFile extends AbstractMnInput {
  imageTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];

  constructor (props) {
    super(props);
    this.state = {
      ...this.state,
      files: [],
      count: 0,
      id: `i${props.name}`,
      loading: false,
      reset: false, // inputのリセット
    };
    this.files = [];
    this.queues = [];

    this.onClick = this.onClick.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.upload = this.upload.bind(this);
    this.delete = this.delete.bind(this);
    this.deleteAll = this.deleteAll.bind(this);
    this.isLoading = this.isLoading.bind(this);
  }

  onClick () {
    const file = document.querySelector(`input#${this.state.id}[type=file]`);
    if (file) {
      file.click();
    }
  }

  onChange (e) {
    const size = e.target.files.length;
    this.hideAnnotation();
    if (this.props.maxFileCount < this.files.length + size) {
      this.showAnnotation(this.props.t ? ['MnInputFile.limit_annotation', { count: this.props.maxFileCount }] : `ファイル数上限に達してしまいます。${this.props.maxFileCount}つまでアップロード可能です。`);
      return;
    }
    this.setState({ loading: true });
    [...e.target.files].map(file => {
      if (this.props.acceptMime && this.props.acceptMime.indexOf(file.type) === -1) {
        this.showAnnotation(this.props.t ? ['MnInputFile.mime_annotation', { filename: file.name }] : `${file.name}は許可されていない種類のファイルです。`);
        return;
      }
      if (this.props.maxFileSize < file.size) {
        this.showAnnotation(this.props.t ? ['MnInputFile.size_annotation', { filename: file.name, size: this.props.maxFileSize / 1048576 }] : `${file.name}はファイルサイズ制限を超過しています。${this.props.maxFileSize / 1048576}MB以内になるように縮小してください。`);
        return;
      }
      this.queues.push({
        key: `${this.state.name}_${uuidv4()}`,
        src: '',
        raw: file,
        info: {},
      });
    });
    this.upload().then(() => {
      this.setState({ loading: false, reset: true });
      setTimeout(() => {
        this.setState({ reset: false });
      }, 10);
      this.props.onChange({ name: this.props.name, files: this.files });
      this.isValid().catch(() => {});
    });
  }

  isValid () {
    return new Promise((resolve, reject) => {
      this.validate(this.files.map(file => file.info?.code)).then(() => {
        this.setState({ errors: undefined });
        resolve();
      }).catch(err => {
        this.setState({ errors: err });
        this.props.onError({ name: this.props.name, errors: err });
        const el = document.querySelector(`#${this.props.name}`);
        if (el) {
          el.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
        reject(err);
      });
    });
  }

  onDelete (e) {
    const file = this.files.find(file => (file.key === e));
    if (file) {
      this.setState({ loading: true });
      this.delete(file).then(() => {
        this.files = this.files.filter(data => (data.key !== file.key));
        this.setState({ files: this.files });
        if (this.props.onChange) {
          this.props.onChange({ name: this.props.name, files: this.files });
        }
      }).catch(() => {
        alert(this.props.t ? this.props.t('MnInputFile.delete_error') : 'ファイルの削除に失敗しました。再度削除を実行してください。');
      }).finally(() => {
        this.setState({ loading: false });
        this.isValid().catch(() => {});
      });
    }
  }

  upload () {
    return new Promise((resolve, reject) => {
      if (this.props.parallel) {
        // 並列アップロード
        const results = [];
        let count = 0;
        this.queues.map(file => {
          this._upload(file).then(data => {
            results.push(data);
          }).finally(() => {
            if (this.queues.length === ++count) {
              this.queues = [];
              resolve(results);
            }
          });
        });
      } else {
        // 直列アップロード
        const file = this.queues.shift();
        if (file) {
          this._upload(file).then(data => {
            this.files[this.files.length] = data;
          }).finally(() => {
            if (this.queues.length === 0) {
              // キューが空になったら完了通知
              this.queues = [];
              resolve();
            } else {
              // キューがある場合は再帰的に処理
              this.upload().then(() => {
                resolve();
              });
            }
          });
        } else {
          // キューがない場合は完了通知
          resolve();
        }
      }
    });
  }

  _upload (file) {
    return new Promise((resolve, reject) => {
      if (file.raw.type.indexOf('image/') !== -1) {
        // 画像ファイルの読み込み
        const reader = new FileReader();
        reader.onload = (ev) => {
          file.src = ev.target.result;
          if (!this.props.uploadFile) { resolve(file); }
        };
        reader.readAsDataURL(file.raw);
      } else {
        // 非画像ファイルかつアップロードしない場合は完了
        if (!this.props.uploadFile) { resolve(file); }
      }
      // ファイルのアップロード
      if (this.props.uploadFile) {
        this.fetch(this.props.uploadFile, {
          methods: 'POST',
          file: file.raw,
        }).then(data => {
          file.info = data.result;
          resolve(file);
        }).catch(() => {
          alert(`${file.raw.name}${this.props.t ? this.props.t('MnInputFile.upload_error') : 'のアップロードに失敗しました。再度ファイルを選択してください。'}`);
          reject(file);
        });
      }
    });
  }

  delete (file) {
    return new Promise((resolve, reject) => {
      console.log(file.info);
      if (file.info && file.info.api_location?.delete) {
        this.fetch(file.info.api_location.delete, {
          methods: 'DELETE',
        }).then(() => {
          resolve(file);
        }).catch(() => {
          reject(file);
        });
      } else {
        resolve(file);
      }
    });
  }

  deleteAll () {
    this.files.map(file => {
      this.delete(file);
    });
  }

  isLoading () {
    return this.state.loading;
  }

  renderIconClose () {
    return (<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9" cy="9" r="9" fill="white"/><path fillRule="evenodd" clipRule="evenodd" d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM6.22032 13.1974C5.83499 13.5827 5.21024 13.5827 4.82491 13.1974C4.43958 12.812 4.43958 12.1873 4.82491 11.802L7.61575 9.01113L4.87449 6.26988C4.48916 5.88455 4.48916 5.2598 4.87449 4.87447C5.25983 4.48914 5.88457 4.48914 6.2699 4.87447L9.01116 7.61572L11.802 4.82491C12.1873 4.43958 12.812 4.43958 13.1974 4.82491C13.5827 5.21025 13.5827 5.83499 13.1974 6.22032L10.4066 9.01113L13.247 11.8515C13.6323 12.2369 13.6323 12.8616 13.247 13.2469C12.8616 13.6323 12.2369 13.6323 11.8515 13.2469L9.01116 10.4065L6.22032 13.1974Z" fill="#AAAAAA"/></svg>);
  }

  render () {
    return (
      <div className="pos-r p-input_file">
        <div className={this.state.loading ? 'p-loading' : ''}>
          { this.state.reset ? null : (<input type="file" id={this.state.id} className="d-n" multiple={this.props.multiple} onChange={this.onChange}/>) }
          <MnPopup annotation={this.state.annotation?.map(text => this.translate(text))} closeIcon={this.renderIconClose} hideAnnotation={this.hideAnnotation}>
            <button
              id={this.props.name}
              className={this.props.class ? this.props.class : 'c-mn-btn--third'}
              onClick={this.onClick}
              disabled={this.props.disabled}
            ><span>{this.props.icon ? this.props.icon() : null}{this.props.t ? this.props.t(this.props.title) : this.props.title}</span></button>
          </MnPopup>
          <div className={ this.files.length > 0 ? 'p-input_file--images' : 'p-input_file--images d-n' }>
            {this.files.map(file => {
              return (
                <div className={file.info ? '' : 'd-n'} key={file.key}>
                  {file.raw.type.indexOf('image/') === -1 ? (
                    <div className="p-input_file--images--box"><div>{file.raw.name}</div></div>
                  ) : (
                    <img src={file.src} />
                  )}
                  <button onClick={ () => { this.onDelete(file.key); }}><span>{this.renderIconClose()}</span></button>
                </div>
              );
            })}
          </div>
          { this.renderErrors() }
        </div>
      </div>
    );
  }
}

MnInputFile.propTypes = {
  ...AbstractMnInput.propTypes, // 基底クラスのpropTypesを継承
  title: PropTypes.string, // ボタンのテキスト
  icon: PropTypes.func, // ボタン内のアイコン
  multiple: PropTypes.bool, // 複数ファイル選択
  maxFileSize: PropTypes.number, // ファイルサイズ上限
  maxFileCount: PropTypes.number, // ファイル数上限
  acceptMime: PropTypes.array, // 許可MIMEタイプ
  uploadFile: PropTypes.string, // ファイルのアップロードURL
  parallel: PropTypes.bool, // 並列アップロードの許容
};

MnInputFile.defaultProps = {
  ...AbstractMnInput.defaultProps,
  title: 'ファイルの選択',
  maxFileSize: 10485760,
  maxFileCount: 5,
  multiple: true,
  parallel: false,
};
