import * as React from 'react';
import PropTypes from 'prop-types';
import SimpleFormat from '@16g/react-simple-format';

export default class MnTab extends React.Component {
  render () {
    return (<div className="c-mn-tab-basic--col-free">
      { this.props.tabs.map(tab => {
        return (<button key={tab.id} className={ tab.id === this.props.active ? 'is-active' : ''} onClick={ () => { this.props.onChange(tab); } } disabled={ tab.id === this.props.active }><span><SimpleFormat text={ tab.title } /></span></button>);
      })}
    </div>);
  }
}

MnTab.propTypes = {
  /** タブ情報 [{ id: string || number, title: string }] */
  tabs: PropTypes.array,
  /** アクティブタブID */
  active: PropTypes.any,
  /** コールバック アクティブタブの切り替え */
  onChange: PropTypes.func,
};

MnTab.defaultProps = {
  tabs: [],
  active: 1,
  onChange: () => {},
};
