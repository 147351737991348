import React from 'react';

export default class Settings extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      ...props,
    };
  }

  handleItemClick (path) {
    location.href = path;
  }

  render () {
    return (
      <section className={ 'hero' }>
        <div className={ 'hero-body' }>
          <div className={ 'container mt-xl' }>
            <div className={ 'column is-4 is-offset-4 my-xl' }>
              <button
                className={ 'c-mn-btn--first my-xl m-a' }
                onClick={ () => this.handleItemClick(this.state.url_location.register_page_sections) }
              >
                オンライン診療ページの登録
              </button>

              <button
                className={ 'c-mn-btn--green my-xl m-a' }
                onClick={ () => this.handleItemClick(this.state.url_location.register_menus) }
              >
                オンライン診療メニューの登録
              </button>

              <div
                className={ 'has-text-centered my-xl c-mn-link' }
                onClick={ () => this.handleItemClick(this.state.url_location.top) }
              >
                トップへ戻る
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
