import * as React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import UploadFile from './UploadFile.js';

export default class UploadFileModal extends React.Component {
  render () {
    return (
      <Modal
        isOpen={this.props.isOpen}
        className="c-mn-modal-dialog is-active has-background-white" overlayClassName="c-mn-modal is-active--dialog"
      >
        <UploadFile
          apiLocation={this.props.apiLocation}
          handleClose={this.props.handleClose}
        />
      </Modal>
    );
  }
}

UploadFileModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  apiLocation: PropTypes.string,
};
