import React from 'react';
import Modal from 'react-modal';
import Layout from './Layout';
import {
  AbstractComponent,
  MnToast,
  AccountsRolesI18n,
} from '../../index';
import AccountListTableCell from './AccountListTableCell';
import NewAccountModal from './modal/NewAccount';

export default class AccountListTable extends AbstractComponent {
  constructor (props) {
    super(props);
    this.state = {
      ...props,
      toastMessage: '',
      errorModalMessage: '',
      isOpenAccountCreateModal: false,
      isOpenAccountCreateCancelConfirmModal: false,
      isOpenAccountDestroyConfirmModal: false,
      isOpenAccountRoleEditConfirmModal: false,
      deleteAccount: {},
      editAccount: {},
    };
    this.handleUpdateAccountRole = this.handleUpdateAccountRole.bind(this);
    this.handleDestroyAccount = this.handleDestroyAccount.bind(this);
  }

  // アカウントの権限の更新
  handleUpdateAccountRole (params) {
    const account = this.state.accounts.filter(account => account.id === params.accountId)[0];
    const endpoint = account.api_location.role;
    this.fetch(endpoint, {
      methods: 'PATCH',
      body: { role: params.role },
    }).then(_ => {
      this.setState({ toastMessage: null });
      setTimeout(() => {
        this.setState({ toastMessage: 'アカウントの権限を更新しました。', isOpenAccountRoleEditConfirmModal: false });
      }, 10);
    }).catch(err => {
      err.json().then(body => {
        this.setState({ errorModalMessage: body.message, isOpenAccountRoleEditConfirmModal: false });
      });
    });
  }

  // アカウントの削除
  handleDestroyAccount (accountId) {
    const account = this.state.accounts.filter(account => account.id === accountId)[0];
    const endpoint = account.api_location.account;
    this.fetch(endpoint, {
      methods: 'DELETE',
    }).then(_ => {
      this.setState({ toastMessage: null });
      setTimeout(() => {
        const newAccounts = this.state.accounts.filter(account => account.id !== accountId);
        this.setState({ toastMessage: 'アカウントを削除しました。', isOpenAccountDestroyConfirmModal: false, accounts: newAccounts });
      }, 10);
    }).catch(err => {
      err.json().then(body => {
        this.setState({ errorModalMessage: body.message, isOpenAccountDestroyConfirmModal: false });
      });
    });
  }

  render () {
    return (
      <Layout state={this.state}>
        <React.Fragment>
          <MnToast shown={ Boolean(this.state.toastMessage) }>
            <>{ this.state.toastMessage }</>
          </MnToast>

          <NewAccountModal
            account={ this.state.new_account }
            showModal={ this.state.isOpenAccountCreateModal }
            onClose={ () => {
              this.setState({
                isOpenAccountCreateModal: false,
                isOpenAccountCreateCancelConfirmModal: true,
              });
            } }
            onSave={ (account) => {
              this.setState({ toastMessage: null });
              setTimeout(() => {
                this.setState({
                  isOpenAccountCreateModal: false,
                  toastMessage: 'アカウントを新規発行しました。',
                  new_account: this.props.new_account,
                  accounts: this.state.accounts.concat(account),
                });
              }, 10);
            } }
          />

          <section className={ 'hero p-account-list' }>
            <Modal isOpen={ this.state.isOpenAccountCreateCancelConfirmModal } className={ 'p-account-create-modal c-mn-modal-dialog is-active has-background-white' } overlayClassName={ 'c-mn-modal is-active--dialog' } >
              <p className="c-mn-modal-dialog__title">アカウントの新規発行をキャンセルしますか？</p>
              <p className="c-mn-modal-dialog__txt">キャンセルすると入力した内容は破棄されます。</p>
              <div className="c-mn-modal-dialog__btn-wrap">
                <button className="c-mn-btn c-mn-btn--basic-s c-mn-btn--third c-mn-btn--compact"
                  onClick={ () => this.setState({ isOpenAccountCreateModal: true, isOpenAccountCreateCancelConfirmModal: false }) }
                >
                  <span>いいえ</span>
                </button>
                <button className="c-mn-btn c-mn-btn--basic-s c-mn-btn--first c-mn-btn--compact" onClick={ () => location.reload() }>
                  <span>キャンセルする</span>
                </button>
              </div>
            </Modal>

            <Modal isOpen={ this.state.isOpenAccountDestroyConfirmModal } className={ 'c-mn-modal-dialog is-active' } overlayClassName={ 'c-mn-modal is-active--dialog' } >
              <p className={ 'c-mn-modal-dialog__title' }>{ this.state.deleteAccount.name }さんを削除しますか？</p>
              <p className={ 'c-mn-modal-dialog__txt' }>削除したデータは元には戻せません。</p>
              <div className={ 'c-mn-modal-dialog__btn-wrap' }>
                <button className={ 'c-mn-btn c-mn-btn--basic-s c-mn-btn--third c-mn-btn--compact' } onClick={ () => this.setState({ isOpenAccountDestroyConfirmModal: false, deleteAccount: {} }) }>
                  <span>キャンセル</span>
                </button>
                <button className={ 'c-mn-btn c-mn-btn--basic-s c-mn-btn--first c-mn-btn--compact' } onClick={ () => this.handleDestroyAccount(this.state.deleteAccount.id) } >
                  <span>削除する</span>
                </button>
              </div>
            </Modal>

            <Modal isOpen={ this.state.isOpenAccountRoleEditConfirmModal } className={ 'c-mn-modal-dialog is-active' } overlayClassName={ 'c-mn-modal is-active--dialog' } >
              <p className={ 'c-mn-modal-dialog__title' }>
                { this.state.editAccount.name }さんの権限を
                { this.state.editAccount.role && AccountsRolesI18n.find(role => role.key === this.state.editAccount.role).value }に変更しますか？
              </p>
              <div className={ 'c-mn-modal-dialog__btn-wrap' }>
                <button className={ 'c-mn-btn c-mn-btn--basic-s c-mn-btn--third c-mn-btn--compact' } onClick={ () => location.reload() }>
                  <span>キャンセル</span>
                </button>
                <button className={ 'c-mn-btn c-mn-btn--basic-s c-mn-btn--first c-mn-btn--compact' } onClick={ () => this.handleUpdateAccountRole({ accountId: this.state.editAccount.id, role: this.state.editAccount.role }) } >
                  <span>変更する</span>
                </button>
              </div>
            </Modal>

            <Modal isOpen={ Boolean(this.state.errorModalMessage) } className={ 'c-mn-modal-dialog is-active' } overlayClassName={ 'c-mn-modal is-active--dialog' } >
              <p className={ 'c-mn-modal-dialog__title' }>エラー</p>
              <p className={ 'c-mn-modal-dialog__txt' }>{ this.state.errorModalMessage }</p>
              <div className={ 'c-mn-modal-dialog__btn-wrap' }>
                <button className={ 'c-mn-btn c-mn-btn--basic-s c-mn-btn--first c-mn-btn--compact' } onClick={ () => { location.reload(); } }>
                  <span>OK</span>
                </button>
              </div>
            </Modal>

            <div className={ 'hero-body p0' }>
              <div className={ 'p-lg has-background-white' }>
                <h1 className={ 'c-mn-title-1 fw-br d-ib p-sm mr-lg' }>
                  アカウント
                </h1>
                <button className="c-mn-btn--third-s c-mn-btn--compact d-ib va-t mt-sm" onClick={ () => this.setState({ isOpenAccountCreateModal: true }) } >
                  <span>
                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" className="c-mn-icon-pencil" xmlns="http://www.w3.org/2000/svg"><path d="M0.0947266 20L1.72608 13.4551L6.76284 18.4903L0.0947266 20ZM3.26986 11.7605L14.9039 0L20.0947 5.18919L8.46229 16.953L3.26986 11.7605Z" fill="black" className="c-mn-icon-pencil-path"></path></svg>
                    新規発行する
                  </span>
                </button>
              </div>

              <table className="c-mn-table c-mn-table--plan">
                <thead>
                  <tr>
                    <th className="c-mn-table-th c-mn-table-th-first">権限</th>
                    <th className="c-mn-table-th">氏名</th>
                    <th className="c-mn-table-th">氏名(カナ)</th>
                    <th className="c-mn-table-th bdr-n">メールアドレス</th>
                    <th className="c-mn-table-th" />
                  </tr>
                </thead>
                <tbody>
                  { this.state.accounts.map(account =>
                    <AccountListTableCell
                      key={ account.id }
                      accountId={ account.id }
                      accountName={ account.name }
                      accountNameKana={ account.name_kana }
                      accountEmail={ account.email }
                      accountRole={ account.role }
                      accountRoleSelectOptions={ this.state.account_role_select_options }
                      handleUpdateAccountRole={ (e) => this.setState({ isOpenAccountRoleEditConfirmModal: true, editAccount: { id: account.id, name: account.name, role: e.value } }) }
                      handleDestroyAccount={ () => this.setState({ isOpenAccountDestroyConfirmModal: true, deleteAccount: { id: account.id, name: account.name } }) }
                    />,
                  ) }
                </tbody>
              </table>
            </div>
          </section>
        </React.Fragment>
      </Layout>
    );
  }
}
