import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  RootContext,
  MnButton,
  MnDialog,
  MnInlineMessage,
  AgoraUtil,
  AgoraApiV1TestsPath,
} from '../../../index';
import { decycle } from 'json-cyclic';

const arrow = (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.21 21.36" className="c-mn-icon-arrow"><path d="M1.5,21.36A1.49,1.49,0,0,1,0,19.86,1.47,1.47,0,0,1,.45,18.8l8.26-8.12L.45,2.57A1.47,1.47,0,0,1,0,1.51,1.46,1.46,0,0,1,.44.44,1.5,1.5,0,0,1,1.5,0a1.47,1.47,0,0,1,1,.43l9.13,9a1.74,1.74,0,0,1,.53,1.25,1.72,1.72,0,0,1-.53,1.24l-9.13,9A1.47,1.47,0,0,1,1.5,21.36Z" className="c-c-icon-arrow-path"></path></svg>);

export default class VideoTesting extends React.Component {
  static contextType = RootContext;
  steps = [{
    id: 1,
    title: 'メディカルノート接続テスト',
    message: [
      'メディカルノートからレスポンスが正しく得られませんでした。',
      'ファイアウォール等の設定の確認の上、再度接続ボタンを押下してください。',
    ],
  }, {
    id: 2,
    title: 'デバイス一覧取得テスト',
    message: [
      '正常なデバイスを利用している場合には発生しないエラーです。',
      'デバイスが正しく接続されているか確認してください。',
    ],
  }, {
    id: 3,
    title: 'ローカルビデオ作成テスト',
    option: (<div id="local_stream" className="c-mn-card-contents__description p-test--stream"></div>),
    message: [
      'カメラ、マイクまたはスピーカーのデバイスの初期化でエラーが発生しました。',
      'デバイスの権限や正しく接続されているか確認してください。',
    ],
  }, {
    id: 4,
    title: 'チャットサービス接続テスト',
    message: [
      'チャットサービスとの接続に失敗しました。',
      'ファイアウォール等の設定の確認の上、再度接続ボタンを押下してください。',
    ],
  }, {
    id: 5,
    title: 'ビデオサービス接続テスト',
    message: [
      'ビデオサービスとの接続に失敗しました。',
      'ファイアウォール等の設定の確認の上、再度接続ボタンを押下してください。',
    ],
  }, {
    id: 6,
    title: 'ビデオ購読テスト',
    option: (<div id="main_stream" className="c-mn-card-contents__description p-test--stream"></div>),
    message: [
      'WebRTCによるビデオの購読に失敗しました。',
      'ファイアウォール等の設定の確認の上、再度接続ボタンを押下してください。',
    ],
  }, {
    id: 7,
    title: 'テスト完了',
  }].reverse();

  constructor (props) {
    super(props);
    this.state = {
      step: 0,
      detail: undefined,
    };

    this.step1 = this.step1.bind(this);
    this.step2 = this.step2.bind(this);
    this.step3 = this.step3.bind(this);
    this.step4 = this.step4.bind(this);
    this.step5 = this.step5.bind(this);
    this.step6 = this.step6.bind(this);
  }

  componentDidMount () {
    // UUID作成or読み込み
    const uuid = window.localStorage.getItem('uuid') || uuidv4();
    if (!window.localStorage.getItem('uuid')) {
      window.localStorage.setItem('uuid', uuid);
    }
    this.setState({
      uuid: uuid,
    });
  }

  step1 () {
    console.log('step1', this.state);
    this.context.fetch(`${AgoraApiV1TestsPath()}?uuid=${this.state.uuid}`).then(rs => {
      this.setState({
        user1: rs.results[0],
        user2: rs.results[1],
        result: [...this.state.result, { ok: true, value: rs.meta }],
        step: this.state.step + 1,
      }, () => {
        this.step2();
      });
    }).catch(err => {
      this.setState({ result: [...this.state.result, { ok: false, value: err }] });
    });
  }

  step2 () {
    console.log('step2', this.state);
    const agora = new AgoraUtil(this.state.user1.uid, this.state.user1.video_id, this.state.user1.app_id, this.state.user1.chat_token);
    agora.getDevice().then(device => {
      this.setState({
        agora: agora,
        result: [...this.state.result, { ok: true, value: device }],
        step: this.state.step + 1,
      }, () => {
        this.step3();
      });
    }).catch(err => {
      this.setState({ result: [...this.state.result, { ok: false, value: err }] });
    });
  }

  step3 () {
    console.log('step3', this.state);
    this.state.agora.createStream().then(rs => {
      this.setState({
        result: [...this.state.result, { ok: true, value: decycle(rs) }],
        step: this.state.step + 1,
      }, () => {
        this.state.agora.forcePlay('local_stream', rs);
        this.step4();
      });
    }).catch(err => {
      this.setState({ result: [...this.state.result, { ok: false, value: err }] });
    });
  }

  step4 () {
    console.log('step4', this.state);
    this.state.agora.joinChat(this.state.user1.channel_name).then(rs => {
      this.state.agora.sendChatMessage('hello world!');
      this.setState({
        chat: rs.channel,
        result: [...this.state.result, { ok: true, value: decycle(rs.channel) }],
        step: this.state.step + 1,
      }, () => {
        this.step5();
      });
    }).catch(err => {
      this.setState({ result: [...this.state.result, { ok: false, value: err }] });
    });
  }

  step5 () {
    console.log('step5', this.state);
    this.state.agora.joinVideo(this.state.user1.channel_name, 'local_stream').then(rs => {
      this.setState({
        result: [...this.state.result, { ok: true, value: rs }],
        step: this.state.step + 1,
      }, () => {
        this.step6();
      });
    }).catch(err => {
      this.setState({ result: [...this.state.result, { ok: false, value: err }] });
    });
  }

  step6 () {
    console.log('step6', this.state);
    this.state.agora.listenEvents().on('stream-added', ev => {
      this.state.agora.subscribe(ev.stream).then();
    }).on('stream-subscribed', ev => {
      this.state.agora.forcePlay('main_stream', ev.stream);
      this.setState({
        result: [...this.state.result, { ok: true, value: decycle(ev) }, { ok: true, value: 'テスト完了しました' }],
        step: this.state.step + 1,
      });
    });
    const agora = new AgoraUtil(this.state.user2.uid, this.state.user2.video_id, this.state.user2.app_id, this.state.user2.chat_token);
    this.setState({ main: agora });
    new Promise((resolve, reject) => {
      agora.getDevice().then(() => {
        agora.createStream(true).then(() => {
          agora.joinVideo(this.state.user2.channel_name, 'dammy_stream').then(uid => {
            resolve(uid);
            setTimeout(() => {
              if (this.state.result.length < this.steps.length) {
                this.setState({ result: [...this.state.result, { ok: false, value: '60秒以内にビデオの購読が完了しませんでした。' }] });
              }
            }, 60000);
          }).catch(err => {
            reject(err);
          });
        }).catch(err => {
          reject(err);
        });
      }).catch(err => {
        reject(err);
      });
    }).catch(err => {
      this.setState({ result: [...this.state.result, { ok: false, value: err }] });
    });
  }

  render () {
    return (<div className="maw420px m-a">
      <h4 className="w90p c-mn-title-2 ta-c">オンライン診療接続テスト</h4>
      <MnInlineMessage mode="caution" messages={[
        'マイクやスピーカーの設定によってハウリングする可能性があります。',
        'スピーカーやヘッドフォンの音量にご注意ください。',
      ]} />
      <MnButton class="c-mn-btn--first mt-md" onClick={() => {
        return new Promise((resolve) => {
          this.setState({
            step: 1,
            result: [],
          }, () => {
            this.step1();
            resolve();
          });
        });
      }}><span>テスト開始</span></MnButton>
      { this.state.result?.map((rs, i) => {
        return !rs.ok ? (<MnInlineMessage key={i} messages={this.steps[this.steps.length - i - 1].message} />) : null;
      })}
      { this.steps?.map(step => {
        return step.id > this.state.step ? null : (<a
          key={step.id}
          className={ this.state.result[step.id - 1]?.ok || false ? 'c-mn-card my-lg mx-16px' : 'c-mn-card my-lg mx-16px c-mn-card--red'}
          onClick={() => {
            this.setState({ detail: JSON.stringify(this.state.result[step.id - 1]?.value, null, 2) });
          }}
        >
          <div className="c-mn-card-contents">
            <div className="c-mn-card-main">
              <div className="c-mn-card-contents__name">
                <p>STEP{step.id}. {step.title}</p>
              </div>
              {this.state.result[step.id - 1]?.ok && !!step.option ? step.option : null}
            </div>
          </div>
          {arrow}
        </a>);
      }) }
      <MnDialog
        isOpen={!!this.state.detail}
        title="詳細"
        btnCloseHidden={true}
        btnSubmitLabel="OK"
        handleSubmit={() => {
          return new Promise(resolve => {
            setTimeout(() => {
              this.setState({ detail: undefined });
            }, 100);
            resolve();
          });
        }}
      >
        <div className="ov-s mah50vh"><pre>{this.state.detail}</pre></div>
      </MnDialog>
    </div>);
  }
}
