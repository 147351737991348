import * as React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import RefundForm from './RefundForm.js';

export default class RefundModal extends React.Component {
  constructor (props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose = () => {
    this.props.handleClose();
  }

  render () {
    return (
      <Modal
        isOpen={this.props.isOpen}
        className="c-mn-modal-dialog is-active has-background-white" overlayClassName="c-mn-modal is-active--dialog"
      >
        <RefundForm
          handleClose={this.handleClose}
          treatment={this.props.treatment}
        />
      </Modal>
    );
  }
}

RefundModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  treatment: PropTypes.object,
};
