import * as React from 'react';
import PropTypes from 'prop-types';
import AbstractMnInput from './AbstractMnInput';
import MnPopup from './MnPopup';

export default class MnButton extends AbstractMnInput {
  constructor (props) {
    super(props);
    this.state = {
      disabled: props.disabled,
    };

    this.onClick = this.onClick.bind(this);
  }

  shouldComponentUpdate (nextProps, nextState) {
    if (this.props.disabled !== nextProps.disabled) {
      nextState.disabled = nextProps.disabled || false;
    }
    return true;
  }

  onClick (e) {
    this.setState({ disabled: true });
    if (this.props.onClick) {
      this.props.onClick({ name: this.props.name, event: e }).finally(() => {
        this.setState({ disabled: false });
      });
    }
  }

  render () {
    return (
      <MnPopup annotation={this.props.annotation} closeIcon={null} hideAnnotation={this.hideAnnotation}>
        <button
          className={this.props.class}
          name={this.props.name}
          onClick={ this.onClick }
          disabled={this.state.disabled}
          {...this.props.data}
        >{this.props.children}</button>
      </MnPopup>
    );
  }
}

MnButton.propTypes = {
  /** クラス名 */
  class: PropTypes.string,
  /** name属性 */
  name: PropTypes.string,
  /** inline表示するDOM要素 */
  children: PropTypes.object,
  /** ポップアップ表示 */
  annotation: PropTypes.array,
  /** disabled属性 */
  disabled: PropTypes.bool,
  /** コールバック promiseを返却すること */
  onClick: PropTypes.func,
};
