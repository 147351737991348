import * as React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import {
  SvgNextPageIcon,
} from '../index';

export default class MnPagenation extends React.Component {
  render () {
    const pages = new Array(this.props.total).fill().map((_, index, self) => {
      const num = index + 1;
      if (this.props.size > 0 && num !== 1 && num !== this.props.total) {
        if (num < this.props.current - this.props.size || num > this.props.current + this.props.size) {
          return { key: uuidv4(), value: 'skip' };
        }
      }
      return { key: uuidv4(), value: num };
    }).filter((item, index, self) => {
      return (self[index - 1])?.value !== item.value;
    });
    return (<div className={`c-mn-paging ${this.props.class}`}>
      <ul>
        <li className="c-mn-paging__prev">{ this.props.current > 1 ? (<a onClick={ () => { this.props.onChange(1); } }>{SvgNextPageIcon}</a>) : (<span>{SvgNextPageIcon}</span>) }</li>
        { pages.map(item => {
          if (item.value === 'skip') {
            return (<li key={item.key} className="c-mn-paging__center"><span>･･･</span></li>);
          }
          return (<li key={item.key} className={ item.value === this.props.current ? 'c-mn-paging__current' : ''}><a onClick={ () => { this.props.onChange(item.value); } }>{ item.value }</a></li>);
        })}
        <li className="c-mn-paging__next">{ this.props.current < this.props.total ? (<a onClick={ () => { this.props.onChange(this.props.total); } }>{SvgNextPageIcon}</a>) : (<span>{SvgNextPageIcon}</span>) }</li>
      </ul>
    </div>);
  }
}

MnPagenation.propTypes = {
  class: PropTypes.string, // クラス名
  current: PropTypes.number, // 現在のページ
  total: PropTypes.number, // ページ数
  size: PropTypes.number, // ページ表示幅(0で全表示)
  onChange: PropTypes.func, // コールバック
};

MnPagenation.defaultProps = {
  current: 1,
  total: 1,
  size: 2,
  onChange: () => {},
};
