import * as React from 'react';

export default class Footer extends React.Component {
  render () {
    return (
      <div className="p-footer">
        <div className="nav">
          <div><a target="_blank" rel="noreferrer" href="https://medicalnote.co.jp/">Medical Noteとは</a></div>
          <div><a target="_blank" rel="noreferrer" href="https://medicalnote.co.jp/privacy">プライバシーポリシー</a></div>
          <div><a target="_blank" rel="noreferrer" href="https://forms.gle/ageLDdgYtjKbLw2w7">お問い合わせ</a></div>
        </div>
        <span>© MedicalNote, Inc. All Rights Reserved.</span>
      </div>
    );
  }
}
