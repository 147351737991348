import * as React from 'react';
import {
  RootContext,
  AbstractComponent,
} from '../../../index';

export default class TreatmentsReferringInstitution extends AbstractComponent {
  static contextType = RootContext;

  render () {
    const {
      treatment,
    } = this.context;

    return (
      <div className={ this.context.isFullscreen ? 'd-n' : 'p-patient is-relative' }>
        <div className="p-patient__information">
          <div className="mb-lg is-flex jc-sb">
            <div>
              <span className={`c-mn-tag p-treatment-state__tag--${this.context.state}`}>{this.context.state_i18n}</span>
            </div>
          </div>
          <ul>
            <li className="columns is-vcentered">
              <div className="column c-mn-form-label">紹介元医師名</div>
              <div className="column lh-md">{treatment.referring_institution.doctor_name}</div>
            </li>
            <li className="columns is-vcentered">
              <div className="column c-mn-form-label">紹介元診療科</div>
              <div className="column lh-md">{treatment.referring_institution.clinical_department || '-'}</div>
            </li>
            <li className="columns is-vcentered">
              <div className="column c-mn-form-label">紹介元医療機関名</div>
              <div className="column lh-md">{treatment.referring_institution.name}</div>
            </li>
            <li className="columns is-vcentered">
              <div className="column c-mn-form-label">紹介元メールアドレス</div>
              <div className="column lh-md">{treatment.referring_institution.email}</div>
            </li>
            <li className="columns is-vcentered">
              <div className="column c-mn-form-label">紹介元電話番号</div>
              <div className="column lh-md">{treatment.referring_institution.telephone}</div>
            </li>
            <li className="columns is-vcentered">
              <div className="column c-mn-form-label">紹介元FAX番号</div>
              <div className="column lh-md">{treatment.referring_institution.fax || '-'}</div>
            </li>
            <li className="columns is-vcentered">
              <div className="column c-mn-form-label">紹介元担当者部署</div>
              <div className="column lh-md">{treatment.referring_institution.staff_department || '-'}</div>
            </li>
            <li className="columns is-vcentered">
              <div className="column c-mn-form-label">紹介元担当者氏名</div>
              <div className="column lh-md">{treatment.referring_institution.staff_name || '-'}</div>
            </li>
          </ul>
          <div className="mt-md">
            <div className="columns fz12">
              <div className="column is-6">ID: {treatment.request_id}</div>
              <div className="column is-7">{treatment.patient.created_at}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
