export const AccountDeleteLogValidators = {
  account_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  account: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  account_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
  deleted_account_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  deleted_account: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  deleted_account_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
};
export const AccountPasswordChangelogValidators = {
  account_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  account: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  account_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
};
export const AccountsRolesI18n = [
  {
    key: 'normal',
    value: '一般',
  },
  {
    key: 'manager',
    value: '管理者',
  },
];
export const AccountsRoles = [
  {
    key: 'normal',
    value: 'accounts.roles.normal',
  },
  {
    key: 'manager',
    value: 'accounts.roles.manager',
  },
];
export const AccountValidators = {
  logincode_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
    isLength: { validate: [{ max: 5 }], message: 'validation.up_to_5_characters' },
  },
  logincode: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
    isLength: { validate: [{ max: 5 }], message: '5文字まで入力できます' },
  },
  logincode_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
    isLength: { validate: [{ max: 5 }], message: 'You can enter up to 5 characters.' },
  },
  email_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
    isEmail: { validate: [], message: 'validation.invalid' },
  },
  email: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
    isEmail: { validate: [], message: '不正な値です' },
  },
  email_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
    isEmail: { validate: [], message: 'Invalid value' },
  },
  family_name_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
    isLength: { validate: [{ max: 255 }], message: 'validation.up_to_255_characters' },
  },
  family_name: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
    isLength: { validate: [{ max: 255 }], message: '255文字まで入力できます' },
  },
  family_name_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
    isLength: { validate: [{ max: 255 }], message: 'You can enter up to 255 characters.' },
  },
  given_name_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
    isLength: { validate: [{ max: 255 }], message: 'validation.up_to_255_characters' },
  },
  given_name: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
    isLength: { validate: [{ max: 255 }], message: '255文字まで入力できます' },
  },
  given_name_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
    isLength: { validate: [{ max: 255 }], message: 'You can enter up to 255 characters.' },
  },
  family_name_kana_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
    isLength: { validate: [{ max: 255 }], message: 'validation.up_to_255_characters' },
  },
  family_name_kana: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
    isLength: { validate: [{ max: 255 }], message: '255文字まで入力できます' },
  },
  family_name_kana_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
    isLength: { validate: [{ max: 255 }], message: 'You can enter up to 255 characters.' },
  },
  given_name_kana_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
    isLength: { validate: [{ max: 255 }], message: 'validation.up_to_255_characters' },
  },
  given_name_kana: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
    isLength: { validate: [{ max: 255 }], message: '255文字まで入力できます' },
  },
  given_name_kana_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
    isLength: { validate: [{ max: 255 }], message: 'You can enter up to 255 characters.' },
  },
  password_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
    isLength: { validate: [{ min: 10, max: 32 }], message: 'validation.10_to_32_characters' },
  },
  password: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
    isLength: { validate: [{ min: 10, max: 32 }], message: '10文字以上32文字以下で入力してください' },
  },
  password_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
    isLength: { validate: [{ min: 10, max: 32 }], message: 'Please enter at least 10 characters and no more than 32 characters.' },
  },
  initial_password_token_i18n: {
  },
  initial_password_token: {
  },
  initial_password_token_en: {
  },
};
export const ChecklistValidators = {
  menu_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  menu: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  menu_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
  subject_i18n: {
    isLength: { validate: [{ max: 50 }], message: 'validation.up_to_50_characters' },
  },
  subject: {
    isLength: { validate: [{ max: 50 }], message: '50文字まで入力できます' },
  },
  subject_en: {
    isLength: { validate: [{ max: 50 }], message: 'You can enter up to 50 characters.' },
  },
};
export const ExaminationValidators = {
  treatment_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  treatment: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  treatment_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
  account_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  account: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  account_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
};
export const InstitutionsKindsI18n = [
  {
    key: 'hospital',
    value: '病院',
  },
  {
    key: 'clinic',
    value: 'クリニック・診療所',
  },
];
export const InstitutionsContractStatesI18n = [
  {
    key: 'requested',
    value: '利用申込',
  },
  {
    key: 'agreed',
    value: '利用中',
  },
  {
    key: 'finished',
    value: '利用終了',
  },
];
export const InstitutionsKinds = [
  {
    key: 'hospital',
    value: 'institutions.kinds.hospital',
  },
  {
    key: 'clinic',
    value: 'institutions.kinds.clinic',
  },
];
export const InstitutionsContractStates = [
  {
    key: 'requested',
    value: 'institutions.contract_states.requested',
  },
  {
    key: 'agreed',
    value: 'institutions.contract_states.agreed',
  },
  {
    key: 'finished',
    value: 'institutions.contract_states.finished',
  },
];
export const InstitutionValidators = {
  name_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
    isLength: { validate: [{ max: 255 }], message: 'validation.up_to_255_characters' },
  },
  name: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
    isLength: { validate: [{ max: 255 }], message: '255文字まで入力できます' },
  },
  name_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
    isLength: { validate: [{ max: 255 }], message: 'You can enter up to 255 characters.' },
  },
  name_kana_i18n: {
    isLength: { validate: [{ max: 255 }], message: 'validation.up_to_255_characters' },
  },
  name_kana: {
    isLength: { validate: [{ max: 255 }], message: '255文字まで入力できます' },
  },
  name_kana_en: {
    isLength: { validate: [{ max: 255 }], message: 'You can enter up to 255 characters.' },
  },
  email_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
    isEmail: { validate: [], message: 'validation.invalid' },
  },
  email: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
    isEmail: { validate: [], message: '不正な値です' },
  },
  email_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
    isEmail: { validate: [], message: 'Invalid value' },
  },
  telephone_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  telephone: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  telephone_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
  fax_i18n: {
  },
  fax: {
  },
  fax_en: {
  },
  postal_code_i18n: {
  },
  postal_code: {
  },
  postal_code_en: {
  },
  prefecture_code_i18n: {
    isIn: { validate: [['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47']], message: 'validation.invalid' },
  },
  prefecture_code: {
    isIn: { validate: [['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47']], message: '不正な値です' },
  },
  prefecture_code_en: {
    isIn: { validate: [['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47']], message: 'Invalid value' },
  },
  address_i18n: {
    isLength: { validate: [{ max: 255 }], message: 'validation.up_to_255_characters' },
  },
  address: {
    isLength: { validate: [{ max: 255 }], message: '255文字まで入力できます' },
  },
  address_en: {
    isLength: { validate: [{ max: 255 }], message: 'You can enter up to 255 characters.' },
  },
  homepage_url_i18n: {
    isLength: { validate: [{ max: 255 }], message: 'validation.up_to_255_characters' },
  },
  homepage_url: {
    isLength: { validate: [{ max: 255 }], message: '255文字まで入力できます' },
  },
  homepage_url_en: {
    isLength: { validate: [{ max: 255 }], message: 'You can enter up to 255 characters.' },
  },
  kind_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  kind: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  kind_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
  contract_state_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  contract_state: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  contract_state_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
};
export const InterviewImageValidators = {
  interview_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  interview: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  interview_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
  upload_file_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  upload_file: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  upload_file_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
};
export const InterviewSettingsInputTypesI18n = [
  {
    key: 'textarea',
    value: '自由回答',
  },
  {
    key: 'radio',
    value: '2択（はい・いいえ）',
  },
  {
    key: 'file',
    value: '画像添付',
  },
];
export const InterviewSettingsInputTypes = [
  {
    key: 'textarea',
    value: 'interview_settings.input_types.textarea',
  },
  {
    key: 'radio',
    value: 'interview_settings.input_types.radio',
  },
  {
    key: 'file',
    value: 'interview_settings.input_types.file',
  },
];
export const InterviewSettingValidators = {
  menu_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  menu: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  menu_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
  title_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
    isLength: { validate: [{ max: 50 }], message: 'validation.up_to_50_characters' },
  },
  title: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
    isLength: { validate: [{ max: 50 }], message: '50文字まで入力できます' },
  },
  title_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
    isLength: { validate: [{ max: 50 }], message: 'You can enter up to 50 characters.' },
  },
  annotation_i18n: {
    isLength: { validate: [{ max: 300 }], message: 'validation.up_to_300_characters' },
  },
  annotation: {
    isLength: { validate: [{ max: 300 }], message: '300文字まで入力できます' },
  },
  annotation_en: {
    isLength: { validate: [{ max: 300 }], message: 'You can enter up to 300 characters.' },
  },
  input_type_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  input_type: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  input_type_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
};
export const InterviewsSelectsI18n = [
  {
    key: 'answer_yes',
    value: 'はい',
  },
  {
    key: 'answer_no',
    value: 'いいえ',
  },
];
export const InterviewsSelects = [
  {
    key: 'answer_yes',
    value: 'interviews.selects.answer_yes',
  },
  {
    key: 'answer_no',
    value: 'interviews.selects.answer_no',
  },
];
export const InterviewValidators = {
  treatment_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  treatment: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  treatment_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
  interview_setting_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  interview_setting: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  interview_setting_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
  interview_images_i18n: {
    isLength: { validate: [{ max: 5 }], message: 'validation.up_to_5_characters' },
  },
  interview_images: {
    isLength: { validate: [{ max: 5 }], message: '5文字まで入力できます' },
  },
  interview_images_en: {
    isLength: { validate: [{ max: 5 }], message: 'You can enter up to 5 characters.' },
  },
  text_i18n: {
    isLength: { validate: [{ max: 1000 }], message: 'validation.up_to_1000_characters' },
  },
  text: {
    isLength: { validate: [{ max: 1000 }], message: '1000文字まで入力できます' },
  },
  text_en: {
    isLength: { validate: [{ max: 1000 }], message: 'You can enter up to 1000 characters.' },
  },
};
export const MenuDatetimeRequestsDayOfWeeksI18n = [
  {
    key: 'monday',
    value: 'Monday',
  },
  {
    key: 'tuesday',
    value: 'Tuesday',
  },
  {
    key: 'wednesday',
    value: 'Wednesday',
  },
  {
    key: 'thursday',
    value: 'Thursday',
  },
  {
    key: 'friday',
    value: 'Friday',
  },
  {
    key: 'saturday',
    value: 'Saturday',
  },
  {
    key: 'sunday',
    value: 'Sunday',
  },
];
export const MenuDatetimeRequestsDayOfWeeks = [
  {
    key: 'monday',
    value: 'menu_datetime_requests.day_of_weeks.monday',
  },
  {
    key: 'tuesday',
    value: 'menu_datetime_requests.day_of_weeks.tuesday',
  },
  {
    key: 'wednesday',
    value: 'menu_datetime_requests.day_of_weeks.wednesday',
  },
  {
    key: 'thursday',
    value: 'menu_datetime_requests.day_of_weeks.thursday',
  },
  {
    key: 'friday',
    value: 'menu_datetime_requests.day_of_weeks.friday',
  },
  {
    key: 'saturday',
    value: 'menu_datetime_requests.day_of_weeks.saturday',
  },
  {
    key: 'sunday',
    value: 'menu_datetime_requests.day_of_weeks.sunday',
  },
];
export const MenuDatetimeRequestValidators = {
  menu_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  menu: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  menu_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
  day_of_week_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  day_of_week: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  day_of_week_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
  start_at_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  start_at: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  start_at_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
  end_at_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  end_at: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  end_at_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
};
export const MenuFeeValidators = {
  menu_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  menu: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  menu_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
  name_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
    isLength: { validate: [{ max: 30 }], message: 'validation.up_to_30_characters' },
  },
  name: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
    isLength: { validate: [{ max: 30 }], message: '30文字まで入力できます' },
  },
  name_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
    isLength: { validate: [{ max: 30 }], message: 'You can enter up to 30 characters.' },
  },
  amount_i18n: {
    isInt: { validate: [{ gt: -1 }], message: 'validation.invalid' },
  },
  amount: {
    isInt: { validate: [{ gt: -1 }], message: '不正な値です' },
  },
  amount_en: {
    isInt: { validate: [{ gt: -1 }], message: 'Invalid value' },
  },
};
export const MenusUseInterviewsI18n = [
  {
    key: 'using',
    value: '表示する',
  },
  {
    key: 'not_using',
    value: '表示しない',
  },
];
export const MenusAcceptStripePaymentsI18n = [
  {
    key: 'acceptable',
    value: '受付する',
  },
  {
    key: 'not_acceptable',
    value: '受付しない',
  },
];
export const MenusTreatmentCategoriesI18n = [
  {
    key: 'insurance',
    value: '保険診療',
  },
  {
    key: 'not_insurance',
    value: '自由診療',
  },
];
export const MenusAcceptDatetimeRequestsI18n = [
  {
    key: 'acceptable',
    value: '受付する',
  },
  {
    key: 'not_acceptable',
    value: '受付しない',
  },
];
export const MenusAcceptPublishesI18n = [
  {
    key: 'acceptable',
    value: '表示',
  },
  {
    key: 'not_acceptable',
    value: '非表示',
  },
];
export const MenusAcceptablePeriodsI18n = [
  {
    key: 'before_one_day',
    value: '1日前',
  },
  {
    key: 'before_three_days',
    value: '3日前',
  },
  {
    key: 'before_one_week',
    value: '1週間前',
  },
  {
    key: 'before_two_weeks',
    value: '2週間前',
  },
  {
    key: 'before_three_weeks',
    value: '3週間前',
  },
];
export const SlotMinutesI18n = [
  {
    key: '30',
    value: '30分',
  },
  {
    key: '60',
    value: '60分',
  },
];
export const MenusUseInterviews = [
  {
    key: 'using',
    value: 'menus.use_interviews.using',
  },
  {
    key: 'not_using',
    value: 'menus.use_interviews.not_using',
  },
];
export const MenusAcceptStripePayments = [
  {
    key: 'acceptable',
    value: 'menus.accept_stripe_payments.acceptable',
  },
  {
    key: 'not_acceptable',
    value: 'menus.accept_stripe_payments.not_acceptable',
  },
];
export const MenusTreatmentCategories = [
  {
    key: 'insurance',
    value: 'menus.treatment_categories.insurance',
  },
  {
    key: 'not_insurance',
    value: 'menus.treatment_categories.not_insurance',
  },
];
export const MenusAcceptDatetimeRequests = [
  {
    key: 'acceptable',
    value: 'menus.accept_datetime_requests.acceptable',
  },
  {
    key: 'not_acceptable',
    value: 'menus.accept_datetime_requests.not_acceptable',
  },
];
export const MenusAcceptPublishes = [
  {
    key: 'acceptable',
    value: 'menus.accept_publishes.acceptable',
  },
  {
    key: 'not_acceptable',
    value: 'menus.accept_publishes.not_acceptable',
  },
];
export const MenusAcceptablePeriods = [
  {
    key: 'before_one_day',
    value: 'menus.acceptable_periods.before_one_day',
  },
  {
    key: 'before_three_days',
    value: 'menus.acceptable_periods.before_three_days',
  },
  {
    key: 'before_one_week',
    value: 'menus.acceptable_periods.before_one_week',
  },
  {
    key: 'before_two_weeks',
    value: 'menus.acceptable_periods.before_two_weeks',
  },
  {
    key: 'before_three_weeks',
    value: 'menus.acceptable_periods.before_three_weeks',
  },
];
export const MenuValidators = {
  institution_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  institution: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  institution_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
  title_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
    isLength: { validate: [{ max: 50 }], message: 'validation.up_to_50_characters' },
  },
  title: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
    isLength: { validate: [{ max: 50 }], message: '50文字まで入力できます' },
  },
  title_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
    isLength: { validate: [{ max: 50 }], message: 'You can enter up to 50 characters.' },
  },
  description_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
    isLength: { validate: [{ max: 300 }], message: 'validation.up_to_300_characters' },
  },
  description: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
    isLength: { validate: [{ max: 300 }], message: '300文字まで入力できます' },
  },
  description_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
    isLength: { validate: [{ max: 300 }], message: 'You can enter up to 300 characters.' },
  },
  treatment_category_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  treatment_category: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  treatment_category_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
  accept_datetime_request_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  accept_datetime_request: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  accept_datetime_request_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
  acceptable_period_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  acceptable_period: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  acceptable_period_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
  disclaimer_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
    isLength: { validate: [{ max: 2000 }], message: 'validation.up_to_2000_characters' },
  },
  disclaimer: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
    isLength: { validate: [{ max: 2000 }], message: '2000文字まで入力できます' },
  },
  disclaimer_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
    isLength: { validate: [{ max: 2000 }], message: 'You can enter up to 2000 characters.' },
  },
  treatment_flow_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
    isLength: { validate: [{ max: 2000 }], message: 'validation.up_to_2000_characters' },
  },
  treatment_flow: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
    isLength: { validate: [{ max: 2000 }], message: '2000文字まで入力できます' },
  },
  treatment_flow_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
    isLength: { validate: [{ max: 2000 }], message: 'You can enter up to 2000 characters.' },
  },
  use_request_flags_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  use_request_flags: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  use_request_flags_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
};
export const OpeningInquiriesMeetingExperiencesI18n = [
  {
    key: 'has',
    value: 'あり',
  },
  {
    key: 'has_not',
    value: 'なし',
  },
  {
    key: 'unknown',
    value: 'わからない',
  },
];
export const OpeningInquiriesRequestedTimingsI18n = [
  {
    key: 'soon',
    value: 'すぐにでも',
  },
  {
    key: 'within_1_month',
    value: '1ヶ月以内',
  },
  {
    key: 'within_6_month',
    value: '半年以内',
  },
  {
    key: 'within_1_year',
    value: '1年以内',
  },
  {
    key: 'undecided',
    value: '未定',
  },
];
export const OpeningInquiriesMeetingExperiences = [
  {
    key: 'has',
    value: 'opening_inquiries.meeting_experiences.has',
  },
  {
    key: 'has_not',
    value: 'opening_inquiries.meeting_experiences.has_not',
  },
  {
    key: 'unknown',
    value: 'opening_inquiries.meeting_experiences.unknown',
  },
];
export const OpeningInquiriesRequestedTimings = [
  {
    key: 'soon',
    value: 'opening_inquiries.requested_timings.soon',
  },
  {
    key: 'within_1_month',
    value: 'opening_inquiries.requested_timings.within_1_month',
  },
  {
    key: 'within_6_month',
    value: 'opening_inquiries.requested_timings.within_6_month',
  },
  {
    key: 'within_1_year',
    value: 'opening_inquiries.requested_timings.within_1_year',
  },
  {
    key: 'undecided',
    value: 'opening_inquiries.requested_timings.undecided',
  },
];
export const OpeningInquiryValidators = {
  institution_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  institution: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  institution_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
  meeting_experience_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  meeting_experience: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  meeting_experience_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
  requested_timing_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  requested_timing: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  requested_timing_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
  detail_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
    isLength: { validate: [{ max: 1000 }], message: 'validation.up_to_1000_characters' },
  },
  detail: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
    isLength: { validate: [{ max: 1000 }], message: '1000文字まで入力できます' },
  },
  detail_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
    isLength: { validate: [{ max: 1000 }], message: 'You can enter up to 1000 characters.' },
  },
  institution_name_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  institution_name: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  institution_name_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
  institution_kind_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  institution_kind: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  institution_kind_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
  account_family_name_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
    isLength: { validate: [{ max: 255 }], message: 'validation.up_to_255_characters' },
  },
  account_family_name: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
    isLength: { validate: [{ max: 255 }], message: '255文字まで入力できます' },
  },
  account_family_name_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
    isLength: { validate: [{ max: 255 }], message: 'You can enter up to 255 characters.' },
  },
  account_given_name_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
    isLength: { validate: [{ max: 255 }], message: 'validation.up_to_255_characters' },
  },
  account_given_name: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
    isLength: { validate: [{ max: 255 }], message: '255文字まで入力できます' },
  },
  account_given_name_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
    isLength: { validate: [{ max: 255 }], message: 'You can enter up to 255 characters.' },
  },
  account_family_name_kana_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
    isLength: { validate: [{ max: 255 }], message: 'validation.up_to_255_characters' },
  },
  account_family_name_kana: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
    isLength: { validate: [{ max: 255 }], message: '255文字まで入力できます' },
  },
  account_family_name_kana_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
    isLength: { validate: [{ max: 255 }], message: 'You can enter up to 255 characters.' },
  },
  account_given_name_kana_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
    isLength: { validate: [{ max: 255 }], message: 'validation.up_to_255_characters' },
  },
  account_given_name_kana: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
    isLength: { validate: [{ max: 255 }], message: '255文字まで入力できます' },
  },
  account_given_name_kana_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
    isLength: { validate: [{ max: 255 }], message: 'You can enter up to 255 characters.' },
  },
  email_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
    isEmail: { validate: [], message: 'validation.invalid' },
  },
  email: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
    isEmail: { validate: [], message: '不正な値です' },
  },
  email_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
    isEmail: { validate: [], message: 'Invalid value' },
  },
  telephone_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  telephone: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  telephone_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
};
export const PageSectionValidators = {
  institution_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  institution: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  institution_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
  title_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
    isLength: { validate: [{ max: 30 }], message: 'validation.up_to_30_characters' },
  },
  title: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
    isLength: { validate: [{ max: 30 }], message: '30文字まで入力できます' },
  },
  title_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
    isLength: { validate: [{ max: 30 }], message: 'You can enter up to 30 characters.' },
  },
  description_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
    isLength: { validate: [{ max: 1000 }], message: 'validation.up_to_1000_characters' },
  },
  description: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
    isLength: { validate: [{ max: 1000 }], message: '1000文字まで入力できます' },
  },
  description_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
    isLength: { validate: [{ max: 1000 }], message: 'You can enter up to 1000 characters.' },
  },
};
export const PatientImagesImageTypesI18n = [
  {
    key: 'insurance_card',
    value: 'Insurance card',
  },
  {
    key: 'etc_card',
    value: 'Etc card',
  },
  {
    key: 'patient_registration_card',
    value: 'Patient registration card',
  },
  {
    key: 'document_related_to_referral_letter',
    value: 'Document related to referral letter',
  },
];
export const PatientImagesImageTypes = [
  {
    key: 'insurance_card',
    value: 'patient_images.image_types.insurance_card',
  },
  {
    key: 'etc_card',
    value: 'patient_images.image_types.etc_card',
  },
  {
    key: 'patient_registration_card',
    value: 'patient_images.image_types.patient_registration_card',
  },
  {
    key: 'document_related_to_referral_letter',
    value: 'patient_images.image_types.patient_document_related_to_referral_letter',
  },
];
export const PatientImageValidators = {
  patient_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  patient: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  patient_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
  upload_file_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  upload_file: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  upload_file_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
};
export const PatientsSexesI18n = [
  {
    key: 'not_known',
    value: '不明',
  },
  {
    key: 'male',
    value: '男性',
  },
  {
    key: 'female',
    value: '女性',
  },
];
export const PatientsLanguageCodesI18n = [
  {
    key: 'en',
    value: 'En',
  },
  {
    key: 'ja',
    value: 'Ja',
  },
];
export const PatientsSexes = [
  {
    key: 'not_known',
    value: 'patients.sexes.not_known',
  },
  {
    key: 'male',
    value: 'patients.sexes.male',
  },
  {
    key: 'female',
    value: 'patients.sexes.female',
  },
];
export const PatientsLanguageCodes = [
  {
    key: 'en',
    value: 'patients.language_codes.en',
  },
  {
    key: 'ja',
    value: 'patients.language_codes.ja',
  },
];
export const PatientValidators = {
  family_name_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
    isLength: { validate: [{ max: 255 }], message: 'validation.up_to_255_characters' },
  },
  family_name: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
    isLength: { validate: [{ max: 255 }], message: '255文字まで入力できます' },
  },
  family_name_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
    isLength: { validate: [{ max: 255 }], message: 'You can enter up to 255 characters.' },
  },
  given_name_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
    isLength: { validate: [{ max: 255 }], message: 'validation.up_to_255_characters' },
  },
  given_name: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
    isLength: { validate: [{ max: 255 }], message: '255文字まで入力できます' },
  },
  given_name_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
    isLength: { validate: [{ max: 255 }], message: 'You can enter up to 255 characters.' },
  },
  family_name_kana_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
    isLength: { validate: [{ max: 255 }], message: 'validation.up_to_255_characters' },
  },
  family_name_kana: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
    isLength: { validate: [{ max: 255 }], message: '255文字まで入力できます' },
  },
  family_name_kana_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
    isLength: { validate: [{ max: 255 }], message: 'You can enter up to 255 characters.' },
  },
  given_name_kana_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
    isLength: { validate: [{ max: 255 }], message: 'validation.up_to_255_characters' },
  },
  given_name_kana: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
    isLength: { validate: [{ max: 255 }], message: '255文字まで入力できます' },
  },
  given_name_kana_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
    isLength: { validate: [{ max: 255 }], message: 'You can enter up to 255 characters.' },
  },
  birthday_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  birthday: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  birthday_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
  sex_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  sex: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  sex_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
  email_address_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
    isEmail: { validate: [], message: 'validation.invalid' },
  },
  email_address: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
    isEmail: { validate: [], message: '不正な値です' },
  },
  email_address_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
    isEmail: { validate: [], message: 'Invalid value' },
  },
  telephone_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
    isLength: { validate: [{ max: 16 }], message: 'validation.up_to_16_characters' },
  },
  telephone: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
    isLength: { validate: [{ max: 16 }], message: '16文字まで入力できます' },
  },
  telephone_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
    isLength: { validate: [{ max: 16 }], message: 'You can enter up to 16 characters.' },
  },
  language_code_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  language_code: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  language_code_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
  postal_code_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
    isLength: { validate: [{ max: 16 }], message: 'validation.up_to_16_characters' },
  },
  postal_code: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
    isLength: { validate: [{ max: 16 }], message: '16文字まで入力できます' },
  },
  postal_code_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
    isLength: { validate: [{ max: 16 }], message: 'You can enter up to 16 characters.' },
  },
  prefecture_code_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  prefecture_code: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  prefecture_code_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
  address_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
    isLength: { validate: [{ max: 255 }], message: 'validation.up_to_255_characters' },
  },
  address: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
    isLength: { validate: [{ max: 255 }], message: '255文字まで入力できます' },
  },
  address_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
    isLength: { validate: [{ max: 255 }], message: 'You can enter up to 255 characters.' },
  },
  address_line1_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
    isLength: { validate: [{ max: 255 }], message: 'validation.up_to_255_characters' },
  },
  address_line1: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
    isLength: { validate: [{ max: 255 }], message: '255文字まで入力できます' },
  },
  address_line1_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
    isLength: { validate: [{ max: 255 }], message: 'You can enter up to 255 characters.' },
  },
  address_line2_i18n: {
    isLength: { validate: [{ max: 255 }], message: 'validation.up_to_255_characters' },
  },
  address_line2: {
    isLength: { validate: [{ max: 255 }], message: '255文字まで入力できます' },
  },
  address_line2_en: {
    isLength: { validate: [{ max: 255 }], message: 'You can enter up to 255 characters.' },
  },
  country_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
    isLength: { validate: [{ max: 255 }], message: 'validation.up_to_255_characters' },
  },
  country: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
    isLength: { validate: [{ max: 255 }], message: '255文字まで入力できます' },
  },
  country_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
    isLength: { validate: [{ max: 255 }], message: 'You can enter up to 255 characters.' },
  },
};
export const RequestStopLogsStatesI18n = [
  {
    key: 'closing',
    value: '受付不可',
  },
  {
    key: 'accepting',
    value: '受付可能',
  },
];
export const RequestStopLogsStates = [
  {
    key: 'closing',
    value: 'request_stop_logs.states.closing',
  },
  {
    key: 'accepting',
    value: 'request_stop_logs.states.accepting',
  },
];
export const RequestStopLogValidators = {
  request_stop_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  request_stop: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  request_stop_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
  account_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  account: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  account_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
  request_stop_id_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  request_stop_id: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  request_stop_id_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
  account_id_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  account_id: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  account_id_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
  state_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  state: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  state_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
};
export const RequestStopsStatesI18n = [
  {
    key: 'closing',
    value: '受付不可',
  },
  {
    key: 'accepting',
    value: '受付可能',
  },
];
export const RequestStopsStates = [
  {
    key: 'closing',
    value: 'request_stops.states.closing',
  },
  {
    key: 'accepting',
    value: 'request_stops.states.accepting',
  },
];
export const RequestStopValidators = {
  institution_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  institution: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  institution_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
  institution_id_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  institution_id: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  institution_id_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
  state_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  state: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  state_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
};
export const TreatmentsHasReferralsI18n = [
  {
    key: 'has_not',
    value: '無し',
  },
  {
    key: 'has',
    value: '有り',
  },
];
export const TreatmentsStatesI18n = [
  {
    key: 'requested',
    value: '診療申込',
  },
  {
    key: 'waiting_examination',
    value: '診療待ち',
  },
  {
    key: 'examining',
    value: '診療中',
  },
  {
    key: 'examined',
    value: '診療済み',
  },
  {
    key: 'invoiced',
    value: '請求済み',
  },
  {
    key: 'deposited',
    value: '入金済み',
  },
  {
    key: 'refunded',
    value: '請求取消',
  },
  {
    key: 'canceled',
    value: 'キャンセル',
  },
];
export const TreatmentsHasReferrals = [
  {
    key: 'has_not',
    value: 'treatments.has_referrals.has_not',
  },
  {
    key: 'has',
    value: 'treatments.has_referrals.has',
  },
];
export const TreatmentsStates = [
  {
    key: 'requested',
    value: 'treatments.states.requested',
  },
  {
    key: 'waiting_examination',
    value: 'treatments.states.waiting_examination',
  },
  {
    key: 'examining',
    value: 'treatments.states.examining',
  },
  {
    key: 'examined',
    value: 'treatments.states.examined',
  },
  {
    key: 'invoiced',
    value: 'treatments.states.invoiced',
  },
  {
    key: 'deposited',
    value: 'treatments.states.deposited',
  },
  {
    key: 'refunded',
    value: 'treatments.states.refunded',
  },
  {
    key: 'canceled',
    value: 'treatments.states.canceled',
  },
];
export const TreatmentValidators = {
  patient_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  patient: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  patient_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
  menu_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  menu: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  menu_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
  state_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  state: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  state_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
  has_referral_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  has_referral: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  has_referral_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
  requested_worker_i18n: {
    isLength: { validate: [{ max: 255 }], message: 'validation.up_to_255_characters' },
  },
  requested_worker: {
    isLength: { validate: [{ max: 255 }], message: '255文字まで入力できます' },
  },
  requested_worker_en: {
    isLength: { validate: [{ max: 255 }], message: 'You can enter up to 255 characters.' },
  },
};
export const TwilioCallbackLogsStatusesI18n = [
  {
    key: 'queued',
    value: '送信待ち',
  },
  {
    key: 'failed',
    value: '失敗',
  },
  {
    key: 'sent',
    value: '送信中',
  },
  {
    key: 'delivered',
    value: '送信完了',
  },
  {
    key: 'undelivered',
    value: '送信失敗',
  },
];
export const TwilioCallbackLogsStatuses = [
  {
    key: 'queued',
    value: 'twilio_callback_logs.statuses.queued',
  },
  {
    key: 'failed',
    value: 'twilio_callback_logs.statuses.failed',
  },
  {
    key: 'sent',
    value: 'twilio_callback_logs.statuses.sent',
  },
  {
    key: 'delivered',
    value: 'twilio_callback_logs.statuses.delivered',
  },
  {
    key: 'undelivered',
    value: 'twilio_callback_logs.statuses.undelivered',
  },
];
export const TwilioCallbackLogValidators = {
  treatment_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  treatment: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  treatment_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
  status_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  status: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  status_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
  uri_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
    isLength: { validate: [{ max: 255 }], message: 'validation.up_to_255_characters' },
  },
  uri: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
    isLength: { validate: [{ max: 255 }], message: '255文字まで入力できます' },
  },
  uri_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
    isLength: { validate: [{ max: 255 }], message: 'You can enter up to 255 characters.' },
  },
  error_code_i18n: {
    isLength: { validate: [{ max: 255 }], message: 'validation.up_to_255_characters' },
  },
  error_code: {
    isLength: { validate: [{ max: 255 }], message: '255文字まで入力できます' },
  },
  error_code_en: {
    isLength: { validate: [{ max: 255 }], message: 'You can enter up to 255 characters.' },
  },
  error_message_i18n: {
    isLength: { validate: [{ max: 255 }], message: 'validation.up_to_255_characters' },
  },
  error_message: {
    isLength: { validate: [{ max: 255 }], message: '255文字まで入力できます' },
  },
  error_message_en: {
    isLength: { validate: [{ max: 255 }], message: 'You can enter up to 255 characters.' },
  },
  body_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  body: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  body_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
};
export const UploadFilesImageMimesI18n = [
  {
    key: 'png',
    value: 'Png',
  },
  {
    key: 'jpeg',
    value: 'Jpeg',
  },
  {
    key: 'jpg',
    value: 'Jpg',
  },
  {
    key: 'gif',
    value: 'Gif',
  },
  {
    key: 'pdf',
    value: 'PDF',
  },
];
export const UploadFilesImageMimes = [
  {
    key: 'png',
    value: 'upload_files.image_mimes.png',
  },
  {
    key: 'jpeg',
    value: 'upload_files.image_mimes.jpeg',
  },
  {
    key: 'jpg',
    value: 'upload_files.image_mimes.jpg',
  },
  {
    key: 'gif',
    value: 'upload_files.image_mimes.gif',
  },
  {
    key: 'pdf',
    value: 'upload_files.image_mimes.pdf',
  },
];
export const UploadFileValidators = {
  institution_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  institution: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  institution_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
};
export const ReferringInstitutionValidators = {
  doctor_name_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
    isLength: { validate: [{ max: 255 }], message: 'validation.up_to_255_characters' },
  },
  doctor_name: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
    isLength: { validate: [{ max: 255 }], message: '255文字まで入力できます' },
  },
  doctor_name_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
    isLength: { validate: [{ max: 255 }], message: 'You can enter up to 255 characters.' },
  },
  clinical_department_i18n: {
    isLength: { validate: [{ max: 255 }], message: 'validation.up_to_255_characters' },
  },
  clinical_department: {
    isLength: { validate: [{ max: 255 }], message: '255文字まで入力できます' },
  },
  clinical_department_en: {
    isLength: { validate: [{ max: 255 }], message: 'You can enter up to 255 characters.' },
  },
  name_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
    isLength: { validate: [{ max: 255 }], message: 'validation.up_to_255_characters' },
  },
  name: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
    isLength: { validate: [{ max: 255 }], message: '255文字まで入力できます' },
  },
  name_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
    isLength: { validate: [{ max: 255 }], message: 'You can enter up to 255 characters.' },
  },
  email_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
    isLength: { validate: [{ max: 255 }], message: 'validation.up_to_255_characters' },
    isEmail: { validate: [], message: 'validation.invalid' },
  },
  email: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
    isLength: { validate: [{ max: 255 }], message: '255文字まで入力できます' },
    isEmail: { validate: [], message: '不正な値です' },
  },
  email_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
    isLength: { validate: [{ max: 255 }], message: 'You can enter up to 255 characters.' },
    isEmail: { validate: [], message: 'Invalid value' },
  },
  email_confirmation_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
  },
  email_confirmation: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
  },
  email_confirmation_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
  },
  staff_department_i18n: {
    isLength: { validate: [{ max: 255 }], message: 'validation.up_to_255_characters' },
  },
  staff_department: {
    isLength: { validate: [{ max: 255 }], message: '255文字まで入力できます' },
  },
  staff_department_en: {
    isLength: { validate: [{ max: 255 }], message: 'You can enter up to 255 characters.' },
  },
  staff_name_i18n: {
    isLength: { validate: [{ max: 255 }], message: 'validation.up_to_255_characters' },
  },
  staff_name: {
    isLength: { validate: [{ max: 255 }], message: '255文字まで入力できます' },
  },
  staff_name_en: {
    isLength: { validate: [{ max: 255 }], message: 'You can enter up to 255 characters.' },
  },
};
export const PatientHealthConditionCurrentStates = [
  {
    key: 'waiting_for_reserevation_at_clinic',
    value: 'patient_health_conditions.current_states.waiting_for_reserevation_at_clinic',
  },
  {
    key: 'inpatient_at_clinic',
    value: 'patient_health_conditions.current_states.inpatient_at_clinic',
  },
  {
    key: 'waiting_for_contact_at_home',
    value: 'patient_health_conditions.current_states.waiting_for_contact_at_home',
  },
];
export const PatientHealthConditionWalkingStates = [
  {
    key: 'walk',
    value: 'patient_health_conditions.walking_states.walk',
  },
  {
    key: 'wheelchair',
    value: 'patient_health_conditions.walking_states.wheelchair',
  },
  {
    key: 'stretcher',
    value: 'patient_health_conditions.walking_states.stretcher',
  },
];
export const PatientHealthConditionMetalsStates = [
  {
    key: 'none',
    value: 'patient_health_conditions.metals_states.none',
  },
  {
    key: 'present',
    value: 'patient_health_conditions.metals_states.present',
  },
];
export const PatientHealthConditionInfectionStates = [
  {
    key: 'none',
    value: 'patient_health_conditions.infection_states.none',
  },
  {
    key: 'present',
    value: 'patient_health_conditions.infection_states.present',
  },
  {
    key: 'yet',
    value: 'patient_health_conditions.infection_states.yet',
  },
];
export const PatientHealthConditionInfectionTypeStates = [
  {
    key: 'hb',
    value: 'patient_health_conditions.infection_type_states.hb',
  },
  {
    key: 'hc',
    value: 'patient_health_conditions.infection_type_states.hc',
  },
  {
    key: 'tpha',
    value: 'patient_health_conditions.infection_type_states.tpha',
  },
  {
    key: 'mrsa',
    value: 'patient_health_conditions.infection_type_states.mrsa',
  },
  {
    key: 'other',
    value: 'patient_health_conditions.infection_type_states.other',
  },
];
export const PatientHealthConditionDrugAllergyStates = [
  {
    key: 'none',
    value: 'patient_health_conditions.drug_allergy_states.none',
  },
  {
    key: 'present',
    value: 'patient_health_conditions.drug_allergy_states.present',
  },
];
export const PatientHealthConditionRenalDysfunctionStates = [
  {
    key: 'none',
    value: 'patient_health_conditions.renal_dysfunction_states.none',
  },
  {
    key: 'present',
    value: 'patient_health_conditions.renal_dysfunction_states.present',
  },
];
export const PatientHealthConditionValidators = {
  illness_name_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
    isLength: { validate: [{ max: 255 }], message: 'validation.up_to_255_characters' },
  },
  illness_name: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
    isLength: { validate: [{ max: 255 }], message: '255文字まで入力できます' },
  },
  illness_name_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
    isLength: { validate: [{ max: 255 }], message: 'You can enter up to 255 characters.' },
  },
  medical_history_i18n: {
    isLength: { validate: [{ max: 255 }], message: 'validation.up_to_255_characters' },
  },
  medical_history: {
    isLength: { validate: [{ max: 255 }], message: '255文字まで入力できます' },
  },
  medical_history_en: {
    isLength: { validate: [{ max: 255 }], message: 'You can enter up to 255 characters.' },
  },
  current_course_i18n: {
    isByteLength: { validate: [{ min: 1 }], message: 'validation.required' },
    isLength: { validate: [{ max: 255 }], message: 'validation.up_to_255_characters' },
  },
  current_course: {
    isByteLength: { validate: [{ min: 1 }], message: '必須入力です' },
    isLength: { validate: [{ max: 255 }], message: '255文字まで入力できます' },
  },
  current_course_en: {
    isByteLength: { validate: [{ min: 1 }], message: 'Required' },
    isLength: { validate: [{ max: 255 }], message: 'You can enter up to 255 characters.' },
  },
  height_i18n: {
    isLength: { validate: [{ max: 5 }], message: 'validation.up_to_5_characters' },
    matches: { validate: [/^(\d+(?:\.\d+)?)?$/], message: 'validation.invalid' },
  },
  height: {
    isLength: { validate: [{ max: 5 }], message: '5文字まで入力できます' },
    matches: { validate: [/^(\d+(?:\.\d+)?)?$/], message: '不正な値です' },
  },
  height_en: {
    isLength: { validate: [{ max: 5 }], message: 'You can enter up to 5 characters.' },
    matches: { validate: [/^(\d+(?:\.\d+)?)?$/], message: 'Invalid value' },
  },
  weight_i18n: {
    isLength: { validate: [{ max: 5 }], message: 'validation.up_to_5_characters' },
    matches: { validate: [/^(\d+(?:\.\d+)?)?$/], message: 'validation.invalid' },
  },
  weight: {
    isLength: { validate: [{ max: 5 }], message: '5文字まで入力できます' },
    matches: { validate: [/^(\d+(?:\.\d+)?)?$/], message: '不正な値です' },
  },
  weight_en: {
    isLength: { validate: [{ max: 5 }], message: 'You can enter up to 5 characters.' },
    matches: { validate: [/^(\d+(?:\.\d+)?)?$/], message: 'Invalid value' },
  },
  metals_detail_i18n: {
    isLength: { validate: [{ max: 15 }], message: 'validation.up_to_15_characters' },
  },
  metals_detail: {
    isLength: { validate: [{ max: 15 }], message: '15文字まで入力できます' },
  },
  metals_detail_en: {
    isLength: { validate: [{ max: 15 }], message: 'You can enter up to 15 characters.' },
  },
  infection_detail_i18n: {
    isLength: { validate: [{ max: 15 }], message: 'validation.up_to_15_characters' },
  },
  infection_detail: {
    isLength: { validate: [{ max: 15 }], message: '15文字まで入力できます' },
  },
  infection_detail_en: {
    isLength: { validate: [{ max: 15 }], message: 'You can enter up to 15 characters.' },
  },
  renal_dysfunction_detail_i18n: {
    isLength: { validate: [{ max: 15 }], message: 'validation.up_to_15_characters' },
  },
  renal_dysfunction_detail: {
    isLength: { validate: [{ max: 15 }], message: '15文字まで入力できます' },
  },
  renal_dysfunction_detail_en: {
    isLength: { validate: [{ max: 15 }], message: 'You can enter up to 15 characters.' },
  },
  drug_allergy_detail_i18n: {
    isLength: { validate: [{ max: 15 }], message: 'validation.up_to_15_characters' },
  },
  drug_allergy_detail: {
    isLength: { validate: [{ max: 15 }], message: '15文字まで入力できます' },
  },
  drug_allergy_detail_en: {
    isLength: { validate: [{ max: 15 }], message: 'You can enter up to 15 characters.' },
  },
  etc_i18n: {
    isLength: { validate: [{ max: 255 }], message: 'validation.up_to_255_characters' },
  },
  etc: {
    isLength: { validate: [{ max: 255 }], message: '255文字まで入力できます' },
  },
  etc_en: {
    isLength: { validate: [{ max: 255 }], message: 'You can enter up to 255 characters.' },
  },
};
