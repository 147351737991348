import AgoraRTC from 'agora-rtc-sdk';
import AgoraRTM from 'agora-rtm-sdk';
import EventEmitter from 'events';

export default class AgoraUtil {
  Code = {
    InputDevice: 'E0001',
    InitLocalStream: 'E0002',
    InitClient: 'E0003',
    PublishVideo: 'E0004',
    ElementId: 'E0005',
    SubscribeStream: 'E0006',
    JoinChatChannel: 'E0007',
    LoginChat: 'E0008',
    LeaveChat: 'E0009',
    LogoutChat: 'E0010',
    SetDeviceOutput: 'E0011',
    SetDeviceInput: 'E0012',
    Mute: 'E0013',
    LeaveVideo: 'E0014',
  };

  Message = {
    E0001: 'input device not found.',
    E0002: 'init local stream failed.',
    E0003: 'init failed.',
    E0004: 'publish video stream failed.',
    E0005: 'element id is empty.',
    E0006: 'subscribe stream failed.',
    E0007: 'chat channel join failed',
    E0008: 'chat login failed.',
    E0009: 'chat channel leave failed.',
    E0010: 'chat logout failed.',
    E0011: 'set output device switch failed.',
    E0012: 'set input device switch failed.',
    E0013: 'mute or unmute failed.',
    E0014: 'video channel leave failed.',
  };

  DefaultResolution = '720p';
  agora = {
    account: '',
    uid: new Date().getTime(),
    events: new EventEmitter(),
    video: {
      appId: '',
      client: null,
      localStream: null,
      remoteStreams: [],
      isMute: {
        local: {
          audio: false,
          video: false,
        },
        remote: {
          audio: false,
          video: false,
        },
      },
      device: {
        audio: null,
        video: null,
        speeker: null,
      },
      option: {
        audio: true, // 音声
        video: true, // 動画
        screen: false, // スクリーンキャプチャ
        mode: 'rtc', // 配信モード
        codec: 'vp8', // コーデック
      },
    },
    chat: {
      appId: '',
      token: '',
      client: null,
      channels: {},
      members: [],
    },
  };

  constructor (account, vid, aid, cid) {
    this.agora.account = account;
    this.agora.video.appId = vid;
    this.agora.chat.appId = aid;
    this.agora.chat.token = cid;
  }

  /**
   * エラーメッセージ取得
   */
  getErrorMessage (code, detail = null) {
    return { code: code, message: this.Message[code], detail: detail };
  }

  /**
   * デバイスの取得
   */
  getDevice () {
    return new Promise((resolve, reject) => {
      // navigator.mediaDevices.enumerateDevices().then(items => {
      AgoraRTC.getDevices((items) => {
        const audio = [];
        const video = [];
        const speeker = [];
        items.filter(item => {
          return ['audioinput', 'videoinput', 'audiooutput'].indexOf(item.kind) !== -1;
        }).map((item, index) => {
          if (item.kind === 'audioinput') {
            audio.push({
              name: item.label || `デバイス${index + 1}`,
              value: item.deviceId,
              kind: item.kind,
            });
          } else if (item.kind === 'videoinput') {
            video.push({
              name: item.label || `デバイス${index + 1}`,
              value: item.deviceId,
              kind: item.kind,
            });
          } else if (item.kind === 'audiooutput') {
            speeker.push({
              name: item.label || `デバイス${index + 1}`,
              value: item.deviceId,
              kind: item.kind,
            });
          }
        });
        if (audio.length === 0 || video.length === 0) {
          reject(this.getErrorMessage(this.Code.InputDevice));
        }
        this.agora.video.device.audio = audio[0]; // 初期選択
        this.agora.video.device.video = video[0]; // 初期選択
        this.agora.video.device.speeker = speeker[0]; // 初期選択
        resolve({ audio, video, speeker });
      });
    });
  }

  setVideoDevice (devices) {
    return new Promise((resolve, reject) => {
      for (const key in devices) {
        if (Object.prototype.hasOwnProperty.call(devices, key) && Object.prototype.hasOwnProperty.call(this.agora.video.device, key)) {
          this.agora.video.device[key] = devices[key];
          switch (key) {
          case 'audio':
          case 'video':
            if (this.agora.video.localStream) {
              this.agora.video.localStream.switchDevice(key, devices[key].value, () => {}, e => {
                reject(this.getErrorMessage(this.Code.SetDeviceInput, e));
              });
            }
            break;
          case 'speeker':
            this.agora.video.remoteStreams.map(stream => {
              stream.setAudioOutput(devices[key].value, () => {}, e => {
                reject(this.getErrorMessage(this.Code.SetDeviceOutput, e));
              });
            });
            break;
          }
        }
      }
    });
  }

  /**
   * ビデオオプション選択
   */
  setVideoOptions (opt) {
    for (const key in opt) {
      if (Object.prototype.hasOwnProperty.call(opt, key) && Object.prototype.hasOwnProperty.call(this.agora.video.option, key)) {
        this.agora.video.option[key] = opt[key];
      }
    }
  }

  /**
   * ローカルストリーム作成
   */
  createStream (isCapture = false, resolution = this.DefaultResolution) {
    return new Promise((resolve, reject) => {
      if (this.agora.video.localStream) {
        console.log('unpublish');
        this.agora.video.client.unpublish(this.agora.video.localStream);
        this.agora.video.localStream.stop();
        this.agora.video.localStream.close();
      }
      this.agora.video.option.screen = isCapture;
      const stream = AgoraRTC.createStream({
        audio: this.agora.video.option.audio,
        video: this.agora.video.option.video,
        screen: this.agora.video.option.screen,
        microphoneId: this.agora.video.device.audio.value,
        cameraId: this.agora.video.device.video.value,
      });
      stream.setVideoProfile(resolution);
      // ローカルストリームの再生開始
      stream.init(() => {
        this.agora.video.localStream = stream;
        resolve(stream);
      }, err => {
        reject(this.getErrorMessage(this.Code.InitLocalStream, err));
      });
    });
  }

  /**
   * ローカルストリーム再生
   */
  localPlay (elementId = null, resolution = this.DefaultResolution) {
    return new Promise((resolve, reject) => {
      if (!elementId) {
        reject(this.getErrorMessage(this.Code.ElementId));
      } else if (!this.agora.video.localStream) {
        this.createStream(resolution).then(stream => {
          this.forcePlay(elementId, stream);
          resolve();
        }).catch(e => {
          reject(e);
        });
      } else {
        if (!this.agora.video.localStream.isPlaying()) {
          this.forcePlay(elementId, this.agora.video.localStream);
        }
        resolve();
      }
    });
  }

  /**
   * イベント購読返却
   */
  listenEvents () {
    return this.agora.events;
  }

  /**
   * イベント追加
   * @param {*} type
   * @param {*} event
   */
  addEvent (type, event) {
    this.agora.events.emit(type, event);
  }

  /**
   * ビデオイベント監視開始
   */
  handleVideoEvents () {
    this.agora.video.client.on('error', (e) => {
      this.addEvent('error', e);
    });
    this.agora.video.client.on('peer-leave', (e) => {
      if (e.stream) {
        e.stream.stop(); // 退出したメンバーの映像を停止
      }
      this.addEvent('peer-leave', e);
    });
    this.agora.video.client.on('stream-published', (e) => {
      this.addEvent('stream-published', e);
    });
    this.agora.video.client.on('stream-added', (e) => {
      this.addEvent('stream-added', e);
    });
    this.agora.video.client.on('stream-subscribed', (e) => {
      this.agora.video.remoteStreams.push(e.stream);
      this.addEvent('stream-subscribed', e);
    });
    this.agora.video.client.on('stream-removed', (e) => {
      this.addEvent('stream-removed', e);
    });
    this.agora.video.client.on('stream-unpublished', (e) => {
      this.addEvent('stream-unpublished', e);
    });
    this.agora.video.client.on('onTokenPrivilegeWillExpire', () => {
      this.addEvent('onTokenPrivilegeWillExpire', null);
    });
    this.agora.video.client.on('onTokenPrivilegeDidExpire', () => {
      this.addEvent('onTokenPrivilegeDidExpire', null);
    });
    this.agora.video.client.on('mute-audio', (e) => {
      this.addEvent('mute-audio', e);
    });
    this.agora.video.client.on('unmute-audio', (e) => {
      this.addEvent('unmute-audio', e);
    });
    this.agora.video.client.on('mute-video', (e) => {
      console.log(e);
      this.addEvent('mute-video', e);
    });
    this.agora.video.client.on('unmute-video', (e) => {
      console.log(e);
      this.addEvent('unmute-video', e);
    });
    this.agora.video.client.on('crypt-error', (e) => {
      this.addEvent('crypt-error', e);
    });
    this.agora.video.client.on('error', (e) => {
      this.addEvent('error', e);
    });
    this.agora.video.client.on('exception', (e) => {
      this.addEvent('exception', e);
    });
    this.agora.video.client.on('stream-fallback', (e) => {
      this.addEvent('stream-fallback', e);
    });
    this.agora.video.client.on('stream-updated', (e) => {
      this.addEvent('stream-updated', e);
    });
  }

  /**
   * 動画の配信開始
   * @param {string} channel
   */
  joinVideo (channel, elementId = 'video', resolution = this.DefaultResolution) {
    return new Promise((resolve, reject) => {
      this.agora.video.client = AgoraRTC.createClient({
        mode: this.agora.video.option.mode,
        codec: this.agora.video.option.codec,
      });
      this.agora.video.client.init(this.agora.video.appId, () => {
        this.handleVideoEvents();
        this.agora.video.client.join(this.agora.video.appId, channel, this.agora.account, (uid) => {
          this.agora.uid = uid;
          this.localPlay(elementId, resolution).then(() => {
            this.agora.video.client.publish(this.agora.video.localStream, err => {
              this.agora.events.emit('join_video', this.getErrorMessage(this.Code.PublishVideo, err));
            });
            resolve(uid);
          }).catch(e => {
            this.agora.events.emit('join_video', this.getErrorMessage(this.Code.PublishVideo, e));
            resolve(uid);
          });
        }, err => {
          this.agora.video.client = null; // joinに失敗したらclientは破棄する
          reject(this.getErrorMessage(this.Code.InitClient, err));
        });
      });
    });
  }

  /**
   * 動画の配信開始
   */
  publishVideo () {
    this.agora.video.client.publish(this.agora.video.localStream, err => {
      this.agora.events.emit('join_video', this.getErrorMessage(this.Code.PublishVideo, err));
    });
  }

  /**
   * 動画の配信終了
   */
  leaveVideo () {
    return new Promise((resolve, reject) => {
      if (this.agora.video.client) {
        this.agora.video.client.leave(() => {
          if (this.agora.video.localStream) {
            this.agora.video.localStream.close();
          }
          resolve();
        });
      } else {
        reject(this.getErrorMessage(this.Code.LeaveVideo));
      }
    });
  }

  /**
   * 配信の一時停止/再開
   * @param {string} elementId
   * @param {stream} stream
   */
  play (elementId, stream = this.agora.video.localStream) {
    if (stream) {
      if (stream.isPlaying()) {
        stream.stop();
      } else {
        stream.play(elementId);
      }
    }
  }

  /**
   * 強制配信開始
   * @param {string} elementId
   * @param {stream} stream
   */
  forcePlay (elementId, stream = this.agora.video.localStream, count = 0) {
    if (stream) {
      if (document.querySelector(`#${elementId}`)) {
        if (stream.isPlaying()) {
          stream.stop();
        }
        stream.play(elementId);
      } else {
        setTimeout(() => {
          this.forcePlay(elementId, stream, ++count);
        }, (count > 10) ? 1 : 100);
      }
    }
  }

  /**
   * リモートストリームから対象のUIDをピックアップ
   * @param {*} uid
   */
  getRemoteStream (uid) {
    return this.agora.video.remoteStreams.find(stream => uid === stream.params.streamID);
  }

  /**
   * 他者の配信の購読開始
   * @param {object} stream
   */
  subscribe (stream) {
    return new Promise((resolve, reject) => {
      if (stream.getId() !== this.agora.uid) {
        this.agora.video.client.subscribe(stream, e => {
          resolve(this.getErrorMessage(this.Code.SubscribeStream, e));
        });
        stream.on('player-status-change', (e) => {
          this.addEvent('player-status-change', e);
        });
      }
    });
  }

  /**
   * 配信の購読中止
   * @param {*} stream
   */
  unsubscribe (stream) {
    return new Promise((resolve, reject) => {
      if (stream.getId() !== this.agora.uid) {
        this.agora.video.client.unsubscribe(stream, e => {
          resolve(this.getErrorMessage(this.Code.SubscribeStream, e));
        });
      }
    });
  }

  /**
   * アクセストークンの更新
   * @param {string} videoToken
   * @param {string} chatToken
   */
  renewVideoToken (videoToken, chatToken) {
    this.agora.video.client.renewToken(videoToken);
    this.agora.chat.client.renewToken(chatToken);
  }

  /**
   * ミュート
   */
  mute (mute = true, isAudio = true, isLocal = true) {
    return new Promise((resolve, reject) => {
      // const streams = isLocal ? [this.agora.video.localStream] : this.agora.video.remoteStreams; // いったんリモートのミュート機能は不要
      const streams = [this.agora.video.localStream];
      let ok = false;
      streams.map(stream => {
        if (stream) {
          if (mute) {
            if (isAudio) {
              ok = stream.muteAudio();
            } else {
              ok = stream.muteVideo();
            }
          } else {
            if (isAudio) {
              ok = stream.unmuteAudio();
            } else {
              ok = stream.unmuteVideo();
            }
          }
        }
      });
      if (ok) {
        this.agora.video.isMute[isLocal ? 'local' : 'remote'][(isAudio) ? 'audio' : 'video'] = mute;
        resolve(mute);
      } else {
        reject(this.getErrorMessage(this.Code.Mute));
      }
    });
  }

  /**
   * ボリュームコントロール
   * @param {integer} value 0-100
   */
  setAudioVolume (value) {
    this.agora.video.remoteStreams.map(stream => {
      stream.setAudioVolume(value);
    });
  }

  /** ***************** ここからチャット機能 *******************/

  /**
   * チャットイベント監視開始
   */
  handleChatEvents () {
    this.agora.chat.client.on('ConnectionStateChanged', (...e) => {
      this.addEvent('ConnectionStateChanged', e);
    });
    this.agora.chat.client.on('MessageFromPeer', (...e) => {
      this.addEvent('MessageFromPeer', e);
    });
  }

  handleChatChannelEvents (key) {
    this.agora.chat.channels[key].channel.on('ChannelMessage', (...e) => {
      this.addEvent('ChannelMessage', e);
    });
    this.agora.chat.channels[key].channel.on('MemberJoined', (...e) => {
      if (this.agora.chat.members.indexOf(e[0]) === -1) {
        this.agora.chat.members.push(e[0]);
      }
      this.addEvent('MemberJoined', e);
    });
    this.agora.chat.channels[key].channel.on('MemberLeft', (...e) => {
      const index = this.agora.chat.members.indexOf(e[0]);
      if (index !== -1) {
        this.agora.chat.members.splice(index, 1);
      }
      this.addEvent('MemberLeft', e);
    });
  }

  joinChat (channelName) {
    return new Promise((resolve, reject) => {
      this.agora.chat.client = AgoraRTM.createInstance(this.agora.chat.appId);
      this.handleChatEvents();
      this.agora.chat.client.login({ uid: this.agora.account, token: this.agora.chat.token }).then(() => {
        const channel = {
          channel: this.agora.chat.client.createChannel(channelName),
          joined: false, // channel state
        };
        this.agora.chat.channels[channelName] = channel;
        this.handleChatChannelEvents(channelName);
        channel.channel.join().then(() => {
          channel.joined = true;
          // 既存のメンバーを探して、MemberJoinedイベントに送る
          channel.channel.getMembers().then(members => {
            members.map(member => {
              this.addEvent('MemberJoined', [member]);
            });
          });
          resolve(channel);
        }).catch(e => {
          reject(this.getErrorMessage(this.Code.JoinChatChannel, e));
        });
      }).catch(e => {
        reject(this.getErrorMessage(this.Code.LoginChat, e));
      });
    });
  }

  /**
   * チャンネルへメッセージ送信
   * @param {*} text
   * @param {*} name
   */
  sendChatMessage (text, name = null) {
    // テキストが設定されていない場合は何もしない
    if (!text) return;
    // チャンネル名が未指定の場合は最初のチャンネルを選択する
    if (!name) {
      name = Object.keys(this.agora.chat.channels)[0];
    }
    if (this.agora.chat.channels[name] && this.agora.chat.channels[name].joined) {
      this.agora.chat.channels[name].channel.sendMessage({ text }).then(() => {
        // 自分にコピーを送信する
        this.agora.events.emit('ChannelMessage', [
          { text, messageType: 'TEXT' },
          this.agora.account,
          { isOfflineMessage: false, serverReceivedTs: new Date().getTime() / 1000 },
        ]);
      });
    }
  }

  /**
   * 個人にメッセージ送信
   */
  sendChatPeerMessage (text, peer) {
    // テキストが設定されていない場合は何もしない
    if (!text) return;
    this.agora.chat.client.sendMessageToPeer({ text }, peer.toString()).then(() => {
      // 自分にコピーを送信する
      this.agora.events.emit('MessageFromPeer', [
        { text, messageType: 'TEXT' },
        this.agora.account,
        { isOfflineMessage: false, serverReceivedTs: new Date().getTime() / 1000 },
      ]);
    });
  }

  /**
   * チャット終了
   */
  leaveChat (name = null) {
    return new Promise((resolve, reject) => {
      // チャンネル名が未指定の場合は最初のチャンネルを選択する
      if (!name) {
        name = Object.keys(this.agora.chat.channels)[0];
      }
      this.agora.chat.channels[name].channel.leave().then(() => {
        this.agora.chat.client.logout().then(() => {
          resolve();
        }).catch(e => {
          reject(this.getErrorMessage(this.Code.LogoutChat, e));
        });
      }).catch(e => {
        reject(this.getErrorMessage(this.Code.LeaveChat, e));
      });
    });
  }
}
