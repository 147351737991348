import React from 'react';
import {
  RootContext,
  AbstractForm,
  MnInputText,
  MnInputTextarea,
  MnInputRadioGroup,
  MnInputSelect,
  MnButton,
  OpeningInquiryValidators,
  InstitutionsKindsI18n,
  OpeningInquiriesMeetingExperiencesI18n,
  OpeningInquiriesRequestedTimingsI18n,
  SvgStep1Icon,
  SvgStep2Icon,
  SvgStep3Icon,
  SvgStepArrowIcon,
  KanaValidator,
  TelValidator,
} from '../../../index';

export default class OpeningInquiryForm extends AbstractForm {
  static contextType = RootContext;

  constructor (props) {
    super(props);
    this.state = {
      opening_inquiry: {},
    };
  }

  // 開設問合せの送信
  onSuccess (resolve) {
    this.context.showLoading();
    this.context.fetch(this.context.api_location.register, {
      methods: 'POST',
      body: { opening_inquiry: this.state.opening_inquiry },
    }).then(_ => {
      this.context.onHeaderToast('お問い合わせが完了しました。');
      this.setState({ opening_inquiry: {} });
    }).catch(_ => {
      this.context.onHeaderToast('お問い合わせに失敗しました。入力内容を確認の上、再度送信してください。');
    }).finally(() => {
      this.context.hideLoading();
      resolve();
    });
  }

  render () {
    console.log(this.context, this.state);
    return (
      <section className="p-opening-inquiry--form">
        <div className="mt-60px">
          <div>
            <h1 className="fz32 fw-br ta-c lh135p mb-lg">導入のお問い合わせ</h1>
            <p className="fz16 ta-c lh32px">医療機関関係者専用のお申し込みページです。</p>
            <p className="fz16 ta-c lh32px">お気軽にお問い合わせください。</p>
          </div>

          <div className="p-opening-inquiry--form--step-area d-fx jc-c my-xxl">
            <div className="p-opening-inquiry--form--step-box mx-16px">
              <p className="fz16 fw-b ta-c lh24px mt-md mb-sm">ステップ1</p>
              <p className="fz14 ta-c lh175p">導入お問い合わせ</p>
              <p className="fz14 ta-c lh175p mb-sm">フォームからお申し込み</p>
              <div className="ta-c">{ SvgStep1Icon }</div>
            </div>
            { SvgStepArrowIcon }
            <div className="p-opening-inquiry--form--step-box mx-16px">
              <p className="fz16 fw-b ta-c lh24px mt-md mb-sm">ステップ2</p>
              <p className="fz14 ta-c lh175p">担当者よりご連絡し</p>
              <p className="fz14 ta-c lh175p mb-sm">システムのご案内</p>
              <div className="ta-c">{ SvgStep2Icon }</div>
            </div>
            { SvgStepArrowIcon }
            <div className="p-opening-inquiry--form--step-box mx-16px">
              <p className="fz16 fw-b ta-c lh24px mt-md mb-sm">ステップ3</p>
              <p className="fz14 ta-c lh175p">アカウントを発行し</p>
              <p className="fz14 ta-c lh175p mb-sm">運用開始</p>
              <div className="ta-c">{ SvgStep3Icon }</div>
            </div>
          </div>

          <div className="maw420px m-a my-xxl">
            { this.state.errorMessage && (
              <div className="c-mn-message--error my-lg">
                <svg width="19" height="20" viewBox="0 0 19 20" fill="none" className="c-mn-icon-error" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M9.77995 20C14.8426 20 18.9466 15.8959 18.9466 10.8333C18.9466 5.77068 14.8426 1.66663 9.77995 1.66663C4.71734 1.66663 0.613281 5.77068 0.613281 10.8333C0.613281 15.8959 4.71734 20 9.77995 20ZM10.7745 12.8867H9.33445L9.01945 7.35168L8.94445 5.26668H11.1645L11.0895 7.35168L10.7745 12.8867ZM11.4345 15.4367C11.4345 16.2467 10.8345 16.8767 10.0545 16.8767C9.27445 16.8767 8.68945 16.2467 8.68945 15.4367C8.68945 14.6117 9.27445 13.9967 10.0545 13.9967C10.8345 13.9967 11.4345 14.6117 11.4345 15.4367Z" fill="#000" className="c-mn-icon-error-path"/></svg>
                <div className="whs-pw">
                  { this.state.errorMessage }
                </div>
              </div>
            )}

            <div className="field py-sm">
              <label className="c-mn-form-label">医療機関名</label>
              <MnInputText
                name='institution_name'
                class={ 'c-mn-input-text w100p maw-n' }
                type="text"
                placeholder="医療機関名を入力してください"
                value={ this.state.opening_inquiry?.institution_name }
                validates={ OpeningInquiryValidators.institution_name }
                onValidate={ this.onValidate }
                onChange={ (e) => { this.setState({ opening_inquiry: { ...this.state.opening_inquiry, institution_name: e.value } }); } }
              />
            </div>

            <div className="field py-sm">
              <label className="c-mn-form-label">医療機関種別</label>
              <MnInputRadioGroup
                name="institution_kind"
                value={ this.state.opening_inquiry?.institution_kind }
                groups={ InstitutionsKindsI18n }
                validates={ OpeningInquiryValidators.institution_kind }
                onValidate={ this.onValidate }
                onChange={ (e) => { this.setState({ opening_inquiry: { ...this.state.opening_inquiry, institution_kind: e.value } }); } }
              />
            </div>

            <div className="field py-sm">
              <label className="c-mn-form-label">担当者名</label>

              <div className="columns">
                <div className="column">
                  <MnInputText
                    name='account_family_name'
                    className={ 'c-mn-input-text w100p maw-n' }
                    type="text"
                    placeholder="姓"
                    value={ this.state.opening_inquiry?.account_family_name }
                    validates={ OpeningInquiryValidators.account_family_name }
                    onValidate={ this.onValidate }
                    onChange={ (e) => { this.setState({ opening_inquiry: { ...this.state.opening_inquiry, account_family_name: e.value } }); } }
                  />
                </div>
                <div className="column">
                  <MnInputText
                    name='account_given_name'
                    class={ 'c-mn-input-text w100p maw-n' }
                    type="text"
                    placeholder="名"
                    value={ this.state.opening_inquiry?.account_given_name }
                    validates={ OpeningInquiryValidators.account_given_name }
                    onValidate={ this.onValidate }
                    onChange={ (e) => { this.setState({ opening_inquiry: { ...this.state.opening_inquiry, account_given_name: e.value } }); } }
                  />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <MnInputText
                    name='account_family_name_kana'
                    class={ 'c-mn-input-text w100p maw-n' }
                    type="text"
                    placeholder="セイ"
                    value={ this.state.opening_inquiry?.account_family_name_kana }
                    convertKana={ true }
                    validates={ { ...OpeningInquiryValidators.account_family_name_kana, ...KanaValidator } }
                    onValidate={ this.onValidate }
                    onChange={ (e) => { this.setState({ opening_inquiry: { ...this.state.opening_inquiry, account_family_name_kana: e.value } }); } }
                  />
                </div>
                <div className="column">
                  <MnInputText
                    name='account_given_name_kana'
                    class={ 'c-mn-input-text w100p maw-n' }
                    type="text"
                    placeholder="メイ"
                    value={ this.state.opening_inquiry?.account_given_name_kana }
                    convertKana={ true }
                    validates={ { ...OpeningInquiryValidators.account_given_name_kana, ...KanaValidator } }
                    onValidate={ this.onValidate }
                    onChange={ (e) => { this.setState({ opening_inquiry: { ...this.state.opening_inquiry, account_given_name_kana: e.value } }); } }
                  />
                </div>
              </div>
            </div>

            <div className="field">
              <div className="columns">
                <div className="column">
                  <label className="c-mn-form-label">メールアドレス</label>
                  <MnInputText
                    name='email'
                    class={ 'c-mn-input-text w100p maw-n' }
                    type="text"
                    placeholder="メールアドレス"
                    value={ this.state.opening_inquiry?.email }
                    validates={ OpeningInquiryValidators.email }
                    onValidate={ this.onValidate }
                    onChange={ (e) => { this.setState({ opening_inquiry: { ...this.state.opening_inquiry, email: e.value } }); } }
                  />
                </div>

                <div className="column">
                  <label className="c-mn-form-label">電話番号</label>
                  <MnInputText
                    name='telephone'
                    class={ 'c-mn-input-text w100p maw-n' }
                    type="text"
                    placeholder="電話番号"
                    value={ this.state.opening_inquiry?.telephone }
                    validates={ { ...OpeningInquiryValidators.telephone, ...TelValidator } }
                    onValidate={ this.onValidate }
                    onChange={ (e) => { this.setState({ opening_inquiry: { ...this.state.opening_inquiry, telephone: e.value } }); } }
                  />
                </div>
              </div>
            </div>

            <div className="field">
              <div className="columns">
                <div className="column">
                  <label className="c-mn-form-label">弊社との面談</label>
                  <MnInputSelect
                    name="meeting_experience"
                    value={ this.state.opening_inquiry?.meeting_experience }
                    groups={ OpeningInquiriesMeetingExperiencesI18n }
                    placeholder="選択"
                    validates={ OpeningInquiryValidators.meeting_experience }
                    onValidate={ this.onValidate }
                    onChange={ (e) => { this.setState({ opening_inquiry: { ...this.state.opening_inquiry, meeting_experience: e.value } }); } }
                  />
                </div>
                <div className="column">
                  <label className="c-mn-form-label">導入のご希望時期</label>
                  <MnInputSelect
                    name="requested_timing"
                    value={ this.state.opening_inquiry?.requested_timing }
                    groups={ OpeningInquiriesRequestedTimingsI18n }
                    placeholder="選択"
                    validates={ OpeningInquiryValidators.requested_timing }
                    onValidate={ this.onValidate }
                    onChange={ (e) => { this.setState({ opening_inquiry: { ...this.state.opening_inquiry, requested_timing: e.value } }); } }
                  />
                </div>
              </div>
            </div>

            <div className="field py-sm">
              <label className="c-mn-form-label">お問い合わせ内容</label>
              <MnInputTextarea
                name="detail"
                class={ 'c-mn-textarea rz-n w100p maw-n' }
                rows={ 4 }
                placeholder="お問い合わせ内容を入力してください"
                value={ this.state.opening_inquiry?.detail }
                validates={ OpeningInquiryValidators.detail }
                onValidate={ this.onValidate }
                onChange={ (e) => { this.setState({ opening_inquiry: { ...this.state.opening_inquiry, detail: e.value } }); } }
              />
            </div>

            <div className="field mt-xl mb-60px">
              <MnButton class="m-a c-mn-btn--first maw200px" name="submit-button" onClick={ this.onSave }>
                <span>送信する</span>
              </MnButton>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
